<template>
    <div>
        <div class="container-fluid" style="max-width:800px; margin-left:auto; margin-right:auto; padding-bottom:150px;">
            <div class="row">
                <div v-on:click="closeThisOverlay()" class="col-2 pt-2 text-white">
                    <strong><span style="font-size:24px; line-height:45px;" class="icon-navig_chiudi"></span></strong>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-12">
                    <p class="titolo-piccolo orange-text-white text-center">RECUPERO PASSWORD</p>
                    <p class="mt-5 text-center testo-generico">Inserisci il tuo indirizzo e-mail o il cellulare o nome utente con cui sei registrato per avviare la procedura di recupero della password.</p>
                </div>
                <div class="col-12">
                    <div class="row mb-5 mt-4">
                        <div class="col-6">
                            <button v-on:click="curMethodIdx = 0" style="border-radius:18px;" class="btn btn-block btn-orange testo-bottoni orange-text-white" :class="{ 'btn-orange-orange' : (curMethodIdx == 0), 'btn-outline-gray' : (curMethodIdx != 0) }" href="#">{{ methods[0] }}</button>
                        </div>
                        <div class="col-6">
                            <button disabled v-on:click="curMethodIdx = 1" style="border-radius:18px;" class="btn btn-block btn-orange testo-bottoni orange-text-white" :class="{ 'btn-orange-orange' : (curMethodIdx == 1), 'btn-outline-gray' : (curMethodIdx != 1) }" href="#">{{ methods[1] }}</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-5">
                    <form class="form-signin">
                        <div v-show="curMethodIdx == 0" class="form-label-group">
                            <div class="ico-container"><span class="icon-tools_profilo"></span></div>
                            <input type="email" ref="recoverEmail" class="ico-input" id="recoverEmail" placeholder="Indirizzo Email" >
                        </div>
                        <div v-show="curMethodIdx == 1" class="form-label-group">
                            <div class="ico-container"><span class="icon-telefono"></span></div>
                            <input type="text" ref="recoverPhone" class="ico-input" id="recoverPhone" placeholder="Numero cellulare" >
                        </div>
                    </form>
                </div>
            </div>

            <div class="row">
                <div class="col-12 mt-5">
                    <button v-on:click="checkFieldsAndRecoverPassword()" class="btn mt-5 btn-block btn-orange btn-orange-orange">Recupera</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import popper from 'popper.js'
import axios from 'axios'
import config from '../../config/config_local.js'

export default {
    name: 'PasswordRecover',
    data () {
        return {
            curMethodIdx: 0,
            methods: [
                'VIA EMAIL',
                'VIA SMS'
            ]
        }
    },
    methods: {
        checkFieldsAndRecoverPassword() {
            let valid = true;
            let email = this.$refs.recoverEmail.value.trim().toLowerCase();
            let phone = parseFloat( this.$refs.recoverPhone.value.trim().toLowerCase() );

            if(this.curMethodIdx == 0) {
                if( (email.length == 0) || (email.indexOf('.') == -1) || (email.indexOf('@') == -1) )
                    valid = false;
            } else {
                if(typeof phone != 'number') 
                    valid = false
            }

            if(!valid) {
                this.$root.$refs.pageMan.showAlert('Verificare i dati inseriti.','OK','');
                console.log('dati errati')
                return false;
            } else {
                this.doRecoverPassword();
                return true;
            }
        },

        doRecoverPassword() {
            //sono passati i tre minuti di attesa per questo metodo di recupero password?
            if(this.$root.userCanRecoverPassword(this.curMethodIdx) == false) {
                this.$root.$refs.pageMan.showAlert('Sarà necessario attendere per recuperare nuovamente la password o utilizzare un metodo differente.','OK','');
                return false;
            }

            this.$root.$refs.pageMan.showWaiting('Invio richiesta...');

            let email = this.$refs.recoverEmail.value.trim().toLowerCase();
            let phone = parseFloat( this.$refs.recoverPhone.value.trim().toLowerCase() );

            var value = '';
            var mode = '';

            if(this.curMethodIdx == 0) {
                mode = 'email';
                value = email;
            } else {
                mode = 'sms';
                value = phone;
            }
            axios.post(config.serverUrl + '/user/recoverpassword',{
                email: value,
                destination: mode,
                iyestoken: 'recover'
            })
            .then( (respwd) => {
                this.$root.$refs.pageMan.hideWaiting();
                if(respwd.data.error) {
                    console.log('WARNING! Do recover password response: ');
                    console.log(respwd);
                    this.$root.$refs.pageMan.showAlert('Impossibile recuperare la password. Verificare i dati inseriti.','OK','');
                } else {
                    this.$root.$refs.pageMan.showAlert('Recupero password avviato. <strong>Non richiedere nuovamente il recupero.</strong>, riceverai una mail o un messaggio con le istruzioni per reimpostare la tua password.','OK','');
                }
                this.$root.userDoLogout();
            })
            .catch( (errpwd) => {
                this.$root.$refs.pageMan.hideWaiting();
                console.log('WARNING! Do recover password: ');
                console.log(errpwd);
                this.$root.$refs.pageMan.showAlert('Impossibile recuperare la password. Verificare i dati inseriti.','OK','');
            });
        },

        closeThisOverlay() {
            this.$root.$refs.pageMan.closeOverlay();
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #centro-logo {
        max-width:80%;
    }
</style>
