import axios from 'axios';
import config from '../config/config_local.js'

import PhoneContact from './classes/classPhoneContacts';

export default  {
    data() {
        return {
            cashback: {
                invitations: [],
                userContacts: [],
                cashbackModal: {
                    visible: false,
                    callbackOk: null,
                    callbackCancel: null
                }            
    
            }
        }
    },
    created() {
    },
    methods: {

        _cleanNumber(number) {
            if(number != '') {
                //remove whitespaces
                number = number.replace(/\s/g, "");
                //remove country prefix
                if(number.substr(0,3) == '+39') {
                    number = number.substr(3,255);
                }           
                //delete number if not a mobile number
                if(number.substring(0,1) != "3") {
                    number == '';
                }
            }

            return number;
        },

        cashbackSelectContacts() {
            let pc = new PhoneContact();
            this.cashback.userContacts = [];
            pc.selectContacts("Regala Orange","Inserisci <strong>nome,cognome e contatto</strong> della persona a cui vuoi regalare il periodo gratuito di prova in Orange gratis.")
            .then( async (res) => {
                if(res.contacts) {
                    if(res.mode != 'pick') {
                        //here we have all the phone contacts
                    } else {

                        console.log('ALL CONTACTS:');
                        console.log(res.contacts);
                        res.contacts.forEach( (cont) => {
                            let id = (typeof(cont.id) != 'undefined') ? cont.id : '';
                            let name = (cont.displayName || cont.firstName); // (android || apple)
                            console.log('Invited name: ' + name);
                            let number = '';
                            //first time here it arrives with ALL the contacts
                            console.log('CONTACT DATA:');
                            console.log(cont);
                            if(cont.phoneNumbers.length > 0) {
                                if(typeof cont.phoneNumbers[0].value != 'undefined' ) {
                                    number = cont.phoneNumbers[0].value;
                                } else if(typeof cont.phoneNumbers[0].number != 'undefined') {
                                    number = cont.phoneNumbers[0].number;
                                }
                                
                                console.log('Raw Number0: ' + number);
                                number = this._cleanNumber(number);
                                console.log('Clean number o:' + number);

                                if( (cont.phoneNumbers.length > 1) && (number == '')){
                                    if(typeof cont.phoneNumbers[1].value != 'undefined' ) {
                                        number = cont.phoneNumbers[1].value;
                                    } else if(typeof cont.phoneNumbers[1].number != 'undefined') {
                                        number = cont.phoneNumbers[1].number;
                                    }
                                }
                                console.log('Raw number 1:' + number);
                            }

                            number = this._cleanNumber(number);
                            console.log('Clean number 1:' + number);

                            let email = '';
                            if (cont.emails.length > 0) {
                                if(typeof cont.emails[0].value != 'undefined') {
                                    email = cont.emails[0].value;
                                } else if( typeof cont.emails[0].address != 'undefined') {
                                    email = cont.emails[0].address;
                                }
                            }

                            if( (email.indexOf('@') == -1) || (email.indexOf('.') == -1) ) {
                                email = '';
                            }
                            let birthDay = (cont.birthday) ? cont.birthday : '';
                            let organization = (cont.organizationName) ? cont.organizationName : '';
                            if( (number != '') || (email != '')) {
                                this.cashback.userContacts.push({
                                    id: id,
                                    name: name,
                                    number: number,
                                    email: email,
                                    birtday: birthDay,
                                    organization: organization,
                                    invited: (res.handMade) ? true : false,
                                    already_existing: false
                                });    
                            }
                        });

                        //remove already existing user contacts
                        await Promise.all( this._cashbackRemoveExistingContacts(this.cashback.userContacts) );

                        if(res.handMade)  {
                            console.log('Cashback create invitation (hand made)');
                            //1 contact entered by hand
                            this._cashbackCreateInvitations();
                        } else {
                            console.log('Cashback showing selection panel (hardware made)');
                            //contacts extracted from phone, ask who to invite
                            this.cashbackShowCashbackModal()
                            .then( (res) => {
                                console.log('Selected invitations closed window');
                                this._cashbackCreateInvitations();
                                this.cashback.cashbackModal.visible = false;
                            })
                            .catch( (cancel) => {
                                this.cashback.cashbackModal.visible = false;
                            });    
                        }
                    } //if all contacts or single
                }
            })
            .catch( (err) => {
                console.log('WARNING! ERROR RETRIEVING CONTACTS: ');
                console.log(err);
            });
        },

        cashbackShowCashbackModal() {
            return new Promise( (resolve,reject) => {
                this.cashback.cashbackModal.title = "CONFERMA INVITI";
                this.cashback.cashbackModal.callbackOk = resolve;
                this.cashback.cashbackModal.callbackCancel = reject;
                this.cashback.cashbackModal.visible = true;    
            });
        },

        cashbackGetAndShow() {
            this.$root.showWaiting();

            this.$root.cashbackGetStatus();
            
            //gets the user contest status
            this.$root.paymentsGetUserContest();

            //gets all epurses and show the first one (false for NOT showing the page)
            this.$root.paymentsGetAndShowMyEpurses(false);

            this.$root.$refs.pageMan.showPage('userpresentaamicopage')
        },

        _cashbackRemoveExistingContacts(_contacts) {
            let promises = [];
            _contacts.forEach( (cont) => {
                let newPromise = new Promise( (resolve, reject) => {
                    axios({
                        method: 'POST',
                        url: config.serverUrl + '/user/exists',
                        data: {
                            iyestoken: this.user.iyestoken,
                            email: cont.email,
                            number: cont.number
                        }
                    })
                    .then( (res) => {
                        if(res.data.error == false) {
                            cont.already_existing = res.data.data;             
                            console.log('User ' + cont.email + ' ' + cont.number + ' exists:' + cont.already_existing);               
                        } else {
                            cont.already_existing = false;
                        }
                        resolve();
                    })
                    .catch( (remerr) => {
                        console.log(remerr);
                        cont.already_existing = true;
                        resolve();
                    })
                }, cont);
                promises.push( newPromise );
            });

            return promises;
        },

        _cashbackCreateInvitations() {
            this.$root.$refs.pageMan.showWaiting();
            let invitEntries = [];
            this.cashback.userContacts.forEach( (contact) => {
                if(contact.invited && (!contact.already_existing))
                    invitEntries.push( contact );
            })
            console.log('_cashbackCreateInvitations invitation entries:');
            console.log(invitEntries);
            if(invitEntries.length > 0) {
                axios({
                    method: 'POST',
                    url: config.serverUrl + '/invitation/create',
                    data: {
                        iyestoken: this.user.iyestoken,
                        inviterlocalid: this.user.localUser.id,
                        inviterid: this.user.iyesUserId,
                        invitername: this.user.name + ' ' + this.user.surname,
                        inviteremail: this.user.email,
                        entries: invitEntries
                    }
                })
                .then( (resinv) => {
                    this.$root.$refs.pageMan.hideWaiting();
                    if(resinv.data && resinv.data.data && (resinv.data.error == false)) {
                        let number = invitEntries[0].number;
                        let message = 'Ciao, ti ho regalato un periodo di prova gratis nelle palestre Orange. Clicca https://reborn.orangepalestre.it/gift/' + resinv.data.data;
                        this.$root.$refs.pageMan.showAlert('Invito inviato con successo!','OK','')
                        .then( (ok) => {
                            window.open('whatsapp://send?text=' + message + '&phone=+39' + number,'_system');
                            this.cashbackGetStatus();
                        })
                        .catch( (err) => {
                            window.open('whatsapp://send?text=' + message + '&phone=+39' + number,'_system');
                            this.cashbackGetStatus();
                        });
                    }
                })
                .catch( (reserr) => {
                    this.$root.$refs.pageMan.hideWaiting();
                    console.log('WARNING! Cannot create invitations');
                    console.log(reserr);
                    this.$root.$refs.pageMan.showAlert('Inviti aggiornati correttamente.','OK','');
                });    
            } else {
                this.$root.$refs.pageMan.hideWaiting();
                this.$root.$refs.pageMan.showAlert('Il contatto risulta già registrato in Orange.','OK','');
            }
        },

        cashbackSetMeSubscribed() {
            //if( (this.user.email) && (typeof(this.user.iyesUser.IDReferral) != 'undefined') && (this.user.iyesUser.IDReferral != '') ) {
                let updUrl = config.serverUrl + '/invitation/updatesub';
                axios({
                    method: 'POST',
                    url: updUrl,
                    data: {
                        iyestoken: this.user.iyestoken,
                        email: this.user.email,
                        number: this.user.localUser.phone
                    }
                })
                .then( (resinv) => {
                    if(resinv.data.error == false) {
                        console.log('Updated my invitation.');
                    } else {
                        console.log('Unable to update my invitation.')
                    }
                })
                .catch( (reserr) => {
                    console.log('Unable to update my invitation.')
                });    
            //}
        },

        cashbackSetMeRegistered() {
            //if( (this.user.email) && (typeof(this.user.iyesUser.IDReferral) != 'undefined') && (this.user.iyesUser.IDReferral != '') ) {
                let updUrl = config.serverUrl + '/invitation/updatereg';
                axios({
                    method: 'POST',
                    url: updUrl,
                    data: {
                        iyestoken: this.user.iyestoken,
                        email: this.user.email,
                        phone: ((this.user.iyesUser.Mobile) ? this.user.iyesUser.Mobile : '')
                    }
                })
                .then( (resinv) => {
                    if(resinv.data.error == false) {
                        console.log('Updated my invitation.');
                    } else {
                        console.log('Unable to update my invitation.')
                    }
                })
                .catch( (reserr) => {
                    console.log('Unable to update my invitation.')
                });    
            //}
        },

        cashbackGetStatus() {
            if(this.user.iyestoken) {
                axios.post( config.serverUrl + '/invitation/getstatus', {
                    iyestoken : this.user.iyestoken,
                    userId: this.user.iyesUserId
                })
                .then( (resmy) => {
                    //console.log('Got epurse movements');
                    //console.log(resmy);
                    if(resmy.data.error == false) {
                        this.cashback.invitations = resmy.data.data;
                    } else {
                        console.log('WARNING! Error retrieving cashback status');
                        console.log(resmy);
                        this.$root.hideWaiting();    
                    }
                })
                .catch( (errmy) => {
                    console.log('WARNING! Error retrieving cashback status');
                    console.log(errmy);
                    this.$root.hideWaiting();
                });    
            }             

        }

    }
}