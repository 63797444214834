import axios from 'axios';
import config from '../config/config_local.js'

import moment from 'moment'
import momentIT from 'moment/locale/it'

import Vue from 'vue'

export default  {
    data() {
        return {
            reservation: {
                curReservCategory: 0,
                startDate: null,
                weekOffset: 0,
                weekString: '',
                days: 7,
                reservationSlots: [],
                calendar: [],
                todayReservationSlots: [],
                selectedCalDay: 0,
                activeReservations: [],
                categoryFilter: 'SALA',
                reservationCategories: [],        //all type of reservations available    
            },
            ingresso: {
                prenotazioneDate: new Date(),
                currentReservationString: '',
                curReservationSlotIdx: 0
            }            
        }
    },
    created() {
        this.reservation.startDate = moment();
    },
    methods: {
        reservationReset() {

            this.reservation.curReservCategory = 0;
            this.reservation.startDate = moment();
            this.reservation.weekOffset = 0;
            this.reservation.weekString = '';
            this.reservation.days = 7;
            this.reservation.reservationSlots = [];
            this.reservation.calendar = [];
            this.reservation.todayReservationSlots = [];
            this.reservation.selectedCalDay = 0;
            this.reservation.activeReservations = [];
            this.reservation.categoryFilter = 'SALA';
            this.reservation.reservationCategories = [];

            this.ingresso.prenotazioneDate= new Date();
            this.ingresso.currentReservationString= '';
            this.ingresso.curReservationSlotIdx = 0;

        },

        reservationGetCategories() {
            if(this.user.iyestoken) {
                axios.post( config.serverUrl + '/reservation/categories', {
                    iyestoken : this.user.iyestoken
                })
                .then( (resmy) => {
                    if(resmy.data.error) {
                        console.log("WARNING! Cannot retrieve reservation categories.");
                        console.log(resmy);
                    } else {
                        this.reservation.reservationCategories = resmy.data.data.data;
                        this.reservation.curReservCategory = this.reservation.reservationCategories.filter( cat => cat.Description.includes('SALA'))[0].CategoryID;
                        //console.log('Got reservation categories');
                        //console.log(resmy.data);    
                    }
                })
                .catch( (errmy) => {
                    console.log('WARNING! Cannot retrieve reservation categories');
                    console.log(errmy);
                });    
            }         
        },

        reservationGetServices(_categoryId) {
            if(this.user.iyestoken) {
                axios.post( config.serverUrl + '/reservation/services', {
                    iyestoken : this.user.iyestoken,
                    categoryId: _categoryId
                })
                .then( (resmy) => {
                    if(resmy.data.data.data.length > 0) {
                        //console.log('Got reservation services:' + _categoryId);
                        this.reservationResetCentersServicesId();
                        //console.log(resmy.data.data);
                        resmy.data.data.data.forEach( (service, idx) => {
                            //this.centers[idx].iyes.serviceId = service.ServiceID;
                            this.assignServiceIdToCenter(service);
                        });
                        this.reservationGetSlots();
                        this.reservationGetMine();
                    } else {
                        this.$root.$refs.pageMan.showAlert('Non esistono slot attivi per questa prenotazione.','OK');
                        this.$root.$refs.pageMan.pageBack();
                    }
                })
                .catch( (errmy) => {
                    console.log('WARNING! Cannot retrieve reservation services');
                    console.log(errmy);
                });    
            }         
        },

        //reset all services IDs for all the centers, alcuni centri non hanno tipi di lezioni
        reservationResetCentersServicesId() {
            this.$root.centers.centers.forEach( (center) => {
                center.iyes.serviceId = -1;
            });
        },

        //assign the correct serice ID using center's names
        assignServiceIdToCenter(service) {
            this.$root.centers.centers.forEach( (center) => {
                if(service.Description.trim().toUpperCase().includes( center.name.trim().toUpperCase() )) {
                    center.iyes.serviceId = service.ServiceID;
                }
            })
        },

        reservationFindCategoryIdByName(_name) {
            let i = 0;
            let found = false;
            while(found == false && i < this.reservation.reservationCategories.length) {
                if(this.reservation.reservationCategories[i].Description.includes(_name)) {
                    found = true;
                }

                if(!found)
                    i++;
            }

            if(found) {
                return this.reservation.reservationCategories[i].CategoryID
            } else {
                return 0;
            }
        },

        reservationGetMine() {
            if(this.user.iyestoken) {
                axios.post( config.serverUrl + '/reservation/myreservations', {
                    iyestoken : this.user.iyestoken
                })
                .then( (resmy) => {
                    //console.log('Got my reservations');
                    //console.log(resmy);
                    let reservationServiceID = this.centers.centers[ this.user.centerId ].iyes.serviceId;
                    let reservations = resmy.data.data.data;
                    this.reservation.activeReservations = [];
                    reservations.forEach( (reserv) => {
                        //Commented because we want all reservations, in the new version all reservations are in one page
                        //if(reserv.BookingID == reservationServiceID) {
                            reserv.day = moment(reserv.StartTime).format('ddd').toUpperCase();
                            this.reservation.activeReservations.push( reserv );
                        //}
                    });
                })
                .catch( (errmy) => {
                    this.reservation.activeReservations = [];
                    console.log('WARNING! Cannot retrieve user reservations');
                    console.log(errmy);
                });    
            }         
        },

        reservationCancelReservation(index) {
            let _slot = this.reservation.activeReservations[ index ];
            if(_slot) {
                this.$root.$refs.pageMan.showAlert('Sicuro di voler cancellare questa prenotazione?','SI','NO')
                .then( (si) => {
                    let startDateTime = _slot.StartTime;
                    let endDateTime = _slot.EndTime;
                    axios.post( config.serverUrl + '/reservation/cancel', {
                        iyestoken : this.user.iyestoken,
                        BookingID: _slot.BookingID,
                        IDLesson: _slot.IDLesson,
                        StartTime: startDateTime,
                        EndTime: endDateTime,
                        Type: 0,
                        IDDurata: 0
                    })
                    .then( (resmy) => {
                        if(resmy.data.error) {
                            console.log('WARNING! Cannot cancel reborn reservation.');
                            console.log(resmy);
                            this.$root.$refs.pageMan.showAlert("Errore durante la cancellazione della prenotazione." + resmy.data.data.data,'OK','');    
                        } else {
                            this.reservationGetMine();
                            //aggiorna anche i borsellini, nel caso ci sia stato un rimborso
                            this.paymentsGetAndShowMyEpurses(false);
                            this.paymentsGetVisiteMedicheEpurses();
                            this.$root.$refs.pageMan.showAlert("Prenotazione cancellata con successo!",'OK','');    
                        }
                    })
                    .catch( (errmy) => {
                        console.log('WARNING! Error cancelling user reservation.');
                        console.log(errmy);
                    });        
                });
            }
        },

        reservationGetSlots() {

            if(this.reservation.startDate == null) {
                this.reservation.startDate = moment();
            }
            let _dataInizio = this.reservation.startDate;
            let _numeroGiorni = this.reservation.days;
            if(this.reservation.categoryFilter == 'MEDIC') {
                //le prenotazioni delle visite mediche posso essere fatte anche a 3 settimane
                _numeroGiorni = 21;
            }
            let categoryId = this.reservation.curReservCategory;

            //build up the string that will be visible on the calendar page top
            this.reservation.weekString = moment( _dataInizio ).format('DD MMM');
            this.reservation.weekString = this.reservation.weekString + ' - ' + moment( moment(_dataInizio).add(_numeroGiorni - 1,'days') ).format('DD MMM');
            this.reservation.weekString = this.reservation.weekString.toUpperCase();

            //per iyes gli ingressi in palestra sono dei servizi, gli orari di ingresso sono gli slot dei vari servizi
            if(this.user.iyestoken) {
                axios.post( config.serverUrl + '/reservation/slots', {
                    iyestoken : this.user.iyestoken,
                    categoryId: categoryId,
                    serviceId: this.centers.centers[ this.user.centerId ].iyes.serviceId,
                    startDate: moment(_dataInizio).format('YYYY-MM-DDT00:00:00'),
                    endDate: moment( moment(_dataInizio).add(_numeroGiorni,'days') ).format('YYYY-MM-DDT00:00:00'),
                    numDays: _numeroGiorni,
                    startTime: moment(_dataInizio).format('YYYY-MM-DD') + 'T06:00:00',
                    endTime: moment(_dataInizio).format('YYYY-MM-DD') + 'T23:30:00'
                })
                .then( (resmy) => {
                    //console.log('Reservation slots.');
                    //console.log(resmy.data.data.data);
                    this.reservation.todayReservationSlots = resmy.data.data.data;
                    if(this.reservation.todayReservationSlots.length != 0) {
                        //serve per il calendario ingressi
                        let curResSlot = this.reservation.todayReservationSlots[ this.ingresso.curReservationSlotIdx ]
                        this.ingresso.currentReservationString = moment( curResSlot.StartTime).format('HH:mm');    
                        this._reservationBuildCalendar();
                        this.hideWaiting()
                    } else {
                        this.reservation.calendar = [];
                        this.ingresso.currentReservationString = '';
                        this.hideWaiting()
                    }
                })
                .catch( (errmy) => {
                    console.log('WARNING! Cannot retrieve reservation slots.');
                    console.log(errmy);
                    this.hideWaiting()
                });    
            }         
        },

        _reservationBuildCalendar() {
            this.reservation.calendar = [];
            let today = moment(new Date() ).add(this.reservation.weekOffset,'week');
            this.reservation.selectedCalDay = 0;
            if(this.reservation.categoryFilter != 'MEDIC') {
                //inserisci i 7 giorni di calendario per le lezioni
                for(let i = 0; i < 7; i++) {
                    this.reservation.calendar.push({
                        'date' : today.format('YYYY-MM-DD'),
                        'day': today.format('ddd'),
                        'selected' : (i == 0) ? true : false,
                        'lessons' : this._findReservationLessons(today.format('YYYY-MM-DD'))
                    });
                    today.add(1,'days');
                }    
            } else {
                //gli slot per l eprenotazioni mediche vanno fino a 3 settimane, a differenza delle lezioni che vanno solo a 7 gg
                for(let i = 0; i < 21; i++) {
                    this.reservation.calendar.push({
                        'date' : today.format('YYYY-MM-DD'),
                        'day': today.format('ddd'),
                        'selected' : (i == 0) ? true : false,
                        'lessons' : this._findReservationLessons(today.format('YYYY-MM-DD'))
                    });
                    today.add(1,'days');
                }    
            }

        },

        _findReservationLessons(dateStr) {
            let res = [];
            //console.log(this.reborn.reservationSlots);
            this.reservation.todayReservationSlots.forEach( (slot) => {
                let slotDate = slot.DateLesson.substring(0,10)
                if(slotDate == dateStr) {
                    res.push(slot);
                }
            });

            return res;
        },

        reservationSetCalendarDay(_newDay) {
            this.$root.reservation.selectedCalDay = _newDay;         
            this.$root.reservation.calendar.forEach( (day) => {
                day.selected = false;
            });

            this.$root.reservation.calendar[_newDay].selected = true;
        },

        reservationPrenotaConferma(_slot) {
            if(_slot) {
                if(this.user.iyestoken) {
                    let startDate = _slot.DateLesson.substr(0,10);
                    let startTime = _slot.StartTime.substr(11,8);

                    let testoPrenotazione = (_slot.AdditionalInfo) ? "Vuoi prenotare la lezione di " + _slot.AdditionalInfo : "Vuoi prenotare questo slot?";
                    this.$root.$refs.pageMan.showAlert( testoPrenotazione,'SI','NO')
                    .then( (ok) => {
                        let startDateTime = startDate + "T" + startTime;
                        let endTime = _slot.EndTime.substr(11,8);
                        let endDateTime = startDate + "T" + endTime;
                        axios.post( config.serverUrl + '/reservation/reservationadd', {
                            iyestoken : this.user.iyestoken,
                            BookingID: _slot.IDServizio,
                            IDLesson: _slot.IDLesson,
                            StartTime: startDateTime,
                            EndTime: endDateTime,
                            Type: 0,
                            IDDurata: 0
                        })
                        .then( (resmy) => {
                            if(resmy.data.error) {
                                console.log('WARNING! ERROR creating reborn reservation');
                                console.log(resmy);
                                let message = resmy.data.data.data;
                                if(message.includes('abbonamento') || message.includes('borsellino')) {
                                    this.$root.$refs.pageMan.showAlert('Il abbonamento non è valido oppure il tuo borsellino è scarico. Vuoi verificare adesso?','SI','NO')
                                    .then( (si) => {
                                        this.showPage('userabbonamentopage');
                                    });
                                } else {
                                    this.$root.$refs.pageMan.showAlert(resmy.data.data.data,'OK');    
                                }
                            } else {
                                //console.log('Confirmed reborn reservation');
                                //console.log(resmy);
                                this.reservationGetMine();

                                //aggiorna anche i borsellini coi nuovi valori
                                this.paymentsGetAndShowMyEpurses(false);
                                this.paymentsGetVisiteMedicheEpurses();

                                this.$root.$refs.pageMan.showAlert(_slot.CategoryDescription.replace("***","") + ' prenotata con successo il ' + startDate + ' alle ' + startTime ,'OK');    
                            }
                        })
                        .catch( (errmy) => {
                            console.log('WARNING! Error creating reservation');
                            console.log(errmy);
                        });        
                    });
                }             
            }                     
        },

        reservationPrenotaNoConferma(_slot) {
            if(_slot) {
                if(this.user.iyestoken) {
                    let startDate = _slot.DateLesson.substr(0,10);
                    let startTime = _slot.StartTime.substr(11,8);
                    
                    let startDateTime = startDate + "T" + startTime;
                    let endTime = _slot.EndTime.substr(11,8);
                    let endDateTime = startDate + "T" + endTime;
                    axios.post( config.serverUrl + '/reservation/reservationadd', {
                        iyestoken : this.user.iyestoken,
                        BookingID: _slot.IDServizio,
                        IDLesson: _slot.IDLesson,
                        StartTime: startDateTime,
                        EndTime: endDateTime,
                        Type: 0,
                        IDDurata: 0
                    })
                    .then( (resmy) => {
                        if(resmy.data.error) {
                            console.log('WARNING! ERROR creating reservation');
                            console.log(resmy);
                            let message = resmy.data.data.data;
                            if(message.includes('abbonamento') || message.includes('borsellino')) {
                                this.$root.$refs.pageMan.showAlert('Il abbonamento non è valido oppure il tuo borsellino è scarico. Vuoi verificare adesso?','SI','NO')
                                .then( (si) => {
                                    this.showPage('userabbonamentopage');
                                });
                            } else {
                                this.$root.$refs.pageMan.showAlert(resmy.data.data.data,'OK');    
                            }
                        } else {
                            //console.log('Confirmed reborn reservation');
                            //console.log(resmy);
                            this.reservationGetMine();
                            this.$root.$refs.pageMan.showAlert(_slot.CategoryDescription + ' prenotata con successo il ' + startDate + ' alle ' + startTime ,'OK');    
                        }
                    })
                    .catch( (errmy) => {
                        console.log('WARNING! Error creating reservation');
                        console.log(errmy);
                    });        
                }             
            }                     
        },

    }
}