<template>
    <div>
        <div class="container-fluid">
            <div class="row" style="min-height:40px;">
                <div class="col-12">
                </div>
                <!-- <div v-on:click="closeThisOverlay()" class="col-2 pt-2 text-black">
                    <strong><span style="font-size:24px; line-height:45px;" class="icon-navig_chiudi"></span></strong>
                </div> -->
            </div>
            <div class="row">
                <div class="col-12" v-if="$root.payments.externalIframeModal.visible == true" style="overflow-y:scroll;">
                    <iframe :src="$root.payments.externalIframeModal.url" style="border:none; width:100%; height: 80vh;"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import popper from 'popper.js'
import axios from 'axios'
import config from '../../config/config_local.js'
import Validator from '../../js/classes/classValidators'

export default {
    name: 'PaymentIframe',
    data () {
        return {
        }
    },
    methods: {
        closeThisOverlay() {
            console.log('cancel pay iframe');
            clearInterval(this.$root.payments.pollingIntervalID);
            this.$root.payments.pollingRetries = 0;
            this.$root.payments.paymentsHideExternalIframeModal()
            this.$root.$refs.pageMan.showAlert('Operazione conclusa.','OK')
            .then( (ok) => {
                this.$root.$refs.pageMan.closeOverlay();
                this.$root.getMe();
                this.$root.$refs.pageMan.resetPagesStack('mainpage');
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #centro-logo {
        max-width:80%;
    }
</style>
