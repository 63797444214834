import Vue from 'vue'

//import * as Sentry from "@sentry/vue";
//import { Integrations } from "@sentry/tracing";

//Sentry.init({
//    Vue,
//    dsn: "https://52d81e30f20c41ec85fb3c3ce8a3fe8b@o983774.ingest.sentry.io/5939699",
//    integrations: [
//      new Integrations.BrowserTracing({
//        //routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//        tracingOrigins: ["localhost", "reborn.orangepalestre.it", /^\//],
//      }),
//    ],
//    // Set tracesSampleRate to 1.0 to capture 100%
//    // of transactions for performance monitoring.
//    // We recommend adjusting this value in production
//    tracesSampleRate: 1.0,
//}); 

//import App from './App.vue'
import PageManager from './components/PageManager.vue'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'

import '@splidejs/splide/dist/css/splide.min.css'

import './scss/_main.scss'

import moment from 'moment'
import momentIT from 'moment/locale/it' 


import User from './js/UserMixin.js'
import Preferences from './js/PreferencesMixin'
import Evofit from './js/EvofitMixin'
import Products from './js/ProductsMixin'
import Abbonamenti from './js/AbbonamentiMixin'
import Reborn from './js/RebornMixin'
import Payments from './js/PaymentsMixin'
import Reservation from './js/ReservationMixin'
import Centers from './js/CentersMixin'
import Registration from './js/RegistrationMixin'
import Sliders from './js/SlidersMixin'
import RBOCalculator from './js/RBOCalculatorMixin'
import Preferiti from './js/PreferitiMixin'
import Documents from './js/DocumentsMixin'
import Cashback from './js/CashBackMixin'
import UIManager from './js/UIMixin'
import Questionario from './js/QuestionarioMixin'

import AppleMixin from './js/AppleMixin'

import config from './config/config_local'
import lsPing from './js/classes/classPing'
import noSleep from 'nosleep.js'

Vue.config.productionTip = false
Vue.prototype.moment = moment;


new Vue({
    el: '#globalvueapp',
    name: 'GlobalApp',
    mixins:[
        User,
        Preferences,
        Evofit,
        Products,
        Abbonamenti,
        Reborn,
        Payments,
        Reservation,
        Centers,
        Registration,
        Sliders,
        RBOCalculator,
        Preferiti,
        Documents,
        Cashback,
        UIManager,
        Questionario,
        AppleMixin
    ],
    components: { 
        PageManager
    },
    template: '<PageManager ref="pageMan"/>',
    data() {
        return {
            waiting: {
                visible: false
            },
            newtworkInfo : {
                duration: 0,
                kbps: 0,
                mbps: 0                
            },
            noSleep: null,
            noSleepEnabled: false,

            deviceRunning: null,
            isApple: config.isApple,
            isAndroid: config.isAndroid,
            isWeb: config.isWeb,
        
            urlParams: null

        }
    },
    created() {

        this.uiCheckVersion();
        
        if(window.hasOwnProperty('cordova')) {
            //For cordova app
            console.log('Cordova FOUND!');
            document.addEventListener("deviceready", () => {
                console.log("Checking login with biometrics");
                this.$root.checkBiometrics();

                this.initializeCastApi();
                this.deviceRunning = window.device;
                let platformStr = device.platform;
                platformStr = platformStr.toLowerCase();
                if(platformStr.includes('ios') || platformStr.includes('iphone') || platformStr.includes('ipad')) {
                    this.isApple = true;
                    console.log('APPLE device found');
                }

            });

            document.addEventListener("backbutton", this.pageBack , false);

            if(window.MobileAccessibility){
                window.MobileAccessibility.usePreferredTextZoom(false);
            }            
        } else {
            //for web
            console.log('Cordova NOT found!');
            document.addEventListener('DOMContentLoaded', this.initializeCastApi );            
        }

        //start the UI update interval
        this.uiInitUpdate();

        this.getNetworkInfo();

        this.noSleep = new noSleep();

        if(navigator.userAgent) {
            console.log('Cookies Enabled: ' + navigator.cookieEnabled);
            console.log('Language: ' + navigator.language);
            console.log('Online: ' + navigator.onLine);
            console.log('User Agent: ' + navigator.userAgent);
        }

        console.log("Orange Client Version: " + config.version.major + "." + config.version.minor );        
        console.log('Running in ' + config.mode.toUpperCase() + ' mode.');

        //retrieve the URL query if any and store it in this.urlParams
        this.retrieveUrlParams();

        if(this.urlParams.has('op') && this.urlParams.has('email') && this.urlParams.has('code') && (this.urlParams.get('op') == 'cp')) {
            //when the user asks for a password change it gets an url like:
            // https://reborn.orangepalestre.it?op=cp&email=testo@test.com&code=tmppass
            window.localStorage.removeItem('iyestoken');
            this.userResetPasswordProcedure(this.urlParams.get('email'), this.urlParams.get('code'));
        } else if(this.urlParams.has('op') && this.urlParams.has('email') && (this.urlParams.get('op') == 'login')) {
            setTimeout( () => {
                this.$root.user.loginEmail = this.urlParams.get('email').trim();
                //makes the email field readonly
                this.$root.user.readOnlyEmail = true;
                console.log('Found login request.');
                this.showPage('loginpage');
                this.hideWaiting();    
            }, 3000);
        } else {
            this.userTryLocalStorageLogin();
        }

    },

    methods: {

        retrieveUrlParams() {
            let queryString = window.location.search;
            this.urlParams = new URLSearchParams(queryString);
        },

        getNetworkInfo() {
            let ping = new lsPing();
            ping.ping()
            .then( (res) => {
                console.log('Network Info:');
                //console.log(res);
                this.newtworkInfo = res;
            })
            .catch( (err) => {
                console.log('Cannot get network info');
                this.newtworkInfo = {
                    duration: 30,
                    kbps:0,
                    mbps: 0                
                };
            });
        },

        showPage(_newPage, _push = true) {
            this.$refs.pageMan.showPage(_newPage, _push);
        },

        pageBack() {
            if(typeof(this.$root.$refs.pageMan) != 'undefined') {
                this.$root.$refs.pageMan.pageBack();
            }
        },

        getCurPage() {
            if(this.$refs.pageMan) {
                return this.$refs.pageMan.curPage;
            } else {
                return '';
            }
        },

        showMain() {
            if(!this.centerDefaultCenterExists()) {
                //this page doesn't need to be in the pages stack
                this.centersShowSceltaCentro('scelta-centro');
                return;
            } 

            this.$refs.pageMan.resetPagesStack('mainpage');
        },

        showWaiting(_message) {
            if(typeof _message == 'undefined')
                _message = 'CARICAMENTO';

            if(this.$refs.pageMan)
                this.$refs.pageMan.showWaiting(_message);
        },

        hideWaiting() {
            this.$refs.pageMan.hideWaiting();
        },

        //shown when a apple user without a subscription tries to do something that requires a subscription
        showAppleMessage() {
            //let freeProd = this.$root.productExistsFree();
            //if(freeProd == null) {
                this.$refs.pageMan.showAlert('Per utilizzare questa funzione devi verificare il tuo account in un centro Orange.','LISTA CENTRI','CHIUDI')
                .then( (vai) => {
                    this.showPage('listacentripage');
                })
                .catch( (chiudi) => {

                });
            /* } else {
                this.$refs.pageMan.showAlert('Per accedere a tutte le funzionalità dell\'app devi attivare il tuo account. Esiste un abbonamento gratuito, lo vuoi attivare adesso?','SI','NO')
                .then( (ressi) => {
                    this.paymentCreateProductsOrder(freeProd);
                })
                .catch( (resno) => {

                });
            } */
        }


    }
});
