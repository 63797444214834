<template>
    <div v-if="visible == true" id="bottomtoolbar">
        <div class="bottom-toolbar toolbar-orange">
            <div v-if="darkMode == true" class="row">
                <div class="col-12 p-0 m-0">
                    <div class="bottombar-iconcontainer">
                        <div v-if="elements.newsButton == true" v-on:click="$root.$refs.pageMan.resetPagesStack('mainpage')" :class="{ 'bottombar-enabled' : ($root.ui.iconBottomBar == 'main') }">
                            <strong><span style="font-size:24px; line-height:45px;" class="icon-tab_home"></span></strong>
                        </div>
                    </div>

                    <div class="bottombar-iconcontainer">
                        <div v-if="elements.homeButton == true" v-on:click="$root.$refs.pageMan.resetPagesStack('reborncategorypage')" :class="{ 'bottombar-enabled' : ($root.ui.iconBottomBar == 'reborn') }">
                            <strong><span style="font-size:24px; line-height:45px;" class="icon-tab_ondemand"></span></strong>
                        </div>
                    </div>

                    <div class="bottombar-iconcontainer">
                        <div v-if="elements.cercaButton == true" v-on:click="$root.$refs.pageMan.resetPagesStack('searchpage')" :class="{ 'bottombar-enabled' : ($root.ui.iconBottomBar == 'cerca') }">
                            <strong><span style="font-size:24px; line-height:45px;" class="icon-tab_cerca"></span></strong>
                        </div>
                    </div>

                    <div class="bottombar-iconcontainer">
                        <div v-if="elements.schedeButton == true" v-on:click="$root.$refs.pageMan.resetPagesStack('trainermainpage')" :class="{ 'bottombar-enabled' : ($root.ui.iconBottomBar == 'trainer') }">
                            <strong><span style="font-size:24px; line-height:45px;" class="icon-tab_programmi"></span></strong>
                        </div>
                    </div>

                    <div class="bottombar-iconcontainer">
                        <div v-if="elements.newsButton == true" v-on:click="$root.prodottiShowNews(true)" :class="{ 'bottombar-enabled' : ($root.ui.iconBottomBar == 'info') }">
                            <strong><span style="font-size:24px; line-height:45px;" class="icon-tab_esprola"></span></strong>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BottomToolbar',
    data () {
        return {
            visible: false,
            darkMode: true,
            elements: {
                solidBg: false,
                newsButton: true,
                homeButton: true,
                cercaButton: true,
                schedeButton: true
            }
        }
    },
    methods: {
        hide() {
            this.visible = false;
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
