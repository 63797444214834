<template>
    <div>
        <div v-if="$root.subscriptions.abbonamentoProduct != null" class="container-fluid">
            <div class="row mt-5 mb-0">
                <!-- <div class="col-12 my-5 text-center">
                    <p class="titolo-piccolo orange-text-black text-center mt-4 mb-2 pb-0" style="text-transform:uppercase;">GESTISCI ABBONAMENTO</p>
                    <p class="testo-generico orange-text-black text-center" >Puoi modificare la tipologia del tuo abbonamento facendo un Upgrade verso un abbonamento migliore o un Downgrade verso un abbonamento più economico.</p>
                </div> -->
                <div class="col-12">
                    <p class="testo-generico orange-text-black text-center" ><strong>Abbonamento attivo</strong></p>
                    <p class="titolo-piccolo orange-text-black text-center mt-4 mb-2 pb-0" style="text-transform:uppercase;">{{ $root.subscriptions.currentAbbonamentoName }}</p>
                </div>
                <div class="col-12">
                    <!-- <p class="testo-generico orange-text-black text-center" ><strong>{{ $root.subscriptions.abbonamentoProduct.Price }} € al mese</strong></p> -->
                    <p class="testo-generico orange-text-black text-center" style="text-align:justify;" v-html="$root.subscriptions.abbonamentoProduct.Description.replaceAll('<br>', '')"></p>
                </div>
                <div v-if="$root.subscriptions.sospensione.sospensioniAttive.length != 0" class="col-12 my-4">
                    <p class="testo-generico orange-text-red text-center mb-2" ><strong>Sospensione attiva:</strong></p>
                    <p class="testo-generico orange-text-black text-center mb-2" style="text-align:justify;">
                        <!-- <span style="font-size:0.8rem;" class="testo-generico orange-text-red">{{ sosp.name }}</span><br> -->
                        <span style="font-size:0.8rem;" class="texto-generico orange-text-black">Dal {{ $root.subscriptions.sospensione.sospensioniAttive[0].inizio.format('DD/MM/YYYY') }} - Al {{ $root.subscriptions.sospensione.sospensioniAttive[0].fine.format('DD/MM/YYYY') }}</span>
                    </p>
                </div>
                <div v-if="$root.subscriptions.disdetta.abbonamentiLista.length != 0" class="col-12 my-4">
                    <p class="testo-generico orange-text-black text-center mb-0" ><strong>Prossima data di fatturazione</strong></p>
                    <p class="testo-generico orange-text-black text-center" style="text-align:justify;">{{ $root.subscriptions.scadenzaAbbonamento.format('DD/MM/YYYY') }}</p>
                </div>
                <div v-else class="col-12 my-4">
                    <p class="testo-generico orange-text-red text-center mb-0" ><strong>ABBONAMENTO DISDETTO</strong></p>
                    <p class="testo-generico orange-text-black text-center mb-0" ><strong>Fine validità:</strong></p>
                    <p class="testo-generico orange-text-black text-center" style="text-align:justify;">{{ $root.subscriptions.scadenzaAbbonamento.format('DD/MM/YYYY') }}</p>
                </div>
            </div>

            <div v-if="$root.getIYESSettingByName('WEBAbilitaSospensioni') == true" class="row mt-0 mb-3">
                <div v-if="$root.payments.rateScadute == false" class="col-12 mt-0 testo-generico orange-text-black text-center">
                    <p v-on:click.stop="$root.showPage('userdisdettepage')" class="orange-link-dark" style="text-decoration-style:dashed;">Disdici o Sospendi</p>
                </div>
            </div>

        </div>
        <div v-else class="container-fluid">
            <div class="row mt-5 mb-0">
                <div class="col-12 my-5 text-center">
                    <p class="titolo-piccolo orange-text-black text-center mt-4 mb-2 pb-0" style="text-transform:uppercase;">GESTISCI ABBONAMENTO</p>
                    <p v-if="$root.isApple == false" class="testo-generico orange-text-black text-center" >Non hai alcun abbonamento attivo attualmente. Seleziona un abbonamento da quelli disponibili.</p>
                    <p v-if="$root.isApple == true" class="testo-generico orange-text-black text-center" >Non hai alcun abbonamento attivo al momento. Recati in un centro Orange per procedere con l'attivazione.</p>
                </div>
            </div>            
        </div>
        <div class="container-fluid" style="max-width:800px; margin-left:auto; margin-right:auto;">
            <div v-if="$root.payments.currentEpursePayments.length > 0" class="row mt-0">
                <div class="col-12 mt-0">
                    <div id="rateaccordion">
                        <div class="card orange-text-white border-0">
                            <div v-if="$root.payments.rateScadute == false" class="card-header collapsed p-0" :id="'rateheading-' + 0">
                                <h5 class="mb-0">
                                    <button v-on:click="toggleAccordion(0)" class="btn btn-block btn-orange btn-outline-white orange-text-black" data-toggle="collapse" :data-target="'#ratecoll-' + 0" aria-expanded="true" aria-controls="collapseOne">
                                        RATE PAGAMENTI
                                        <span style="float:right;" v-show="(openAccordion.hasOwnProperty(0) ) && (openAccordion[0].open)" class="orange-ico orange-ico-dark"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_apri"></span></span>
                                        <span style="float:right;" v-show="(!(openAccordion.hasOwnProperty(0))) || (!openAccordion[0].open)" class="orange-ico orange-ico-dark"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_chiudi"></span></span>
                                    </button>
                                </h5>
                            </div>
                            <div v-if="$root.payments.rateScadute == true" class="card-header collapsed p-0 orange-text-white" :id="'rateheading-' + 0">
                                <h5 class="mb-0">
                                    <button v-on:click="toggleAccordion(0)" class="btn btn-block btn-orange background-red orange-text-white" data-toggle="collapse" :data-target="'#ratecoll-' + 0" aria-expanded="true" aria-controls="collapseOne" style="color:#fff;">
                                        <span v-if="$root.isApple == false">PAGA RATE</span>
                                        <span v-if="$root.isApple == true">RATE SCADUTE</span>
                                        <span style="float:right;" v-show="(openAccordion.hasOwnProperty(0) ) && (openAccordion[0].open)" class="orange-ico orange-ico-light"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_chiudi"></span></span>
                                        <span style="float:right;" v-show="(!(openAccordion.hasOwnProperty(0))) || (!openAccordion[0].open)" class="orange-ico orange-ico-light"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_apri"></span></span>
                                    </button>
                                </h5>
                            </div>

                            <div :id="'ratecoll-' + 0" class="collapse" :aria-labelledby="'heading-' + 0" data-parent="#rateaccordion">
                                <div v-if="$root.isApple == false" class="card-body testo-generico orange-text-black" style="font-size:0.8rem;">
                                    <div class="row" v-for="paym, index in $root.payments.currentEpursePayments" :key="'paym-' + index">
                                        <div class="col-4">
                                            <strong>{{ paym.name }}</strong><br>{{ paym.date }}
                                        </div>
                                        <div class="col-4 text-right">
                                            {{ paym.amount }}&nbsp;&euro;                                            
                                        </div>
                                        <div v-on:click.stop="$root.paymentsCreateRateOrder(index)" class="col-4 text-right orange-link-dark" style="font-size:inherit;">
                                            Paga Ora
                                        </div>
                                    </div>
                                </div>
                                <div v-if="$root.isApple == true" class="card-body testo-generico orange-text-black" style="font-size:0.8rem;">
                                    <div class="row" v-for="paym, index in $root.payments.currentEpursePayments" :key="'paym-' + index">
                                        <div class="col-12 mb-3">
                                            <span class="testo-generico orange-text-red">Recati in un centro Orange per maggiori informazioni.</span>
                                        </div>
                                    </div>
                                    <div class="row" v-for="paym, index in $root.payments.currentEpursePayments" :key="'paym-' + index">
                                        <div class="col-6">
                                            <strong>{{ paym.name }}</strong><br>{{ paym.date }}
                                        </div>
                                        <div class="col-6 text-right">
                                            {{ paym.amount }}&nbsp;&euro;                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-12 mt-0 testo-generico orange-text-black text-center">
                    <span style="text-decoration:underline;">Metodo di pagamento</span>
                    <p v-if="($root.payments.userCards) && ($root.payments.userCards.Sorgente) && ($root.payments.userCards.Sorgente != '')" class="testo-generico orange-text-black">{{ $root.payments.userCards.Sorgente }}</p>
                    <p v-else class="testo-generico orange-text-black">Nessun metodo di pagamento inserito.</p>
                    <span v-if="($root.user.IBAN != null) && ($root.user.IBAN != '')">IBAN</span>
                    <p v-if="($root.user.IBAN != null) && ($root.user.IBAN != '')" class="testo-generico orange-text-black">{{ $root.user.IBAN }}</p>
                </div>
            </div>

            <div v-if="$root.isApple == true" class="row mt-5">
                <div class="col-12 mt-0 testo-generico orange-text-black text-center">
                    <p v-on:click="$root.appleAskDeleteUser()" class="orange-link-dark orange-text-red">Cancella Utente</p>
                </div>
            </div>


            <!-- <div v-if="$root.isApple == false && $root.payments.rateScadute == false" class="row mt-5">
                <div v-if="$root.subscriptions.abbonamentoProduct != null" class="col-12 mt-0 testo-generico orange-text-black text-center">
                    <button v-on:click="$root.showPage('userabbonamentocambiapage', false)" class="btn btn-block btn-orange btn-orange-orange">CAMBIA ABBONAMENTO</button>
                </div>
                <div v-else class="col-12 mt-0 testo-generico orange-text-black text-center">
                    <button v-on:click="$root.showPage('buysubscription', true)" class="btn btn-block btn-orange btn-orange-orange">ABBONATI</button>
                </div>
            </div> -->

        </div>
    </div>
</template>

<script>
import axios from 'axios'
import config from '../../../config/config_local.js'
import Vue from 'vue'

export default {
    name: 'UserAbbonamento',
    data () {
        return {
            openAccordion: []
        }
    },
    methods: {

        toggleAccordion(index) {
            console.log('toggle');
            if(typeof this.openAccordion[index] == 'undefined') {
                Vue.set(this.openAccordion,  index, {
                    open: true
                } );
            } else {
                this.openAccordion[index].open = !this.openAccordion[index].open;
            }
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #centro-logo {
        max-width:80%;
    }
</style>
