import axios from 'axios';
import config from '../config/config_local.js'

import moment from 'moment';
import { Alert } from 'bootstrap';

export default  {
    data() {
        return {
            user: {
                loginEmail : '',    
                loginPassword: '',            
    
                name : '',
                surname : '',
                email : '',
                readOnlyEmail: false,
                id: null,
                iyesUserId: null,
    
                tmpPassword: '',

                IBAN: null,
    
                iyestoken : '',
                localUser: {},
                iyesUser: {},

                centerId: 0,
                realCenterId: 0,

                customFields: [],

                greenPass: null,

                evofittoken : null,

                canUseBiometrics: false,
                biometricsStored: false,

                documents: [],

                //last recupero password with both methods (sms, email)
                lastRecuperoPassword: [null,null],

            }
        }
    },
    methods: {

        userCanRecoverPassword(_methodIdx) {
            let now = new Date().getTime();
            let elapsed = 0;
            
            if(this.user.lastRecuperoPassword[_methodIdx] == null) {
                elapsed = (60 * 3) + 1;
            } else {
                elapsed = Math.round( (now / 1000) - (this.user.lastRecuperoPassword[_methodIdx] / 1000) );
            }

            if(elapsed > (60 * 3)) {
                this.user.lastRecuperoPassword[_methodIdx] = now;
                return true;
            } else {
                return false;
            }
        },
        
        userTryLocalStorageLogin() {
            let centroID = localStorage.getItem('centro-default');
            if(centroID) {
                this.user.centerId = parseInt(centroID);
                this.user.realCenterId = this.user.centerId;
                //if the user has a reborn center for some reasons
                if(this.user.centerId < 2) {
                    this.user.centerId = 2;
                    this.user.realCenterId = 2;
                    localStorage.setItem('centro-default', 2);
                }
            } else {
                this.user.centerId = 2;
                this.user.realCenterId = 2;
                localStorage.setItem('centro-default', 2);
            }
    
            //check if we have a iyestoken in the localstorage
            let token = localStorage.getItem('iyestoken');
            if(token && token.length > 150 ) {
                console.log('Found user token in local storage');
                //if a token exists try to login with it
                this.user.iyestoken = token;
                //retrieves the user data from local DB and uyes
                this.getMe();
            } else {
                let closeInterval = null;
                closeInterval = setInterval( () => {
                    if(this.$root.$refs.pageMan) {
                        this.hideWaiting();
                        clearInterval( closeInterval );
                    }
                }, 500);
            }
        },

        //checks if biometrics are available on this device
        checkBiometrics() {
            if( typeof(window.Fingerprint) == 'undefined') {
                this.user.canUseBiometrics = false;
                console.log("No fingerprint available");
                return;
            }
            window.Fingerprint.isAvailable( this.biometricsAvailable, this.biometricsMissing );
        },

        biometricsMissing() {
            this.user.canUseBiometrics = false;
            console.log("Biometrics Not available");
        },

        biometricsAvailable() {
            console.log("Biometrics available");
            this.user.canUseBiometrics = true;    
        },

        //try requesting fingerprint for storing the token
        storeBiometricsCredentials() {
            var token = this.user.iyestoken;
            window.Fingerprint.registerBiometricSecret({
                title: "Orange Palestre, salva login con impronta o volto",
                description: "OrangeBiometricLogin",
                secret: token,
                invalidateOnEnrollment: true,
                disableBackup: true, // always disabled on Android
            }, (res) => {
                console.log(res);
                console.log("Stored biometric credentials");
            }, (err) => {
                console.log(err);
                console.log("Cannot store biometric credentials");
            });    
        },

        doBiometricsLogin() {
            window.Fingerprint.loadBiometricSecret({
                title: "Orange Palestre, login con impronta o volto",
                description: "OrangeBiometricLogin",
                disableBackup: true, // always disabled on Android
              }, (secret) => {
                if(secret && secret.length > 0)  {
                    console.log("Got biometrics token" + secret);
                    this.user.iyestoken = secret;
                    this.getMe();
                } else {
                    this.$root.$refs.pageMan.showAlert("Sarà necessario effettuare almeno un login per poter utilizzare l'accesso con impronta o volto.","OK","");
                }
              }, (error) => {
                console.log("Cannot retrieve bio token");
                console.log(error);
                this.$root.$refs.pageMan.showAlert("Sarà necessario effettuare almeno un login per poter utilizzare l'accesso con impronta o volto.","OK","");
              });
        },

        doLogin( _email, _pass) {
            //if(config.isApple) {
            //    this.askApplePermission()
            //    .then( (resTracking) => {
            //        return this.doNormalLogin(_email,_pass);
            //    });
            //} else {
                return this.doNormalLogin(_email,_pass);
            //}
        },

        doAppleLogin(_email,_pass) {
            return new Promise( (resolve, reject) => {            

            }); 
        },

        doNormalLogin( _email, _pass) {
            //store the email used to try the login

            return new Promise( (resolve, reject) => {
                const inEmail = _email;
                const inPass = _pass;
                if(inPass && inEmail) {
                    axios.post( config.serverUrl + '/auth/v2/logininforyou', {
                        username: inEmail,
                        password: inPass
                    })    
                    .then( (res) => {
                        if(res.data) {
                            if(res.data.error == false) {
                                this.user.iyestoken = res.data.data.iyesusertoken;
                                localStorage.setItem('iyestoken', this.user.iyestoken);

                                //save biometric data if possible
                                if(this.user.canUseBiometrics) {
                                    this.storeBiometricsCredentials();
                                }
                                //retrieves the user data from local DB and iyes DB
                                this.getMe();
                            } else {
                                if(res.data.data) {
                                    console.log('login');
                                    if(res.data.data.toUpperCase() == "PASSWORD EXPIRED.") {
                                        //the password expired change password requires the old password
                                        this.user.tmpPassword = _pass;
                                        this.$refs.pageMan.showOverlay('password-change');
                                    } else {
                                        console.log(res);
                                        this.$refs.pageMan.showAlert('Impossibile effettuare login. Verificare le credenziali o fare recupero password.', 'OK','');
                                    }    
                                } else {
                                    console.log(res);
                                    this.$refs.pageMan.showAlert('Impossibile effettuare login. Verificare le credenziali o fare recupero password.', 'OK','');
                                }
                            }
                        } else {
                            console.log(res);
                            this.$refs.pageMan.showAlert('Credenziali di accesso errate.', 'OK','');
                        }
                    })
                    .catch( (err) => {    
                        console.log('WARNING! Do login: ');
                        console.log(err);
                        this.$refs.pageMan.showAlert('Impossibile effettuare login. Verificare le credenziali o riprovare più tardi.', 'OK','');
                    });
                }
            }); 
        },

        doChangePassword(_oldpass, _newpass) {
            this.$root.$refs.pageMan.showWaiting();
            let iyestoken = (this.user.iyestoken != "") ? this.user.iyestoken : "none";
            let email = this.user.loginEmail;
            axios.post(config.serverUrl + '/user/passwordchange',{
                email: email,
                oldpassword: _oldpass,
                newpassword: _newpass,
                iyestoken: iyestoken
            })
            .then( (respwd) => {
                this.$root.$refs.pageMan.hideWaiting();
                if(respwd.data.error) {
                    console.log('WARNING! Password change response:');
                    console.log(respwd);
                    if(respwd.data.data.data) {
                        this.$root.$refs.pageMan.showAlert(respwd.data.data.data,'OK','');
                    } else {
                        this.$root.$refs.pageMan.showAlert('Al momento è impossibile reimpostare la password. Riprovare più tardi.');
                    }
                    this.userDoLogout();
                } else {
                    this.$root.$refs.pageMan.showAlert('La tua password è stata impostata correttamente.','OK','')
                    .then( (okrec) => {
                        this.doLogin( email, _newpass);
                    });
                }
            })
            .catch( (errpwd) => {
                this.$root.$refs.pageMan.hideWaiting();
                console.log('WARNING! Password change:');
                console.log(errpwd);
                this.$root.$refs.pageMan.showAlert('Non è stato al momento possibile cambiare la password. Riprovare più tardi.');
            })    
        },

        userResetPasswordProcedure(_email, _pass) {

            //rembember that _pass also can be a temporary password, see main.js for more info
            
            const inEmail = _email;
            const inPass = _pass;
            if(inPass && inEmail) {
                axios.post( config.serverUrl + '/auth/v2/logininforyou', {
                    username: inEmail,
                    password: inPass
                })    
                .then( (res) => {
                    this.$root.hideWaiting();
                    if(res.data) {
                        if((res.data.error == false) || ((res.data.data != null) && (res.data.data.includes('EXPIRED'))) ) {
                            this.user.tmpPassword = _pass;
                            this.user.loginEmail = _email;
                            this.$refs.pageMan.showOverlay('password-change');

                        } else {
                            this.$root.$refs.pageMan.resetPagesStack('welcomepage');
                        }
                    } else {
                        console.log(res);
                        this.$root.$refs.pageMan.resetPagesStack('welcomepage');
                    }
                })
                .catch( (err) => {    
                    console.log('WARNING! Do login: ');
                    console.log(err);
                    this.$root.hideWaiting();
                    this.$root.$refs.pageMan.showAlert('Questa richiesta di recupero password non è valida.<br> Assicurarsi di aver utilizzato <strong>il link più recente</strong> o eseguire nuovamente la richiesta di recupero password.')
                    .then( (ok) => {
                        this.$root.$refs.pageMan.resetPagesStack('welcomepage');
                    });
                });
            }
        },

        userGetAndShowGreenPass () {
            this.$root.showWaiting();
            axios.post( config.serverUrl + '/user/getcustomfields', {
                iyestoken: this.user.iyestoken,
                iyesuserid: this.user.iyesUserId
            })    
            .then( (res) => {
                this.$root.hideWaiting();
                if(res.data) {
                    if(res.data.error == false ) {
                        this.user.customFields = res.data.data;
                        this.user.greenPass = null;
                        this.$root.showPage('usergreenpasspage');
                    } else {
                        console.log(res);
                    }
                } else {
                    console.log(res);
                }
            })
            .catch( (err) => {    
                console.log(err);
                this.$root.hideWaiting();
            });
        },

        setIubendaConsent() {
            axios({
                method: 'POST',
                url: config.serverUrl + '/user/consent',
                data: {
                    iyestoken: this.user.iyestoken,
                    user: this.user.iyesUser,
                    consent: true
                }
            })
            .then( (res) => {
                console.log(res);
            })
            .catch( (err) => {
                console.log(err);
            })
        },

        _copyUserDataFromIYES(iyesUser) {
            this.user.name = iyesUser.FirstName;
            this.user.surname = iyesUser.LastName;
            this.user.birthDate = iyesUser.BirthDate;
            this.user.email = iyesUser.Email;
            this.user.gender = iyesUser.Gender;
            this.user.iyesUserId = iyesUser.UserID;   

            this.user.tmpPassword = iyesUser.WebPassword;
            this.user.loginEmail = iyesUser.WebLogin;
        },


        userIsUnsubscribedReferral() {
            if( (typeof(this.user.iyesUser.IDReferral) != 'undefined') && (this.user.iyesUser.IDReferral != "")) {
                if(this.subscriptions.abbonamenti.length == 0) {
                    return true;
                }
            }

            return false;
        },

        _userGetIBAN() {
            console.log('Getting user IBAN');
            if(this.user.iyestoken) {
                axios.post( config.serverUrl + '/user/myiban', {
                    iyestoken: this.user.iyestoken,
                    userEmail: this.user.email,
                    userId: this.user.iyesUserId
                })
                .then( (res) => {
                    if(res.data.error == false) {
                        this.user.IBAN = res.data.data;
                    }
                })
                .catch( (erriban) => {
                    console.log('IBAN: cannot retrieve iban.');
                });
            }
        },

        //set user data directly (for example during a signup)
        userSetMe(iyesUser) {
            this.user.iyestoken = iyesUser.AuthToken;
            localStorage.setItem('iyestoken', this.user.iyestoken);

            this.user.localUser = null;
            this.user.iyesUser = iyesUser;
            this.user.id = null;
            this._copyUserDataFromIYES(iyesUser);
        },

        //retrieve the user from the server
        getMe() {
            if(this.user.iyestoken != '' && this.user.iyestoken != null) {
                this.$root.showWaiting();

                //load all user preferences
                this.preferencesSetupDefaults();

                axios.post( config.serverUrl + '/user/v2/me', {
                    iyestoken : this.user.iyestoken
                })
                .then( (res) => {
                    if(res.data) {
                        if(!res.data.error) {
                            let iyesUser = res.data.data.data.iyes;
                            let localUser = (typeof res.data.data.data.local == 'string') ? JSON.parse(res.data.data.data.local) : res.data.data.data.local;

                            this.user.localUser = localUser;
                            this.user.iyesUser = iyesUser;
                            this.user.id = localUser.id;
                            this._copyUserDataFromIYES(iyesUser);
                                            
                            this._userSavePhone();

                            this._userGetIBAN();
                            this.reservationGetCategories();

                            //update my invitation status to registered
                            this.cashbackSetMeRegistered();

                            this.surveyGetMine();

                            //this function creates subscriptions data, anyhting that needs subs data must go after this one
                            //this also sets if the user can see reborn videos or not
                            this.getMyStatus()
                            .then( (ok) => {
                                console.log('GetMyStatus: OK');
                                //retrieve some products and payments details
                                this.paymentsGetUserCards();

                                //carico i dati per disdette e sospensioni
                                this.abbonamentiGetDatiSospensione();
                                this.abbonamentiGetDatiDisdetta();

                                this.paymentsGetVisiteMedicheEpurses();

                                //premi acquistabili coi borsellini
                                this.paymentsGetUserContest();
                                
                                //retrieve list of user receipts
                                this.documentGetReceipts();

                                this.loadEpursePayments()
                                .then( () => {

                                    
                                    //reload the specific product list for this user (in the mounted we don't have the user id so we load the public producst)
                                    //we also need to know what subscription the user has to filter some products (see function for more info)
                                    this.productsGetList(this.user.iyesUserId)
                                    .then( () => {
                                        this.rebornSetup();
                                        this.rboGetData();
                                        this.preferitiGetAll();
                                        this.evofitGetMe();    

                                        //retrieve last saved pageman data (stored before buying)
                                        this.$root.$refs.pageMan.loadPageManSteps()
                                        .then( () => {
                                            this.$root.$refs.pageMan.lockWaiting = false;
                                            this.$root.hideWaiting();
                                        })
                                        .catch( (err) => {
                                            this.$root.$refs.pageMan.lockWaiting = false;
                                            this.$root.hideWaiting();
                                            console.log("No pageMan steps present");
                                            console.error(err);
                                            if(this.userIsUnsubscribedReferral() == true && this.products.referral.length > 0) {
                                                this.prodottiShowNews(true);
                                            } else {
                                                this.$root.trainerSurveyCheckMustReview()
                                                .then( (restrainreview) => {
                                                    if(restrainreview) {
                                                        this.$root.$refs.pageMan.showAlert('In questi giorni hai parlato con un nostro trainer, vorresti rispondere a poche domande per <strong>aiutarci a migliorare la tua esperienza</strong>?','SI','NO')
                                                        .then( (si) => {    
                                                            this.$root.$refs.pageMan.showPage('trainerreviewpage');
                                                        })
                                                        .catch((no) => {
                                                            //increase the creation date on the survey record, so that we'll ask it again in 2 days
                                                            //also increases the snooze count
                                                            this.$root.trainerSurveySnooze();
                                                            this.$root.showMain();
                                                        });
                                                    } else {
                                                        this.$root.showMain();
                                                    }
                                                })
                                                .catch( (restrainerr) => {
                                                    this.$root.showMain();
                                                });
                                            }
                                        });
                                        
                                    });

                                });

                            })
                            .catch( (error) => {
                                console.log('GetMyStatus: ERROR');
                                this.$root.hideWaiting();
                                this.$root.$refs.pageMan.showAlert('Si è verificato un errore durante il caricamento. Contattare l\'assistenza','OK','')
                                .then( (okpress) => {
                                    this.$root.showPage('welcomepage');
                                })

                            })


                        } else {
                            let data = (res.data.data) ? res.data.data : '';
                            if(data.indexOf('deleted') != -1) {
                                this.$root.hideWaiting();
                                this.$root.$refs.pageMan.showAlert('Questo utente ha richiesto la disattivazione del profilo.<br>Contattare assistenza per riabilitare l\'utente.','OK')
                                .then( (r) => {
                                    console.log('WARNING! Unable to get user data get me.');
                                    this.$root.$refs.pageMan.showPage('loginpage');
                                    this.userDoLogout();        
                                });
                            } else {
                                this.$root.hideWaiting();
                                console.log('WARNING! Unable to get user data get me.');
                                this.$root.$refs.pageMan.showPage('loginpage');
                                this.userDoLogout();    
                            }
                        }
                    } else {
                        this.$root.hideWaiting();
                        console.log('WARNING! Unable to get response for user data get me.');
                        this.$oot.$reft.pageMan.showPage('loginpage');
                        this.userDoLogout();
                    }
                    //this.$root.hideWaiting();
                })
                .catch( (err) => {
                    this.$root.hideWaiting();
                    console.log('WARNING! Unable to get user data get me.');
                    console.log(err);
                    this.userDoLogout();
                });    
            } else {
                this.$root.hideWaiting();
                console.log('Doing logout due to missing iyes token.');
                this.userDoLogout();
            }
        },

        updateAnagrafica() {
            axios.post( config.serverUrl + '/user/update', {
                iyestoken : this.user.iyestoken,
                newAnagrafica: this.user.iyesUser
            })
            .then( (res) => {
                if(res.data.error) {
                    console.log("ERRORE! Aggiornando anagrafica.");
                } else {
                    console.log("Updated anagrafica");
                }
            })
            .catch( (err) => {
                console.log("ERROR! Cannot update anagrafica");
            });
        },

        //user phone is needed for passwrodless login, so store it in local DB if it exists
        _userSavePhone() {
            let phone = '';
            if(typeof(this.user.iyesUser.Mobile) != 'undefined') {
                if(this.user.iyesUser.Mobile.length > 0) {
                    phone = this.user.iyesUser.Mobile;
                    axios({
                        method: 'POST',
                        url: config.serverUrl + '/user/v2/setphone',
                        data: {
                            iyestoken: this.user.iyestoken,
                            phone: phone,
                            username: this.user.email
                        }
                    })
                    .then( (phoneok) => {
                        if(phoneok.data.error == true) {
                            console.log(phoneok.data);
                        }
                    })
                    .catch( (phoneerr) => {
                        console.log('Error setting user phone');
                        console.log(phoneerr);
                    });
                }
            }
        },

        userRinnovaOCompra() {
            if(this.$root.isApple) {
                this.$root.showAppleMessage();
            } else {
                if(this.$root.subscriptions.abbonamenti.length == 0) {
                    this.$root.$refs.pageMan.showPage('buysubscription');
                } else if( this.$root.payments.rateScadute == true) {
                    this.$root.$refs.pageMan.showPage('userabbonamentopage');
                } else if(this.$root.subscriptions.scadenzaAbbonamento != null && (moment(this.$root.subscriptions.scadenzaAbbonamento) < moment())) {
                    this.$root.$refs.pageMan.showPage('buysubscription');
                }    
            }

        },

        userAbbonamERateOK() {
            if(this.subscriptions.abbonamenti.length == 0)
                return false;

            let oggi = moment();
            let abbonamScaduto = false;
            //nessun abbonamento
            if(this.subscriptions.scadenzaAbbonamento == null) {
                abbonamScaduto = true;
            }

            if(moment(this.subscriptions.scadenzaAbbonamento) < oggi) {
                abbonamScaduto = true;
            }

            return ((this.payments.rateScadute == false) || (abbonamScaduto == true));
        },

        getMyStatus() {
            return new Promise( (resolve, reject) => {
                if(this.user.iyestoken) {
                    this.user.documents = [];
                    axios.post( config.serverUrl + '/user/mystatus', {
                        iyestoken : this.user.iyestoken
                    })
                    .then( (resmy) => {
                        //console.log('Got user status');
                        //console.log(resmy.data.data.data);
                        resmy.data.data.data.Contents.forEach( (doc,index) => {
                            if(index != 0) {
                                if(doc.Text.includes("CERTIFICAT") || doc.Text.includes("ABBONAMENTI") || doc.Text.includes("PRENOTAZIONI")) {
                                    this.user.documents.push(doc);
                                }
                                if(doc.Text.includes("SOSPENSIO")) {
                                    this.$root.abbonamentiAggiungiSospensioneDaDoc(doc.Contents);
                                }
                            }
                        });
                        this.getRevocableSubscriptions();
                        this.getScadenzaCertificatoMedico();
                        this.getScadenzaAbbonamento();
                        resolve(true);
                    })
                    .catch( (errmy) => {
                        console.log('WARNING! Getting user status get my status');
                        console.log(errmy);
                        reject(false);
                    });    
                }         
    
            });
        },

        userReset() {
            this.user.id = 0;
            this.user.name = '';
            this.user.surname = '';
            this.user.birthDate = null;
            this.user.gender = null;
            this.user.email = null;
            this.user.iyesUserId = null;
            this.user.centerId = 0;
            this.user.iyestoken = '';
            this.user.documents = [];
            this.user.readOnlyEmail = false;
            this.user.evofittoken = null;
            localStorage.removeItem('iyestoken');
        },

        userDoLogout() {
            this.userReset();
            this.evofitReset();
            this.productsReset();
            this.rebornReset();
            this.reservationReset();
            this.abbonamentiReset();
            this.paymentsReset();
            this.registrationReset();
            this.$refs.pageMan.resetPagesStack('welcomepage');
        },

        openWhatsappAmministrazione(event) {
            event.preventDefault();
            window.open('whatsapp://send?text=Inserisci qui la tua domanda&phone=+393899061688','_blank');
        },

        openWhatsappTrainer(event) {
            event.preventDefault();
            window.open('whatsapp://send?text=Inserisci qui il tuo messaggio&phone=+393518560037','_blank');
        },

    }
}
