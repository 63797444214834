import axios from 'axios';
import config from '../config/config_local.js'

import centersJson from './centersBackup'

export default  {
    data() {
        return {
            centers: {
                centers: [],
                curCenterDetails: null
            }            
        }
    },
    created() {
        console.log('Assigning default centers');
        this.centers.centers = centersJson;
        this.centers.curCenterDetails = this.centers.centers[2];    
        console.log('Getting real centers');
        axios({
            method: 'GET',
            url: config.serverUrl + '/centers'
        })
        .then( (cenres) => {
            this.centers.centers = cenres.data;
            this.centers.curCenterDetails = this.centers.centers[2];
        })
        .catch( (err) => {
            this.centers.centers = centersJson;
            this.centers.curCenterDetails = this.centers.centers[2];    
        })
    },
    methods: {
        centerDefaultCenterExists() {
            this.centers.curCenterDetails = this.centers.centers[ 2 ];
            return true;
        },

        centersShowSceltaCentro() {
            this.$root.$refs.pageMan.showOverlay('scelta-centro');
        }
    }
}