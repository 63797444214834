import axios from 'axios';
import config from '../config/config_local.js'

import moment from 'moment'
import momentIT from 'moment/locale/it'

import Scheda from './classes/schedaClass'
import SedutaPlayer from './classes/sedutaPlayerClass'

export default  {
    data() {
        return {
            evofit: {
                curScheda: null,
                
                modsched: {
                    schede: [],
                    obiettivi: [],
                    curObiettivoId: -1,
                    curObiettivoName: null,
                    curObiettivoSchede: []
                },

                modSchedeLoaded: false,

                sedutaPlayer: null,

                searchResults: []
    
            }
        }
    },
    created() {
        this.evofit.curScheda = new Scheda();
    },

    methods: {
        
        evofitReset() {
            this.evofit.curScheda = null;
            this.evofit.curScheda = new Scheda();
                
            this.evofit.modsched.schede = [];
            this.evofit.modsched.obiettivi = [];
            this.evofit.modsched.curObiettivoId= -1;
            this.evofit.modsched.curObiettivoName = null;
            this.evofit.modsched.curObiettivoSchede = [];
            this.evofit.sedutaPlayer = null;

            this.modSchedeLoaded = false;
            this.sedutaPlayer = null;
            this.searchResults = [];
        },

        evofitInitSeachResults() {
            let i = 0;
            this.evofit.searchResults = [];
            for(i = 0; i < 8; i++) {
                let pos = Math.floor( Math.random() * this.evofit.modsched.schede.length);
                this.evofit.searchResults.push( this.evofit.modsched.schede[ pos] );
            }
        },

        evofitGetMe() {
            let userEmail = this.user.email;
            let userId = this.user.iyesUserId;
            axios.post( config.serverUrl + '/evofit/i4ylogin', {
                email: userEmail,
                id: userId,
                iyestoken : this.user.iyestoken
            })
            .then( (evores) => {
                if(!evores.data.error) {
                    //console.log('OK evolution fit login');
                    this.user.evofittoken = evores.data.data.token;
                    if(this.evofit.curScheda == null) {
                        this.evofit.curScheda = new Scheda();
                    }
                    
                    this.evofit.curScheda.setCredentials(this.user.iyestoken, this.user.evofittoken);

                    this.evofitGetModelliScheda();

                    //riceve il diario degli allenamenti da evofit per le stats di RBO
                    this.evofitGetStatsAllenamento();
                } else {
                    //console.log('WARNING! Error evolution fit login');
                    //console.log(evores);
                    this.evofitGetGeneric();
                }
                //console.log(evores);
            })
            .catch( (evoerr) => {
                console.log('WARNING! Error getting evofit data');
                console.log(evoerr);
                this.evofitGetGeneric();
            });
        },

        evofitGetGeneric() {
            console.log('Getting EVOFIT generic');
            axios.post( config.serverUrl + '/evofit/genericlogin', { 
                iyestoken : 'none'
            })
            .then( (evores) => {
                if(!evores.data.error) {
                    //console.log('OK evolution fit generic');
                    this.user.evofittoken = evores.data.data.token;
                    this.evofit.curScheda.setCredentials(this.user.iyestoken, this.user.evofittoken);
                    this.evofitGetModelliScheda();
                } else {
                    console.log('WARNING! Evofit generic');
                    //console.log(evores);
                }
                //console.log(evores);
            })
            .catch( (evoerr) => {
                console.log('WARNING! Getting evofit generic user');
                console.log(evoerr);
            });
        },

        evofitGetModelliScheda() {
            console.log('Getting modelli scheda.');
            if(this.user.evofittoken) {
                axios({
                    method: 'POST',
                    url: config.serverUrl + '/evofit/getmodels',
                    data: {
                        iyestoken: this.user.iyestoken,
                        evofittoken: this.user.evofittoken
                    }
                })
                .then( (res) => {
                    this.evofit.modsched.schede = [];
                    res.data.data.forEach( (scheda) => {
                        if(this.subscriptions.abbonamenti.length > 0)    {
                            this.evofit.modsched.schede.push( scheda );
                        } else {
                            if(scheda.obiettivo.toUpperCase().includes('GRATUIT')) {
                                this.evofit.modsched.schede.push( scheda );
                            }
                        }
                    });

                    this.evofitInitSeachResults();                    

                    if( this.preferitiLoaded == true)
                        this.preferitiAssignSchedeToPreferiti();

                    this._buildArrayObiettiviModelliScheda( this.evofit.modsched.schede );

                    this.evofit.modsched.schede.forEach( (modscheda) => {
                        
                        if(modscheda.descrizione.includes('*')) {
                            let descsplit = modscheda.descrizione.split('*');
                            if(descsplit.length == 2) {
                                modscheda.descrizione = descsplit[0].trim().toUpperCase();
                                modscheda.descimmagine = descsplit[1].trim().toUpperCase();
                            }    
                        } else {
                            modscheda.descrizione = modscheda.descrizione.trim().toUpperCase();
                            if(modscheda.descimmagine == "")
                                modscheda.descimmagine = '';
                        }
                        

                        if(modscheda.obiettivo.includes('*')) {
                            let descsplit = modscheda.obiettivo.split('*');
                            if(descsplit.length == 2) {
                                modscheda.obiettivo = descsplit[0].trim().toUpperCase();
                            }    
                        } else {
                            modscheda.obiettivo = modscheda.obiettivo.trim().toUpperCase();
                        }
                                                
                    });

                    this.setupObiettiviSchedeSlider();

                    this.evofit.modSchedeLoaded = true;
                })
                .catch( (err) => {
                    console.log('WARNING! Evofit get scheda models');
                    console.log(err);
                })
            }
        },

        evofitGetAndShowMyTraining(_push) {
            this.$root.$refs.pageMan.showWaiting();
            if(this.user.evofittoken) {
                this.evofit.curScheda.reset();
                axios({
                    method: 'POST',
                    url: config.serverUrl + '/evofit/training',
                    data: {
                        evofittoken: this.user.evofittoken,
                        iyestoken: this.user.iyestoken
                    }
                })
                .then( (res) => {
                    //console.log("Evofit scheda OK!");
                    //console.log(res);
                    this.$root.$refs.pageMan.hideWaiting();
                    if(this.evofit.curScheda.setup(res.data.data)) {
                        this.evofit.curScheda.process();
                        this.evofit.curScheda.isPersonal = true;
                        if(_push) {
                            this.$refs.pageMan.showPage('trainerschedaattualepage', _push);
                        } else {
                            this.$refs.pageMan.resetPagesStack('trainerschedaattualepage');
                        }
                    }
                })
                .catch( (err) => {
                    this.$root.$refs.pageMan.hideWaiting();
                    console.log("WARNING! Evofit scheda error");
                    console.log(err);
                })
            } else {
                this.$root.$refs.pageMan.hideWaiting();
                //this.$root.$refs.pageMan.showAlert('Il tuo profilo di allenamento richiede qualche minuto per essere allineato nel sistema informatico. Prima che questo avvenga<br><strong>è comunque possibile compilare il questionario di allenamento</strong>..','OK','')
                //.then( (ok) => {
                    this.$refs.pageMan.showPage('trainerschedaattualepage', true);
                //});
            }
        },

        //interrompe l'allenamento, chiamata dal player o da un cambio pagina
        evofitFineAllenamento() {
            if(this.evofit.sedutaPlayer != null) {
                this.evofit.sedutaPlayer.stopAllenamento();
            }

            this.evofit.sedutaPlayer = null;

            //end of allenamento, back to main scheda page
            this.$root.$refs.pageMan.showPage('trainerschedaattualepage',false);                    
        },

        evofitFineSingoloEsercizio() {
            console.log('Fine singolo esercizio');

            if(this.evofit.sedutaPlayer != null) {
                //mark the esercizio as done
                this.evofitMarkEsercizioEseguito(this.evofit.sedutaPlayer.singleModeEsercizioCodice);
                //destroy sedutaPlayer
                this.evofit.sedutaPlayer.stopAllenamento();
            }

            //remove all eseguipage from pageMan stack
            while(this.$root.$refs.pageMan.navStack[ this.$root.$refs.pageMan.navStack.length - 1] == 'eseguipage') {
                this.$root.$refs.pageMan.navStack.pop();
            }
            //end of single excercise, back to excercise list
            this.$root.$refs.pageMan.showPage('manualelistapage',false);                   
        },

        evofitModificaValoreSerie(_oldval, _modifica) {
            _oldval += _modifica;
            if(_oldval < 1)
                _oldval = 1;

            return _oldval;
        },

        evofitModificaSerieEsercizio() {
            this.$root.$refs.pageMan.alertModificaSerie.esercizioName = this.evofit.curScheda.curPlaylistEntry.esercizio.descrizione;
            this.$root.$refs.pageMan.alertModificaSerie.arrSerie = [];
            this.evofit.curScheda.curPlaylistEntry.esercizio.serieObj.valori.forEach( (valore) => {
                if(valore.val1 != '' || valore.val2 != '') {
                    this.$root.$refs.pageMan.alertModificaSerie.arrSerie.push( { val1 : parseInt(valore.val1), val2: parseInt(valore.val2) })
                }
            });
            this.$root.$refs.pageMan.alertModificaSerie.tipoSerie = this.evofit.curScheda.curPlaylistEntry.esercizio.serieObj.tipo;
            this.$root.$refs.pageMan.showAlertModificaSerie()
            .then( (newserie) => {
                //aggiorna la scheda corrente con le nuove serie
                newserie.forEach( (newval, index) => {
                    this.evofit.curScheda.curPlaylistEntry.esercizio.serieObj.valori[index].val1 = String(newval.val1);
                    this.evofit.curScheda.curPlaylistEntry.esercizio.serieObj.valori[index].val2 = String(newval.val2);
                });

                //update the json string too
                this.evofit.curScheda.curPlaylistEntry.esercizio.serie = JSON.stringify({
                    tipo: this.evofit.curScheda.curPlaylistEntry.esercizio.serieObj.tipo,
                    valori: this.evofit.curScheda.curPlaylistEntry.esercizio.serieObj.valori
                });

                //force pages update
                this.$root.$refs.pageMan.$refs.eseguipage.$forceUpdate();
                this.$root.$refs.pageMan.$refs.manualelistapage.$forceUpdate();

                this.evofitSalvaScheda();
            })
            .catch( (canc) => {
                console.log(canc);
            });
        },

        evofitSalvaScheda() {
            //salva la scheda attuale all'utente
            if(this.user.evofittoken) {
                axios({
                    method: 'POST',
                    url: config.serverUrl + '/evofit/savescheda',
                    data: {
                        evofittoken: this.user.evofittoken,
                        iyestoken: this.user.iyestoken,
                        scheda: this.evofit.curScheda.scheda
                    }
                })
                .then( (res) => {
                    //console.log("Evofit scheda OK!");
                    //console.log(res);
                    console.log('evofit salvaScheda: scheda salvata.');
                })
                .catch( (err) => {
                    console.log("WARNING! Evofit save scheda error");
                    console.log(err);
                })
            }
        },

        /* evofitModificaValoreSerie(_codiceEsercizio, _valoreIndex, _val1OrVal2, _modifica) {
            console.log('Modifica valore');
            this.evofit.curScheda.sedute[ this.evofit.curScheda.curSeduta ].esercizi.forEach( (eserc) => {
                if(eserc.codice == _codiceEsercizio) {
                    if(_val1OrVal2 == 'val1') {
                        eserc.serieObj.valori[ _valoreIndex ].val1 = parseInt(eserc.serieObj.valori[ _valoreIndex ].val1) + _modifica;
                    } else {
                        eserc.serieObj.valori[ _valoreIndex ].val2 = parseInt(eserc.serieObj.valori[ _valoreIndex ].val2) + _modifica;
                    }
                    this.$root.$refs.pageMan.alertModificaSerie.esercizio = eserc;
                }
            });
        }, */

        evofitMarkEsercizioEseguito(_codiceEsercizio) {
            this.evofit.curScheda.sedute[ this.evofit.curScheda.curSeduta ].esercizi.forEach( (eserc) => {
                if(eserc.codice == _codiceEsercizio) {
                    eserc.eseguito = true;
                }
            });
        },

        evofitShowMain(_obiettivoIdx) {
            // obiettivoIdx is -1 if we want our personal scheda, otherwise is the obbiettivo id that we are opening (schede modello)
            if(_obiettivoIdx >= 0) {
                this.$root.$refs.pageMan.resetPagesStack('trainermainpage');
            }else {
                this.$root.evofitGetAndShowMyTraining(false);
            }
        },

        evofitShowObiettivo(index) {
            this.evofit.modsched.curObiettivoId = index;
            this.evofit.modsched.curObiettivoName = this.evofit.modsched.obiettivi[ index ];

            //carico tutte le schede di questo obiettivo
            this.evofit.modsched.curObiettivoSchede = [];
            this.evofit.modsched.schede.forEach( (scheda,index) => {
                if(scheda.obiettivo == this.evofit.modsched.curObiettivoName.descrizione) {
                    //retrieve this scheda and push it to curObiettivoSchede
                    this._evofitGetSchedaModello(index);
                }
            });

            this.$root.$refs.pageMan.showPage('trainerschedeobiettivopage');
        },

        evofitSetSchedaFromModello(_index) {
            console.log('Evofit: Setting scheda from modello');
            let modScheda = this.$root.evofit.modsched.curObiettivoSchede[_index];
            this.$root.evofit.curScheda.reset();
            if(this.$root.evofit.curScheda.setup( modScheda )) {
                this.$root.evofit.curScheda.process();
                this.$root.evofit.curScheda.isPersonal = false;   //scheda modello, non personale
                console.log('Showing scheda anteprima: ' + this.$root.evofit.curScheda.loaded);
                this.$root.$refs.pageMan.resetPagesStack('trainerschedaattualepage');
            }            
        },

        evofitSetSchedaFromPreferiti(_schedaCodice) {
            console.log('Evofit: Setting scheda from modello');
            let foundIdx = -1;
            this.evofit.modsched.schede.forEach( (scheda, index) => {
                if(scheda.codice == _schedaCodice) {
                    foundIdx = index;
                }
            });
            if(foundIdx != -1) {
                this._evofitGetSchedaModello( foundIdx)
                .then( (okScheda) => {
                    this.$root.evofit.curScheda.reset();
                    if(this.$root.evofit.curScheda.setup( okScheda )) {
                        this.$root.evofit.curScheda.process();
                        this.$root.evofit.curScheda.isPersonal = false;   //scheda modello, non personale
                        console.log('Showing scheda anteprima: ' + this.$root.evofit.curScheda.loaded);
                        this.$root.$refs.pageMan.resetPagesStack('trainerschedaattualepage');
                    } 
                });    
            }
        },

        evofitStartEsercitazione(_automatico) {
            this.evofit.sedutaPlayer = new SedutaPlayer(this.evofit.curScheda, this._showPage, this._evofitUpdateDiary, () => {
                //callback fine allenamento
                this.evofitFineAllenamento();
            });
            this.evofit.sedutaPlayer.scheda.modalitaAutomatica = _automatico;
            this.evofit.sedutaPlayer.start();
        },

        //used in manual mode
        evofitPrepareEsercitazione( _automatico ) {
            this.evofit.sedutaPlayer = new SedutaPlayer(this.evofit.curScheda, this._showPage, this._evofitUpdateDiary, () => {
                //callback fine allenamento
                this.evofitFineSingoloEsercizio();
            });
            this.evofit.sedutaPlayer.scheda.modalitaAutomatica = _automatico;
            //This only prepares the sedutaPlayer without actually starting it
            //this.evofit.sedutaPlayer.start();
        },

        _showPage(_page,_push) {
            this.$refs.pageMan.showPage(_page,_push);
        },

        _evofitUpdateDiary() {
            if(this.user.evofittoken) {
                axios({
                    method: 'POST',
                    url: config.serverUrl + '/evofit/updatediary',
                    data: {
                        iyestoken: this.user.iyestoken,
                        evofittoken: this.user.evofittoken,
                        esercizio: this.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.codice,
                        serie: this.evofit.sedutaPlayer.scheda.curPlaylistEntry.serie,
                        esercizioscheda: this.evofit.curScheda.scheda.codice,
                        //vecchia versione esercizioscheda: this.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.esercizioscheda,
                        data: moment().format('YYYY-MM-DD')
                    }
                })
                .then( (res) => {
                    //console.log(res);
                })
                .catch( (err) => {
                    console.log('WARNING! Cannot update evofit diary data');
                    console.log(err);
                })
            }
        },

        _evofitGetSchedaModello(schedaidx) {
            return new Promise( (resolve, reject) => {
                //Api/scheda/codice modello/aaaa-mm-dd/lingua/token
                axios({
                    method:'POST',
                    url: config.serverUrl + '/evofit/detmodscheda',
                    data: {
                        codModello: this.evofit.modsched.schede[ schedaidx ].codice,
                        iyestoken: this.user.iyestoken,
                        evofittoken: this.user.evofittoken                    
                    }
                })
                .then( (modres) => {
                    //console.log(modres);
                    let curScheda = modres.data.data;
                    curScheda.descrizione = curScheda.descrizione.trim().toUpperCase();
                    if(curScheda.descrizione.includes('*')) {
                        let desSplit = curScheda.descrizione.split('*');
                        if(desSplit.length == 2) {
                            curScheda.descrizione = desSplit[0].trim();
                            curScheda['descimmagine'] = desSplit[1].trim();
                        } 
                    }
                    this.evofit.modsched.curObiettivoSchede.push( curScheda );
                    resolve( curScheda );
                })
                .catch( (err) => {
                    console.log('WARNING! Evofit get single scheda model');
                    console.log(err);
                    reject(false);
                });

            });
        },

        evofitGetSchedaByCodice(_codiceScheda) {
            let i = 0;
            let found = false;
            
            if( (this.evofit.modsched && this.evofit.modsched.schede)) {
                while(i < this.evofit.modsched.schede.length && found == false) {
                    if(this.evofit.modsched.schede[i].codice == _codiceScheda) {
                        found = true;
                    }
    
                    if(!found)
                        i++;
                }
                if(found)
                    return this.evofit.modsched.schede[ i ];
                else
                    return null;    
            } else {
                return null;
            }
        },

        //crea un array degli obiettivi presenti nei modellis cheda, in modo da poter vedere uno slider degli obiettivi
        _buildArrayObiettiviModelliScheda(schede) {
            schede.forEach( (scheda) => {
                if(scheda.obiettivo.includes('*')) {
                    let descsplit = scheda.obiettivo.split('*');
                    if(descsplit.length == 2) {
                        scheda.obiettivo = descsplit[0].trim().toUpperCase();
                        scheda.obimmagine = descsplit[1].trim().toUpperCase();
                    }    
                } else {
                    scheda.obiettivo = scheda.obiettivo.trim().toUpperCase();
                    scheda.obimmagine = "default";
                }

                //cerca se esiste già questo obiettivo
                let found = false;
                let foundIdx = -1;
                this.evofit.modsched.obiettivi.forEach( (obiettivo, index) => {
                    if(obiettivo.descrizione == scheda.obiettivo) {
                        found = true;
                        foundIdx = index;
                    }
                });

                if(!found) {
                    //if we did not find the descrizione, then i'ts a new obiettivo
                    this.evofit.modsched.obiettivi.push({
                        descrizione: scheda.obiettivo,
                        immagine: scheda.obimmagine,
                        schedeCount: 1
                    });
                } else {
                    //if we found this obiettivo increase the schede count
                    this.evofit.modsched.obiettivi[ foundIdx ].schedeCount ++;
                }
            });
        },

        //retrieve the evofit diary data
        evofitGetStatsAllenamento() {
            console.log('Getting Stats Allenamento');
            let allUrl = config.serverUrl + '/evofit/getallenamento';
            axios({
                url: allUrl,
                method: 'POST',
                data: {
                    evofittoken: this.user.evofittoken,
                }
            })
            .then( (res) => {
                if(!res.data.error) {
                    console.log(res);
                    //store the diary data into rbo.evofit.diaryData
                    this.rboSetEvofitDiaryData(res.data.data);
                } else {
                    this.rboSetEvofitDiaryData([]);
                }
            })
            .catch( (err) => {
                console.log(err);
            });
        }

    }
}