<template>
    <div>
        <div class="container-fluid">
            <div class="row mt-5">
                <div class="col-12 my-5 text-center">
                    <p class="titolo-piccolo orange-text-black text-center mt-4 mb-2 pb-0" style="text-transform:uppercase;">GREEN PASS</p>
                    <p class="testo-generico orange-text-black text-center" >Da questa pagina è possibile gestire il tuo Green Pass.</p>
                </div>            
            </div>
            <div class="row text-center">
                <div class="col-12">
                    <p v-if="$root.subscriptions.scadenzaCertMedico != null && $root.subscriptions.scadenzaCertMedico.isAfter( moment())">
                        Il tuo GREEN PASS risulta essere valido.
                    </p>
                </div>
            </div>

            <div class="row text-center">
                <div v-if="$root.user.greenPass != null && $root.user.greenPass.valid" class="col-12">
                    <p class="testo-generico orange-text-black mb-0">Green Pass Attivo</p>
                    <p class="testo-generico orange-text-gray mt-0 mb-3">{{ $root.user.greenPass.certid }}</p>
                    <p class="testo-generico orange-text-black mt-0 mb-0">Validità: <strong>{{ $root.user.greenPass.validTo }}</strong></p>
                    <p class="testo-generico orange-text-black mt-0 mb-0">Nome Cognome: <strong style="text-transform:uppercase;">{{ $root.user.greenPass.nome }} {{ $root.user.greenPass.cognome }}</strong></p>
                    <p v-if="$root.user.greenPass.tipo == 'vaccino'" class="testo-generico orange-text-black mt-0 mb-0">Dose: <strong>{{ $root.user.greenPass.numerodose }} / {{ $root.user.greenPass.totdosi }}</strong></p>
                    <p class="testo-generico orange-text-black mt-0 mb-0">Msg: <strong>{{ $root.user.greenPass.validMessage }} </strong></p>
                    <div class="form-check mt-3 text-left">
                        <input ref="privacyGPCheck" class="form-check-input" type="checkbox" value="" id="privacyCheck">
                        <label class="form-check-label pl-2 text-justify orange-text-black testo-generico" for="privacyCheck">
                            Richiedo espressamente al fine di evitare rischi di assembramento all’ingresso per la lettura quotidiana e ripetuta del mio green pass, di poter rendere a Voi disponibile i dati pubblici del mio green pass e relativa scadenza, con la contestuale richiesta di una sua corretta conservazione (come per il certificato medico) nel pieno rispetto della normativa privacy.
                        </label>
                    </div>
                    <button v-on:click.stop="trySendGreenPassData" class="btn btn-block testo-bottoni btn-orange-orange mt-4">CONFERMA</button>
                </div>
                <div v-if="$root.user.greenPass != null && $root.user.greenPass.valid == false" class="col-12">
                    <p class="testo-generico orange-text-black mb-3">Green Pass Non valido</p>
                    <p class="testo-generico orange-text-black mt-0 mb-0">Il QR Code letto non è valido o non è stato possibile leggerlo.Contattare l'amministrazione o recarsi in segreteria.</p>
                    <button v-on:click.stop="$root.user.greenPass = null" class="btn btn-block testo-bottoni btn-orange-orange mt-4">RIEPOVA</button>
                </div>
            </div>

            <div v-if="$root.user.greenPass == null && $root.subscriptions.scadenzaCertMedico == null" style="height:40px; line-height:40px;" class="row mt-5 mx-2 testo-generico background-red orange-text-white">
                <div class="col-8 border-right testo-bottoni text-center">
                    <span class="icon-allert ico-bottone-documenti"></span>
                    <span>GREEN PASS</span>
                </div>
                <div class="col-4 testo-bottoni text-center">
                    <label for="aggiungiGreenPass">
                    <input v-on:change.stop="$root.documentPrepareForGreenPass" type="file" id="aggiungiGreenPass" name="aggiungiGreenPass" accept="image/jpeg, image/png, image/jpg">
                    AGGIUNGI &nbsp;&nbsp;<strong style="font-size:1.2rem;">+</strong>
                    </label>
                </div>
            </div>

            <div v-if="$root.subscriptions.scadenzaCertMedico != null && $root.subscriptions.scadenzaCertMedico.isAfter( moment()) == true && $root.user.greenPass == null" style="height:40px; line-height:40px;" class="row mt-5 mx-2 testo-generico background-green orange-text-white">
                <div class="col-8 border-right testo-bottoni text-center">
                    
                    <span>GREEN PASS</span>
                </div>
                <div class="col-4 testo-bottoni text-center">
                    <label for="aggiungiGreenPass">
                    <input v-on:change.stop="$root.documentPrepareForGreenPass" type="file" id="aggiungiGreenPass" name="aggiungiGreenPass" accept="image/jpeg, image/png, image/jpg">
                    AGGIORNA &nbsp;&nbsp;<strong style="font-size:1.2rem;">+</strong>
                    </label>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import Vue from 'vue'

import axios from 'axios'
import config from '../../../config/config_local.js'

export default {
    name: 'UserGreenPass',
    data () {
        return {
            openAccordion: []
        }
    },
    methods: {
        toggleAccordion(index) {
            console.log('toggle');
            if(typeof this.openAccordion[index] == 'undefined') {
                Vue.set(this.openAccordion,  index, {
                    open: true
                } );
            } else {
                this.openAccordion[index].open = !this.openAccordion[index].open;
            }
        },

        trySendGreenPassData() {
            if(this.$refs.privacyGPCheck.checked) {
                this.$root._sendGreenPassDate()
            } else {
                this.$root.$refs.pageMan.showAlert('È necessario accettare i termini della privacy sul Green Pass per proseguire.','OK','');
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.ico-bottone-documenti {
    font-size: 18px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 5px;
    margin-right: 10px;    
}

.ico-bottone-documenti-piu {
    font-size: 18px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 5px;
    margin-right: 10px;    
}

#aggiungiGreenPass {
    position:absolute;
    top:0px;
    left:0px;
    visibility:hidden;
    width:100%;
    height: 40px;
}

#aggiornaGreenPass {
    position:absolute;
    top:0px;
    left:0px;
    visibility:hidden;
    width:100%;
    height: 40px;
}

</style>