module.exports = {
    mode: 'dev',  //prod
    serverUrl: 'https://reborn.orangepalestre.it',
    //serverUrl: 'http://localhost:3000',
    version: {
        major: 2,
        minor: 1,
        build: 95
    },
    isApple: false,
    isAndroid: false,
    isWeb: false
}
