<template>
    <div>
        <div class="container-fluid">
            <div class="row mt-5">
                <div class="col-12 my-5 text-center orange-text-black">
                    <p class="titolo-piccolo orange-text-black">BORSELLINO</p>
                </div>
            </div>
            <div class="row mb-3 testo-generico orange-text-black">
                <div class="col-12 text-center">
                    <p :class="{ 'orange-text-green' : ($root.payments.epurses.epurseBalance > 0), 'orange-text-red' : ($root.payments.epurses.epurseBalance <= 0) }">Saldo Borsellino<br>
                    <strong>{{ $root.payments.epurses.epurseBalance }} &euro;</strong>
                    </p>
                </div>
                <!-- <div class="col-6 text-center">
                    <p v-if="$root.payments.contest.contest != null" :class="{ 'orange-text-green' : ($root.payments.epurses.epurseBalance > 0), 'orange-text-red' : ($root.payments.epurses.epurseBalance <= 0) }">Saldo Cashback<br>
                     <strong>{{ $root.payments.contest.contest.PointBalance }}&euro;</strong>
                     </p>
                </div> -->
            </div>

            <div class="row">
                <div v-if="$root.payments.epurses.currentEpurseMovements.length > 0" class="col-12 mt-0">
                    <div id="movimaccordion">
                        <div class="card orange-text-white border-0">
                            <div class="card-header collapsed p-0" :id="'movimheading-' + 0">
                                <h5 class="mb-0">
                                    <button v-on:click="toggleAccordion(0)" class="btn btn-block btn-orange btn-outline-white orange-text-black" data-toggle="collapse" :data-target="'#movimcoll-' + 0" aria-expanded="true" aria-controls="collapseOne">
                                        DETTAGLIO MOVIMENTI
                                        <span style="float:right;" v-show="(openAccordion.hasOwnProperty(0) ) && (openAccordion[0].open)" class="orange-ico orange-ico-dark"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_chiudi"></span></span>
                                        <span style="float:right;" v-show="(!(openAccordion.hasOwnProperty(0))) || (!openAccordion[0].open)" class="orange-ico orange-ico-dark"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_apri"></span></span>
                                    </button>
                                </h5>
                            </div>

                            <div :id="'movimcoll-' + 0" class="collapse" :aria-labelledby="'movimheading-' + 0" data-parent="#movimaccordion">
                                <div class="card-body scrollaccordion testo-generico orange-text-black" style="font-size:0.8rem;">
                                    <div class="row border-bottom mb-2 py-2" v-for="movim, index in $root.payments.epurses.currentEpurseMovements" :key="'movem-' + index">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-10">
                                                    <strong>{{ movim.cause }}</strong>
                                                </div>
                                                <div class="col-2">
                                                    <span v-if="movim.amount != 0">{{ movim.amount }} &euro;</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div  class="col-12">
                                            <div class="row mt-2">
                                                <div class="col-6 text-left">
                                                    {{ movim.name }}
                                                </div>
                                                <div class="col-6 text-right">
                                                    {{ movim.date }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="col-12 testo-generico orange-text-black">
                    <p class="text-center">Nessun movimento presente.</p>
                </div>
            </div>

            <div v-if="$root.isApple == false" class="row text-center">
                <div v-if="($root.payments.userCards != null) && (typeof $root.payments.userCards.Sorgente != 'undefined')" class="col-12">
                    <p class="testo-generico orange-text-black mt-5"><strong>Carta Attiva</strong></p>
                    <p class="testo-generico orange-text-black">{{ $root.payments.userCards.Sorgente }}</p>
                </div>
                <div v-else class="col-12">
                    <p class="testo-generico orange-text-black mt-3"><strong>Carta Attiva</strong></p>
                    <p class="testo-generico orange-text-black">NESSUNA</p>
                </div>
            </div>

            <div v-if="($root.payments.contest.contest != null) && ($root.payments.contest.contest.PointBalance > 0) && ($root.isApple == false)" class="row mt-3">
                <div class="col-6">
                    <button disabled style="opacity:0.4" v-on:click.stop="openOverlayCashback" class="btn btn-block btn-orange btn-orange-gray testo-bottoni">USA IL CASHBACK</button>
                </div>
                <div class="col-6">
                    <button v-on:click.stop="showProductsRicariche" class="btn disabled btn-block btn-orange btn-orange-orange testo-bottoni">RICARICA</button>
                </div>
            </div>
            <div v-else class="row mt-3">
                <div class="col-12">
                    <button v-if="$root.isApple == false" v-on:click.stop="showProductsRicariche" class="btn btn-block btn-orange btn-orange-orange testo-bottoni">RICARICA</button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import Vue from 'vue';

export default {
    name: 'UserBorsellino',
    data () {
        return {
            openAccordion: []
        }
    },
    methods: {
        toggleAccordion(index) {
            console.log('toggle');
            if(typeof this.openAccordion[index] == 'undefined') {
                Vue.set(this.openAccordion,  index, {
                    open: true
                } );
            } else {
                this.openAccordion[index].open = !this.openAccordion[index].open;
            }
        },

        openOverlayCashback() {
            this.$root.$refs.pageMan._showAlertSceltaCashback();
        },

        showProductsRicariche() {
            this.$root.$refs.pageMan.showOverlay('productsricaricheoverlay');
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .contrattolink {
        text-decoration: none;
    }

    .scrollaccordion {
        max-height: 300px;
        overflow-x:hidden;
        overflow-y:scroll;
    }
</style>