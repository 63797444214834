<template>
    <div>
        <div class="container-fluid">
            <div class="row mt-5">
                <div class="col-12 text-center">
                    <img id="login-logo" src="img/orangeLogo.svg">
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <p class="text-center titolo-categoria">Crea il tuo account</p>
                    <p class="mb-4 text-center titolo-categoria">Scegli il tuo abbonamento</p>
                </div>
                <div class="col-12 mt-4">
                    <div id="prodsaccordion">
                        <div v-for="prod, index in $root.products.vetrina" :key="'subprod-' + index" style="border:none; border-bottom: 1px solid #666;" class="card orange-text-white">
                            <div v-show="($root.isApple == false) || ($root.isApple == true && prod.Price == 0)" class="card-header collapsed p-0" :id="'heading-' + index">
                                <h5 class="mb-0">
                                    <button v-on:click="toggleAccordion(index)" class="btn btn-block btn-orange btn-outline-gray border-0" data-toggle="collapse" :data-target="'#prodcoll-' + index" aria-expanded="true" aria-controls="collapseOne">
                                        <div v-if="(!(openAccordion.hasOwnProperty(index))) || (!openAccordion[index].open)" class="row">
                                            <div class="col-4 text-left">
                                                <p v-if="prod.Name.toLowerCase().includes('light')" class="titolo-piccolo orange-text-white my-0 py-0">REBORN</p>
                                                <p v-else class="titolo-piccolo orange-text-white my-0 py-0">ORANGE</p>
                                                <p class="titolo-piccolo orange-text-orange my-0 py-0">{{ prod.Name }}</p>
                                            </div>
                                            <div class="col-4 text-center">
                                                <p v-if="prod.Price == 0" class="testo-generico orange-text-orange">Gratuito</p>
                                            </div>
                                            <div class="col-4 text-right">
                                                <p v-if="prod.Price != 0" class="titolo-piccolo py-0 my-0 orange-text-orange">{{ prod.Price }}&euro;</p>
                                                <!-- <p v-if="prod.Price != 0" class="titolo-piccolo py-0 my-0 orange-text-white" style="text-transform:none;">Mese</p> -->
                                            </div>
                                        </div>
                                        <div v-if="(openAccordion.hasOwnProperty(index) ) && (openAccordion[index].open)" class="row">
                                            <div class="col-4 text-left">
                                                <p v-if="prod.Name.toLowerCase().includes('light')" class="titolo-35 orange-text-white my-0 py-0">REBORN</p>
                                                <p v-else class="titolo-35 orange-text-white my-0 py-0">ORANGE</p>
                                                <p class="titolo-35 orange-text-orange my-0 py-0">{{ prod.Name }}</p>
                                            </div>
                                            <div class="col-4 text-center">
                                                <p v-if="prod.Price == 0" class="testo-generico orange-text-orange">Gratuito</p>
                                            </div>
                                            <div class="col-4 text-right">
                                                <p v-if="prod.Price != 0" class="titolo-35 py-0 my-0 orange-text-orange">{{ prod.Price }}&euro;</p>
                                                <!-- <p v-if="prod.Price != 0" class="titolo-piccolo py-0 my-0 orange-text-white" style="text-transform:none;">Mese</p> -->
                                            </div>
                                        </div>
                                    </button>
                                </h5>
                            </div>

                            <div :id="'prodcoll-' + index" class="collapse" :aria-labelledby="'heading-' + index" data-parent="#prodsaccordion">
                                <div class="card-body testo-generico px-0 text-center">
                                    <p class="px-2" style="font-weigth:bold; text-align:justify;" v-html="prod.Description"></p>
                                    <div class="row pb-4">
                                        <div class="col-12 text-center mt-2">
                                            <label for="iubendaconsent">
                                                <input v-model="condizioniAccettate" id="iubendaconsent" type="checkbox" style="height:20px; width:20px;">
                                                <span class="text-white" style="font-weight:bold; height:20px; display:inline-block; vertical-align:top; margin-left:10px;line-height: 20px;">&nbsp;&nbsp;Ho preso visione ed accetto le condizioni del <a target="_blank" href="https://orangepalestre.it/docs/condizioni_generali_contratto_orange.pdf">contratto</a></span>
                                            </label>
                                        </div>
                                    </div>
                                    <button v-if="prod.Price != 0" :disabled="!condizioniAccettate" v-on:click="setAbbonamentoAndShowRegistration(index)" style="margin-left:auto; margin-right:auto;" class="btn testo-bottoni btn-block btn-orange btn-orange-orange">ABBONATI</button>
                                    <button v-if="prod.Price == 0" :disabled="!condizioniAccettate" v-on:click="setAbbonamentoAndShowRegistration(index)" style="margin-left:auto; margin-right:auto;" class="btn testo-bottoni btn-block btn-orange btn-orange-orange">ATTIVA</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'ChooseSubscription',
    data () {
        return {
            openAccordion: [],
            condizioniAccettate: false
        }
    },
    created() {
    },
    methods: {
        toggleAccordion(index) {
            console.log('toggle');
            if(typeof this.openAccordion[index] == 'undefined') {
                Vue.set(this.openAccordion,  index, {
                    open: true
                } );
            } else {
                this.openAccordion[index].open = !this.openAccordion[index].open;
            }
        },

        setAbbonamentoAndShowRegistration(index) {
            console.log('showregistration');
            if(!this.condizioniAccettate) {
                this.$root.$refs.pageMan.showAlert('Sarà necessario accettare le condizioni di contratto.','Ok','');
            } else {
                this.$root.register.registrationSubscriptionIdx = index;
                this.$root.showPage('registrationpage');
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #register-logo {
        max-width:80%;
    }
</style>
