import "core-js/stable";
import "regenerator-runtime/runtime";

import axios from 'axios';
import conf from '../../config/config_local.js';
import Vue from 'vue'
import config from '../../config/config_local'

export default class Scheda {


    constructor() {
        this.iyestoken = null;
        this.evofittoken = null;
        this.loaded = false;
        this.scheda =  {};    
        this.timer =  null;
        this.timerValue =  null;
        this.progress =  0;
        this.isPlaying =  false;
        this.isPersonal = true;      //scheda personale o modello?
        this.modalitaAutomatica =  true;
        this.curSeduta =  0;
        this.curSedutaPlaylist =  [];
        this.totEsercizi = 0;
        this.sedute =  [];
        this.curPlaylistEntry =  null;
        this.curPlaylistEntryIndex =  0;
        this.consigli =  [];
        this.curConsiglio =  '';

        this.reset();
        return this;
    }

    setCredentials(_iyestoken, _evofittoken) {
        this.evofittoken = _evofittoken;
        this.iyestoken = _iyestoken;    
    }

    reset() {
        this.loaded = false;
        this.scheda = null;
        this.consigli = [];
        this.curPlaylistEntry = null;
        this.curPlaylistEntryIndex = 0;
        this.curSedutaPlaylist = [];
        this.sedute = [];
    }

    setup(serverData) {
        console.log('Scheda setup');
        if(serverData) {
            this.scheda = null;
            this.scheda = serverData;
            return this;
        } else {
            console.log('SchedaClass setup: missing server data!');
            return false;
        }
    }

    process() {
        if(this.scheda.dettagli) {
            this.scheda.dettagli.forEach( (dett, index) => {
                //keep it reactive
                Vue.set(this.scheda.dettagli[ index], 'eseguito', false);
            }); 
            this.scheda.descrizione = this.scheda.descrizione.trim().toUpperCase();
            if(this.scheda.descimmagine == '' || !this.scheda.descimmagine)
                this.scheda.descimmagine = 'default';
                
            if(this.scheda.descrizione.includes('*')) {
                let splitDesc = this.scheda.descrizione.split('*');
                if(splitDesc.length == 2) {
                    this.scheda.descrizione = splitDesc[0].trim().toUpperCase();
                    this.scheda.descimmagine = splitDesc[1].trim().toUpperCase();
                }
            }

            if(this.scheda.obiettivo) {
                if(this.scheda.obiettivo.includes('*')) {
                    let obiArr = this.scheda.obiettivo.split('*');
                    this.scheda.obiettivo = obiArr[0];
                    this.scheda.obimmagine = obiArr[1];
                }
            }
    
            this.clearDettaglioScheda();
            this.fillExerciseDetails();
            this.buildSeduteArray();
            this.updateSeduteTotalTime();
    
            this.loaded = true;    
            console.log('Setting curScheda loaded true.');
        } else {
            console.log('curScheda process: missing dettagli');
        }
    }

    prepareSeduta(sedutaIdx) {
        console.log('Preparing seduta ' + sedutaIdx + ' playlist');
        this.curSeduta = sedutaIdx;
        this.buildSedutaPlaylist();
    }

    //data una seduta crea una playlist di playlistEntries da eseguire in sequenza
    //in curSedutaPlaylist
    buildSedutaPlaylist() {

        this.curSedutaPlaylist = [];
        
        //add the warmup to playlist
        this.curSedutaPlaylist.push({
            tipo: 'warmup',
            atempo: true,
            durata: 6,
            ripetizioni: 1,
            intensita: 0,
            esercizio: null,
            serie: 0,
            next: null,
            recupero: 0,
            idserie: 0
        });

        let esIdx = 0;
        while(esIdx < this.sedute[ this.curSeduta ].esercizi.length) {
            let curEserc = this.sedute[ this.curSeduta ].esercizi[ esIdx ];
            switch(curEserc.tipo)  {
                case 'A':
                case 'I':
                    let trovati = this.addEserciziCircuitoToSedutaPlaylist( curEserc, esIdx );
                    esIdx += trovati;
                    break;   
                case 'E':
                    this.addEsercizioToSedutaPlaylist( curEserc, esIdx );
                    break;             
                case 'N':
                    break;
            }    
            //this also skips the E for circuits 
            esIdx++;
        }

        //add the endreward to playlist
        this.curSedutaPlaylist.push({
            tipo: 'endreward',
            atempo: true,
            durata: 5,
            ripetizioni: 1,
            intensita: 0,
            esercizio: null,
            serie: 0,
            next: null,
            recupero: 0,
            idserie: -1
        });

        this.buildNextLinksInCurSedutaPlaylist();
    }
        
    getEsercizioCurSeduta(esIndex) {
        return this.sedute[ this.curSeduta ].esercizi[ esIndex ];
    }

    setEsesrcizioEseguito(esindex) {
        this.sedute[ this.curSeduta ].esercizi[ esIndex ].eseguito = true;
    }

    //add teh NEXT info to every sedutaPlaylist entry
    buildNextLinksInCurSedutaPlaylist() {
        this.curSedutaPlaylist.forEach( (plistElem, index) => {
            if(index < this.curSedutaPlaylist.length - 1) {
                this.curSedutaPlaylist[index].next = this.curSedutaPlaylist[index + 1].esercizio;
            }
        });
    }


    getCircuitoEserciziCount(circuitoIdx, _curTipo) {
        let curTipo = 'I';
        let tmpIdx = circuitoIdx;
        let foundIdx = -1;
        let trovati = 0;
        while( (curTipo != 'F') && (tmpIdx < this.scheda.dettagli.length) && (curTipo != 'B')) {
            curTipo = this.sedute[ this.curSeduta].esercizi[ tmpIdx ].tipo;
            //fine circuito o superserie
            if(curTipo == 'F' || curTipo == 'B')
                foundIdx = tmpIdx;
            if(curTipo == 'E')
                trovati++;

            tmpIdx++;
        }
        
        return trovati;
    }


    addEsercizioToSedutaPlaylist(curEserc, esIdx) {
        let esercSerie = JSON.parse(curEserc.serie);
        let isCardio = (esercSerie.tipo == "car");
        let recupero = this.evofitParseTempoRecupero(curEserc.recupero);
        if(curEserc.tipo == 'B') {
            recupero = 0;
        }
        if(isCardio) {
            //esercizio cardio (a tempo)
            let valoreIdx = 0;
            while(valoreIdx < esercSerie.valori.length) {
                if(esercSerie.valori[ valoreIdx ].val1 != "") {
                    this.curSedutaPlaylist.push({
                        tipo: 'esercizio',
                        atempo: true,
                        durata: this.parseTempoEsercizio(esercSerie.valori[ valoreIdx ].val1),
                        ripetizioni: 1,
                        intensita: 0,
                        esercizio: curEserc,
                        serie: valoreIdx + 1,
                        next: null,
                        recupero: recupero,
                        idserie: valoreIdx
                    });
                    if(recupero > 0) {
                        this.curSedutaPlaylist.push({
                            tipo: 'recupero',
                            atempo: true,
                            durata: recupero,
                            ripetizioni: 1,
                            intensita: 0,
                            esercizio: null,
                            serie: 0,
                            next: null,
                            recupero: 0,
                            idserie: (valoreIdx < esercSerie.valori.length - 1) ? valoreIdx + 1 : 0
                        });        
                    }
                }
                valoreIdx++;
            }
        } else {
            //esercizio muscolare (a ripetizioni)
            let valoreIdx = 0;
            while(valoreIdx < esercSerie.valori.length) {
                if(esercSerie.valori[ valoreIdx ].val1 != "") {
                    this.curSedutaPlaylist.push({
                        tipo: 'esercizio',
                        atempo: false,
                        durata: 0,
                        ripetizioni: parseInt(esercSerie.valori[ valoreIdx ].val1),
                        intensita: esercSerie.valori[ valoreIdx ].val2,
                        esercizio: curEserc,
                        serie: valoreIdx + 1,
                        next: null,
                        recupero: recupero,
                        idserie: valoreIdx
                    });
                    if(recupero > 0) {
                        this.curSedutaPlaylist.push({
                            tipo: 'recupero',
                            atempo: true,
                            durata: recupero,
                            intensita: 0,
                            ripetizioni: 1,
                            esercizio: null,
                            serie: 0,
                            next: null,
                            recupero: recupero,     //so we can show "next recupero" (must be fixed for the last one) during the recupero screen
                            idserie: (valoreIdx < esercSerie.valori.length - 1) ? valoreIdx + 1 : 0
                        });        
                    }
                }
                valoreIdx++;
            }
        }
    }

    //aggiunge gli esercizi di un circuito alla playlist della seduta
    addEserciziCircuitoToSedutaPlaylist(esInizCirc, esIdx) {
        let trovati = this.getCircuitoEserciziCount(esIdx);
        let circTempo = (esInizCirc.serie.includes('<T>') || esInizCirc.serie == 'A');
        let recuperoFinaleCircuito = this.evofitParseTempoRecupero( esInizCirc.recupero );
        //current esIx points to the circuito inizio
        let esCircIdx = esIdx + 1;
        if(circTempo) {
            //circuito a tempo, dobbiamo solo aggiungere gli esercizi fino a F o B
            let durataTotCircuito = this.evofitParseTempoCircuito( esInizCirc.serie.replace('<T>','') );
            let durataEsercizi = Math.ceil( durataTotCircuito / trovati );
            //add all the excercises
            while( (this.sedute[ this.curSeduta ].esercizi[ esCircIdx ].tipo != 'F') && (this.sedute[ this.curSeduta ].esercizi[ esCircIdx ].tipo != 'B')) {
                let esCirc = this.sedute[ this.curSeduta ].esercizi[ esCircIdx ];
                if( (esCirc.tipo != 'F') && (esCirc.tipo != 'B')){
                    //add this excercise (with SERIE) to the playlist
                    this.addEsercizioToSedutaPlaylist(esCirc, esCircIdx);
                }
                esCircIdx++;
            }

            //aggiunge il recupero del circuito
            if(recuperoFinaleCircuito > 0) {

                //se l'ultimo è un recupero POP del recupero e aggiunge il recupero del circuito
                if(this.curSedutaPlaylist[ this.curSedutaPlaylist.length - 1].tipo == 'recupero') {
                    this.curSedutaPlaylist.pop();
                }

                this.curSedutaPlaylist.push({
                    tipo: 'recupero',
                    atempo: true,
                    durata: recuperoFinaleCircuito,
                    ripetizioni: 1,
                    intensita: 0,
                    esercizio: null,
                    next: null,
                    recupero: 0,
                    idserie: -1
                });
            }
        } else {
            //circuito a giri, dobbiamo inserire gli esercizi fino a F ripetute volte
            //potrebbe essere una superserie da 1 giro 
            let giriCircuito = 1;
            if(esInizCirc.serie.includes('G')) {
                giriCircuito = parseInt( esInizCirc.serie.replace('<G>','') );
            }
            let curGiro = 0;
            while(curGiro < giriCircuito) {
                esCircIdx = esIdx + 1;
                while( this.sedute[ this.curSeduta ].esercizi[ esCircIdx ].tipo != 'F' && this.sedute[ this.curSeduta ].esercizi[ esCircIdx ].tipo != 'B') {
                    let esCirc = this.sedute[ this.curSeduta ].esercizi[ esCircIdx ];
                    if(esCirc.tipo != 'F' && esCirc.tipo != 'B') {
                        //add this excercise (with SERIE) to the playlist
                        this.addEsercizioToSedutaPlaylist(esCirc, esCircIdx);
                    }
                    esCircIdx++;
                }
                curGiro++; 
            }
            if(recuperoFinaleCircuito > 0) {
                //se l'ultimo è un recupero POP del recupero e aggiunge il recupero del circuito
                if(this.curSedutaPlaylist[ this.curSedutaPlaylist.length - 1].tipo == 'recupero') {
                    this.curSedutaPlaylist.pop();
                }

                this.curSedutaPlaylist.push({
                    tipo: 'recupero',
                    atempo: true,
                    durata: recuperoFinaleCircuito,
                    intensita: 0,
                    ripetizioni: 1,
                    esercizio: null,
                    next: null,
                    recupero: 0,
                    idserie: -1
                });
            }   
        }     
        return trovati;       
    }

    clearDettaglioScheda() {            
        if(!this.scheda.dettagli)
            return false;

        this.scheda.dettagli.forEach( (dett) => {
            if(dett.descrizione) {
                let htmlIdx = dett.descrizione.indexOf('&nbsp');
                if(htmlIdx != -1) {
                    let tmpDescrizione = dett.descrizione;
                    //strip the name only
                    dett.descrizione = dett.descrizione.substring(htmlIdx, 0);
                    //retrieve video url
                    let vidstart = tmpDescrizione.indexOf('http');
                    let vidend = tmpDescrizione.indexOf('", "_system');
                    let res = tmpDescrizione.substring(vidstart, vidend);
                    dett.extvideo = res;
                }  
                //dopo l'* ci sono i dettagli per gli istruttori, da nascondere
                if(dett.descrizione.includes('*')) {
                    let nomeArr = dett.descrizione.split('*');
                    if(nomeArr.length > 0)
                        dett.descrizione = nomeArr[0].trim();
                }
            }
        });
    }

    //per ogni esercizio della scheda ne recupera i dettagli via API
    fillExerciseDetails() {
        if(!this.evofittoken)
            return false;
        
        if(!this.iyestoken)
            return false;

        if(!this.scheda)
            return false;

        this.scheda.dettagli.forEach( (dett) => {
            if(dett.tipo == "E") {
                axios({
                    method: 'POST',
                    url: config.serverUrl + '/evofit/esercizio',
                    data: {
                        evofittoken: this.evofittoken,
                        iyestoken: this.iyestoken,
                        codEsercizio: dett.codice
                    }
                })
                .then( (res) => {
                    //console.log(res);
                    dett.info = res.data.data;

                    if(dett.info.linkvideo == "" || dett.info.linkvideo == null)
                        dett.info.linkvideo = "https://evolutionfitIMG_orange.teamsystem.com/imgEserciziEVF/video/";

                    if(dett.extvideo == null)
                        dett.extvideo = "";

                    if(dett.extvideo.includes('.swf'))
                        dett.extvideo = "";

                    if(dett.extvideo.includes('.mp4'))
                        dett.extvideo = "";

                    dett.extvideo = dett.extvideo.trim();
                    //replace the HTML from evofit with mine
                    if(dett.extvideo && dett.extvideo != "") {
                        if(dett.extvideo.includes('.html')) 
                            dett.extvideo = dett.info.linkvideo + dett.codice + '.mp4';
                    } else if(dett.extvideo == "") {
                        dett.extvideo = dett.info.linkvideo + dett.codice + '.mp4';
                    }

                    //strip html from consigli
                    if(dett.info.descCons)
                        if(dett.info.descCons.includes('<img')) 
                            dett.info.descCons = dett.info.descCons.split('<img')[0];
                    if(dett.info.descErr)                            
                        if(dett.info.descErr.includes('<img')) 
                            dett.info.descErr = dett.info.descErr.split('<img')[0];
                    if(dett.info.descIniziale)                                    
                        if(dett.info.descIniziale.includes('<img')) 
                            dett.info.descIniziale = dett.info.descIniziale.split('<img')[0];
                    if(dett.info.descMov)                            
                        if(dett.info.descMov.includes('<img')) 
                            dett.info.descMov = dett.info.descMov.split('<img')[0];
                    if(dett.info.descResp)                            
                        if(dett.info.descResp.includes('<img')) 
                            dett.info.descResp = dett.info.descResp.split('<img')[0];
                    if(dett.info.descScopo)                            
                        if(dett.info.descScopo.includes('<img')) 
                            dett.info.descScopo = dett.info.descScopo.split('<img')[0];

                })
                .catch( (err) => {
                    console.log(err);
                });    
            }
        });
    }

    //caricati i dettagli scheda li suddivide nelle varie sedute
    buildSeduteArray() {
        this.scheda.dettagli.forEach( (dett) => {
            let seduta = dett.seduta;
            let foundIdx = -1;
            //abbiamo già aggiunto questa seduta?
            this.sedute.forEach( (sed, index) => {
                if(sed.id == seduta) {
                    foundIdx = index;
                }
            });
            if(dett.serie && dett.tipo == 'E') {
                dett.serieObj = JSON.parse(dett.serie);
            } else {
                dett.serieObj = {
                    tipo: '',
                    valori: []
                };
            }

            if(foundIdx == -1) {
                //nuova seduta
                this.sedute.push({
                    id: seduta,
                    numEsercizi: 1,
                    esercizi: [ dett ],
                    durataMin: '',
                    durataSec: ''
                })
            } else {
                //aggiorniamo una seduta già pushata
                this.sedute[ foundIdx ].numEsercizi += 1;
                this.sedute[ foundIdx ].esercizi.push( dett );
            }
        });

        //calcolo totale degli esercizi della scheda
        let totEs = 0;
        this.sedute.forEach( (seduta) => {
            totEs += seduta.esercizi.length;
        });
        this.totEsercizi = totEs;
    }

    //after building the currentSedutaPlaylist we update the seduta total duration
    updateSeduteTotalTime() {
        let totTimeSeduta = 0;
        let totTimeEsercizio = 0;
        let durMin = 0;
        let durSec = 0;
        let circuitoGiri = 1;
        this.sedute.forEach( (seduta) => {
            totTimeSeduta = 0;
            circuitoGiri = 1;
            seduta.esercizi.forEach( (esercizio) => {
                totTimeEsercizio = 0;
                if(esercizio.tipo == 'I') {
                    if(esercizio.serie.includes('<G>')) {
                        let tmpGiri = esercizio.serie.replace('<G>', '');
                        circuitoGiri = parseFloat( tmpGiri );
                    }
                }
                if(esercizio.tipo == 'F') 
                    circuitoGiri = 1;

                esercizio.serieObj.valori.forEach( (val) => {
                    if(esercizio.serieObj.tipo == 'car') {
                        totTimeEsercizio += this.parseTempoEsercizio(val.val1);
                    } else {
                        if(val.val1 != '')
                            totTimeEsercizio += (parseInt(val.val1) * 6);
                    }
                });
                totTimeSeduta += (totTimeEsercizio * circuitoGiri);
            });
            durMin = String(Math.floor( totTimeSeduta / 60));
            durSec = String(totTimeSeduta % 60);
            seduta.durataMin = durMin.padStart(2, '0');
            seduta.durataSec = durSec.padStart(2,'0');
        });            
    }


    //############################################################################################################################################
    //############################################################################################################################################
    //############################################################################################################################################
    //## PARSING UTILS ###########################################################################################################################
    //############################################################################################################################################
    //############################################################################################################################################
    //############################################################################################################################################

    //converte una stringa di tipo 2.5 in 120
    parseTempoEsercizio(tempoCardioString) {
        if(tempoCardioString == '')
            return 0;

        tempoCardioString = tempoCardioString.replace('.',',');
        tempoCardioString = tempoCardioString.replace(':',',');
        let minSecArray = tempoCardioString.split(',');
        let tempoTot = 0;
        if(minSecArray) {
            tempoTot = parseInt(minSecArray[0]) * 60;
            if(minSecArray.length == 2) {
                tempoTot += parseInt(minSecArray[1]);
            }    
        }
        return tempoTot;
    }

    convertSecondsToString(_inseconds) {
        let _seconds = 0;
        if(_inseconds.includes('sec')) {
            _seconds = this.evofitParseTempoRecupero(_inseconds);
        } else {
            _seconds = parseInt(_inseconds);
        }
        let min = String(Math.floor(_seconds / 60));
        let minStr = min.padStart(2, '0');

        let sec = String(Math.floor(_seconds % 60));
        let secStr = sec.padStart(2, '0');

        return minStr + ':' + secStr;
    }

    //parse a time in M min S sec
    evofitParseTempoRecupero(recuperoStr) {
        let newStr = recuperoStr.replace(/\s+/g,'');
        newStr = newStr.trim().replace('min',':');
        newStr = newStr.replace('sec','');
        //are we missing th minutes?  :S
        if(newStr.substr(0,1) == ':') 
            newStr = '0' + newStr;

        let recup = this.evofitParseTempoCircuito( newStr );
        return recup;
    }

    evofitParseTempoCircuito(tempoStr) {
        let newStr = tempoStr.replace('.',':');
        let tempo = newStr.split(':');
        let min = 0;
        let sec = 0;
        if(tempo.length == 2) {
            min = parseInt(tempo[0]);
            sec = 0;
            if(tempo[1] != "") {
                if(tempo[1].length == 2)
                    sec = parseInt(tempo[1]);    
                else
                    sec = parseInt(tempo[1]) * 10;
            } else {
                sec = 0;
            }
        } else if (tempo.length == 1) {
            min = parseInt(tempo[0]);
            sec = 0;
        }
        return (min * 60) + sec;
    }



}


