<template>
    <div ref="trainerMainCore">

        <div class="container-fluid mt-3 px-0">
            <div class="row pt-3 mt-5 titolo-categoria orange-text-white">
                <div class="col-12 text-center">
                    <div ref="titleSlider" id="title-slider" style="font-size:1.0rem;">
                        <div v-on:click="clickTitle(0)" id="title-0"  class="title-slider-slide titolo-piccolo">
                            <span class="slide-selected">PROGRAMMI</span>
                        </div>
                        <div v-on:click="clickTitle(1)" id="title-1"  class="title-slider-slide titolo-piccolo">
                            <span>MIO PROGRAMMA</span>
                        </div>
                        <div v-on:click="clickTitle(2)" id="title-2"  class="title-slider-slide titolo-piccolo">
                            <span>STATISTICHE</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <img id="trainer-logo" src="img/logo_rbo.png">
                </div>
                <div class="col-12 mt-5 text-center titolo-grande">
                    PROGRAMMI DI ALLENAMENTO
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12 mt-4 text-center">
                    <div class="listobiettivi-slide" v-for="obiettivo,index in $root.evofit.modsched.obiettivi" v-bind:key="'obb-' + index" :index="index">
                        <div v-on:click.stop="$root.evofitShowObiettivo(index)" class="listobiettivi-preview">
                            <div class="listobiettivi-preview-image" v-bind:style="{ backgroundImage: 'url(img/obiettivi/OBIETTIVO1_1/' + obiettivo.immagine + '.jpeg)' }">
                                <!-- <span style="position:absolute; left:10px; top:10px; width:20px; border-radius:5px; height:20px; background-color:rgba(0,0,0,0.5); color:#fff;">{{ obiettivo.immagine }}</span> -->
                            </div>
                            <div class="listobiettivi-preview-darkbox">
                                <div class="titolo-obiettivo">
                                    <b style="display:block; text-transform: uppercase; font-size:1.1rem; font-weight: bold; white-space:normal;">{{ obiettivo.descrizione }}</b>
                                    <span style="font-size:0.9rem;">{{ obiettivo.schedeCount }} schede</span>
                                </div>
                            </div>
                        </div>        
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'TrainerMain',
    data () {
        return {
            curTitleSelected: 0,
            curOffset: 0
        }
    },
    methods: {
        clickTitle( _idx) {
            this.curTitleSelected = _idx;
            let elem = document.getElementById('title-' + _idx);
            if(_idx == 1) {
                this.$root.evofitGetAndShowMyTraining(false);
            } else if(_idx == 2) {
                this.$root.rboShowPage();
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #trainer-logo {
        width:20%;
        max-width: 130px;
    }

    #title-slider {
        display:block;
        word-break: unset;
        white-space: nowrap;
        overflow-x: scroll;
        transform: translateX(0);
    }

</style>
