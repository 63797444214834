import axios from 'axios';
import config from '../config/config_local.js'

export default  {
    data() {
        return {
            preferences: {
            },
            iyessettings: [],
            policiesUrls: {
                privacy: '',
                regolamento: '',
                condizioni: ''
            }
        }
    },
    created() {
        this.getIYESSettings();
    },
    methods: {

        getIYESSettings() {
            axios({
                method: 'post',
                url: config.serverUrl + '/prefs/iyessettings',
                data: {
                    iyestoken: 'faketoken'
                }
            })
            .then( (sett) => {
                console.log(sett);
                this.iyessettings = sett.data.data;

                //retrieve polcies url
                this.policiesUrls.privacy = this.getIYESSettingByName('WEBPrivacyURL');
                this.policiesUrls.regolamento = this.getIYESSettingByName('WEBRegolamentoURL');
                this.policiesUrls.condizioni = this.getIYESSettingByName('WEBContrattoURL');
            })
            .catch( (setterr) => {
                console.log(setterr);
            });
        },

        getIYESSettingByName(_name) {
            let found = false;
            let i = 0;

            while(!found && i < this.iyessettings.length) {
                if(this.iyessettings[i].Name == _name) {
                    found = true;
                } else {
                    i++;
                }
            }

            if(found) {
                if(this.iyessettings[i].Type == "isBool") {
                    return (this.iyessettings[i].Value == "-1");
                } else {
                    return this.iyessettings[i].Value;
                }
            } else {
                return null;
            }
        },

        preferencesSetupDefaults() {
            console.log('Implemente preferences setupPreferencesDefaults');
        }
    }
}