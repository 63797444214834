<template>
    <div>
        <div class="container-fluid">
            <div class="row my-5">
                <div class="col-12 mt-5 text-center">
                    <p class="titolo-piccolo orange-text-black text-center mt-4 mb-2 pb-0" style="text-transform:uppercase;">DISDICI O SOSPENDI</p>
                    <p class="testo-generico orange-text-black text-center" >Puoi sospendere temporaneamente un abbonamento o disdirlo definitivamente.</p>
                    <p v-if="$root.subscriptions.scadenzaAbbonamento != null" class="testo-generico orange-text-black text-center mt-2" >In caso di disdetta la stessa deve avvenire entro il <strong>{{ $root.subscriptions.scadenzaAbbonamento.clone().subtract(2,'days').format('DD/MM/YYYY') }}</strong></p>
                </div>
            </div>

            <div ref="sospendi-section" id="sospendiaccordion" class="row text-center">
                <div v-if="$root.subscriptions.sospensione.sospensioniAttive.length == 0" class="col-12">

                     <div lass="card bg-secondary">

                        <div :id="'sospcoll-' + 0" :aria-labelledby="'heading-' + 0" data-parent="#sospendiaccordion">
                            <!--  SE BORSELLINO SUPERIORE A 10 -->

                            <div v-if="($root.payments.epurses.epurseBalance >= 10 || $root.subscriptions.currentAbbonamentoName.indexOf('EX') == -1) && $root.subscriptions.sospensione.durateLista.length > 0" class="card-body testo-generico orange-text-black">
                                <div class="row no-gutters">

                                    <div class="col-12">
                                        <p><strong>{{ $root.subscriptions.currentAbbonamentoName }}</strong></p>
                                        <p>
                                            Seleziona la data di inizio sospensione e per quanti gironi vuoi sospendere l'abbonamento.
                                        </p>
                                        <p v-if="$root.subscriptions.currentAbbonamentoName.indexOf('EX') !== -1">
                                            Il costo della sospensione è di <strong>10 &euro; ogni 30 giorni</strong>. Scegli il periodo e la durata! Ricorda che la sospensione sarà attiva dalla data inizio sospensione e che al termine il tuo abbonamento ripartirà!<br>
                                        </p>
                                        <p v-if="$root.subscriptions.currentAbbonamentoName.indexOf('EX') == -1">
                                            La sospensione è <strong>gratuita</strong>, Scegli la data inizio della tua sospensione e la durata. Ricorda che la sospensione sarà attiva dalla data inizio sospensione e che al termine il tuo abbonamento ripartirà!<br>
                                        </p>
                                    </div>
                                </div>
                        
                                <div class="row no-gutters">
                                    <div style="display:none;" class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="abbonamento">Abbonamento</label>
                                            <select id="abbonamento" v-model="$root.subscriptions.sospensione.abbonamentoIdx" class="form-control form-control-light">
                                                <option v-for="abbonam, index in $root.subscriptions.sospensione.abbonamentiLista" :key="'sospabb' + index" :value="index">{{ abbonam.DescrizioneAbbonamento }} {{ abbonam.DataFine }}</option>
                                            </select>
                                        </div>
                                    </div>
                        
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="motivo">Motivo</label>
                                            <select id="motivo" v-model="$root.subscriptions.sospensione.motivoIdx" class="form-control">
                                                <option v-for="motivo, index in $root.subscriptions.sospensione.motiviLista" :key="'sospmot' + index" :value="index">{{ motivo }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                        
                                <div class="row no-gutters">
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="giorni">Data Inizio Sospensione</label>
                                            <select id="giorni" v-model="$root.subscriptions.sospensione.giornoInizioIdx" class="form-control">
                                                <option v-for="giorno, index in $root.subscriptions.sospensione.giorniInizioLista" :key="'sospdate' + index" :value="index">{{ giorno.testo }}</option>
                                            </select>
                                        </div>
                                    </div>
                        
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="mesi">Durata Sospensione</label>
                                            <select id="mesi" v-model="$root.subscriptions.sospensione.durataMesi" class="form-control">
                                                <option value="1">1 Mese</option>
                                                <!-- <option value="2">2 Mesi</option> -->
                                                <!-- <option value="3">3 Mesi</option> -->
                                            </select>
                                        </div>
                                    </div>
                                </div> 
                                <div class="row no-gutters">
                                    <div class="col-12 testo-generico">
                                        <button v-on:click="$root.abbonamentiDoSospensione()" class="btn btn-block testo-bottoni btn-orange btn-orange-orange" style="min-width:130px;">SOSPENDI</button>
                                    </div>
                                </div>
                            </div>
                            <!--  SE BORSELLINO INFERIORE A 10 -->

                            <div v-if="$root.payments.epurses.epurseBalance < 10 && $root.subscriptions.currentAbbonamentoName.indexOf('EX') !== -1 && $root.subscriptions.sospensione.durateLista.length > 0" class="card-body testo-generico orange-text-black">
                                <div class="row no-gutters">

                                    <div class="col-12">
                                        <p><strong>{{ $root.subscriptions.currentAbbonamentoName }}</strong></p>
                                        <p>
                                            Il credito sul tuo borsellino <strong>non è sufficiente per la sospensione</strong>.
                                        </p>
                                        <p>
                                            Il costo della sospensione è di <strong>10 &euro; ogni 30 giorni</strong>. Ricorda che la sospensione sarà attiva dalla data inizio sospensione e che al termine il tuo abbonamento ripartirà!<br>
                                        </p>
                                    </div>
                                </div>
                        
                        
                                <div class="row no-gutters">
                                    <div class="col-12 testo-generico">
                                        <button v-on:click.stop="showOverlayRicariche" class="btn btn-block btn-orange btn-orange-orange testo-bottoni">RICARICA</button>
                                    </div>
                                </div>
                            </div>

                            <!-- <div v-if="$root.subscriptions.sospensione.durateLista.length == 0" class="card-body testo-generico orange-text-black">
                                <div class="row no-gutters">

                                    <div class="col-12">
                                        <p><strong>{{ $root.subscriptions.currentAbbonamentoName }}</strong></p>
                                        <p>
                                            Il tuo abbonamento attuale non prevede la sospensione. Puoi passare ad un abbonamento più economico.
                                        </p>
                                    </div>
                                </div>
                        
                        
                                <div class="row no-gutters">
                                    <div class="col-12 testo-generico">
                                        <button v-on:click="$root.showPage('userabbonamentocambiapage', false)" class="btn btn-block btn-orange btn-orange-orange">CAMBIA ABBONAMENTO</button>
                                    </div>
                                </div>

                            </div> -->


                        </div>  <!-- end accordion container -->

                    </div>
                </div>

                <div class="col-12">
                    <div v-if="$root.subscriptions.disdetta.abbonamentiLista.length != 0" class="row no-gutters mt-5">
                        <div v-if="$root.payments.rateScadute == false" class="col-12 testo-generico">
                            <button v-on:click="tryDisdetta()" class="btn btn-block testo-bottoni btn-orange btn-outline-white orange-text-black" style="min-width:130px;">DISDICI</button>
                        </div>
                        <div v-if="$root.payments.rateScadute == true" class="col-12 testo-generico">
                            <p class="orange-text-black testo-generico">Impossibile disdire poichè esistono <a href="#" class="orange-text-black testo-generico orange-link-dark" v-on:click.stop="$root.$refs.pageMan.pageBack()">rate insolute</a>.</p>
                        </div>
                    </div>
                </div>

            </div> <!-- end sospendi section -->

        </div>
    </div>
</template>

<script>
import axios from 'axios'
import config from '../../../config/config_local.js'
import Vue from 'vue'

export default {
    name: 'UserDisdette',
    data () {
        return {
            openAccordion: []
        }
    },
    methods: {
        toggleAccordion(index) {
            console.log('toggle');
            if(typeof this.openAccordion[index] == 'undefined') {
                Vue.set(this.openAccordion,  index, {
                    open: true
                } );
            } else {
                this.openAccordion[index].open = !this.openAccordion[index].open;
            }
        },

        showOverlayRicariche() {
            this.$root.$refs.pageMan.showOverlay('productsricaricheoverlay');
        },

        tryDisdetta() {
            if(this.$root.subscriptions.sospensione.giorniInizioLista.length > 0) {
                let alrtTxt = 'Hai la possibilità di sospendere il tuo abbonamento <b>gratuitamente</b> per un mese.<br> Sei sicuro di voler disdire il tuo abbonamento?';
                this.$root.$refs.pageMan.showAlert(alrtTxt, 'Conferma','Annulla')
                .then( (conferma) => {
                    this.$root.abbonamentiTryDisdetta();
                })
                .catch( (annulla) => {  
                    //this.toggleAccordion(0);
                });
            } else {
                this.$root.abbonamentiTryDisdetta();
            }            
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #centro-logo {
        max-width:80%;
    }

</style>
