<template>
    <div>
        <div class="container-fluid">
            <div class="row pt-3 mt-5 titolo-categoria orange-text-white">
                <div class="col-4 text-center" style="font-size:1.0rem;">
                    <div v-on:click="clickTitle(0)" id="title-0"  class="title-slider-slide titolo-piccolo">
                        <span class="slide-selected" style="text-decoration:none;">NEWS</span>
                    </div>
                </div>
                <div class="col-4 text-center" style="font-size:1.0rem;">
                    <div v-on:click="clickTitle(1)" id="title-1"  class="title-slider-slide titolo-piccolo">
                        <span style="text-decoration:none;">PALESTRE</span>
                    </div>
                </div>
                <div class="col-4 text-center" style="font-size:1.0rem;">
                    <!-- <div v-on:click="clickTitle(2)" id="title-2"  class="title-slider-slide titolo-categoria">
                        <span>CONTATTI</span>
                    </div> -->
                </div>
            </div>
            <div class="row mt-5 pt-5" style="max-width:600px; margin-left:auto; margin-right:auto;">
                <div class="col text-center">
                    <img id="login-logo" src="img/orangeLogo.svg">
                    <p class="titolo-grande">NEWS</p>
                </div>
            </div>
            <div v-for="(news,index) in $root.news" :key="'news-' + index" class="row mb-5" style="max-width:600px; margin-left:auto; margin-right:auto;">
                <div class="col-12 text-center py-4 px-0">
                    <img :src="news.Image" style="width:100%; margin-left:auto; margin-right:auto; display:block;">
                </div>
                <div class="col-12 py-2 text-center p-2">
                    <h4 class="titolo-35 orange-text-white my-0"><strong>{{ news.Name }}</strong></h4>
                </div>
                <div class="col-12 py-2 orange-text-white testo-generico text-justify" v-html="news.Description">
                </div>
                <div v-if="news.Link" class="col-12 text-center">
                    <a v-if="!news.Link.includes('mostrapagina')" :href="news.Link" target="_news" style="display:block; position:relative; line-height:33px;" class="btn pt-2 btn-block testo-bottoni btn-orange btn-orange-orange">Dettagli</a>
                    <a v-if="news.Link.includes('mostrapagina')" v-on:click.stop="mostraPagina(news)" target="_news" style="display:block; position:relative; line-height:33px;" class="btn pt-2 btn-block testo-bottoni btn-orange btn-orange-orange">Dettagli</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NewsPage',
    data () {
        return {
            curTitleSelected: 0
        }
    },
    methods: {
        clickTitle( _idx) {
            this.curTitleSelected = _idx;
            let elem = document.getElementById('title-' + _idx);
            if(_idx == 1) {
                this.$root.showPage('listacentripage', false);
            } else if(_idx == 2) {
                this.$root.showPage('contattipage', false);
            }
        },

        mostraPagina(news) {
            let newsLink = news.Link;
            let tmpArr = newsLink.split(':');
            let pagina = '';
            if(tmpArr.length > 1) {
                pagina = tmpArr[1];
                this.$root.showPage(pagina);
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #login-logo {
        max-width:40%;
    }

</style>
