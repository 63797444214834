<template>
    <div>
        <div class="container-fluid p-0">
            <div id="videoContainer">
                <iframe v-if="$root.player.playerUseNative == false" :src="$root.player.playerCurrentVideoUrl" style="width:100%; height:100%; position:relative; display:block; border:none;" frameborder="0" donotallowfullscreen ></iframe>
                <!-- <div class="overlay">
                    <span><i class="fas fa-play button"></i></span>
                </div> -->
                <div id="video">
                    <div v-if="$root.player.playerUseNative == true" id="video_overlay"><span id="video_overlay_text"><i class="fab fa-chromecast"></i>&nbsp;Casting...</span></div>
                    <video id="video_element" ref="video_element" v-if="$root.player.playerUseNative == true" controlsList="nodownload">
                        <!-- <source :src="$root.player.playerCurrentVideoUrl"> -->
                    </video>
                </div>
                <div id="controls">
                    <div id="play_controls" class="playControls">
                        <span class="playBtn" id="play_btn"><span class="icon-play"></span></span>
                        <span class="pauseBtn" id="pause_btn"><span class="icon-pausa"></span></span>
                    </div>
                    <div class="sliderContainer">
                        <input type="range" class="slider" id="progress" min="1" max="100">
                    </div>
                    <div class="volume">
                        <div class="icon">
                            <span id="audio_on"><i class="fas fa-volume-up"></i></span>
                            <span id="audio_off" style="display:none;"><i class="fas fa-volume-mute"></i></span>
                        </div>      
                        <div id="intensityBar" class="sliderContainer">
                            <input type="range" class="slider" id="intensity" min="1" max="100">
                        </div>
                    </div>
                    <div id="cast_button_container" class="cast">
                        <google-cast-launcher id="cast_button"></google-cast-launcher>
                        <!-- <div class="icon">
                        <i class="fab fa-chromecast"></i>
                        </div> -->
                    </div>
                </div>
            </div>



            <!-- <div class="container" id="video-description" v-if="(this.screenOrientation == 0) && (playerCurrentVideo != null)"> -->
            <div class="container" id="video-description" v-if="(($root.player.screenOrientation == 0)) && ($root.player.playerCurrentVideo != null)">
                <div class="row p-2 testo-generico orange-text-white mt-3">
                    <div class="col-12">
                        <h5><strong>{{ $root.player.playerCurrentVideo.name.toUpperCase() }}</strong></h5>
                        <h5>{{ $root.player.playerCurrentVideo.durata }} min</h5>
                        <p>{{ $root.player.playerCurrentVideo.description }}</p>
                    </div>
                </div>    
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: 'RebornPlayer',
    data () {
        return {
        }
    },
    created() {

    },
    methods: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
