import axios from 'axios';
import config from '../config/config_local.js'

export default  {
    data() {
        return {
            preferiti: [],
            preferitiLoaded: false            
        }
    },
    created() {
    },
    computed: {
    },
    methods: {
        isPreferito(_type,_prefid) {
            let found = false;
            let i = 0;
            let pridstr = String(_prefid);
            while(i < this.preferiti.length && found == false) {
                if( (this.preferiti[i].pref_type == _type) && (this.preferiti[i].pref_id == pridstr)) {
                    found = true;
                }

                if(!found) {
                    i++;
                }
            }

            return found;
        },

        preferitiGetAll() {
            if(this.user.iyestoken) {
                axios.post( config.serverUrl + '/preferiti/getall', {
                    iyestoken : this.$root.user.iyestoken,
                    userid: this.$root.user.iyesUserId
                })
                .then( (resmy) => {
                    if(resmy.data.error) {
                        console.log("WARNING! Cannot retrieve preferiti.");
                        console.log(resmy);
                    } else {
                        console.log('Got Preferiti.');
                        let prefs = [];
                        resmy.data.data.forEach( (pref) => {
                            prefs.push( pref );
                            prefs[ prefs.length - 1].videoObj = null;
                            prefs[ prefs.length - 1].schedaObj = null;
                        });
                        this.preferiti = prefs; 
                        this.preferitiLoaded = true;  
                        if(this.reborn.videosLoaded == true) {
                            this.preferitiAssignSchedeToPreferiti();
                            this.preferitiAssignVideosToPreferiti();
                        }
                    }
                })
                .catch( (errmy) => {
                    console.log('WARNING! Cannot retrieve preferiti');
                    console.log(errmy);
                    this.preferiti = [];
                });    
            }         
        },

        //this is called by rebornMixin when the videos are loaded
        preferitiAssignVideosToPreferiti() {
            console.log('Assigning preferiti videos');
            this.preferiti.forEach( (pref, index) => {
                if(pref.pref_type == 'video') {
                    let video = this.findVideoById( pref.pref_id);
                    this.preferiti[index].videoObj = video;    
                } 
            });

            //if we got any preferiti and loaded the videos then setup the main slides SEE slidersMixin
            this.$root.setupPreferitiMainSlider();
        },

        //this is called by evofit when the schede are loaded
        preferitiAssignSchedeToPreferiti() {
            console.log('Assinging preferiti schede');
            this.preferiti.forEach( (pref, index) => {
                if( pref.pref_type == 'scheda') {
                    let scheda = this.evofitGetSchedaByCodice( pref.pref_id );
                    this.preferiti[ index ].schedaObj = scheda;
                }
            });

            //if we got any preferiti and loaded the videos then setup the main slides SEE slidersMixin
            this.$root.setupPreferitiMainSlider();
        },

        preferitiAddOne(_prefData) {
            if(this.user.iyestoken) {
                _prefData.pref_name = _prefData.pref_name.replace("'","`");
                axios.post( config.serverUrl + '/preferiti/set', {
                    iyestoken : this.$root.user.iyestoken,
                    userid: this.$root.user.iyesUserId,
                    prefData: _prefData
                })
                .then( (resmy) => {
                    if(resmy.data.error) {
                        console.log("WARNING! Cannot set preferiti.");
                        console.log(resmy);
                    } else {
                        this.preferitiGetAll();   
                    }
                })
                .catch( (errmy) => {
                    console.log('WARNING! Cannot set preferiti');
                    console.log(errmy);
                });    
            }         
        },

        preferitoRemove(_prefData) {
            if(this.user.iyestoken) {
                axios.post( config.serverUrl + '/preferiti/unset', {
                    iyestoken : this.$root.user.iyestoken,
                    userid: this.$root.user.iyesUserId,
                    prefData: _prefData
                })
                .then( (resmy) => {
                    if(resmy.data.error) {
                        console.log("WARNING! Cannot unset preferiti.");
                        console.log(resmy);
                    } else {
                        this.preferitiGetAll();   
                    }
                })
                .catch( (errmy) => {
                    console.log('WARNING! Cannot unset preferiti');
                    console.log(errmy);
                });    
            }         
        },

        togglePreferitiVideo(_type, _video) {
            if(this.isPreferito(_type,_video.id) == false) {
                this.preferitiAddOne({
                    pref_type: _type,
                    pref_id: _video.id,
                    pref_name: _video.name
                });    
            } else {
                this.preferitoRemove({
                    pref_type: _type,
                    pref_id: _video.id,
                    pref_name: _video.name
                })
            }
        },

        togglePreferitiScheda( _schedaid, _schedaname) {
            if(this.isPreferito('scheda',_schedaid) == false) {
                this.preferitiAddOne({
                    pref_type: 'scheda',
                    pref_id: _schedaid,
                    pref_name: _schedaname
                });    
            } else {
                this.preferitoRemove({
                    pref_type: 'scheda',
                    pref_id: _schedaid,
                    pref_name: _schedaname
                })
            }
        }

    }
}