<template>
    <div>

        <div v-if="$root.survey.canPostNew" class="container-fluid mt-3 px-3">
            <div class="row mt-5">
                <div class="col-12 text-center px-5">
                    <p class="orante-text-black titolo-piccolo">QUESTIONARIO PROGRAMMA PERSONALIZZATO</p>
                    <p class="testo-generico orange-text-black mb-0">In questa pagina puoi compilare il questionario che verrà poi analizzato dai nostri personal trainer per crearti un programma di allenamento personalizzato.</p>
                    <p class="testo-generico orange-text-red mt-0">I dati inseriti saranno gestiti in forma <b>anonina</b> dal nostro personale.</p>
                </div>
                <div class="col-12">
                    <div class="survey-container" style="width:100%; height:3px;">
                        <div class="survey-value" :style="{ 'width' : String(($root.survey.curQuestionIdx / $root.survey.questions.length) * 100) + '%' }"></div>
                    </div>
                </div>
            </div>
            <div v-if="$root.survey.curQuestion != null" class="row pt-3 mt-5 px-3 titolo-categoria orange-text-white">

                <div v-if="$root.survey.curQuestion.type == 'number'" class="col-12 testo-generico py-5 box-domanda">
                    <h5 class="text-center testo-generico orange-text-black mt-3 mb-4" v-html="$root.survey.curQuestion.question"></h5>
                    <label for="curAnswer" class="orange-text-black testo-generico">{{ $root.survey.curQuestion.hint }}</label>
                    <!-- <input v-on:keyup.enter="$refs.surveyAvantiBtn.focus()" type="text" :placeholder="$root.survey.curAnswer.answer" class="form-control" v-model="$root.survey.curAnswer.answer" id="curAnswer" name="curAnswer"> -->
                    <scroll-picker ref="scrollPicker" :key="'qst-' + $root.survey.curQuestionIdx" :options="$root.survey.pickerOptions" v-model="$root.survey.curAnswer.answer" style="color:#000; font-size:20px;"></scroll-picker>
                </div>

                <div v-if="$root.survey.curQuestion.type == 'select'" class="col-12 testo-generico py-5 box-domanda">
                    <h5 class="text-center testo-generico orange-text-black mt-3 mb-4" v-html="$root.survey.curQuestion.question"></h5>
                    <label for="curAnswer" class="orange-text-black testo-generico">{{ $root.survey.curQuestion.hint }}</label>
                    <select class="form-control" v-model="$root.survey.curAnswer.answer" id="curAnswer" name="curAnswer">
                        <option v-for="opt,index in $root.survey.curQuestion.options" :key="'opt-' + index" :value="index">{{ opt }}</option>
                    </select>
                    <div class="mt-3" v-if="$root.survey.curQuestion.otherTextNeeded && $root.survey.curAnswer.answer == $root.survey.curQuestion.otherTextNeededIf">
                        <label for="curOtherText" class="orange-text-black testo-generico">Spiegazione</label>
                        <textarea v-on:keyup.enter="$refs.surveyAvantiBtn.focus()" maxlength="255" v-model="$root.survey.curAnswer.note" class="form-control" rows="3"></textarea>
                    </div>
                </div>

                <div v-if="$root.survey.curQuestion.type == 'checks'" class="col-12 testo-generico py-5 box-domanda">
                    <h5 class="text-center testo-generico orange-text-black mt-3 mb-4" v-html="$root.survey.curQuestion.question"></h5>
                    <div style="display:block" class="form-check my-3" v-for="opt,index in $root.survey.curQuestion.options" :key="'opt-' + index">
                        <input type="checkbox" class="form-check-input" style="vertical-align:middle; height:24px; width:24px;" v-model="$root.survey.curAnswer.answer" :value="index">
                        <label for="curAnswer" class="form-check-label texto-generico orange-text-black ml-3" style="vertical-align:middle; height: 24px; line-height: 24px; padding-top: 5px;">{{ opt }}</label>
                    </div>
                    <div class="mt-3" v-if="$root.survey.curQuestion.otherTextNeeded && String($root.survey.curAnswer.answer).includes($root.survey.curQuestion.otherTextNeededIf)">
                        <label for="curOtherText" class="orange-text-black testo-generico">Spiegazione</label>
                        <textarea v-model="$root.survey.curAnswer.note" maxlength="255" class="form-control" rows="3"></textarea>
                    </div>
                </div>

                <div v-if="$root.survey.curQuestion.type == 'yesno'" class="col-12 testo-generico py-5 box-domanda">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="text-center testo-generico orange-text-black mt-3 mb-4" v-html="$root.survey.curQuestion.question"></h5>
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col-6 py-3" :style="{ 'background-color' : ($root.survey.curAnswer.answer == true) ? 'orange' : 'white' }">
                            <div style="display:block" class="form-check">
                                <input type="radio" class="form-check-input survey-radio" style="vertical-align:middle; height:24px; width:24px;" v-model="$root.survey.curAnswer.answer" :value="1">
                                <label for="curAnswer" :style="{ 'color' : ($root.survey.curAnswer.answer == true) ? 'white' : 'black' }" class="form-check-label survey-radio-label texto-generico" style="vertical-align:middle;">SI</label>
                            </div>
                        </div>
                        <div class="col-6 py-3" :style="{ 'background-color' : ($root.survey.curAnswer.answer == true) ? 'white' : 'orange', 'color' : ($root.survey.curAnswer.answer == true) ? 'black' : 'white' }">
                            <div style="display:block" class="form-check">
                                <input type="radio" class="form-check-input survey-radio" style="vertical-align:middle; height:24px; width:24px;" v-model="$root.survey.curAnswer.answer" :value="0">
                                <label for="curAnswer" :style="{ 'color' : ($root.survey.curAnswer.answer == true) ? 'black' : 'white' }" class="form-check-label survey-radio-label texto-generico" style="vertical-align:middle;">NO</label>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3" v-if="$root.survey.curQuestion.otherTextNeeded && $root.survey.curAnswer.answer == true">
                        <label for="curOtherText" class="orange-text-black testo-generico">Spiegazione</label>
                        <textarea v-on:keyup.enter="$refs.surveyAvantiBtn.focus()" maxlength="255" v-model="$root.survey.curAnswer.note" class="form-control" rows="3"></textarea>
                    </div>
                </div>

                <div v-if="$root.survey.curQuestion.type == 'freetext'" class="col-12 testo-generico py-5 box-domanda">
                    <h5 class="text-center testo-generico orange-text-black mt-3 mb-4" v-html="$root.survey.curQuestion.question"></h5>
                    <div class="mt-3">
                        <label for="curOtherText" class="orange-text-black">Spiegazione</label>
                        <textarea v-model="$root.survey.curAnswer.answer" maxlength="255" class="form-control" rows="3"></textarea>
                    </div>
                </div>

                <div v-if="$root.survey.curQuestion.type == 'range'" class="col-12 testo-generico py-5 box-domanda">
                    <h5 class="text-center testo-generico orange-text-black mt-3 mb-4" v-html="$root.survey.curQuestion.question"></h5>
                    <div class="form-group">
                        <label class="texto-generico orange-text-black" for="formControlRange">Risposta: <strong>{{ $root.survey.curAnswer.answer }}</strong></label>
                        <input type="range" :min="$root.survey.curQuestion.min" :max="$root.survey.curQuestion.max" class="survey-range" id="formControlRange" v-model="$root.survey.curAnswer.answer">
                    </div>

                </div>

                <div class="col-6 pt-4">
                    <button v-if="$root.survey.curQuestionIdx > 0" v-on:click.stop="$root.surveyBackQuestion" class="btn btn-block btn-orange btn-outline-gray orange-text-black">Indietro</button>
                </div>
                <div class="col-6 pt-4">
                    <button ref="surveyAvantiBtn" v-on:click.stop="$root.surveyAnswerQuestion()" class="btn btn-block btn-orange btn-orange-orange">Avanti</button>
                </div>
            </div>
        </div>

        <div v-else class="container-fluid mt-3 px-3">
            <div class="row mt-5">
                <div class="col-12 text-center px-5">
                    <p class="orante-text-black titolo-piccolo">QUESTIONARIO PROGRAMMA PERSONALIZZATO</p>
                </div>
                <div class="col-12">
                    <p class="testo-generico orange-text-black my-5">
                        I nostri personal trainer stanno creando la tua scheda personalizzata in base alle risposte del tuo precedente questionario.<br><strong>Attendi la consegna della tua scheda personalizzata</strong> oppure contatta un personal trainer per personalizzarla ulteriormente.
                    </p>
                    <button v-on:click="$root.openWhatsappTrainer" style="vertical-align:middle;" class="btn btn-block btn-orange btn-outline-gray orange-text-black testo-bottoni"><span style="display:inline-block; vertical-align:middle; height:10px; line-height:18px; margin-top:-10px; margin-right:10px; position:relative; font-size:18px;" class="icon-tools_whatsapp"></span><span style="vertical-align:middle;">CONTATTA TRAINER</span></button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import Vue from 'vue'
import "vue-scroll-picker/dist/style.css"
import { ScrollPicker, ScrollPickerGroup } from "vue-scroll-picker"

export default {
    name: 'Questionario',
    components: {
        ScrollPicker,
        ScrollPickerGroup
    },
    data () {
        return {
        }
    },
    created() {
    },
    methods: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.box-domanda {
    border:1px solid 666;
    box-shadow:0 0 8px 8px rgba(0,0,0,0.05);
}

.survey.container {
    width:100%;
    height:3px;
    border:none;
    display:block;
}

.survey-value {
    background-color: orange;
    height:3px;
    margin:0px;
}

.survey-radio {
    position: absolute !important;
    top: 0px !important;
    left: 0px !important;
    width: 100% !important;
    height: 100% !important;
    opacity: 0 !important;    
}

.survey-radio-label {
    vertical-align: middle !important;
    width: 100% !important;
    text-align: center !important;
    padding: 0px !important;
}

.survey-range {
  width: 100%;
  margin: 8.8px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
.survey-range:focus {
  outline: none;
}
.survey-range::-webkit-slider-runnable-track {
  background: #bdbdbf;
  border: 0px solid #010101;
  width: 100%;
  height: 8.4px;
  cursor: pointer;
}
.survey-range::-webkit-slider-thumb {
  margin-top: -9px;
  width: 25px;
  height: 26px;
  background: #ff8900;
  border: 1px solid #dc6500;
  border-radius: 15px;
  cursor: pointer;
  -webkit-appearance: none;
}
.survey-range:focus::-webkit-slider-runnable-track {
  background: #cacacc;
}
.survey-range::-moz-range-track {
  background: #bdbdbf;
  border: 0px transparent;
  width: 100%;
  height: 8.4px;
  cursor: pointer;
}
.survey-range::-moz-range-thumb {
  width: 25px;
  height: 26px;
  background: #ff8900;
  border: 1px solid #dc6500;
  border-radius: 15px;
  cursor: pointer;
}
.survey-range::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 9.8px 0;
  color: transparent;
  width: 100%;
  height: 8.4px;
  cursor: pointer;
}
.survey-range::-ms-fill-lower {
  background: #b0b0b2;
  border: 0px solid #010101;
}
.survey-range::-ms-fill-upper {
  background: #bdbdbf;
  border: 0px solid #010101;
}
.survey-range::-ms-thumb {
  width: 25px;
  height: 26px;
  background: #ff8900;
  border: 1px solid #dc6500;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
.survey-range:focus::-ms-fill-lower {
  background: #bdbdbf;
}
.survey-range:focus::-ms-fill-upper {
  background: #cacacc;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align:auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  .survey-range {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}

.vue-scroll-picker-item -selected {
    font-weight: bold;
}

</style>
