<template>
    <div>
        <div class="container-fluid">
            <div class="row mt-5">
                <div class="col-12 text-center">
                    <img id="login-logo" src="img/orangeLogo.svg">
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <p class="mb-4 text-center titolo-categoria">Accedi con email e password</p>
                </div>
                <div class="col-12 mt-4">
                    <form class="form-signin" style="max-width:600px; margin-left:auto; margin-right:auto;">
                        <div class="form-label-group">
                            <div class="ico-container"><span class="icon-tools_profilo"></span></div>
                            <input type="email" v-on:keyup.enter="$refs.inputPassword.focus()" :readonly="$root.user.readOnlyEmail" v-model="$root.user.loginEmail" ref="inputEmail" class="testo-generico ico-input" id="inputEmail" placeholder="Indirizzo Email" required autofocus>
                            <!-- <label for="inputEmail">Indirizzo Email</label> -->
                            <span v-show="emailValid == false" class="label-error">Verificare email</span>
                        </div>
                        <div v-show="passVisible == false" class="form-label-group mt-4">
                            <div class="ico-container"><span class="icon-password"></span></div>
                            <input  type="password" v-on:keyup.enter="$event.target.blur();" ref="inputPassword" v-model="$root.user.loginPassword" class="testo-generico ico-input" id="inputPassword" placeholder="Password" required autofocus>
                            <!-- <label for="inputPassword">Password</label> -->
                            <span v-show="passValid == false" class="label-error">Verificare password</span>
                            <img v-on:click="passVisible = true" src="img/eye-icon.png" class="show-pwd">
                        </div>
                        <div v-show="passVisible == true" class="form-label-group mt-4">
                            <div class="ico-container"><span class="icon-password"></span></div>
                            <input  type="text" v-on:keyup.enter="$event.target.blur();" v-model="$root.user.loginPassword" class="testo-generico ico-input" id="inputPasswordVis" placeholder="Password" required autofocus>
                            <!-- <label for="inputPassword">Password</label> -->
                            <span v-show="passValid == false" class="label-error">Verificare password</span>
                            <img v-on:click="passVisible = false" src="img/eye-icon.png" class="show-pwd">
                        </div>
                    </form>
                </div>
                <div class="col-12 mt-5 text-center">
                    <span v-on:click="showPasswordRecovery()" class="orange-link-light">Recupera password</span>
                </div>
                <div class="col-12 mt-3">
                    <button ref="loginBtn" v-on:click="checkFieldsAndLogin()" class="btn btn-block testo-bottoni btn-orange btn-orange-orange">entra</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data () {
        return {
            emailValid: true,
            passValid: true,
            passVisible: false
        }
    },
    methods: {
        checkFieldsAndLogin() {
            this.passVisible = false;
            this.emailValid = true;
            this.passValid = true;
            let inEmail = this.$refs.inputEmail.value;
            let inPassword = this.$refs.inputPassword.value;
            if(inEmail.length < 5) {
                this.emailValid = false;
                return false;
            }

            if(!inEmail.includes('.') || !inEmail.includes('@')) {
                this.emailValid = false;
                return false;
            }

            if(inPassword.length < 4) {
                this.passValid = false;
                return false;
            }

            this.$root.doLogin( inEmail, inPassword );
                
        },

        showPasswordRecovery() {
            this.$root.$refs.pageMan.showOverlay('password-recovery');
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #login-logo {
        max-width:80%;
    }

    .show-pwd {
        width:25px;
        height: 25px;
        display:block;
        position:absolute;
        top:10px;
        right:10px;
    }
</style>
