<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div v-on:click="closeThisOverlay()" class="col-2 pt-2 text-black">
                    <strong><span style="font-size:24px; line-height:45px;" class="icon-navig_chiudi"></span></strong>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-12 mb-5">
                    <p class="titolo-piccolo orange-text-black text-center">RICARICHE</p>
                    <p class="testo-generico orange-text-black">Scegli il taglio della ricarica ed aggiungila al saldo del tuo borsellino Orange.</p>
                </div>
                <div v-for="ricar,index in $root.products.ricariche" :key="'ricar-' + index" class="col-12">
                    <div class="row px-2 py-0 my-2">
                        <div class="col-8">
                            {{ ricar.Name }}<br>
                            <strong>{{ ricar.Price }}&euro;</strong>
                        </div>
                        <div class="col-4">
                            <a href="#" v-on:click.stop="compraRicarica(ricar)" class="orange-link-dark">Ricarica</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import popper from 'popper.js'
import axios from 'axios'

export default {
    name: 'ProductsRicariche',
    data () {
        return {
        }
    },
    methods: {
        closeThisOverlay() {
            this.$root.$refs.pageMan.closeOverlay();
        },

        compraRicarica(_ricarProduct) {
            this.$root.paymentCreateProductsOrder(_ricarProduct);
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #centro-logo {
        max-width:80%;
    }
</style>
