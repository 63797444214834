<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="text-center">
                        <p class="titolo-35 orange-text-black text-center mt-4 mb-2 pb-0" style="text-transform:uppercase;">PRENOTA</p>
                        <p class="testo-generico orange-text-black text-center" >Vieni ad allenarti in palestra. Ti ricordiamo che è necessario prenotarsi. Scegli cosa, dove e quando.</p>
                        <p v-if="(this.$root.centers.centers) && ( typeof($root.centers.centers[ this.$root.user.centerId]) != 'undefined')" class="testo-generico orange-text-black text-center mb-0 mt-3" >La tua palestra preferita è</p>
                        <p v-if="(this.$root.centers.centers) && ( typeof($root.centers.centers[ this.$root.user.centerId]) != 'undefined')" class="testo-generico orange-text-black text-center my-0"><strong> {{ this.$root.centers.centers[ this.$root.user.centerId].name }}</strong></p>
                        <p v-if="(this.$root.centers.centers) && ( typeof($root.centers.centers[ this.$root.user.centerId]) != 'undefined')" class="testo-generico orange-text-black text-center my-0">{{ this.$root.centers.centers[ this.$root.user.centerId].address }}</p>
                        <a v-on:click.prevent="$root.centersShowSceltaCentro('scelta-centro')" class="orange-link-dark mt-0">Cambia</a> 
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row no-gutters mt-4 p-0">
                <div class="col-6 border border-gray text-center">
                    <div v-on:click="checkGenderGirlPower()" style="width:170px; height:150px; margin-left:auto; margin-right:auto;">
                        <img style="max-width:150px; margin-top:0px;" src="img/girl-power-2_small.png" alt="image">
                        <!-- <p class="testo-generico orange-text-black text-center my-0 mt-3">SOLO INGRESSO  </p> -->
                    </div>
                </div>
                <div class="col-6 border border-gray text-center">
                    <div v-on:click="showCalendarioLezioni()" style="width:150px; height:150px; margin-left:auto; margin-right:auto;">
                        <img style="max-width:65px; margin-top:30px;" src="img/appicons/prenotamain/classe.png" alt="image">
                        <p class="testo-generico orange-text-black text-center my-0 mt-2">CLASSE</p>
                    </div>
                </div>

            </div>
        </div>
        <div class="container-fluid">
            <div class="row px-0 mt-3">
                <div class="col-12">
                    <button v-on:click="showMiePrenotazioni()" class="btn btn-block testo-bottoni orange-text-black btn-orange btn-outline-white left-icon-holder"><span class="ico-button"><span class="icon-tools_prenota"></span></span>LE MIE PRENOTAZIONI</button>
                </div>
                <div class="col-12 mt-2">
                    <button v-on:click="showCalendarioVisite()" class="btn btn-block testo-bottoni orange-text-black btn-orange btn-outline-white left-icon-holder"><span class="ico-button"><span class="icon-agenda"></span></span>PRENOTA VISITA MEDICA</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'PrenotaMain',
    data () {
        return {
        }
    },
    created() {
    },
    methods: {

        checkGenderGirlPower(_forceCenter = null) {
            if(this.$root.user.iyesUser.Gender == "M") {
                this.$root.$refs.pageMan.showAlert("Sei <strong>sicuro sicuro</strong> di voler prenotare <strong>GIRL POWER</strong>?",'SI','NO')
                .then( (si) => {
                    this.showCalendarioIngressi(_forceCenter);
                })
                .catch( (no) => {

                })
            } else {
                this.showCalendarioIngressi(_forceCenter);
            }
        },

        showCalendarioIngressi(_forceCenter = null) {

            //restore the real user center id if he changed temporarily to outdoor center
            this.$root.user.centerId = this.$root.user.realCenterId;

            //load data to show the correct data for prenota ingresso
            this.$root.reservation.reservationCategories.forEach( (cat) => {
                if(cat.Description) {
                    if(cat.Description.includes('***'))
                        this.$root.reservation.curReservCategory = cat.CategoryID;
                }
            });                
            if(_forceCenter != null) {
                this.$root.user.realCenterId = this.$root.user.centerId;
                this.$root.user.centerId = _forceCenter;
            }
            this.$root.reservationGetServices(this.$root.reservation.curReservCategory );
            this.$root.reservation.categoryFilter = '***';            

            this.$root.$refs.pageMan.showPage('prenotaingressopage');
        },

        showMiePrenotazioni() {
            this.$root.reservationGetMine();
            this.$root.showPage('prenotamiepage');
        },

        showCalendarioLezioni() {
            //restore the real user center id if he changed temporarily to outdoor center
            this.$root.user.centerId = this.$root.user.realCenterId;

            //select MUSICALE by default before showing the lezioni calendar
            this.$root.reservation.curReservCategory = this.$root.reservationFindCategoryIdByName('SALA');
            this.$root.reservation.categoryFilter = 'SALA';
            this.$root.reservationGetServices(this.$root.reservation.curReservCategory);
            this.$root.$refs.pageMan.showPage('prenotaingressopage');
        },

        showCalendarioVisite() {
            //check if the user has all the required fields to book a medical check
            let missingFields = this.checkIfCanShowCalendarioVisite();
            if(missingFields.length > 0) {
                let str = missingFields.join(', ');
                this.$root.$refs.pageMan.showAlert('<strong>Dati anagrafici mancanti</strong>. Recarsi in segreteria per attivare questa funzionalità. <br> ' + str);
                return false;
            } else {
                //find the VISITA medica category
                let catId = -1;
                this.$root.reservation.reservationCategories.forEach( (cat) => {
                    if(cat.Description.includes('MEDIC'))
                        catId = cat.CategoryID;
                }); 
                if(catId > 0) {
                    //select MUSICALE by default before showing the lezioni calendar
                    this.$root.reservation.curReservCategory = catId;
                    this.$root.reservationGetServices(this.$root.reservation.curReservCategory);
                    this.$root.reservation.categoryFilter = 'MEDIC';
                    this.$root.$refs.pageMan.showPage('prenotaingressopage');
                } else {
                    this.$root.$refs.pageMan.showAlert('Non è attualmente possibile prenotare visite mediche.','OK');
                }
            }
        },

        checkIfCanShowCalendarioVisite() {
            let missingFields = [];

            if( (!this.$root.user.iyesUser.FiscalCode || this.$root.user.iyesUser.FiscalCode == "" || this.$root.user.iyesUser.FiscalCode.substr(0,3) == "XYZ")) {
                missingFields.push("Codice Fiscale");
            }

            if( (!this.$root.user.iyesUser.BirthDate || this.$root.user.iyesUser.BirthDate == "" || this.$root.user.iyesUser.BirthDate == null)) {
                missingFields.push("Data di nascita");
            }

            if( (!this.$root.user.iyesUser.BirthPlace || this.$root.user.iyesUser.BirthPlace == "" || this.$root.user.iyesUser.BirthPlace == null)) {
                missingFields.push("Luogo di nascita");
            }

            if( (!this.$root.user.iyesUser.Street || this.$root.user.iyesUser.Street == "" || this.$root.user.iyesUser.Street == null)) {
                missingFields.push("Via di residenza");
            }

            if( (!this.$root.user.iyesUser.CivicNumber || this.$root.user.iyesUser.CivicNumber == "" || this.$root.user.iyesUser.CivicNumber == null)) {
                missingFields.push("Numero Civico");
            }

            if( (!this.$root.user.iyesUser.City || this.$root.user.iyesUser.City == "" || this.$root.user.iyesUser.City == null)) {
                missingFields.push("Città residenza");
            }

            if( (!this.$root.user.iyesUser.Province || this.$root.user.iyesUser.Province == "" || this.$root.user.iyesUser.Province == null)) {
                missingFields.push("Provincia residenza");
            }

            if( (!this.$root.user.iyesUser.ZipCode || this.$root.user.iyesUser.ZipCode == "" || this.$root.user.iyesUser.ZipCode == null)) {
                missingFields.push("CAP");
            }

            if( (!this.$root.user.iyesUser.Mobile || this.$root.user.iyesUser.Mobile == "" || this.$root.user.iyesUser.Mobile == null)) {
                missingFields.push("N.ro Cellulare");
            }

            //if( (!this.$root.user.iyesUser.Phone1 || this.$root.user.iyesUser.Phone1 == "" || this.$root.user.iyesUser.Phone1 == null)) {
            //    missingFields.push("N.ro Cellulare");
            //}

            return missingFields;

        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
