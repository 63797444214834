'use strict'

class lsPing {
    constructor() {
        this.pingImgUrl = 'http://reborn.orangepalestre.it/images/ping.png';
        this.fileSize = 500000;
    }

    ping() {
        return new Promise( (resolve, reject) => {
            let img = document.createElement('img');
            img.width = 1;
            img.height = 1;
            let rand = Math.random();
            let end_time = 0;
            let me = this;
            img.onload = function() {
                end_time = new Date().getTime();
                let time_duration = (end_time - start_time) / 1000;
                let loaded_bits = me.fileSize * 8;
                var bps = (loaded_bits / time_duration).toFixed(2);
                var speed_in_kbs = (bps / 1024).toFixed(2);
                var speed_in_mbs = (speed_in_kbs / 1024).toFixed(2);
                resolve({ 
                    duration: time_duration,
                    kbps:speed_in_kbs,
                    mbps: speed_in_mbs
                });
            }   

            img.onerror = function(err) {
                console.log(err);
                reject(null);
            };
            var start_time = new Date().getTime();
            img.src = this.pingImgUrl + '?nocache=' + rand;

        });
    }
}

module.exports = lsPing;