<template>
    <div>
        <div class="container-fluid">
            <div class="row mt-5">
                <div class="col text-center">
                    <img id="registration-logo" src="img/orangeLogo.svg">
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <p class="mb-4 text-center titolo-categoria">Crea il tuo account</p>
                    <!-- <p class="testo-generico text-center">Registrandoti adesso attivi il periodo gratuito di allenamento con REBORN LIGHT</p> -->
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <form class="form-signin">
                        <div class="form-label-group mt-3">
                            <div class="ico-container"><span class="icon-tools_profilo"></span></div>
                            <input type="text" ref="regNome" class="ico-input" id="regNome" placeholder="Nome" required autofocus>
                            <!-- <label for="regNome">Nome</label> -->
                            <span v-show="checks.nome == false" class="label-error">Verificare nome</span>
                        </div>
                        <div class="form-label-group mt-3">
                            <div class="ico-container"><span class="icon-tools_profilo"></span></div>
                            <input type="text" ref="regCognome" class="ico-input" id="regCognome" placeholder="Cognome" required>
                            <!-- <label for="regCognome">Cognome</label> -->
                            <span v-show="checks.cognome == false" class="label-error">Verificare cognome</span>
                        </div>
                        <div class="form-label-group mt-3">
                            <div class="ico-container"><span class="icon-email"></span></div>
                            <input type="email" ref="regEmail" class="ico-input" id="regEmail" placeholder="Email" required>
                            <!-- <label for="regEmail">Email</label> -->
                            <span v-show="checks.email == false" class="label-error">Verificare email</span>
                        </div>
                        <div class="form-label-group mt-3">
                            <div class="ico-container"><span class="icon-telefono"></span></div>
                            <input type="text" ref="regPhone" class="ico-input" id="regPhone" placeholder="Cellulare" required>
                            <!-- <label for="regPhone">Cellulare</label>-->
                            <span v-show="checks.phone == false" class="label-error">Verificare telefono</span>
                        </div>
                        <div id="regDatanascita" ref="regDatanascita" class="row pl-5 mt-3 mb-3">
                            <div class="col-4">
                                <div class="ico-container-sel"><span class="icon-nascita"></span></div>
                                <select class="form-control orange-select-dark" v-model="tmpdata.datanascitayear">
                                    <option :key="'year--1' "  selected disabled value="-1">AAAA</option>  
                                    <option v-for="year in registrationYears" :key="'year-' + year" :value="year">{{ year }}</option>
                                </select>
                            </div>
                            <div class="col-4">
                                <select class="form-control orange-select-dark" v-on:change="updateDataNascita" v-model="tmpdata.datanascitamese">
                                    <option selected disabled value="-1">MMM</option>  
                                    <option v-for="mese,index in mesi" :key="'month-' + String(index + 1).padStart(2,'0')" :value="index">{{ mese }}</option>
                                </select>
                            </div>
                            <div class="col-4">
                                <select class="form-control orange-select-dark" v-on:change="updateDataNascita" v-model="tmpdata.datanascitagiorno">
                                    <option  selected disabled value="-1">GG</option>  
                                    <option v-for="giorno,index in registrationDays" :key="'day-' + String(index + 1).padStart(2,'0')" :value="String(index + 1).padStart(2,'0')">{{ giorno }}</option>
                                </select>
                            </div>
                            <div class="col-12">
                                <span v-show="checks.datanascita == false" class="label-error">Verificare data nascita</span>
                            </div>
                        </div>
                        <div id="regGenere" ref="regGenere" class="row pl-5 mt-4">
                            <div class="col-12">
                                <div class="ico-container-sel"><span class="icon-sesso"></span></div>
                                <select class="form-control orange-select-dark" v-model="$root.register.genere">
                                    <option :key="'genere-1' "  selected disabled :value="'S'">Genere</option>  
                                    <option :value="'M'">Maschio</option>
                                    <option :value="'F'">Femmina</option>
                                    <option :value="'X'">Non specifico</option>
                                </select>
                                <span v-show="checks.genere == false" class="label-error">Verificare genere</span>
                            </div>
                        </div>
                        <div id="regCentro" ref="regCentro" class="row pl-5 mt-4">
                            <div class="col-12">
                                <div class="ico-container-sel"><span class="icon-tab_home"></span></div>
                                <select class="form-control orange-select-dark" v-model="$root.register.centro">
                                    <option :key="'centro-sel' "  selected disabled default :value="-1">Scegli la palestra preferita</option>  
                                    <option v-for="centro,index in $root.centers.centers.filter( ce => ( (!ce.name.includes('OUTDOOR')) && (!ce.name.includes('REBORN')) ) )" :key="'centro-' + index"  :value="centro.iyes.id">{{ centro.name }}</option>  
                                </select>
                                <p class="mt-3 mb-0 orange-text-white testo-generico">*potrai cambiare palestra quando lo desideri</p>
                            </div>
                        </div>
                        <!-- <div class="form-label-group mt-4 border-bottom border-dark pb-3">
                            <div class="ico-container"><span class="icon-codicefiscale"></span></div>
                            <input type="text" ref="inputCodFiscale" class="ico-input" id="inputCodFiscale" placeholder="CodFiscale" required>
                            <label for="inputCodFiscale">Cod Fiscale</label>
                            <span v-show="checks.codfiscale == false" class="label-error">Verificare cod fiscale</span>
                        </div> -->

                        <div class="form-check pl-4 pt-3 mt-4">
                            <input type="checkbox" v-model="$root.register.condizioni" id="inputCondizioni" class="form-check-input" required>
                            <label class="form-check-label pl-4" for="inputCondizioni">Confermo di aver letto ed accettato le <a class="orange-link-light" target="_blank" :href="$root.policiesUrls.condizioni">condizioni di servizio</a></label>
                        </div>

                        <div class="form-check pl-4 mt-4">
                            <input type="checkbox" v-model="$root.register.privacy" id="inputPrivacy" class="form-check-input" required>
                            <label class="form-check-label pl-4" for="inputPrivacy">Confermo di aver letto ed accettato l'<a class="orange-link-light" target="_blank" :href="'https://www.orangepalestre.it/docs/Informativa_privacy_app.pdf'">informativa sulla privacy</a></label>
                        </div>

                        <div class="form-check pl-4 mt-4">
                            <input type="checkbox" v-model="$root.register.regolamento" id="inputRegolamento" class="form-check-input" required>
                            <label class="form-check-label pl-4" for="inputRegolamento">Confermo di aver letto ed accettato il <a class="orange-link-light" target="_blank" :href="$root.policiesUrls.regolamento">regolamento della struttura</a></label>
                            <span v-show="checks.policies == false" class="label-error">Confermare policies</span>
                        </div>

                    </form>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <button v-on:click="tryRegister()" class="btn btn-block py-3 btn-orange-orange">AVANTI</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import moment from 'moment'
import momentIT from 'moment/locale/it'
import Validator from '../../js/classes/classValidators'

export default {
    name: 'Registration',
    computed: {
        registrationYears () {
            const year = new Date().getFullYear() - 10;
            return Array.from({length: year - 1930}, (value, index) => year - index)
        },
        registrationDays () {
            let padMese = String(this.tmpdata.datanascitamese).padStart(2,'0');
            const days = moment(this.tmpdata.datanascitayear + '-' + padMese + '-01 00:00:00.000').daysInMonth();
            return Array.from({length: days}, (value, index) => 1 + index)
        }

    },
    data () {
        return {
            tmpdata: {
                datanascitamese: -1,
                datanascitayear: -1,
                datanascitagiorno: -1,
                valid: false
            },
            checks: {
                nome: true,
                cognome: true,
                email: true,
                phone: true,
                datanascita: true,
                genere:true,
                codfiscale: true,
                policies: true
            },
            mesi: { 1: 'Gen', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'Mag', 6: 'Giu', 7: 'Lug' , 8:'Ago', 9:'Set', 10: 'Ott', 11: 'Nov', 12:'Dic'}
        }
    },
    methods: {
        updateDataNascita() {
            let year = String(this.tmpdata.datanascitayear);
            let month = String(this.tmpdata.datanascitamese).padStart(2,'0');
            let day = String(this.tmpdata.datanascitagiorno).padStart(2,'0');
            this.$root.register.datanascita = moment(year + '-' + month + '-' + day + ' 00:00:00.000');
        },

        resetChecks() {
            this.checks.nome = true;
            this.checks.cognome = true;
            this.checks.email = true;
            this.checks.phone = true;
            this.checks.datanascita = true;
            this.checks.genere =true;
            this.checks.codfiscale = true;
            this.checks.policies = true;
        },

        tryRegister() {
            console.log('Try register');
            let valid = this.checkRegisterFieldsAndRegister();
            if(!valid) {
                this.$root.$refs.pageMan.showAlert('Verificare i dati inseriti, tutti i campi sono richiesti.','OK','');
            } else {    
                if(this.$root.register.centro != -1) {
                    localStorage.setItem('centro-default', this.$root.register.centro);
                }
                this.$root.doSignup();
            }
        },

        checkRegisterFieldsAndRegister() {
            
            this.resetChecks();

            let validator = new Validator();

            let nome = this.$refs.regNome.value.trim();
            if(nome.length < 3) {
                this.checks.nome = false;
                this.$refs.regNome.scrollIntoView();
                return false;
            }

            /* if(!validator.isLettersOnly(nome)) {
                this.checks.nome = false;
                this.$refs.regNome.scrollIntoView();
                return false;
            } */

            let cognome = this.$refs.regCognome.value.trim();
            if(cognome.length < 3) {
                this.checks.cognome = false;
                this.$refs.regCognome.scrollIntoView();
                return false;
            }

            /* if(!validator.isLettersOnly(cognome)) {
                this.check.cognome = false;
                this.$refs.regCognome.scrollIntoView();
                return false;
            } */

            let email = this.$refs.regEmail.value.trim().toLowerCase();

            if(!validator.isValidEmail(email)) {
                this.checks.email = false;
                this.$refs.regEmail.scrollIntoView();
                return false;
            }

            let phone = this.$refs.regPhone.value.trim();
            if(!validator.isValidPhone(phone)) {
                this.checks.phone = false;
                this.$refs.regPhone.scrollIntoView();
                return false;
            }

            let datan = this.$root.register.datanascita;
            if(typeof datan == undefined || this.$root.register.datanascita == null) {
                this.checks.datanascita = false;
                this.$refs.regDatanascita.scrollIntoView();
                return false;
            }
            
            if(!datan.isValid()) {
                this.checks.datanascita = false;
                this.$refs.regDatanascita.scrollIntoView();
                return false;
            }

            if(this.$root.register.genere == 'S') {
                this.checks.genere = false;
                this.$refs.regGenere.scrollIntoView();
                return false;
            }

            /*
            let codfiscale = this.$refs.inputCodFiscale.value.trim();
            if(codfiscale.length != 16) {
                this.checks.codfiscale = false;
                this.$refs.inputCodFiscale.scrollIntoView();
                return false;
            }
            

            if(!validator.isCodiceFiscale(codfiscale)) {
                this.checks.codfiscale = false;
                this.$refs.inputCodFiscale.scrollIntoView();
                return false;
            }
            */

           //generate a fake codfiscale
           let rand1 = Math.floor(Math.random() * (99 - 11 + 1) + 11);
           let rand2 = Math.floor(Math.random() * (30 - 11 + 1) + 11);
           let rand3 = Math.floor(Math.random() * (230 - 150 + 1) + 150);
           let codfiscale = 'XYZABC' + rand1 + 'T' + rand2 + 'L' + rand3 + 'P';


            let condizioni = this.$root.register.condizioni;
            let privacy = this.$root.register.privacy;
            let regolamento = this.$root.register.regolamento;

            if(!condizioni || !privacy || !regolamento) {
                this.checks.policies = false;
                return false;
            }

            this.$root.register.nome = nome;
            this.$root.register.cognome = cognome;
            this.$root.register.codfiscale = codfiscale;
            this.$root.register.phone = phone;
            this.$root.register.email = email;
            //genere is a vue model
            //this.$root.register.genere = genere;

            return true;

        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #registration-logo {
        max-width:80%;
    }
</style>
