<template>
    <div>

        <div class="container-fluid mt-3 px-0">
            <div class="row pt-3 mt-5 titolo-categoria orange-text-white">
                <div class="col-12 text-center">
                    <div ref="titleSlider" id="title-slider-rbodettagli" style="font-size:1.0rem;">
                        <div v-on:click="clickTitle(0)" id="title-0"  class="title-slider-slide titolo-piccolo">
                            <span>PROGRAMMI</span>
                        </div>
                        <div v-on:click="clickTitle(1)" id="title-1"  class="title-slider-slide titolo-piccolo">
                            <span>MIO PROGRAMMA</span>
                        </div>
                        <div id="title-2"  class="title-slider-slide titolo-piccolo">
                            <span class="slide-selected">STATISTICHE</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 text-center mt-0">
                    <img id="rbo-logo" src="img/logo_rbo.png">
                    <p class="titolo-grande mt-4 mb-0 px-5">I TUOI RISULTATI</p>
                </div>

                <div class="col-12">
                    <div class="row mt-3 mx-3 px-0">
                        <div class="col-4 px-0 text-center">
                            <button v-on:click.stop="changePeriod('settimana')" :class="{ 'btn-orange-orange' : (curPeriod == 'settimana'), 'btn-outline-gray' : (curPeriod != 'settimana') }" class="btn btn-orange testo-bottoni btn-rbo-period">SETTIMANA</button>
                        </div>
                        <div class="col-4 px-0 text-center">
                            <button v-on:click.stop="changePeriod('mese')" :class="{ 'btn-orange-orange' : (curPeriod == 'mese'), 'btn-outline-gray' : (curPeriod != 'mese') }" class="btn btn-orange testo-bottoni btn-rbo-period">MESE</button>
                        </div>
                        <div class="col-4 px-0 text-center">
                            <button v-on:click.stop="changePeriod('anno')" :class="{ 'btn-orange-orange' : (curPeriod == 'anno'), 'btn-outline-gray' : (curPeriod != 'anno') }" class="btn btn-orange testo-bottoni btn-rbo-period">ANNO</button>
                        </div>
                    </div>
                </div>


                <div class="col-12 p-0 m-0">
                    <div class="rbo-container">
                        <div class="rbo-img-container">
                            <img src="img/rbo/RBO_sotto50.png" class="rbo-img"  style="-webkit-animation:spinr 8s linear infinite; -moz-animation:spinr 8s linear infinite; animation:spinr 8s linear infinite;    
" :style="{'opacity' : ( ($root.rbo.curValue <= 25) ? 0.9 : 0.3) }">
                        </div>
                        <div class="rbo-img-container">
                            <img src="img/rbo/RBO_50-99.png" class="rbo-img" style="-webkit-animation:spinl 12s linear infinite; -moz-animation:spinl 12s linear infinite; animation:spinl 12s linear infinite;    
" :style="{'opacity' : ( ($root.rbo.curValue > 25) ? 0.9 : 0.3) }">
                        </div>
                        <div class="rbo-img-container">
                            <img src="img/rbo/RBO_100-149.png" class="rbo-img" style="-webkit-animation:spinr 16s linear infinite; -moz-animation:spinr 16s linear infinite; animation:spinr 16s linear infinite;    
" :style="{'opacity' : ( ($root.rbo.curValue > 50) ? 0.9 : 0.3) }">
                        </div>
                        <div class="rbo-img-container">
                            <img src="img/rbo/RBO_150-200.png" class="rbo-img" style="-webkit-animation:spinl 20s linear infinite; -moz-animation:spinl 20s linear infinite; animation:spinl 20s linear infinite;    
" :style="{'opacity' : ( ($root.rbo.curValue > 75) ? 0.9 : 0.3) }">
                        </div>
                        <div class="rbo-img-container">
                            <img src="img/rbo/RBO_sopra200.png" class="rbo-img" style="-webkit-animation:spinr 24s linear infinite; -moz-animation:spinr 24s linear infinite; animation:spinr 24s linear infinite;    
" :style="{'opacity' : ( ($root.rbo.curValue > 90) ? 0.9 : 0.3) }">
                        </div>
                        <div class="rbo-img-container">
                        <p class="rbo-value titolo-medio" style="color:white;">{{ $root.rbo.curValue }}<span style="font-size:0.8em; font-weight:light;">/10</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-12 px-4">
                    <div>
                    <NumAllenamentiChart ref="numallenam"/>
                    </div>
                </div>
            </div>

            <div class="row mt-3 pt-4 px-3">
                <div class="col-12">
                    <p class="titolo-piccolo orange-text-white">FREQUENZA ALLENAMENTO&nbsp;&nbsp;</p>
                </div>
                <div class="col-12">
                    <div class="row orange-text-white testo-piccolo">
                        <div class="col-4 text-left">
                            BASSA
                        </div>
                        <div class="col-4 text-center">
                            NORMALE
                        </div>
                        <div class="col-4 text-right">
                            OTTIMA
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div v-if="curPeriod == 'settimana'" class="rbo-progress-container">
                        <div :style="{ 'width' : $root.rbo.ingressi.week.perf + '%' }" class="rbo-progress-left">
                            &nbsp;
                        </div>
                        <div :style="{ 'width' : (100 - $root.rbo.ingressi.week.perf) + '%' }" class="rbo-progress-right">
                            &nbsp;
                        </div>
                    </div>
                    <div v-if="curPeriod == 'mese'" class="rbo-progress-container">
                        <div :style="{ 'width' : $root.rbo.ingressi.month.perf + '%' }" class="rbo-progress-left">
                            &nbsp;
                        </div>
                        <div :style="{ 'width' : (100 - $root.rbo.ingressi.month.perf) + '%' }" class="rbo-progress-right">
                            &nbsp;
                        </div>
                    </div>
                    <div v-if="curPeriod == 'anno'" class="rbo-progress-container">
                        <div :style="{ 'width' : $root.rbo.ingressi.year.perf + '%' }" class="rbo-progress-left">
                            &nbsp;
                        </div>
                        <div :style="{ 'width' : (100 - $root.rbo.ingressi.year.perf) + '%' }" class="rbo-progress-right">
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-2 pt-4 px-3">
                <div class="col-12">
                    <p class="titolo-piccolo orange-text-white">TIPOLOGIA ALLENAMENTO&nbsp;&nbsp;<span class="infobtn" v-on:click.stop="showRboEvofitInfo();">?</span></p>
                </div>
                <div class="col-12">
                    <div class="row orange-text-white testo-piccolo">
                        <div class="col-6 text-left">
                            CARDIO
                        </div>
                        <div class="col-6 text-right">
                            MUSCOLARE
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="rbo-progress-container">
                        <div :style="{ 'width' : $root.rbo.evofit.tipoCardioMuscolare.rapporto + '%' }" class="rbo-progress-left">
                            {{ $root.rbo.evofit.tipoCardioMuscolare.rapporto }} %
                        </div>
                        <div :style="{ 'width' : (100 - $root.rbo.evofit.tipoCardioMuscolare.rapporto) + '%' }" class="rbo-progress-right">
                            {{ 100 - $root.rbo.evofit.tipoCardioMuscolare.rapporto }} %
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-5 pt-5 px-3">
                <div class="col-12">
                    <p class="titolo-piccolo orange-text-white">GRUPPI MUSCOLARI&nbsp;&nbsp;<span class="infobtn" v-on:click.stop="showRboEvofitInfo();">?</span></p>
                </div>
                <div class="col-12">
                    <div class="row mt-3" v-for="gruppo,index in $root.rbo.evofit.gruppiMuscolari" :key="'rbogruppo-' + index">
                        <div class="col-4 orange-text-white" style="text-transform:uppercase; line-height:40px; font-size:12px;">
                            {{ gruppo.nome }}
                        </div>
                        <div class="col-8 orange-text-white">
                            <div class="rbo-progress-container" :style="{ 'width' : (gruppo.perc + 20) + '%' }">
                                <span>{{ gruppo.perc }} %</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

import NumAllenamentiChart from './charts/ChartNumeroAllenamenti'

export default {
    name: 'RBODettagli',
    components: {
        NumAllenamentiChart
    },
    data () {
        return {
            curPeriod: ''
        }
    },
    created() {
        setTimeout( () => {
            if(this.$refs.numallenam) {
                this.changePeriod('mese');
            }
        }, 4000);
    },
    methods: {
        clickTitle( _idx) {
            //if(this.$root.userAbbonamERateOK() ) {
                this.curTitleSelected = _idx;
                let elem = document.getElementById('title-' + _idx);
                //elem.scrollIntoView({ behavior: 'smooth' , alignToTop: false});
                if(_idx == 1) {
                    this.$root.evofitGetAndShowMyTraining(true);
                } else if(_idx == 0) {
                    this.$root.showPage('trainermainpage', false);
                }
            //}
        },

        changePeriod(_period) {
            this.curPeriod = _period;
            if(_period == 'settimana') {
                this.$root.rboCalcAllenamentiPeriodo(7);
                this.$refs.numallenam.setType('bar');
                this.$refs.numallenam.setData( this.$root.rbo.records.numAllenamenti);
            } else if( _period == 'mese') {
                this.$root.rboCalcAllenamentiPeriodo(30);
                this.$refs.numallenam.setType('bar');
                this.$refs.numallenam.setData( this.$root.rbo.records.numAllenamenti);
            } else if( _period == 'anno') {
                this.$root.rboCalcAllenamentiPeriodo(330);
                this.$refs.numallenam.setType('line');
                this.$refs.numallenam.setData( this.$root.rbo.records.numAllenamenti);
            }
        },

        showRboEvofitInfo() {
            this.$root.$refs.pageMan.showAlert("Le tue statistiche personali vengono aggiornate ogni volta che ti rechi in palestra, ogni volta che clicchi su FATTO o FATTO TUTTO nei singoli esercizi durante l\'allenamento manuale o quando termina un esercizio nell\'allenamento automatico e ogni volta che usi un video allenamento.",'OK','');
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #rbo-logo {
        width:20%;
        max-width: 90px;
    }
    
    #title-slider-rbodettagli {
        display:block;
        word-break: unset;
        white-space: nowrap;
        overflow-x: scroll;
        transform: translateX(0);
    }
    .btn-rbo-period {
        border-radius:15px !important;
        min-height: 30px !important;
        height: 30px !important;
    }

    .infobtn {
        display:inline-block;
        width:20px;
        height:20px;
        color:#999;
        line-height: 18px;
        font-size: 14px;
        font-weight: bolder;
        border:2px solid #999;
        border-radius:11px;
        text-align: center;
        margin-left:10px;
    }

    .rbo-progress-container {
        display:block;
        margin:0px;
        padding:0px;
        height: 40px;
        border:1px solid #666;
        background: rgb(34,195,47);
        background: -moz-linear-gradient(90deg, rgba(34,195,47,0.5) 0%, rgba(98,131,191,0.5) 29%, rgba(165,121,172,0.5) 63%, rgba(253,45,45,0.5) 100%);
        background: -webkit-linear-gradient(90deg, rgba(34,195,47,0.5) 0%, rgba(98,131,191,0.5) 29%, rgba(165,121,172,0.5) 63%, rgba(253,45,45,0.5) 100%);
        background: linear-gradient(90deg, rgba(34,195,47,0.5) 0%, rgba(98,131,191,0.5) 29%, rgba(165,121,172,0.5) 63%, rgba(253,45,45,0.5) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#22c32f",endColorstr="#fd2d2d",GradientType=1);
        border-radius: 10px;
        overflow-x:hidden;
        overflow-y:hidden;
        white-space: nowrap;
    }

    .rbo-progress-container span {
        height:40px;
        padding:0px;
        padding-left:10px;
        margin:0px;
        border:none;
        line-height: 40px;
        font-size: 15px;
        text-align:left;
        color:white;
        font-family: 'Circle Bold',sans-serif;
    }

    .rbo-progress-left {
        display:inline-block;
        border:none;
        background-color: rgba(255,255,255,0.3);
        line-height: 40px;
        font-size: 15px;
        text-align:left;
        color:white;
        font-family: 'Circle Bold',sans-serif;
        border-right: 1px solid rgba(255,255,255,0.5);
        padding-left:15px;
        overflow-x:show;
        vertical-align: top;
        min-height: 40px;
    }
    
    .rbo-progress-right {
        display:inline-block;
        border:none;
        background-color: transparent;
        line-height: 40px;
        color:white;
        font-size: 15px;
        text-align:right;
        font-family: 'Circle Bold',sans-serif;
        padding-right: 15px;
        overflow-x:hidden;
        vertical-align: top;
        min-height: 40px;
    }
    

</style>
