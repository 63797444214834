<template>
  <div>
    <canvas id="num-allenamenti-chart"></canvas>
  </div>
</template>

<script>

import Chart from 'chart.js'

import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.plugins.register(ChartDataLabels);

import drawRoundedBars from './RoundedBars'
Chart.elements.Rectangle.prototype.draw = drawRoundedBars;

export default {
    name: 'NumAllenamentiChart',
    data() {
        return {
            chart:null,
            gradient: null,
            numAllenamentiData: {
                type: "bar",
                    data: {
                    labels: ["", "", "", "", "", "", ""],
                    datasets: [
                        {
                            label: "Number of Moons",
                            data: [0, 0, 0, 0, 0, 0, 0, 0],
                            backgroundColor: '#f0f',
                            borderColor: "#000",
                            borderWidth: 0
                            //fillColor: 'rgba(123,54,64,1);'
                        }
                    ]
                },
                options: {
                    cornerRadius: 20,
                    responsive: true,
                    legend: {
                        display:false
                    },
                    tooltips: {
                        display:false
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    display: false,
                                    beginAtZero: true
                                }
                            }
                        ],
                        xAxes: [{
                            ticks: {
                                fontColor: '#fff'
                            }
                        }]
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            color: '#fff',
                            clamp: true,
                            formatter: function(value,context) {
                                return ((value > 0) && (context.dataset.data.length < 300)) ? value : '';
                            }
                        }
                    }
                }
            }
        }
    },
    mounted() {
        const ctx = document.getElementById('num-allenamenti-chart').getContext('2d');

        this.chart = new Chart( ctx,this.numAllenamentiData );
    },

    created() {
        
    },
    methods: {
        setData(_data) {
            this.chart.data.datasets[0].data = _data.data;
            this.chart.data.labels = _data.labels;
            this.chart.update();
        },

        setType(_type) {
            switch(_type) {
                case 'bar':
                    this.chart.data.type = 'bar';
                    this.chart.data.datasets[0].fill = true;
                    this.chart.data.datasets[0].borderColor = '#000';
                    this.chart.data.datasets[0].borderWidth = 1;
                    break;
                case 'line':
                    this.chart.data.type = 'line';
                    this.chart.data.datasets[0].backgroundColor = '#606'
                    this.chart.data.datasets[0].fill = false;
                    this.chart.data.datasets[0].borderColor = '#808';
                    this.chart.data.datasets[0].borderWidth = 3;
                    break;
            }
        }
    }
}
</script>