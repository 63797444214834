<template>
    <div>
        <div class="container-fluid">
            <div class="row pt-3 mt-5 titolo-categoria orange-text-white">
                <div class="col-4 text-center" style="font-size:1.0rem;">
                    <div v-on:click="clickTitle(0)" id="title-0"  class="title-slider-slide titolo-piccolo">
                        <span  style="text-decoration:none;">NEWS</span>
                    </div>
                </div>
                <div class="col-4 text-center" style="font-size:1.0rem;">
                    <div id="title-1"  class="title-slider-slide titolo-piccolo">
                        <span class="slide-selected" style="text-decoration:none;">PALESTRE</span>
                    </div>
                </div>
                <div class="col-4 text-center" style="font-size:1.0rem;">
                    <!-- <div v-on:click="clickTitle(2)" id="title-2"  class="title-slider-slide">
                        <span>CONTATTI</span>
                    </div> -->
                </div>
            </div>
            <div class="row mt-5 pt-5">
                <div class="col text-center">
                    <img id="login-logo" src="img/orangeLogo.svg">
                    <p class="titolo-grande">ORANGE PALESTRE</p>
                </div>
            </div>
            <div v-on:click.stop="showCenterDetails(centro)" v-for="(centro,index) in $root.centers.centers.filter( ct => (!ct.name.includes('OUTDOOR') && !ct.name.includes('REBORN')) )" :key="'centro-' + index" class="row mb-5" style="max-width:600px; margin-left:auto; margin-right:auto;">
                <div class="col-12 text-center py-4 px-0" style="display:block; position:relative; width:100%; min-height:250px; background-size:cover;" :style="{ backgroundImage: 'url(img/centri/' + centro.name.replaceAll(' ','_') + '/' + centro.name.replaceAll(' ','_') + '1.jpg)' }">
                    <div style="position:absolute; top:0px; left:0px; width:100%; height:100%; display:block; background-color:rgba(0,0,0,0.3);"></div>
                    <p class="titolo-categoria orange-text-white" style="display:block; margin-top:120px; transform:translateY(-50%);">
                        <span class="mb-2" style="display:block;">PALESTRA</span>
                        <span><strong class="titolo-35">{{ centro.name }}</strong></span>
                    </p>
                </div>
                <div class="col-12 py-2 text-center p-2 orange-text-white testo-generico">
                    <p class="mt-3 mb-0">Indirizzo: {{ centro.address }}</p>
                    <p>{{ centro.times }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ListaCentriPage',
    data () {
        return {
            curTitleSelected: 1
        }
    },
    methods: {
        clickTitle( _idx) {
            this.curTitleSelected = _idx;
            let elem = document.getElementById('title-' + _idx);
            if(_idx == 0) {
                this.$root.prodottiShowNews(false);
            } else if(_idx == 2) {
                this.$root.showPage('contattipage', false);
            }
        },

        showCenterDetails(_centro) {

            this.$root.centers.curCenterDetails = _centro;
            this.$root.$refs.pageMan.showOverlay('dettaglicentrooverlay');
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #login-logo {
        max-width:40%;
    }

</style>
