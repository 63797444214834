<template>
    <div>
        <div class="container-fluid">
            <div class="row my-5">
                <div class="col-12 my-5 text-center">
                    <p class="titolo-piccolo orange-text-black text-center mt-4 mb-0 pb-0" style="text-transform:uppercase;">I MIEI DATI</p>
                    <p class="testo-generico orange-text-black text-center" >Puoi verificare e mdificare le tue impostazioni</p>
                    <div class="avatar-container">
                        <span style="width:25%" class="icon-avatar"></span>
                    </div>
                    <p class="titolo-35 orange-text-black text-center mt-4 mb-0 pb-0" style="text-transform:uppercase;">{{ $root.user.name }} {{ $root.user.surname }}</p>
                </div>
            </div>
            <div class="row text-center">
                <!-- <div class="row-scelta text-left pl-4 col-12 border-top border-gray">
                    <div class="testo-generico orange-text-black">
                        ANAGRAFICA
                        <div class="icon-avanti">
                            <span class="icon-navig_back"></span>
                        </div>
                    </div>
                </div> -->
                <div v-if="$root.isApple == false" v-on:click="$root.$refs.pageMan.showPage('userabbonamentopage')" class="row-scelta text-left pl-4 col-12 border-top border-gray">
                    <div class="titolo-piccolo orange-text-black">GESTISCI ABBONAMENTO
                        <div class="icon-avanti">
                            <span class="icon-navig_back"></span>
                        </div>
                    </div>
                </div>

                <div v-if="$root.isApple == true" v-on:click="$root.$refs.pageMan.showPage('userabbonamentopage')" class="row-scelta text-left pl-4 col-12 border-top border-gray">
                    <div class="titolo-piccolo orange-text-black">INFO ABBONAMENTO
                        <div class="icon-avanti">
                            <span class="icon-navig_back"></span>
                        </div>
                    </div>
                </div>

                <div v-if="$root.isApple == false" v-on:click="$root.$refs.pageMan.showPage('userpagamentopage')" class="row-scelta text-left pl-4 col-12 border-top border-gray">
                    <div class="titolo-piccolo orange-text-black">METODO PAGAMENTO
                        <div class="icon-avanti">
                            <span class="icon-navig_back"></span>
                        </div>
                    </div>
                </div>
                <div v-if="$root.isApple == false" v-on:click="showCertMedico()" class="row-scelta text-left pl-4 col-12 border-top border-gray">
                    <div class="titolo-piccolo orange-text-black">CERTIFICATO MEDICO
                        <div class="icon-avanti">
                            <span class="icon-navig_back"></span>
                        </div>
                    </div>
                </div>
                <div v-on:click="$root.documentsGetAndShow()" class="row-scelta text-left pl-4 col-12 border-top border-gray">
                    <div class="titolo-piccolo orange-text-black">DOCUMENTI PERSONALI
                        <div class="icon-avanti">
                            <span class="icon-navig_back"></span>
                        </div>
                    </div>
                </div>
                <!-- <div class="row-scelta text-left pl-4 col-12 border-top border-gray">
                    <div class="titolo-piccolo orange-text-black">PRIVACY POLICY
                        <div class="icon-avanti">
                            <span class="icon-navig_back"></span>
                        </div>
                    </div>
                </div> -->
                <div  v-on:click.stop="cambiaPassword" class="row-scelta text-left pl-4 col-12 border-top border-gray">
                    <div class="titolo-piccolo orange-text-black">CAMBIA PASSWORD
                        <div class="icon-avanti">
                            <span class="icon-navig_back"></span>
                        </div>
                    </div>
                </div>
                <div  v-on:click.stop="vediTermini" class="row-scelta text-left pl-4 col-12 border-top border-bottom border-gray">
                    <div class="titolo-piccolo orange-text-black">TERMINI E CONDIZIONI
                        <div class="icon-avanti">
                            <span class="icon-navig_back"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import config from '../../../config/config_local.js'

export default {
    name: 'UserMieiDati',
    data () {
        return {
        }
    },
    methods: {

        vediTermini() {
            this.$root.$refs.pageMan.showPage('userterminipage');
        },

        showCertMedico() {
            this.$root.documentUpload.Type = "CertificatoMedico";
            this.$root.$refs.pageMan.showPage('usercertmedicopage')            
        },

        cambiaPassword() {
            this.$root.$refs.pageMan.showOverlay('usercambiopassword', false);
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #centro-logo {
        max-width:80%;
    }

    .avatar-container {
        display:block;
        position:relative;
        width: 90px;
        height: 90px;
        font-size:90px;
        color:#000;
        margin-left: auto;
        margin-right: auto;
    }

    .avatar-container {
        display:block;
        top:0px;
    }

    .row-scelta {
        height:50px;
        line-height: 50px;
    }

    .icon-avanti {
        float:right;
        height:50px;
        width: 50px;
        line-height: 50px !important;
        font-size:20px;
        color:#000;
        transform:scaleX(-1);
    }

    .icon-avanti ::after {
        clear:both;
    }
</style>
