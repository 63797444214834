<template>
    <div>
        <div class="container-fluid">
            <div class="row mt-5">
                <div class="col-12 my-5 testo-generico orange-text-black">
                    Consulta i documenti relativi al contratto, privacy e le condizioni di utilizzo del servizio.
                </div>

                <div class="row-scelta text-left pl-4 col-12 border-top border-gray">
                    <div v-on:click.stop="openContract($root.getIYESSettingByName('WEBContrattoURL'))" class="contrattolink" style="display:block;">
                        <div class="titolo-piccolo orange-text-black">CONTRATTO
                            <div class="icon-avanti">
                                <span style="font-size:1.1em" class="icon-pdf"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 'https://www.orangepalestre.it/docs/Informativa_privacy_app.pdf' -->

                <div class="row-scelta text-left pl-4 col-12 border-top border-gray">
                    <div v-on:click.stop="openContract('https://www.orangepalestre.it/docs/Informativa_privacy_app.pdf')" class="contrattolink" style="display:block;">
                        <div class="titolo-piccolo orange-text-black">PRIVACY POLICY
                            <div class="icon-avanti">
                                <span style="font-size:1.1em" class="icon-pdf"></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row-scelta text-left pl-4 col-12 border-top border-bottom border-gray">
                    <div v-on:click.stop="openContract($root.getIYESSettingByName('WEBRegolamentoURL'))" class="contrattolink" style="display:block;">
                        <div class="titolo-piccolo orange-text-black">REGOLAMENTO
                            <div class="icon-avanti">
                                <span style="font-size:1.1em" class="icon-pdf"></span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: 'UserTermini',
    data () {
        return {
        }
    },
    methods: {
        openContract(_url) {
            window.open( _url, '_system');
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .contrattolink {
        text-decoration: none;
    }
</style>