<template>
    <div>
        <div class="container-fluid">

            <div v-if="$root.cashback.cashbackModal.visible == true" id="payment-modal-back" style="z-index:4000;">
                <div class="row no-gutters" style="margin-top:150px;">
                    <div class="col-12 m-0 p-0">
                        <div class="card background-brown border-0 testo-generico orange-text-white">
                            <div class="card-body px-2" style="max-height:300px; overflow-y:scroll;">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <p class="titolo-piccolo orange-text-white"><strong>{{ $root.cashback.cashbackModal.title }}</strong></p>
                                    </div>
                                </div>
                                <div class="row" v-for="cont,index in $root.cashback.userContacts" :key="'cont-' + index">
                                    <div class="col-10 text-left">
                                        {{ cont.name }}
                                    </div>
                                    <div class="col-2">
                                        <input :id="'invite-' + index" type="checkbox" v-model="$root.cashback.userContacts[index].invited" class="switch">
                                        <label :for="'invite-' + index">&nbsp;</label>                
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-6">
                                        <button v-on:click="$root.cashback.cashbackModal.callbackCancel" class="btn btn-block btn-orange border-white testo-bottoni btn-outline-white">Annulla</button>
                                    </div>
                                    <div class="col-6">
                                        <button v-on:click="$root.cashback.cashbackModal.callbackOk" class="btn btn-block btn-orange testo-bottoni btn-orange-orange">Invita</button>
                                    </div>
                                </div>
                            </div>
                        </div>                
                    </div>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-12 mt-5 mb-0 testo-generico orange-text-black text-center">
                    <p class="titolo-medio orange-text-black"><strong>PRESENTA UN AMICO</strong></p>
                    <p class="testo-generico orange-text-black text-justify">
                        Invita un amico e <strong>ottieni 5 €</strong> se attiva la prova e conferma l'iscrizione
                    </p>
                </div>
            </div>
            
            <div class="row my-3">
                <div class="col-12 testo-generico orange-text-black text-center">
                    <p :class="{ 'orange-text-green' : ($root.payments.epurses.epurseBalance > 0), 'orange-text-red' : ($root.payments.epurses.epurseBalance <= 0) }">Saldo Borsellino<br>
                    <strong>{{ $root.payments.epurses.epurseBalance }} &euro;</strong>
                    </p>

                    <!-- <p v-if="$root.payments.contest.contest != null" :class="{ 'orange-text-green' : ($root.payments.epurses.epurseBalance > 0), 'orange-text-red' : ($root.payments.epurses.epurseBalance <= 0) }">Saldo Cashback<br>
                     <strong>{{ $root.payments.contest.contest.PointBalance }}&euro;</strong>
                     </p> -->
                </div>
                <!-- <div v-if="$root.payments.contest.contest != null && $root.payments.contest.contest.PointBalance > 0" class="col-12 mb-5">
                    <button v-on:click.stop="openOverlayCashback" class="btn btn-block btn-orange btn-orange-orange testo-bottoni">USA IL CASHBACK</button>
                </div> -->
            </div>

            <div v-if="$root.cashback.invitations.length > 0" class="row px-2">
                <div class="col-12 testo-generico orange-text-black">
                    <div class="row pb-2 border-bottom">
                        <div class="col-4">
                            Contatto
                        </div>
                        <div class="col-4 text-center">
                            Data
                        </div>
                        <div class="col-4 text-center">
                            Stato
                        </div>
                    </div>
                </div>
                <div class="col-12 testo-generico orange-text-black" style="max-height:200px; overflow-y:scroll;">
                    <div class="row pt-2 border-bottom" v-for="invit,index in $root.cashback.invitations" :key="'invit-' + index">
                        <div class="col-4">
                            {{ invit.invitedname }}
                        </div>
                        <div class="col-4 text-center">
                            {{ moment(invit.createdAt).format('DD-MM-YYYY') }}
                        </div>
                        <div class="col-4 text-right">
                            <p v-if="invit.visited < 2 && invit.registered == 0 && invit.subscribed == 0">inviato<span class="pallino-rosso"></span></p>
                            <p v-if="invit.visited > 1 && invit.registered == 0 && invit.subscribed == 0">visto<span class="pallino-giallo"></span></p>
                            <p v-if="invit.registered > 0 && invit.subscribed == 0">registrato<span class="pallino-arancio"></span></p>
                            <p v-if="invit.subscribed > 0">abbonato<span class="pallino-verde"></span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12 px-3">
                    <p class="testo-generico orange-text-black">Non hai ancora invitato nessuno.</p>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <a href="#" v-on:click.stop="getAndShowEpurses" class="orange-link-dark">Vai al tuo borsellino</a>
                </div>
                <div class="col-12 mt-5">
                    <button v-on:click.stop="$root.cashbackSelectContacts" class="btn btn-block btn-oange btn-orange-orange testo-bottoni">INVITA DALLA RUBRICA</button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: 'UserPresentaAmico',
    data () {
        return {
        }
    },
    methods: {
        //same exact code from UserMain page
        getAndShowEpurses() {
            this.$root.showWaiting();

            //gets the user contest status
            this.$root.paymentsGetUserContest();

            //gets all epurses and show the first one (treu for showing the page)
            this.$root.paymentsGetAndShowMyEpurses(true);
        },
        
        openOverlayCashback() {
            this.$root.$refs.pageMan._showAlertSceltaCashback();
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.pallino-rosso {
    float:right;
    height: 12px;
    width:12px;
    background-color: red;
    border-radius:6px;
    margin-left:5px;
}

.pallino-giallo {
    float:right;
    height: 12px;
    width:12px;
    background-color: yellow;
    border-radius:6px;
    margin-left:5px;
}

.pallino-arancio {
    float:right;
    height: 12px;
    width:12px;
    background-color: orange;
    border-radius:6px;
    margin-left:5px;
}

.pallino-verde {
    float:right;
    height: 12px;
    width:12px;
    background-color: green;
    border-radius:6px;
    margin-left:5px;
}

</style>