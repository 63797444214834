<template>
    <div>

        <div class="row pt-5 mt-3" v-if="$root.evofit.curScheda && $root.evofit.curScheda.sedute.length > 0">
            <div class="col-12">
                <p class="titolo-piccolo orange-text-white text-center mb-1">LISTA ESERCIZI</p>
                <p class="testo-generico orange-text-white text-center mt-1">{{ (parseInt($root.evofit.curScheda.curSeduta) + 1) }}° Seduta</p>
            </div>

            <div class="col-12 mb-4">
                <div class="row no-gutters">
                    <div class="col-12 orange-text-white text-center px-4">
                        <p class="mb-0 testo-generico">OBIETTIVO</p>
                        <p class="mt-0 testo-generico"><strong>{{ $root.evofit.curScheda.scheda.obiettivo }}</strong></p>
                    </div>
                    <div class="col-12 orange-text-white text-center px-4">
                        <p class="mt-0 mb-0 testo-generico">Esercizi {{ $root.evofit.curScheda.sedute[ $root.evofit.curScheda.curSeduta ].esercizi.length }}</p>
                        <p class="mt-0 testo-generico"><strong>Livello {{ $root.evofit.curScheda.scheda.livello }}</strong></p>
                    </div>
                    <div v-if="$root.evofit.curScheda.scheda.note != ''" class="col-12 orange-text-white text-center px-4">
                        <p class="mb-0 testo-generico orange-text-orange">Il trainer dice:</p>
                        <p class="mb-0 testo-generico">{{ $root.evofit.curScheda.scheda.note }}</p>
                    </div>                
                </div>
            </div>

            <div class="col-12" v-for="(eserman, indexesman) in $root.evofit.curScheda.sedute[ $root.evofit.curScheda.curSeduta ].esercizi" :key="'mm-' + indexesman">
                <div v-if="(eserman.tipo == 'I')" class="row p-0 pl-2 mb-4 testo-generico orange-text-white">
                    <div class="col-12"><p class="text-generico orange-text-white">Inizio Circuito. <strong><span v-text="eserman.serie.replace('<G>',' ')"></span> giri</strong></p></div>
                </div>
                <div v-if="(eserman.tipo == 'F')" class="row p-0 pl-2 mb-4 testo-generico orange-text-white">
                    <div class="col-12"><p class="text-generico orange-text-white">Fine Circuito.</p></div>
                </div>
                <div v-if="(eserman.tipo == 'A')" class="row p-0 pl-2 mb-4 testo-generico orange-text-white">
                    <div class="col-12"><p class="text-generico orange-text-white">Inizio Superserie.</p></div>
                </div>
                <div v-if="(eserman.tipo == 'B')" class="row p-0 pl-2 mb-4 testo-generico orange-text-white">
                    <div class="col-12"><p class="text-generico orange-text-white">Fine Superserie.</p></div>
                </div>
                <div v-if="(eserman.serieObj != null && eserman.tipo == 'E')" class="row p-0 pl-2 mb-4 testo-generico orange-text-white" v-on:click.stop="playSingoloEsercizio(eserman)">

                    <div class="col-12 p-0 px-4 m-0 mb-3 text-center">
                        <div class="img-col pr-1">
                            <div class="img-image" :style="{ 'background-image' : 'url(' + eserman.immagine + ')' }">
                            </div>
                            <span v-if="eserman.eseguito" class="esercizio-done orange-text-orange icon-fatto_cerchio"></span>
                        </div>
                        <div class="descr-col pr-0 pl-2">
                            <p class="pb-0 mb-0 orange-text-white" style="font-size:0.8rem;"><strong>{{ eserman.descrizione }}</strong></p>
                            <p v-if="eserman.gruppo1 != ''" class="pt-1 my-0">{{ eserman.gruppo1 }}</p>
                            <p v-else class="pt-1 my-0">Cardio</p>
                        </div>

                        <div class="serie-col px-0" v-if="eserman.serieObj.tipo == 'car'">
                            <div class="serie-row m-0 p-0" v-for="serval,valindex in eserman.serieObj.valori.filter( sr => ( (sr.val1 != '') || (sr.val2 != '')) )" :key="'servalman-' +valindex">
                                <!-- inverted order due ot float right -->
                                <div v-if="eserman.recupero != '0 min 0 sec'" class="serie-val-car text-left p-0 m-0 icone-ripetizioni">
                                    <span class="icon-recupero" style="font-size:20px;"></span>
                                    <span style="font-size:11px; padding-top:2px;"><b>{{ $root.evofit.curScheda.convertSecondsToString(eserman.recupero) }}</b></span> 
                                </div>
                                <div v-else class="serie-val-car text-left p-0 m-0 icone-ripetizioni">
                                    <span class="icon-recupero" style="font-size:20px;"></span>
                                    <span style="font-size:11px; padding-top:2px;"><b>--&nbsp;&nbsp;</b></span> 
                                </div>
                                <div v-if="serval.val2 != ''" class="serie-val-car p-0 m-0 icone-ripetizioni text-left">
                                    <span class="icon-ripetizioni" style="font-size:20px;"></span>
                                    <span style="font-size:11px; padding-top:2px;"><b>{{ serval.val2 }}&nbsp;</b></span>
                                </div>
                                <div class="serie-val-car p-0 m-0 icone-ripetizioni text-left">
                                    <span class="icon-durata" style="font-size:20px;"></span>
                                    <span style="font-size:11px; padding-top:2px;"><b>{{ serval.val1 }}&nbsp;</b></span>
                                </div>
                            </div>
                        </div>


                        <div class="serie-col px-0" v-if="eserman.serieObj.tipo == 'mus'">
                            <div class="serie-row m-0 p-0" v-for="serval,valindex in eserman.serieObj.valori.filter( sr => ( (sr.val1 != '') || (sr.val2 != '')) )" :key="'servalman-' +valindex">
                                <div v-if="eserman.recupero != 0" class="serie-val-mus text-left p-0 m-0 icone-ripetizioni">
                                    <!-- inverted order due ot float right -->
                                    <span class="icon-recupero" style="font-size:20px;"></span>
                                    <span style="font-size:11px; padding-top:2px;"><b>{{ $root.evofit.curScheda.convertSecondsToString(eserman.recupero) }}</b></span> 
                                </div>
                                <div v-else class="serie-val text-center p-0 m-0 icone-ripetizioni">
                                    &nbsp;
                                </div>
                                <div class="serie-val-mus p-0 m-0 icone-ripetizioni text-center">
                                    <span class="icon-pesi" style="font-size:20px;"></span>
                                    <span style="font-size:11px; padding-top:2px;"><b>{{ ( serval.val2 != '') ? serval.val2 : '--'  }}&nbsp;</b></span>
                                </div>
                                <div v-if="serval.val1 != ''" class="serie-val-mus p-0 m-0 icone-ripetizioni text-right">
                                    <span class="icon-ripetizioni" style="font-size:20px;"></span>
                                    <span style="font-size:11px; padding-top:2px;"><b>{{ serval.val1 }}&nbsp;</b></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'PlayerManualeLista',
    data () {
        return {
        }
    },
    methods: {
        playSingoloEsercizio(_esercizio) {
            if(!this.$root.evofit.sedutaPlayer) {
                console.log('SedutaPlayer missing in ManualeLista');
                return false;
            }
            //always push the first page, so that pageBack returns here and NOT on the sedute list
            this.$root.evofit.sedutaPlayer.mustPushPage = true;
            this.$root.evofit.sedutaPlayer.startSingle(_esercizio.codice);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.img-col {
    width: 18vw;
    height: 18vw;
    max-width: 85px;
    display:inline-block;
    vertical-align: top;
    background-color: #fff;
    position:relative;
}

@media all and (min-width:750px) {
    .img-col {
        width: 150px;
        height: 150px;
        max-width: 150px;
        display:inline-block;
        vertical-align: top;
        background-color: #fff;
        position:relative;
    }
}

.img-image {
    display:block;
    width: 100%;
    height: 100%;
    position:relative;
    background-size: cover;
    background-position: center center;
}

.img-col.done {
    background-color: #999;
    opacity:0.5;
}

.img-col.done  span {
    opacity:1;
}

.descr-col {
    width: 33%;
    max-width: 120px;
    display:inline-block;
    vertical-align: top;
}

.serie-col {
    width:48%;
    display:inline-block; 
    vertical-align: top;
}

.serie-row {
    width:100%;
    display:block;
    overflow-x:hidden;
}

.serie-val-car {
    display:inline;
    width:48%;
    float:right;
}

.serie-val-mus {
    display:inline;
    width:33%;
    float:right;
}

.esercizio-done {
    display:block;
    position:absolute;
    top:0px;
    left:0px;
    width:100%;
    text-align: center;
    font-size:27px;
    margin-top: 50%;
    transform: translateY(-50%)    
}
</style>
