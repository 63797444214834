import axios from 'axios';
import config from '../config/config_local.js'

export default  {
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
        appleAskDeleteUser() {
            this.$root.$refs.pageMan.showAlert('Questa funzione ti permette di cancellare definitivamente il tuo utente e <b>tutti i dati</b> ad esso associati.<br>I tuoi dati, le schede di allenamento e le statistiche andranno perse.<br><br>Sicuro di voler procedere?','PROCEDI','NO')
            .then( (ok) => {
                this.$root.$refs.pageMan.showAlert('Confermi di voler <b>cancellare definitivamente il tuo utente</b>?','CANCELLA','NO')
                .then( (ok) => {
                    this.appleDeleteUser();
                })
                .catch( (canc) => {
    
                });
            })
            .catch( (canc) => {

            });
        },

        appleDeleteUser() {
            this.$root.showWaiting('Attendere prego');
            let email = 
            axios({
                method: 'POST',
                url: config.serverUrl + '/user/v2/delete',
                data: {
                    email: this.user.email,
                    iyestoken : this.user.iyestoken
                }
            })
            .then( (deluser) => {
                console.log(deluser);
                this.$root.hideWaiting();
                if(deluser.data.error == false) {
                    this.$root.$refs.pageMan.showAlert('Il tuo utente è in fase di cancellazione. A breve sarà eliminato dal nostro staff.','OK');    
                    this.doLogout();
                } else {
                    this.$root.$refs.pageMan.showAlert('Spiacente, non è stato possibile cancellare il tuo utente. Riprovare più tardi.','OK');    
                }
            })
            .catch( (errdel) => {
                console.log(errdel);
                this.$root.hideWaiting();
            });
        },

        askApplePermission() {
            return new Promise( (resolve,reject) => {
                //check if the user gave tracking permission
                let appleTracking = window.localStorage.getItem('apple-tracking');
                //debug only
                appleTracking = null;
                if(appleTracking == null) {
                    window.plugins.impacTracking.trackingAvailable( (trackAvail) => {
                        console.log('Apple tracking available:');
                        console.log(trackAvail);

                        //modern iOS, ask for request
                        window.plugins.impacTracking.canRequestTracking( (canReq) => {
                            console.log('Apple can request:');
                            console.log(canReq);
                            if(canReq == true) {
                                let TrackingRequestReasons = [
                                    {
                                        text: 'Dati di allenamento',
                                    }
                                ];
                                let TrackingRequestInfo = {
                                    title: 'Permesso di tracciamento',
                                    text: 'Orange necessita il tuo permesso per tracciare il tuo allenamento',
                                    subText: 'I tuoi dati non saranno condivisi con nessuna azienda esterna',
                                    buttonTitle: 'Accetto',
                                    reasons: TrackingRequestReasons
                                };
                                window.plugins.impacTracking.requestTracking( undefined, 
                                    (trackResponse) => {
                                        console.log(trackResponse);
                                        window.localStorage.setItem('apple-tracking',trackResponse);
                                        resolve(trackResponse);
                                    }
                                );
                            } else {
                                window.localStorage.setItem('apple-tracking',true);
                                //return this.doNormalLogin(_email,_pass);        
                                resolve(true);
                            }
                        });
                    });
                } else {
                    resolve(true);
                }

            });
        }

    }
}