<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div v-on:click="closeThisOverlay()" class="col-2 pt-2 text-white">
                    <strong><span style="font-size:24px; line-height:45px;" class="icon-navig_chiudi"></span></strong>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-12">
                    <p class="titolo-piccolo orange-text-white text-center">CAMBIO PASSWORD</p>
                    <p class="mt-5 text-justify testo-generico">La tua password <strong>è scaduta, hai richiesto il cambio oppure sei un nuovo cliente</strong>.<br>Inserisci 2 volte la nuova password che hai scelto per confermare. Nota bene: è necessario impostare una password: <strong>lunga almeno 10 caratteri e che contenga numeri e lettere</strong>.</p>
                </div>
                <div class="col-12 mt-5">
                    <form class="form-signin">
                        <div style="display:none;" class="form-label-group">
                            <div class="ico-container"><span class="icon-password"></span></div>
                            <input type="text" v-on:keyup.enter="$refs.inputNewPassword.focus()" ref="inputTmpPassword" v-model="$root.user.tmpPassword" class="ico-input" id="inputTmpPassword" readonly>                            
                        </div>
                        <div class="form-label-group mt-5">
                            <div class="ico-container"><span class="icon-password"></span></div>
                            <input type="text" v-on:keyup.enter="$refs.inputConfNewPassword.focus()" ref="inputNewPassword" class="ico-input" id="inputNewPassword" placeholder="Nuova password" >
                        </div>
                        <div class="form-label-group">
                            <div class="ico-container"><span class="icon-password"></span></div>
                            <input type="text" v-on:keyup.enter="$event.target.blur();" ref="inputConfNewPassword" class="ico-input" id="inputConfNewPassword" placeholder="Conferma nuova password" >
                            <span v-show="checks.valid == false" class="label-error">Verificare password inserite</span>
                        </div>
                    </form>
                </div>
                <div class="col-12 mt-5">
                    <button v-on:click="checkFieldsAndChangePassword()" ref="btnConfCambioPass" class="btn mt-5 btn-block btn-orange btn-orange-orange">Conferma</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import popper from 'popper.js'
import axios from 'axios'
import config from '../../config/config_local.js'
import Validator from '../../js/classes/classValidators'

export default {
    name: 'CambioPassword',
    data () {
        return {
            checks: {
                valid: true
            }
        }
    },
    methods: {
        checkFieldsAndChangePassword() {
            let tmp = this.$refs.inputTmpPassword.value.trim();
            let nuova = this.$refs.inputNewPassword.value.trim();
            let confnuova = this.$refs.inputConfNewPassword.value.trim();

            if(tmp.length < 3 || nuova.length < 3 || confnuova.length < 3) {
                this.checks.valid = false;
                return false;
            }

            if(nuova != confnuova) {
                this.checks.valid = false;
                return false;
            }

            let validator = new Validator();

            if(validator.isStrongPassword(nuova) == false) {
                this.checks.valid = false;
                return false;
            }

            //user mixin
            this.$root.doChangePassword(tmp, nuova);
        },


        closeThisOverlay() {
            this.$root.$refs.pageMan.closeOverlay();
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #centro-logo {
        max-width:80%;
    }
</style>
