<template>
    <div>
        <div class="container-fluid pb-5">
            <div class="row">
                <div v-on:click="closeThisOverlay()" class="col-2 pt-2 text-black">
                    <strong><span style="font-size:24px; line-height:45px;" class="icon-navig_chiudi"></span></strong>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-12">
                    <div class="text-center">
                        <p class="titolo-piccolo orange-text-black text-center mt-4 mb-2 pb-0" style="text-transform:uppercase;">LA MIA PALESTRA</p>
                        <p class="testo-generico orange-text-black text-center" >Indica il centro che frequenti più spesso. Orari e lezioni nell'app saranno sincronizzate con questo centro.</p>
                        <p class="testo-generico orange-text-black text-center" ><strong>le palestre Orange</strong></p>
                    </div>
                </div>
            </div>
            <div class="row mt-2 mb-5 px-3">
                <div v-for="center,index in $root.centers.centers.filter( ct => (ct.name.indexOf('REBORN') == -1) && (ct.name.indexOf('OUTDOOR') == -1))" 
                    :key="'centers-' + index" 
                    :style="{ 'color' : ( ($root.user.centerId == center.iyes.id) ? '#fff' : '#333' ), 'background-color' : ( ($root.user.centerId == center.iyes.id) ? '#EF7E2D' : '#fff' )}" 
                    class="col-12 mb-2 py-3 testo-generico border border-dark">
                    <div  class="row py-0 mt-0">
                        <div class="col-12 text-left">
                            <span><strong>{{ center.name }}</strong></span>
                        </div>
                        <div class="col-12">
                            <div class="row py-0 mt-0">
                                <div class="col-10 text-left" v-on:click="centroChangeCenter(center)">
                                    <span>{{ center.address }}</span>
                                </div>
                                <div v-on:click="showCenterDetailsOverlay(center)" class="col-2 text-right">
                                    <span>VEDI</span>
                                </div>
                            </div>
                            <div class="row py-0 mt-0">
                                <div class="col-12 text-left">
                                    <span>{{ center.times }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'SceltaCentro',
    data () {
        return {
        }
    },
    methods: {
        centroChangeCenter(center) {
            this.$root.$refs.pageMan.showAlert('Impostare ' + center.name + ' come centro preferito?','OK','ANNULLA')
            .then( (okctr) => {
                let index = center.iyes.id;
                this.$root.user.realCenterId = index;
                this.$root.user.centerId = index;
                localStorage.setItem('centro-default', index);
                this.$root.$refs.pageMan.closeOverlay();
            });
        },

        closeThisOverlay() {
            this.$root.$refs.pageMan.closeOverlay();
        },

        showCenterDetailsOverlay(_centro) {
            this.$root.centers.curCenterDetails = _centro;
            this.$root.$refs.pageMan.showOverlay('dettaglicentrooverlay');
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #centro-logo {
        max-width:80%;
    }
</style>
