'use strict'

class gallery3d {

    constructor(_centerClickhandler) {
        this.images = [];
        this.className = '.img-cont';
        this.rotating = false;
        this.centerClickHandler = null;
        this.initGal(_centerClickhandler);
    }

    initGal(_centerClickHandler) {

        this.centerClickHandler = _centerClickHandler;

        this.swipeRegion = new ZingTouch.Region( document.getElementById('gal-parent'), true, false) ;
        let swipeTarget = document.getElementById('gal-container');
        var me = this;
        this.swipeRegion.bind( swipeTarget, 'swipe', (e) => {
            if(this.rotating == true) {
                console.log('No swipe cause it is rotating')
                return false;
            }

            console.log(e.detail.data[0].distance);
            if(e.detail.data[0].distance < 6)
                return false;

            let dir = e.detail.data[0].currentDirection;
            if( (dir > 314 && dir <= 360) || (dir >= 0 && dir < 45) ) {
                me.scrollLeft();
            } else if( (dir > 135) && (dir <= 225) ) {
                me.scrollRight();
            }

        });

        let tmpimg = document.querySelectorAll('.img-cont');
        this.images = Array.from(tmpimg);
        this.images.forEach( (img, index) => {
            if(index == 0) {
                img.classList.add('img-left');
            } else if( index == 1) {
                img.classList.add('img-center');
            } else if( index == 2) {
                img.classList.add('img-right');
            } else {
                img.classList.add('img-hidden');
            }

            img.addEventListener('click', (event) => {
                this.manageClick(event.currentTarget);
            });

            this.swipeRegion.bind( img, 'tap', (e) => {
                this.manageClick(e.target);
            });
        });

    }

    manageClick(target) {
        if(this.rotating == true)
            return false;

        let img = target;
        if(img.classList.contains('img-center')) {
            console.log('center clicked');
            this.centerClickHandler(target);
            return;
        } else {
            console.log('side clicked');
            if(img.classList.contains('img-right')) {
                this.scrollRight();
            } else {
                this.scrollLeft();
            }
        }
    }    

    _swapClasses( destIdx, srcIdx ) {
        let srcClasses = this.images[srcIdx].classList.value;
        this.images[ destIdx ].classList.value = srcClasses;
    }

    scrollRight() {

        this.rotating = true;
        console.log('RIGHT');
        let tmpimg = document.querySelectorAll('.img-cont');
        this.images = Array.from(tmpimg);

        let tmpCl = this.images[ this.images.length - 1].classList.value;
        //move the classes from last image to first one
        for(let i = this.images.length - 1; i > 0; i--){
            this._swapClasses( i, i - 1);
            //this.images[i].classList = this.images[i - 1].classList;
        }
        this.images[0].classList = tmpCl;
        setTimeout( () => {
            this.rotating = false;
        }, 800);
    }

    scrollLeft() {
        if(this.rotating == true)
            return false;

        this.rotating = true;
        console.log('LEFT');
        let tmpimg = document.querySelectorAll('.img-cont');
        this.images = Array.from(tmpimg);

        let tmpCl = this.images[ 0 ].classList.value;
        //move the classes from last image to first one
        for(let i = 0; i < this.images.length - 1; i++){
            this._swapClasses( i, i + 1);
            //this.images[i].classList = this.images[i - 1].classList;
        }
        this.images[ this.images.length - 1].classList = tmpCl;
        setTimeout( () => {
            this.rotating = false;
        }, 700);
    }


}

module.exports = gallery3d;