import axios from 'axios';
import config from '../config/config_local.js'

import moment from 'moment'
import momentIT from 'moment/locale/it'

export default  {
    data() {
        return {
            register: {
                registrationSubscriptionIdx: null,
                email: '',
                nome: '',
                cognome: '',
                phone: '',
                codfiscale: '',
                genere: 'S',
                condizioni: false,
                privacy: false,
                regolamento: false,
                datanascita: null,
                centro: -1,
                registerProduct: null
            }
        }
    },
    methods: {

        registrationReset() {
            this.register.registrationSubscriptionIdx = null;
            this.register.email = '';
            this.register.nome = '';
            this.register.cognome = '';
            this.register.phone = '';
            this.register.codfiscale = '';
            this.register.genere = 'S';
            this.register.condizioni = false;
            this.register.privacy = false;
            this.register.regolamento = false;
            this.register.datanascita = null;
            this.register.centro = -1;
            this.register.registerProduct = null;            
        },

        doSignup() {
            //if(config.isApple) {
            //    this.askApplePermission()
            //    .then( () => {
            //        this.doNormalSignup();
            //    });    
            //} else {
                this.doNormalSignup();
            //}
        },

        doNormalSignup() {
            this.$root.$refs.pageMan.showWaiting();
            axios.post( config.serverUrl + '/user/add', {
                "newUser" : {
                    "AgreeContract" : this.register.condizioni,
                    "AgreePrivacy" : this.register.privacy,
                    "AgreeRule" : this.register.regolamento,
                    "AllowEmail" : true,
                    "AllowPaper" : true,
                    "AllowSMS" : true,
                    "BirthDate" : moment(this.register.datanascita).format('YYYY-MM-DDT00:00:00'),
                    "CategoryID": 14,
                    "Gender" : this.register.genere,
                    "CurrentCompanyID" : 8,
                    "Email": this.register.email,
                    "FirstName" : this.register.nome,
                    "FiscalCode" : this.register.codfiscale,
                    "LastName" : this.register.cognome,
                    "WebLogin": this.register.email,
                    "Mobile" : this.register.phone,
                    "WebPassword" : "",
                    "Phone1" : this.register.phone,
                    "Province" : "",
                    "Source" : "ON-LINE",
                    "BirthProvince" : "",
                },
                "iyestoken" : "register"
            })
            .then( (res) => {
                if(typeof res.data.error != 'undefined' && res.data.error == false) {
                    this.userSetMe(res.data.data.data);
                    this._verifyNewUserProductRegistration();
                    this.$root.setIubendaConsent();
                } else {
                    this.$root.$refs.pageMan.hideWaiting();
                    if(res.data.message.indexOf('già registrato') !== -1) {
                        this.$root.$refs.pageMan.showAlert("Esiste già un utente con questi dati registrati sul sistema. Fai il login o recupera la password per accedere.",'OK','');
                    } else {
                        this.$root.$refs.pageMan.showAlert("Non è stato possibile registrare il nuovo utente sul sistema. " + res.data.message,'OK','');
                    }
                    //this.showPage('login');    
                }
            })
            .catch( (err) => {
                this.$root.$refs.pageMan.hideWaiting();
                alert("Non è stato possibile registrare il nuovo utente. Contattare assistenza");
                //this.showPage('login');
            });

        },

        _verifyNewUserProductRegistration() {

            //user skipped the product selection or isApple
            //if isApple this flag was set to -1 due to no free products while signup
            if(this.$root.register.registrationSubscriptionIdx == -1) {
                this.$root.hideWaiting();
                this.showPage('registrationpasswordpage');
                return false;
            }

            this.register.registerProduct = this.$root.products.vetrina[ this.$root.register.registrationSubscriptionIdx ];
            //both functions are in payments mixin
            if(this.register.registerProduct.Price == 0) {
                let dataInizio = moment().format('YYYY-MM-DD');
                this.paymentGiftProduct( this.register.registerProduct, dataInizio)
                .then( (ok) => {
                    this.$root.hideWaiting();
                    /* 
                    the new login process forces the user to select a password BEFORE sending the confirmation email
                    this.$root.$refs.pageMan.showAlert("Registrazione avvenuta con successo. Attendi la conferma via mail.",'OK','');
                    this.register.registerProduct = null;
                    this.getMe(); */
                    this.$root.$refs.pageMan.showPage('registrationpasswordpage');
                })
                .catch( (ko) => {
                    this.register.registerProduct = null;
                    this.$root.hideWaiting();
                    this.$root.$refs.pageMan.showPage('loginpage');
                });
            } else {
                //se il prodotto ha un prezzo serve inserire la carta di credito
                this.$root.hideWaiting();
                this.paymentsAddNewCard();
                //old this.paymentCreateProductsOrder(this.register.registerProduct);
            }
        }

    }
}