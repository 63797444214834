import axios from 'axios';
import config from '../config/config_local.js'

import moment from 'moment';
import momentIT from 'moment/locale/it'

export default  {
    data() {
        return {
            rbo: {
                records: {
                    all: [],
                    week: [],
                    month: [],
                    year: [],
                    numAllenamenti: {
                        data: [1,4,2,3,6,10,6],
                        labels: ['','','','','','','']
                    }
                },                

                ingressi: {
                    week: {
                        count: 0,
                        perf: 0
                    },
                    month: {
                        count: 0,
                        perf: 0
                    },
                    year: {
                        count: 0,
                        perf: 0
                    }
                },

                curValue: 53,
                evofit: {
                    rawDiaryData: [],
                    gruppiMuscolari: [],
                    gruppiMuscolariTotal: -1,
                    tipoCardioMuscolare: {
                        cardio: 0,
                        pesi: 0,
                        totale: 0,
                        rapporto: 50
                    }
                }
            }
        }
    },
    created() {

    },

    methods: {
        //retrieve all stats from the rbo_stats table
        //the stats for the evofit diary are loaded inside evofitGetStatsAllenamento
        rboGetData() {
            if(this.user.iyestoken) {
                axios.post( config.serverUrl + '/rbo/getme', {
                    iyestoken : this.user.iyestoken,
                    iyesUserId: this.user.iyesUserId
                })
                .then( (resmy) => {
                    if(!resmy.data.error) {
                        let now = moment();
                        this.rbo.records.all = [];
                        this.rbo.records.week = [];
                        this.rbo.records.month = [];
                        this.rbo.records.year = [];
                        this.rbo.curValue = 0;
                        this.rbo.ingressi.week.count = 0;
                        this.rbo.ingressi.month.count = 0;
                        this.rbo.ingressi.year.count = 0;
                        this.rbo.ingressi.week.perf = 0;
                        this.rbo.ingressi.month.perf = 0;
                        this.rbo.ingressi.year.perf = 0;

                        resmy.data.data.forEach( (rec) => {
                            let tmpRec = {
                                operazione: rec.operazione,
                                descrizione: rec.descrizione,
                                tipo_allenamento: rec.tipo_allenamento,
                                codice: rec.codice,
                                quantita: rec.quantita,
                                valore_rbo: parseInt(rec.valore_rbo),
                                data: moment(rec.data),
                            };

                            this.rbo.records.all.push( tmpRec );

                            let daysDiff = Math.abs( now.diff( tmpRec.data,'days') );
                            if( daysDiff <= 7) {
                                this.rbo.records.week.push( tmpRec );
                                this.rbo.curValue += rec.valore_rbo;
                                if(tmpRec.descrizione.indexOf('ingresso')) {
                                    this.rbo.ingressi.week.count ++;
                                }
                            }

                            if(daysDiff <= 30) {
                                this.rbo.records.month.push( tmpRec );
                                if(tmpRec.descrizione.indexOf('ingresso')) {
                                    this.rbo.ingressi.month.count ++;
                                }
                            }

                            if(daysDiff <= 365) {
                                this.rbo.records.year.push( tmpRec );
                                if(tmpRec.descrizione.indexOf('ingresso')) {
                                    this.rbo.ingressi.year.count ++;
                                }
                            }

                        });
                        this.rboSetPerformancesIngressi();
                        this.rboCalcStats();
                    } else {
                        console.log('WARNING! Cannot retrieve RBO values');
                        console.log(resmy.data);    
                    }
                })
                .catch( (errmy) => {
                    console.log('WARNING! Cannot retrieve RBO values');
                    console.log(errmy);
                });    
            }         
        },
        
        rboSetPerformancesIngressi() {
            if(this.rbo.ingressi.week.count <= 1) {
                this.rbo.ingressi.week.perf = 20;
            } else if(this.rbo.ingressi.week.count == 1) {
                this.rbo.ingressi.week.perf = 30;
            } else if(this.rbo.ingressi.week.count == 2) {
                this.rbo.ingressi.week.perf = 50;
            } else if(this.rbo.ingressi.week.count == 3) {
                this.rbo.ingressi.week.perf = 70;
            } else if(this.rbo.ingressi.week.count == 4) {
                this.rbo.ingressi.week.perf = 80;
            } else if(this.rbo.ingressi.week.count > 4) {
                this.rbo.ingressi.week.perf = 85;
            }

            if(this.rbo.ingressi.month.count <= 4) {
                this.rbo.ingressi.month.perf = 20;
            } else if( (this.rbo.ingressi.month.count > 4 ) && (this.rbo.ingressi.month.count < 2*4 )) {
                this.rbo.ingressi.month.perf = 30;
            } else if( (this.rbo.ingressi.month.count == 2*4) && (this.rbo.ingressi.month.count <= 3*4)) {
                this.rbo.ingressi.month.perf = 50;
            } else if((this.rbo.ingressi.month.count == 3*4) && (this.rbo.ingressi.month.count <= 4*4)){
                this.rbo.ingressi.month.perf = 70;
            } else if((this.rbo.ingressi.month.count == 4*4) && (this.rbo.ingressi.month.count <= 5*4)){
                this.rbo.ingressi.month.perf = 80;
            } else if(this.rbo.ingressi.month.count > 5*4) {
                this.rbo.ingressi.month.perf = 85;
            }

            if(this.rbo.ingressi.year.count <= 52) {
                this.rbo.ingressi.year.perf = 25;
            } else if( (this.rbo.ingressi.year.count > 52 ) && (this.rbo.ingressi.year.count <= 2*52 )) {
                this.rbo.ingressi.year.perf = 35;
            } else if( (this.rbo.ingressi.year.count > 2*52 ) && (this.rbo.ingressi.year.count <= 3*52 )) {
                this.rbo.ingressi.year.perf = 50;
            } else if( (this.rbo.ingressi.year.count > 3*52 ) && (this.rbo.ingressi.year.count <= 4*52 )) {
                this.rbo.ingressi.year.perf = 70;
            } else if( (this.rbo.ingressi.year.count > 4*52 ) && (this.rbo.ingressi.year.count <= 5*52 )) {
                this.rbo.ingressi.year.perf = 80;
            } else if(this.rbo.ingressi.year.count > 5*52) {
                this.rbo.ingressi.year.perf = 85;
            }

        },

        rboCalcStats() {
            //calcola il numero di allenamenti per ogni giorno della settimana
            this.rboCalcAllenamentiPeriodo(30);
        },

        rboCalcAllenamentiPeriodo(_lunghPeriodo) {
            let curDate = moment();//.subtract(1,'days');
            let curDateStr = curDate.format('YYYY-MM-DD');
            this.rbo.records.numAllenamenti.data = new Array(_lunghPeriodo);
            this.rbo.records.numAllenamenti.labels = new Array(_lunghPeriodo);     
            this.rbo.curValue = 0;
            let i = 0;
            let ingressi = 0;
            let schede = 0;
            let video = 0;
            for(i = 0; i < _lunghPeriodo; i++) {
                let todayCount = 0;
                this.rbo.records.all.forEach( (rec) => {
                    if(rec.data.format('YYYY-MM-DD') == curDateStr) {
                        todayCount++;
                        this.rbo.curValue += rec.valore_rbo;
                        if(rec.operazione.indexOf('ingresso') != -1) {
                            ingressi++;
                        }
                        if(rec.operazione.indexOf('open_scheda') != -1) {
                            schede++;
                        }
                        if(rec.operazione.indexOf('reborn') != -1) {
                            video++;
                        }
                    }
                });

                if(_lunghPeriodo > 300) {
                    this.rbo.records.numAllenamenti.labels[ (_lunghPeriodo - 1) -i ] = curDate.locale('it').format('MMM');
                    this.rbo.records.numAllenamenti.data[ (_lunghPeriodo - 1) - i] = todayCount;
                } else {
                    this.rbo.records.numAllenamenti.labels[ (_lunghPeriodo - 1) -i ] = curDate.locale('it').format('ddd');
                    this.rbo.records.numAllenamenti.data[ (_lunghPeriodo - 1) - i] = todayCount;
                }
                curDate.subtract(1,'days');
                curDateStr = curDate.format('YYYY-MM-DD');
            }

            let cur = 0;
            let settimane = (_lunghPeriodo / 7); 
            if(ingressi == 0) {
                if(schede > 0) {
                    cur = 5;
                } else {
                    cur = 4;
                }
            } else if (ingressi == 1 * settimane) {
                if(schede > 0) {
                    cur = 6;
                } else {
                    cur = 5;
                }
            } else if (ingressi == 2 * settimane) {
                if(schede > 0) {
                    cur = 7;
                } else {
                    cur = 6;
                }
            } else if (ingressi == 3 * settimane) {
                if(schede > 0) {
                    cur = 8;
                } else {
                    cur = 7;
                }
            } else if (ingressi == 4 * settimane) {
                if(schede > 0) {
                    cur = 9;
                } else {
                    cur = 8;
                }
            } else if (ingressi == 4 * settimane) {
                if(schede > 0) {
                    cur = 10;
                } else {
                    cur = 9;
                }
            }

            if(cur < 1) {
                cur = 2;
            }
            
            this.rbo.curValue = cur;
        },

        rboShowPage() {
            this.$root.$refs.pageMan.showPage('rbodettaglipage', false);
            setTimeout( () => {
                let titleSl = document.getElementById('title-slider-rbodettagli');
                if(titleSl) {
                    titleSl.scrollLeft += 300;
                }
            },200);
        },


        rboAddData(_operazione, _descrizione, _tipo_allenamento, _codice, _quantita, _valore_rbo) {
            console.log('RBO adding record');

            let _rboData = {
                operazione: _operazione,
                descrizione: _descrizione,
                tipo_allenamento: _tipo_allenamento,
                codice: _codice,
                quantita: _quantita,
                valore_rbo: _valore_rbo
            };
            this._rboSaveRecord(_rboData);
        },

        _rboSaveRecord(_rboData) {
            if(this.user.iyestoken) {
                axios.post( config.serverUrl + '/rbo/addrecord', {
                    iyestoken : this.user.iyestoken,
                    iyesUserId: this.user.iyesUserId,
                    rboData: _rboData
                })
                .then( (resmy) => {
                    if(resmy.data.error) {
                        console.log("WARNING! Cannot save RBO values.");
                        console.log(resmy);
                    } else {
                        console.log(resmy.data);  
                        this.rboGetData();  
                    }
                })
                .catch( (errmy) => {
                    console.log('WARNING! Cannot save RBO values');
                    console.log(errmy);
                });    
            }         
        },

        rboSetEvofitDiaryData(_rawdata) {
            this.rbo.evofit.rawDiaryData = _rawdata;
            this.rbo.evofit.gruppiMuscolari = [];
            this.rbo.evofit.tipoCardioMuscolare.cardio = 0;
            this.rbo.evofit.tipoCardioMuscolare.pesi = 0;
            this.rbo.evofit.tipoCardioMuscolare.totale = 0;

            this.rbo.evofit.rawDiaryData.forEach( (evodata) => {
                this._rboUpsertGruppoMuscolare(evodata);
                if(evodata.cardio > 0) {
                    this.rbo.evofit.tipoCardioMuscolare.cardio += evodata.cardio;
                } else if(evodata.pesi > 0) {
                    this.rbo.evofit.tipoCardioMuscolare.pesi += evodata.pesi;
                }
                this.rbo.evofit.tipoCardioMuscolare.totale += (evodata.cardio + evodata.pesi);
            });

            //add one to cardio or pesi just for the appearence of the graph
            if(this.rbo.evofit.tipoCardioMuscolare.pesi == 0) {
                this.rbo.evofit.tipoCardioMuscolare.pesi = 1;
                this.rbo.evofit.tipoCardioMuscolare.totale += 1;
            }
            if(this.rbo.evofit.tipoCardioMuscolare.cardio == 0) {
                this.rbo.evofit.tipoCardioMuscolare.cardio = 1;
                this.rbo.evofit.tipoCardioMuscolare.totale += 1;
            }

            this.rbo.evofit.tipoCardioMuscolare.rapporto = Math.round(100 * (this.rbo.evofit.tipoCardioMuscolare.cardio / this.rbo.evofit.tipoCardioMuscolare.totale)); 
            
            this.rbo.evofit.gruppiMuscolariTotal = 0;
            this.rbo.evofit.gruppiMuscolari.forEach( (gm) => {
                this.rbo.evofit.gruppiMuscolariTotal += gm.count;
            });

            //update percentuali gruppi muscolari
            this.rbo.evofit.gruppiMuscolari.forEach( (gm) => {
                gm.perc = Math.round(100 * (gm.count / this.rbo.evofit.gruppiMuscolariTotal) * 100) / 100;
            });
        },

        _rboUpsertGruppoMuscolare(evodata) {
            let found = false;
            let i = 0;
            while( i < this.rbo.evofit.gruppiMuscolari.length && found == false) {
                if( this.rbo.evofit.gruppiMuscolari[i].nome == evodata.gruppo) {
                    found = true;
                }
                if(!found)
                    i++;
            }

            if(!found) {
                //add this gruppo
                this.rbo.evofit.gruppiMuscolari.push({
                    nome: evodata.gruppo,
                    count: evodata.pesi + evodata.cardio,
                    perc: 0
                });

                this.rbo.evofit.gruppiMuscolariTotal += (evodata.pesi + evodata.cardio);
            } else {
                //update this gruppo
                this.rbo.evofit.gruppiMuscolari[i].count += (evodata.pesi + evodata.cardio);
                this.rbo.evofit.gruppiMuscolariTotal += this.rbo.evofit.gruppiMuscolari[i].count;
           }
        }

    }
}