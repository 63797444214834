import Vue from 'vue';
import axios from 'axios';
import config from '../config/config_local.js'

export default  {
    data() {
        return {
            ui: {
                squareHeight: 300,
                updateInterval: null,
                iconTopBar: '',
                iconBottomBar: ''
            }
        }
    },
    created() {
    },
    methods: {
        uiCheckVersion() {
            axios.get( config.serverUrl + '/version/get')
            .then( (resmy) => {
                if( (typeof(resmy.data) != 'undefined') && (typeof(resmy.data.app_needs_update) != 'undefined')){
                    console.log('Getting server version:');
                    console.log(resmy.data.server_version);
                    console.log('App version:' + config.version.major + '.' + config.version.minor + '.' + config.version.build);
                    if( (resmy.data.app_needs_update == true) || (this.uiAppVersionValid(resmy.data.server_version) == false)){
                        let block = window.document.createElement('div');
                        block.style = "position:fixed; left:0px; text-align:center; color:#fff; padding-top:100px; top:0px; width:100%; height:100%; background-color:#000; z-index:10000;";
                        block.innerHTML = 'Info:<br>Necessario aggiornamento.';
                        if(window.hasOwnProperty('cordova')) {
                            if( confirm('Sarà necessario aggiornare l\'applicazione dallo store per proseguire. Aggiornare adesso?') == true) {
                                this.uiOpenStore();
                            }                                
                        } else {
                            alert('Sarà necessario aggiornare l\'applicazione dallo store per proseguire.');
                        }
                        window.document.body.append( block );
                    }
                } else {
                    console.log('Unable to get server version.');
                }
            })
            .catch( (errmy) => {
                console.log('WARNING! Cannot retrieve server version');
                console.log(errmy);
            });    
        },

        uiOpenStore() {
            if(window.hasOwnProperty('cordova')) {
                if(this.$root.isApple) {
                    //apple store
                    window.open();
                } else {
                    //google play store
                    window.open('market://details?id=reborn.orangepalestre.com');
                }
            } 
        },

        uiAppVersionValid(_serverVersionStr) {
            let srvArr = _serverVersionStr.split('.');
            let srvVer = (srvArr[0] * 1000) + (srvArr[1] * 100) + (srvArr[2] * 100);
            let appVer = (config.version.major * 1000) + (config.version.minor * 100) + (config.version.build * 100);
            return (appVer >= srvVer);
        },

        uiInitUpdate() {
            this.uiUpdate();
            this.updateInterval = setInterval( () => {
                this.uiUpdate();
            }, 1000);
        },

        uiUpdate() {
            this.ui.squareHeight = window.innerWidth;
            if(this.ui.squareHeight > 1000) {
                this.ui.squareHeight = 600;
            }
        },

        uiSetBottomIcon(_name) {
            this.ui.iconBottomBar = _name.toLowerCase();
        }, 

        uiSetTopIcon(_name) {
            this.ui.iconTopBar = _name.toLowerCase();
        },

        uiResetAllIcons() {
            this.ui.iconBottomBar = '';
            this.ui.iconTopBar = '';
        },

        uiScrollToTop() {
            Vue.nextTick( ()=> {
                let pages = window.document.querySelectorAll('.ls-page');
                pages.forEach( (page) => {
                    page.scrollTop = 0;
                });
            });
        },

    }
}