let centersJson = [
    {   
        iyes : {
            id:0,
	        idgymansium:351,
            serviceId: 0
        } ,
        name: "CERCENASCO",
        address: "VIA CERCENASCO, 17 - 10135 TORINO",
        times: "LUN./VEN. 8/22 - SAB. 9/19",
        telefono: "",
        email: "info@orangepalestre.it",        
        gmapsiframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2820.0677382949357!2d7.639737315098531!3d45.02355037075918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478812e024cdc72d%3A0xc1b25f4a92e58924!2sVia+Cercenasco%2C+17%2C+10135+Torino+TO!5e0!3m2!1sit!2sit!4v1534933471564",
        numfoto: 13
    },
    {   
        iyes : {
            id:1,
	        idgymansium:353,
            serviceId: 0
        } ,
        name: "CHIVASSO",
        address: "STRADALE TORINO, 95 - 10034 CHIVASSO",
        times: "LUN./VEN. 8/22 - SAB. 9/18",
        telefono: "",
        email: "info@orangepalestre.it",        
        gmapsiframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1405.953509366609!2d7.874056325600651!3d45.188978415486766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4788783069c828c7%3A0xc89c4116daae8331!2sOrange+Chivasso!5e0!3m2!1sit!2sit!4v1534934429383",
        numfoto: 20
    },		     
    {   
        iyes : {
            id:2,
	        idgymansium:349,
            serviceId: 0
        } ,
        name: "COLLEGNO",
        address: "VIA TORINO, 184 - 10093 COLLEGNO",
        times: "LUN./VEN. 6/24 - SAB./DOM. 9/19",
        telefono: "",
        email: "info@orangepalestre.it",        
        gmapsiframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2817.624734170643!2d7.592098914873489!3d45.073123079098316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47886b6e6449a85b%3A0x8e9b31ab1312b77f!2sOrange!5e0!3m2!1sit!2sit!4v1614089689373",
        numfoto: 20
    },
    {   
        iyes : {
            id:3,
	        idgymansium:350,
            serviceId: 0
        } ,
        name: "INVORIO",
        address: "VIA INVORIO, 24 - 10146 TORINO",
        times: "LUN./VEN. 7/23 - SAB. 9/19",
        telefono: "",
        email: "info@orangepalestre.it",        
        gmapsiframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2817.17337808686!2d7.625323614873908!3d45.08227717909823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47886c89db6ed78b%3A0x32987d33459b0ab1!2sOrange!5e0!3m2!1sit!2sit!4v1614090220758",
        numfoto: 21
    },
    {   
        iyes : {
            id:4,
	        idgymansium:355,
            serviceId: 0
        } ,
        name: "NIZZA",
        address: "PIAZZA NIZZA, 44 - 10126 TORINO",
        times: "LUN./VEN. 6/24 - SAB./DOM. 9/19",
        telefono: "",
        email: "info@orangepalestre.it",        
        gmapsiframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2818.6778656783345!2d7.6743251!3d45.0517585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47886d385bf01025%3A0xa82384ee3d05b4a!2sOrange%20Nizza!5e0!3m2!1sit!2sit!4v1614090349622",
        numfoto: 20
    },
    {   
        iyes : {
            id:5,
	        idgymansium:348,
            serviceId: 0
        } ,
        name: "ORBETELLO",
        address: "VIA ORBETELLO, 98 - 10148 TORINO",
        times: "LUN./VEN. 6/24 - SAB./DOM. 9/19",
        telefono: "",
        email: "info@orangepalestre.it",        
        gmapsiframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2815.987393177045!2d7.6689891148749725!3d45.10632357909834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47886dce746d9fed%3A0x7d30bd2cfd3de453!2sOrange!5e0!3m2!1sit!2sit!4v1614090569144",
        numfoto: 17
    },
    {   
        iyes : {
            id:6,
	        idgymansium:347,
            serviceId: 0
        } ,
        name: "ROSSELLI",
        address: "VIA SAN PAOLO, 169 - 10141 TORINO",
        times: "LUN./VEN. 6/24 - SAB./DOM. 9/19",
        telefono: "",
        email: "info@orangepalestre.it",        
        gmapsiframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2818.431129531156!2d7.6343012148727505!3d45.05676467909826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47886cc45fd1b59b%3A0xe5ef6fbcee63d0d9!2sOrange%20Rosselli!5e0!3m2!1sit!2sit!4v1614090632818",
        numfoto: 20
    },
    {   
        iyes : {
            id:7,
	        idgymansium:352,
            serviceId: 0
        } ,
        name: "SETTIMO",
        address: "VICOLO GIOACCHINO ROSSINI, 9 - 10036 SETTIMO TORINESE",
        times: "LUN./VEN. 7/23 - SAB. 9/19 - DOM. 9/14",
        telefono: "",
        email: "info@orangepalestre.it",        
        gmapsiframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2814.575909306954!2d7.764448114876267!3d45.13492897909842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4788710ec7f999f9%3A0x19f69a42e20c7f1e!2sOrange%20Palestre%20Settimo!5e0!3m2!1sit!2sit!4v1614090702531",
        numfoto: 16
    },
    {   
        iyes : {
            id:8,
	        idgymansium:380,
            serviceId: 0
        } ,
        name: "MARCONI",
        address: "VIA NIZZA, 30A - 10125 TORINO",
        times: "LUN./VEN. 8-22 - SAB. 9/18 - DOM. Chiuso",
        telefono: "",
        email: "info@orangepalestre.it",        
        gmapsiframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2818.509792835034!2d7.673706614872696!3d45.055168679098145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47886d46c7bbbfc1%3A0xeee51266da22166b!2sPalestre%20Italiane%20Torino!5e0!3m2!1sit!2sit!4v1614090872088",
        numfoto: 9
    },
    {   
        iyes : {
            id:9,
	        idgymansium:383,
            serviceId: 0
        } ,
        name: "LINGOTTO",
        address: "VIA PIO VII, 130 - 10127 TORINO",
        times: "LUN./VEN. 8-22 - SAB. 9/19 - DOM. Chiuso",
        telefono: "",
        email: "info@orangepalestre.it",        
        gmapsiframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1410.1119905225758!2d7.648957260712481!3d45.02037848190138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478812e66ef6bf3b%3A0xd30c1e4413ce5bc5!2sVia%20Pio%20VII%2C%20130%2C%2010127%20Torino%20TO!5e0!3m2!1sit!2sit!4v1622880584116",
        numfoto: 4
    }

    
];

module.exports = centersJson;