import axios from 'axios';
import config from '../config/config_local.js'

import moment from 'moment'
import momentIT from 'moment/locale/it'
import Vue from 'vue';

export default  {
    data() {
        return {
            questionario: {
                visible: false,
                url: ''
            },
            trainerreview: {
                mustReviewTrainer: false,
                questions:[],
                curQuestionIdx: 0,
                curQuestion: null,
                curAnswer: '',
                answers: [],
                userreviewrec: null
            },
            survey: {
                questions: [],
                oldquestions: [],
                pickerOptions: [],
                curQuestion: null,
                curQuestionIdx: 0,
                curAnswer: '',
                answers: [],
                usersurvey: null,
                canPostNew: true
            }
        }
    },
    created() {
    },
    methods: {

        trainerSurveyCheckMustReview() {
            return new Promise( (resolve, reject) => {
                axios({
                    url: config.serverUrl + '/profiling/v1/mustreviewtrainer',
                    method: 'POST',
                    data: {
                        userid: this.$root.user.iyesUserId
                    }
                })
                .then( (questres) => {
                    if(questres.data.error) {
                        reject(false);
                    } else {
                        this.trainerreview.mustReviewTrainer = questres.data.data.mustreview;
                        this.trainerreview.questions = questres.data.data.questions;
                        this.trainerreview.userreviewrec = questres.data.data.userreviewrec;
                        let replaceGen = 'o';
                        if(this.user.iyesUser.Gender != "M") {
                            replaceGen = 'a';
                        }
                        this.trainerreview.questions.forEach( (questgender) => {
                            questgender.domanda = questgender.domanda.replace('[GEN]', replaceGen);
                        });
                        this.trainerreview.curQuestion = this.trainerreview.questions[0];
                        this.trainerreview.questions.forEach( (qstansw) => {
                            let defAnswer = 1;
                            if(qstansw.answertype == 'yesno') {
                                defAnswer = 1;
                            } else if( qstansw.answertype == 'freetext') {
                                defAnswer = '';
                            }
    
                            this.trainerreview.answers.push({
                                question: qstansw.domanda,
                                answer: defAnswer,
                                questionid: qstansw.id,
                                note: ''
                            });
                        });
                        this.trainerreview.curQuestionIdx = 0;
                        this.trainerreview.curAnswer = this.trainerreview.answers[ 0 ];
        
                        resolve(questres.data.data.mustreview);
                    }
                })
                .catch( (errquest) => {
                    console.log(errquest);
                    reject(false);
                })
            });
        },

        trainerSurveySnooze() {
            return new Promise( (resolve, reject) => {
                axios({
                    url: config.serverUrl + '/profiling/v1/snoozetrreview',
                    method: 'POST',
                    data: {
                        userid: this.$root.user.iyesUserId
                    }
                })
                .then( (snzres) => {
                    console.log(snzres);
                })
                .catch( (snerr) => {
                    console.log(snerr);
                });
            });
        },

        trainerreviewAnswerQuestion(_options) {
            this._trainerreviewSaveQuestion();
            this._trainerreviewNextQuestion();
        },

        _trainerreviewNextQuestion() {
            if( (this.trainerreview.curQuestionIdx + 1) < this.trainerreview.questions.length) {
                this.trainerreview.curQuestionIdx += 1;
                this.trainerreview.curQuestion = this.trainerreview.questions[ this.trainerreview.curQuestionIdx];
                this.trainerreview.curAnswer = this.trainerreview.answers[ this.trainerreview.curQuestionIdx];
            } else {
                this._saveAllTrainerreviewAnswers();
            }
        },

        trainerreviewBackQuestion() {
            if(this.trainerreview.curQuestionIdx > 0) {
                this.trainerreview.curQuestionIdx -= 1;
                this.trainerreview.curQuestion = this.trainerreview.questions[ this.trainerreview.curQuestionIdx];
                this.trainerreview.curAnswer = this.trainerreview.answers[ this.trainerreview.curQuestionIdx];
            }
        },


        _trainerreviewSaveQuestion() {
            this.trainerreview.answers[ this.trainerreview.curQuestionIdx ].answer = this.trainerreview.curAnswer.answer; 
            //se la domanda è la prima, e il cliente risponde che in realtà non è stato contattato dal trainer
            //allora skippo alla penultima domanda e gli chiedo se vuole essere contattato
            if(this.trainerreview.curQuestionIdx == 0 && this.trainerreview.curAnswer.answer == 0) {
                this.trainerreview.curQuestionIdx = this.trainerreview.questions.length - 3;  
                let domandaOriginale = this.trainerreview.questions[ this.trainerreview.questions.length - 2].domanda;
                domandaOriginale = domandaOriginale.replace(' nuovamente', '');
                this.trainerreview.questions[ this.trainerreview.questions.length - 2].domanda = domandaOriginale;
            } 

            //se la domanda attuale è "vuoi essere contattato dal trainer" (id: 5) e la risposta è YES (1)
            //allora gli imposto il check34 (refresh) per farlo ricontattare da qualcuno
            if(this.trainerreview.curQuestionIdx == 5 && this.trainerreview.curAnswer.answer == 1) {
                this._trainerReviewSetCheckRefresh();
            }
        },

        _trainerReviewSetCheckRefresh() {
            return new Promise( (resolve, reject) => {
                axios({
                    url: config.serverUrl + '/user/setcheckrefresh',
                    method: 'POST',
                    data: {
                        iyesuserid: this.$root.user.iyesUserId,
                        checkvalue: true,
                        iyestoken: this.$root.user.iyestoken
                    }
                })
                .then( (snzres) => {
                    console.log(snzres);
                })
                .catch( (snerr) => {
                    console.log(snerr);
                });
            });
        },

        _saveAllTrainerreviewAnswers() {
            return new Promise( (resolve, reject) => {
                let priority = 0;
                if(this.subscriptions.isPromo) {
                    priority = 1;
                }
                axios({
                    url: config.serverUrl + '/profiling/v1/trainersetanswers',
                    method: 'POST',
                    data: {
                        userid: this.$root.user.iyesUserId,
                        username: this.$root.user.name + ' ' + this.$root.user.surname,
                        email: this.$root.user.email,
                        answers: this.trainerreview.answers,
                        userreviewrec: this.trainerreview.userreviewrec
                    }
                })
                .then( (saveok) => {
                    this.$root.$refs.pageMan.showAlert('Grazie mille per il tempo che ci hai dedicato! La tua opinione per noi è importante e ci aiuterà a migliorare ulteriormente il servizio offerto.','OK','')
                    .then( (ok) => {
                        this.$root.$refs.pageMan.resetPagesStack('mainpage');                            
                    });     
                    console.log(saveok);
                })
                .catch( (saveerr) => {
                    this.$root.$refs.pageMan.showAlert('Grazie mille per il tempo che ci hai dedicato! La tua opinione per noi è importante e ci aiuterà a migliorare ulteriormente il servizio offerto.','OK','')
                    .then( (ok) => {
                        this.$root.$refs.pageMan.resetPagesStack('mainpage');                            
                    });     
                    console.log(saveerr);
                });
            });
        },


        surveyLoadQuestions() {
            return new Promise( (resolve, reject) => {
                axios({
                    url: config.serverUrl + '/profiling/v1/getquestions',
                    method: 'POST',
                    data: {
                        userid: this.$root.user.iyesUserId
                    }
                })
                .then( (quest) => {
                    this.survey.questions = [];
                    quest.data.data.forEach( (quest) => {
                        this.survey.questions.push({
                            question:  quest.question,
                            hint:  quest.hint,
                            type:  quest.type,
                            options:  (quest.options != null) ? quest.options.split('|') : [],
                            optionsPoints:  (quest.optionspoints != null) ? quest.optionspoints.split('|') : [],
                            optionsImages: (quest.optionsimages != null) ? quest.optionsimages.split('|') : [],
                            showIf:  quest.showif,
                            min:  quest.min,
                            max:  quest.max,
                            step:  quest.step,
                            default:  (quest.type == 'checks') ? [] :quest.defaultvalue,
                            needed:  (quest.needed == 1),
                            otherTextNeeded:  (quest.othertextneeded == 1),
                            otherTextNeededIf: parseInt(quest.othertextneededif),
                        });
                    });
                    resolve(true);                    
                })
                .catch( (errquest) => {
                    console.log(errquest);
                    this.$root.$refs.pageMan.showAlert('Questionario al momento non disponibile. Riprovare più tardi.','OK','');
                    reject(false);
                })
            });
        },

        surveyGetMine() {
            let userId = this.$root.user.iyesUserId;
            axios({
                method: 'POST',
                url: config.serverUrl + '/profiling/mysurvey',
                data: {
                    userid: userId
                }
            })
            .then( (surv) => {
                if( (surv.data.error == false) && (surv.data.data != null)){
                    this.survey.usersurvey = surv.data.data;
                    let survdate = moment(this.survey.usersurvey.surveyuserdate);
                    let today = moment();
                    let diff = Math.abs( today.diff( survdate, 'days') );
                    this.survey.canPostNew = (diff > 29);
                    /* if(this.survey.usersurvey.surveystatus == 'green') {
                        this.survey.canPostNew = true;
                    } */
                } else {
                    this.survey.usersurvey = null;
                    this.survey.canPostNew = true;
                }
            })
            .catch( (surverr) => {
                console.log(surverr);
            })
        },

        surveyShow() {
            /* this.questionario.url = 'https://docs.google.com/forms/d/e/1FAIpQLScy4SvxPsugOUKuc4fb5GZYhsXKZ3cyyWdt_twkoZrHU2xbXg/viewform?entry.857995898=' + this.user.name + '+' + this.user.surname + '&entry.919411073=' + this.user.email;
            this.questionario.visible = true;
            this.$root.$refs.pageMan.showPage('trainerquestionariopage');   

            return;  */

            this.surveyLoadQuestions()
            .then( (ok) => {
                this.survey.answers = [];
                this.survey.curQuestion = this.survey.questions[0];
                this.survey.questions.forEach( (quest) => {
                    this.survey.answers.push({
                        question: quest.question,
                        answer: quest.default,
                        note: '',
                        descranswer: ''
                    });
                });
                this.survey.curQuestionIdx = 0;
                this.survey.curAnswer = this.survey.answers[ 0 ];
                this._arrayFromMinMaxStep(this.survey.curQuestion.min, this.survey.curQuestion.max,this.survey.curQuestion.step);
                this.$root.showPage('surveypage');     
            })
            .catch( (err) => {
                console.log(err);
                alert('Error loading questions');
            })
        },

        questionarioHide() {
            this.questionario.visible = false;
            this.questionario.url = '';
        },

        surveyBackQuestion() {
            if(this.survey.curQuestionIdx > 0) {
                this.survey.curQuestionIdx -= 1;
                this.survey.curQuestion = this.survey.questions[ this.survey.curQuestionIdx];
                this.survey.curAnswer = this.survey.answers[ this.survey.curQuestionIdx];
                this._arrayFromMinMaxStep(this.survey.curQuestion.min, this.survey.curQuestion.max,this.survey.curQuestion.step);
            }
        },

        surveyAnswerQuestion(_options) {
            let valid = false;
            switch( this.survey.curQuestion.type ) {
                case 'number':
                    valid = this._surveyCheckIfNumber();
                    break;
                case 'select':
                    valid = this._surveycheckValidSelect();
                    break;
                case 'checks':
                    valid = this._surveycheckValidChecks();
                    break;
                case 'range':
                case 'freetext':
                    valid = true;
                    break;
                case 'yesno':
                    valid = this._surveycheckValidYesNo();
                    break;
                }    

            if(this.survey.curQuestion.otherTextNeeded && this.survey.curAnswer == this.survey.curQuestion.otherTextNeededIf) {
                if(this.survey.curAnswer.note.length < 3) {
                    valid = false;
                }
            }

            if(valid) {
                this._surveySaveQuestion();
                this._surveyNextQuestion();
            } else {
                if(this.survey.curQuestion.needed == false) {
                    this._surveySaveQuestion();
                    this.curAnswer = '';
                    this._surveyNextQuestion();
                } else {
                    this.$root.$refs.pageMan.showAlert('Verificare la risposta data. In alcuni casi è necessario inserire una breve spiegazione o approfondimento.','OK','');
                }
            }

        },

        _surveySaveQuestion() {
            this.survey.answers[ this.survey.curQuestionIdx ].answer = this.survey.curAnswer.answer; 
            if(this.survey.curQuestion.type == 'select' || this.survey.curQuestion.type == 'checks') {
                let opts = this.survey.curQuestion.options;
                this.survey.answers[ this.survey.curQuestionIdx ].descranswer = opts[this.survey.curAnswer.answer]; 
            } else {
                this.survey.answers[ this.survey.curQuestionIdx ].descranswer = (this.survey.curAnswer.answer == null) ? '' : this.survey.curAnswer.answer; 
            }
            //this.survey.answers[ this.survey.curQuestionIdx ].note = this.survey.otherAnswer; 
        },

        _arrayFromMinMaxStep(min,max,step) {
            //build the array for the vue picker options
            let cur = min;
            let end = max;
            let res = [];
            while(cur < end) {
                res.push( cur );
                cur += step;
            }
            this.survey.pickerOptions = res;
        },

        _surveyNextQuestion() {
            if( (this.survey.curQuestionIdx + 1) < this.survey.questions.length) {
                this.survey.curQuestionIdx += 1;
                this.survey.curQuestion = this.survey.questions[ this.survey.curQuestionIdx];
                this.survey.curAnswer = this.survey.answers[ this.survey.curQuestionIdx];
                this._arrayFromMinMaxStep(this.survey.curQuestion.min, this.survey.curQuestion.max,this.survey.curQuestion.step);
                //this.survey.otherAnswer = '';
            } else {
                this._saveAllAnswers();
            }
        },

        _saveAllAnswers() {
            return new Promise( (resolve, reject) => {
                this.$root.$refs.pageMan.showWaiting();
                let priority = 0;
                if(this.subscriptions.isPromo) {
                    priority = 1;
                }
                axios({
                    url: config.serverUrl + '/profiling/v1/setanswers',
                    method: 'POST',
                    data: {
                        userid: this.$root.user.iyesUserId,
                        username: this.$root.user.name + ' ' + this.$root.user.surname,
                        email: this.$root.user.email,
                        answers: this.survey.answers,
                        priority: priority
                    }
                })
                .then( (saveok) => {
                    this.$root.$refs.pageMan.hideWaiting();
                    if(saveok.data.error == false) {
                        this.$root.$refs.pageMan.showAlert('Questionario salvato correttamente. Verrà preso in carico il prima possibile da uno dei nostri personal trainer.','OK','')
                        .then( (ok) => {
                            this.$root.$refs.pageMan.resetPagesStack('mainpage');                            
                        });     
                        this.survey.canPostNew = false;    
                        //imposta il check36 che notifica l'utente della ricezione del questionario e attesa di gestione
                        this._surveyInviaAvvisoUtente();               
                    } else {
                        this.$root.$refs.pageMan.showAlert('Impossibile salvare il questionario! Riprovare più tardi.','OK','')
                        .then( (ok) => {
                            this.$root.$refs.pageMan.resetPagesStack('mainpage');                            
                        });                        
                    }
                    console.log(saveok);
                })
                .catch( (saveerr) => {
                    this.$root.$refs.pageMan.hideWaiting();
                    console.log(saveerr);
                });
            });
        },

        _surveyInviaAvvisoUtente() {
            //imposta il check36 che avviserà l'utente dell'avvenuta ricezione del questionario nuovo e di attendere la scheda
            return new Promise( (resolve, reject) => {
                axios({
                    url: config.serverUrl + '/user/setricevutoq',
                    method: 'POST',
                    data: {
                        iyesuserid: this.$root.user.iyesUserId,
                        iyestoken: this.$root.user.iyestoken,
                        ricevuto: 1
                    }
                })
                .then( (saveok) => {
                    if(saveok.data.error == true) {
                        console.log('ERROR: Survey check36 not set!');
                    }
                })
                .catch( (saveerr) => {
                    console.log(saveerr);
                });
            });
        },

        _surveycheckValidYesNo() {
            if(this.survey.curAnswer.answer == null || typeof(this.survey.curAnswer.answer) == 'undefined')
                return false;

            let valid = true;

            if(this.survey.curQuestion.otherTextNeeded && String(this.survey.curAnswer.answer).includes(this.survey.curQuestion.otherTextNeededIf)) {
                if(this.survey.curAnswer.note.length < 3) {
                    valid = false;
                }
            }
            return valid;
        },

        _surveycheckValidChecks() {
            if(this.survey.curAnswer.answer == null || typeof(this.survey.curAnswer.answer) == 'undefined')
                return false;

            let valid = (this.survey.curQuestion.needed && this.survey.curAnswer.answer.length > 0);

            if(this.survey.curQuestion.otherTextNeeded && String(this.survey.curAnswer.answer).includes(this.survey.curQuestion.otherTextNeededIf)) {
                if(this.survey.curAnswer.note.length < 3) {
                    valid = false;
                }
            }
            return valid;
        },

        _surveycheckValidSelect() {
            let valid = true;
            if(this.survey.curAnswer.answer == null || typeof(this.survey.curAnswer.answer) == 'undefined')
                valid = false;
    
            let valInt = parseInt(this.survey.curAnswer.answer);
            if(valInt >= 0 && valInt <= this.survey.curQuestion.options.length) {
                //return true;
            } else {
                valid = false;
            }

            if(this.survey.curQuestion.otherTextNeeded && valInt == this.survey.curQuestion.otherTextNeededIf) {
                if(this.survey.curAnswer.note.length < 3) {
                    valid = false;
                }
            }

            return valid;
        },

        _surveyCheckIfNumber() {
            if(this.survey.curAnswer.answer == null || typeof(this.survey.curAnswer.answer) == 'undefined')
                return false;

            this.survey.curAnswer.answer = String(this.survey.curAnswer.answer).replace(',','.');
            let valFloat = parseFloat( this.survey.curAnswer.answer);
            if(isNaN(valFloat))
                return false;

            if(this.survey.curAnswer.answer == String(this.survey.curAnswer.answer)) {
                if(this.survey.curQuestion.min != -1 && this.survey.curQuestion.max != -1) {
                    return (valFloat >= this.survey.curQuestion.min && valFloat <= this.survey.curQuestion.max);
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
    }
}