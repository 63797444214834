import axios from 'axios';
import { utc } from 'moment';
import config from '../config/config_local.js'

export default  {
    data() {
        return {
            products: {
                categorie: [],
                byService: [],
                vetrina: [],
                ricariche: [],
                tutti: [],
                abbonamentiCambio: [],
                referral: [],
                visiteMediche: [],
                disdette: []
            },

            news: [],
            newsreferral: []
        }
    },
    created() {
        //retrieve the pubic products list
        this.productsGetList(null);
    },

    methods: {
        productsReset() {
            this.products.categorie = [];
            this.products.byService = [];
            this.products.vetrina = [];
            this.products.ricariche = [];
            this.products.tutti = [];
            this.products.abbonamentiCambio = [];
            this.products.referral = [];
            this.products.visiteMediche = [];
            this.products.disdette = [];
            this.news = [];
            this.newsreferral = [];
            //restore de logged off products
            this.productsGetList(null);
        },

        productsGetList(userIYESId) {
            return new Promise( (resolve, reject) => {
                console.log('Getting product list (' + this.subscriptions.currentAbbonamentoName + ')');
                axios.post( config.serverUrl + '/product/list', {
                    iyestoken : this.user.iyestoken,
                    idutente: userIYESId
                })
                .then( (resmy) => {
                    //console.log('Got products categories');
                    //console.log(resmy.data.data.data);
                    this.products.categorie = resmy.data.data.data;
                    this.products.vetrina = [];
                    this.products.ricariche = [];
                    this.products.referral = [];
                    this.products.abbonamentiCambio = [];
                    this.products.visiteMediche = [];
                    this.products.disdette = [];
                    this.products.tutti = [];
                    this.news = [];
                    this.newsreferral = [];
                    resmy.data.data.data.forEach( (prodcat) => {
                        prodcat.Products.forEach( (prod) => {
    
                            //users cannot change from a PREMIUM subscriptino to another PREMIUM subscription
                            let skipProduct = false;
                            if(this.subscriptions)
                                skipProduct = (this.subscriptions.currentAbbonamentoName.includes('PREMIUM') && (prod.Name.includes('PREMIUM')));
    
                            //if(prodcat.Description.toLowerCase().includes('abbonamenti cambio'))
                            if(!prodcat.Description.toLowerCase().includes('news')) {
                                if(prodcat.Description.toLowerCase().includes('cambio')) {  //era prospect
                                    this.products.abbonamentiCambio.push( prod );
                                }
        
                                //CAT: VETRINA PROSPECT (usato per i nuovi utenti non invitati)
                                if( (!skipProduct) && (prodcat.Description.toLowerCase().includes('vetrina')) &&  (!prodcat.Description.toLowerCase().includes('referral'))) {
                                    this.products.vetrina.push( prod );
                                }
        
                                if( (!skipProduct) && (prodcat.Description.toLowerCase().includes('disdett')) ){
                                    this.products.disdette.push( prod );
                                }



                                //CAT: VETRINA REFERRALL (usato per gli invitati)
                                if(prodcat.Description.toLowerCase().includes('referral')) {
                                    this.products.referral.push( prod );
                                }
        
                                if(prod.Description.toLowerCase().includes('ricaric')) {
                                    this.products.ricariche.push( prod );
                                }
        
                                if(prod.Description.toLowerCase().includes('medic')) {
                                    this.products.visiteMediche.push( prod );
                                }
        
                                if(!prodcat.Description.toLowerCase().includes('referral')) {
                                    this.products.tutti.push( prod );
                                }
                            }
    
                            if(prodcat.Description.toLowerCase().includes('newsref')) {
                                this.newsreferral.push( prod );
                            } else if(prodcat.Description.toLowerCase().includes('news')) {
                                this.news.push( prod );
                            }
                        });
                    });
    
                    this.sortNewsByDate();

                    //now that we have the correct products list assing the product object to the user subscription
                    //see userMixins for this function
                    this.abbonamentiSetCurrentProduct();
    
                    //swap vetrina with referral products if user is a referral and referrall array is filled
                    if(userIYESId != null) {
                        if( (this.user.iyesUser) && (typeof(this.user.iyesUser.IDreferral) != 'undefined') && (this.user.iyesUser.IDreferral != '') ) {
                            if(this.products.referral.length > 0) {
                                this._productsReplaceVetrinaWithReferral();
                            }
                        }
                    } 
                    this._cleanNewsDescriptions();

                    resolve();
    
                })
                .catch( (errmy) => {
                    console.log('WARNING! Error retrieving products list');
                    console.log(errmy);
                    this.productsReset();
                    
                    resolve();
                });    
    
            });
        },

        //restituisce il rpdotto per la ricarica gratuita del borsellino disdette
        productsFindRicaricaDisdetta(findGratuito) {
            let prod = null;
            for(let i = 0; i < this.products.disdette.length; i++) {
                let curProd = this.products.disdette[i];
                if(findGratuito) {
                    if(curProd.Name == 'DISDETTA PROVA') {
                        prod = curProd;
                    }
                } else {
                    if(curProd.Name.toUpperCase() == 'DISDETTA') {
                        prod  =curProd;
                    }
                }
            }

            return prod;
        },

        sortNewsByDate() {

        },

        productExistsFree() {
            let freeProduct = null;
            let found = false;
            this.products.vetrina.forEach( (prod) => {
                if( (prod.Price == 0) && (!found)){
                    freeProduct = prod;
                    found = true;
                }
            });

            return freeProduct;

        },

        prodottiShowNews(_reset) {
            //check what category of news to show to the user, referral or normal
            if( this.userIsUnsubscribedReferral() && this.products.referral.length > 0) {
                this.news = [];
                this.newsreferral.forEach( (nws) => {
                    this.news.push( nws );
                });
            }
            
            if(_reset == true) {
                this.$root.$refs.pageMan.resetPagesStack('newspage', _reset);
            } else {
                this.$root.$refs.pageMan.showPage('newspage', _reset);
            }

        },

        //if the user is a referral and referral products array is filled then he must see those products instead of vetrina ones
        _productsReplaceVetrinaWithReferral() {
            this.products.vetrina = [];
            this.products.referrall.forEach( (prodref) => {
                this.products.vetrina.push( prodref );
            })
        },

        _cleanNewsDescriptions() {
            console.log('Cleaning news descriptions');
            this.news.forEach( (news) => {
                news.Description = news.Description.replaceAll('<br>', '');
            });
        },


    }
}