<template>
    <div>
        <div class="container-fluid">
            <div class="row mt-5">
                <div class="col text-center">
                    <img id="registration-logo" src="img/orangeLogo.svg">
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <p class="mb-4 text-center titolo-categoria">Scegli una password</p>
                    <p class="mt-5 text-justify testo-generico">Per accedere ai servizi Orange hai bisogno di impostare una password.<br>Inserisci 2 volte la password che hai scelto e memorizzala in un posto sicuro per non dimenticarla. Nota bene: è necessario impostare una password: <strong>lunga almeno 10 caratteri e che contenga numeri e lettere</strong>.</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">

                    <form class="form-signin">
                        <div style="display:none;" class="form-label-group">
                            <div class="ico-container"><span class="icon-password"></span></div>
                            <input type="text" v-on:keyup.enter="$refs.inputNewPassword.focus()" ref="inputTmpPassword" v-model="$root.user.tmpPassword" class="ico-input" id="inputTmpPassword" readonly>                            
                        </div>
                        <div class="form-label-group mt-5">
                            <div class="ico-container"><span class="icon-password"></span></div>
                            <input type="text" v-on:keyup.enter="$refs.inputConfNewPassword.focus()" ref="regSetPassword" class="ico-input" id="inputNewPassword" placeholder="Nuova password" >
                        </div>
                        <div class="form-label-group">
                            <div class="ico-container"><span class="icon-password"></span></div>
                            <input type="text" v-on:keyup.enter="$event.target.blur();" ref="regSetPasswordConf" class="ico-input" id="inputConfNewPassword" placeholder="Conferma nuova password" >
                        </div>
                    </form>

                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <button v-on:click="registerSetPassword()" class="btn btn-block py-3 btn-orange-orange">AVANTI</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import moment from 'moment'
import momentIT from 'moment/locale/it'

export default {
    name: 'RegistrationPasswordPage',
    computed: {
    },
    data () {
        return {
            
        }
    },
    methods: {

        registerSetPassword() {
            let pass = this.$refs.regSetPassword.value;
            let passconf = this.$refs.regSetPasswordConf.value;

            if(pass.length < 10 || passconf.length < 10) {
                this.$root.$refs.pageMan.showAlert('La password scelta è troppo corta.','OK','');
                return false;
            }

            if(pass != passconf) {
                this.$root.$refs.pageMan.showAlert('Le password inserite non coincidono.','OK','');
                return false;
            }

            this.$root.doChangePassword(this.$root.user.tmpPassword, passconf);
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #registration-logo {
        max-width:80%;
    }
</style>
