<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div v-on:click="closeThisOverlay()" class="col-2 pt-2 orange-text-black">
                    <strong><span style="font-size:24px; line-height:45px;" class="icon-navig_chiudi"></span></strong>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-12">
                    <p class="titolo-piccolo orange-text-black text-center">CAMBIO PASSWORD</p>
                    <p class="mt-5 text-justify orange-text-black testo-generico">Puoi cambiare la tua password di accesso ai servizi Orange. È necessario impostare una password:<br><strong> lunga almeno 10 caratteri e che contenga numeri e lettere</strong>.</p>
                </div>
                <div class="col-12 mt-5">
                    <form class="form-signin">
                        <div class="form-label-group" style="border-bottom:none;">
                            <div class="ico-container"><span class="icon-password" style="color:#000;"></span></div>
                            <input v-on:keyup.enter="$refs.inputNewUserPassword.focus()" type="text" ref="inputTmpUserPassword" class="user-pwd-input form-control rounded-0" id="inputTmpUserPassword" placeholder="Vecchia Password" >
                            <label for="inputTmpPassword">Vecchia password</label>
                        </div>
                        <div class="form-label-group mt-5" style="border-bottom:none;">
                            <div class="ico-container"><span class="icon-password" style="color:#000;"></span></div>
                            <input v-on:keyup.enter="$refs.inputConfNewUserPassword.focus()" type="text" ref="inputNewUserPassword" class="user-pwd-input form-control rounded-0" id="inputNewUserPassword" placeholder="Nuova password" >
                            <label for="inputNewPassword">Nuova password</label>
                        </div>
                        <div class="form-label-group" style="border-bottom:none;">
                            <div class="ico-container"><span class="icon-password" style="color:#000;"></span></div>
                            <input type="text" ref="inputConfNewUserPassword" class="user-pwd-input form-control rounded-0" id="inputConfNewUserPassword" placeholder="Conferma password" >
                            <label for="inputConfNewPassword">Conferma password</label>
                            <span v-show="checks.valid == false" class="label-error">Verificare password inserite</span>
                        </div>
                    </form>
                </div>
                <div class="col-12 mt-5">
                    <button v-on:click="checkFieldsAndChangeUserPassword()" class="btn mt-5 btn-block btn-orange btn-orange-orange">Conferma</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import popper from 'popper.js'
import axios from 'axios'
import config from '../../config/config_local.js'
import Validator from '../../js/classes/classValidators'

export default {
    name: 'UserCambioPassword',
    data () {
        return {
            checks: {
                valid: true
            }
        }
    },
    methods: {
        checkFieldsAndChangeUserPassword() {
            let tmp = this.$refs.inputTmpUserPassword.value.trim();
            let nuova = this.$refs.inputNewUserPassword.value.trim();
            let confnuova = this.$refs.inputConfNewUserPassword.value.trim();

            if(tmp.length < 3 || nuova.length < 3 || confnuova.length < 3) {
                this.checks.valid = false;
                return false;
            }

            if(nuova != confnuova) {
                this.checks.valid = false;
                return false;
            }

            let validator = new Validator();

            if(validator.isStrongPassword(nuova) == false) {
                this.checks.valid = false;
                return false;
            }

            //user mixin
            this.$root.doChangePassword(tmp, nuova);
        },


        closeThisOverlay() {
            this.$root.$refs.pageMan.closeOverlay();
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #centro-logo {
        max-width:80%;
    }

    .user-pwd-input, .user-pwd-input:focus {
        box-shadow:none !important;
        outline:none !important;
        border-color:orange !important;
    }
</style>
