<template>
    <div>
        <div class="container-fluid">
            <div v-if="$root.reborn.videosLoaded" class="row  mb-0 pb-5" :style="{ 'min-height' : '350px', 'background-size' : 'cover', 'background-position' : 'center center', 'background-image' : 'url(img/categorie_video/' + $root.reborn.currentFilters.category.name.trim().toLowerCase() + '.jpeg)' }">
                <div class="col-12 px-0 text-center" style="height:50px;">
                    <div class="header-scroller-container">
                        <div v-for="cat in $root.reborn.categorie.filter( ca => ca.name != 'Short') " :id="'cat-' + cat.name.trim().toLowerCase()" :key="'catscroll-' + cat.name" class="scroll-elem titolo-piccolo">
                            <span v-on:click.stop="handleTopCatClick(cat.name)" :class="{ 'elem-sel' : (($root.reborn.currentFilters.category != '') && ($root.reborn.currentFilters.category.name.trim().toLowerCase() == cat.name.trim().toLowerCase())) }">{{ cat.name }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center mt-5">
                    <img id="category-logo" src="img/logo_rbo.png">
                </div>
                <div class="col-12 pt-5 text-center">
                    <span class="titolo-grande" style="text-transform:uppercase;">{{ $root.reborn.currentFilters.category.name }}</span>
                </div>
                <div class="col-12 pt-2 text-center">
                    <span class="titolo-categoria">{{ $root.reborn.currentFilters.category.description }}</span>
                </div>
                <div class="col-12 pt-2 text-center">
                    <span class="testo-generico">{{ $root.reborn.currentFilters.category.numVideos }} video</span>
                </div>
            </div>
            <div class="row mt-3 mb-0 pb-0">
                <div v-for="name in $root.reborn.filteredVideos.names" :key="'rebnames-' + name.name" class="col-12 text-left mb-2 px-4">
                    <span v-on:click="handleNamesClick(name.name)"  class="titolo-sottile"><span class="icon-navig_giu"></span>{{ name.name }}</span>
                </div>
            </div>
            <div class="row mt-0 pt-0 px-0">
                <div class="col-12 px-0">
                </div>
            </div>
        </div>

        <div v-if="$root.reborn.videosLoaded">

            <div v-for="name, index in $root.reborn.filteredVideos.names" :key="'videos-' + name.name" :id="'#' + name.name + 'anchor'" class="container-fluid mx-0 mt-3 px-0">
                <div class="row no-gutters">
                    <div class="col-12 py-2 px-4">
                        <h5 class="titolo-categoria"><strong>{{ name.name }}</strong></h5>
                    </div>
                </div>
                <div class="row no-gutters m-0 mt-0 min-row-height">
                    <div class="col-12 text-center">
                        <div :id="'slider-' + index" class="splide">
                            <div class="splide__track">
                                <ul class="splide__list">
                                    <li class="splide__slide swiper-slide" v-for="video,index in name.videos" v-bind:key="'results' + index" :index="index">
                                        <div v-on:click.stop="$root.rebornShowVideo(video.id)" class="lesson-preview">
                                            <div v-on:click.stop="$root.togglePreferitiVideo('video',video)" class="lesson-preview-icons">
                                                <span v-if="$root.isPreferito('video',video.id) == false" class="icon-preferito_cerchio"></span>
                                                <span v-else style="color:#EE7D2D;" class="icon-preferito_cerchio"></span>
                                            </div>
                                            <div class="lesson-preview-image" v-bind:style="{ backgroundImage: 'url(' + video.url_preview + ')' }">
                                                <div v-if="video.url_video == ''" class="video-blocked"><span class="icon-allert"></span></div>
                                            </div>
                                            <div class="lesson-preview-darkbox">
                                                <b style="text-transform: uppercase; font-size:1.1rem; font-weight: bold;">{{ video.name.replace("_","'") }}</b>
                                                <span style="padding-bottom:2px;"><i class="far fa-user"></i>&nbsp;{{ video.istruttore }} - <i class="far fa-clock"></i>&nbsp;{{ video.durata }} min</span>
                                            </div>
                                        </div>        
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

export default {
    name: 'RebornCategory',
    data () {
        return {
        }
    },
    created() {

    },
    methods: {
        handleTopCatClick(_catName) {
                let filterType = 'category';
                this.$root.rebornUpdateFilters(filterType,'', _catName);
                this.$root.showPage('reborncategorypage');
        },

        handleNamesClick(_tipo) {
            document.getElementById('#' + _tipo + 'anchor').scrollIntoView({ 
                behavior: 'smooth' 
            });            
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #category-logo {
        width:20%;
        max-width: 90px;
    }

</style>
