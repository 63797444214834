import Splide from '@splidejs/splide'
import Vue from 'vue'

export default  {
    data() {
        return {
            videoSliders: {
                names: [],
                preferitiMain: null,
                recentiMain: null,
                obiettiviMain: null
            }
        }
    },
    created() {
    },
    methods: {
        setupSlider(elemSelector) {
            let elem = window.document.querySelector(elemSelector);
            if(elem == null) {
                console.log('setupSlider ERROR: element ' + elemSelector + ' doesn\'t exist.');
                return null;
            }

            var slider = new Splide( elemSelector, {
                type: 'slide',
                padding: {
                    right: '6rem',
                    left: '0rem'
                },
                autoWidth: true,
                pagination: false,
                arrows:false
            } ).mount();

            return slider;
        },

        setupCenteredSlider(elemSelector) {
            let elem = window.document.querySelector(elemSelector);
            if(elem == null) {
                console.log('setupCenteredSlider ERROR: element ' + elemSelector + ' doesn\'t exist.');
                return null;
            }

            var slider = new Splide( elemSelector, {
                type: 'slide',
                autoWidth: false,
                pagination: false,
                padding: {
                    left: 0,
                    right: 40
                },
                gap:20,
                start: 1,
                arrows:false
            } ).mount();

            return slider;
        },

        setupPreferitiMainSlider() {
            setTimeout( () => {
                if(this.preferitiMain != null)
                    this.preferitiMain.destroy(true);

                console.log('Setup main preferiti slider (' + this.preferiti.length + ' videos)');
                let prefSlider = this.setupSlider('#sliderpreferitimain');
                if(prefSlider != null) {
                    this.videoSliders.preferitiMain = prefSlider;
                } else {
                    console.log('ERROR: cannot setup preferiti Slider!');
                    console.log('Preferiti count: ' + this.preferiti.length);
                }    
            }, 1000);
        },

        setupRecentimainSlider() {
            setTimeout( () => {
                if(this.recentiMain != null)
                    this.recentiMain.destroy(true);

                console.log('Setup main recenti slider (' + this.reborn.recent.length + ' videos)');
                let prefSlider = this.setupSlider('#sliderrecentimain');
                if(prefSlider != null) {
                    this.videoSliders.recentiMain = prefSlider;
                } else {
                    console.log('ERROR: cannot setup recenti Slider!');
                    console.log('Recenti count: ' + this.reborn.recent.length);
                }    
            }, 1000);
        },

        setupObiettiviSchedeSlider() {
            setTimeout( () => {
                if(this.obiettiviMain != null)
                    this.obiettiviMain.destroy(true);

                console.log('Setup obiettivi main slider (' + this.evofit.modsched.obiettivi.length + ' obiettivi)');
                let obietSlider = this.setupCenteredSlider('#sliderobiettivischeda');
                if(obietSlider != null) {
                    this.videoSliders.obiettiviMain = obietSlider;
                } else {
                    console.log('ERROR: cannot setup obiettivi main Slider!');
                    console.log('Obiettivi count: ' + this.evofit.modsched.obiettivi);
                }    
            }, 1000);
        }
    }
}