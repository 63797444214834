<template>
    <div>

        <div class="container-fluid my-0 px-0">
            <div class="row pt-3 mt-5 titolo-categoria orange-text-white">
                <div class="col-12 text-center">
                    <div ref="titleSlider" id="title-slider" class="text-center" style="font-size:1.0rem;">
                        <div v-on:click="clickTitle(0)" id="title-0"  class="title-slider-slide titolo-piccolo">
                            <span>PROGRAMMI</span>
                        </div>
                        <div v-on:click="clickTitle(1)" id="title-1"  class="title-slider-slide titolo-piccolo">
                            <span v-if="$root.evofit.curScheda.isPersonal" class="slide-selected">MIO PROGRAMMA</span>
                            <span v-else class="slide-selected">SCHEDA SCELTA</span>
                        </div>
                        <div v-on:click="clickTitle(2)" id="title-2"  class="title-slider-slide titolo-piccolo">
                            <span >STATISTICHE</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div v-if="$root.evofit.curScheda && $root.evofit.curScheda.loaded == true" class="row">
                <div v-if="$root.evofit.curScheda.isPersonal == false" class="col-12" style="min-height:320px; background-size:cover; background-position:center top;" :style="{ 'background-image':'url(img/obiettivi/MODELLO1_1/' + $root.evofit.curScheda.scheda.descimmagine +'.jpeg)'}">
                    <div id="schedaanteprima-back">
                        <p style="padding-top:120px;" class="titolo-35 orange-text-white">{{ $root.evofit.curScheda.scheda.descrizione }}</p>
                        <p class="testo-generico orange-text-white">{{ $root.evofit.curScheda.sedute.length }} circuiti | {{ $root.evofit.curScheda.totEsercizi }} esercizi | Livello {{ $root.evofit.curScheda.scheda.livello }}</p>
                    </div>
                </div>    
                <div v-else class="col-12" style="min-height:320px;">
                    <div id="schedaanteprima-back">
                        <img id="trainerscheda-logo" src="img/logo_rbo.png">
                        <p class="titolo-grande orange-text-white">{{ $root.evofit.curScheda.scheda.descrizione }}</p>
                        <p class="testo-generico orange-text-white">{{ $root.evofit.curScheda.sedute.length }} circuiti | {{ $root.evofit.curScheda.totEsercizi }} esercizi | Livello {{ $root.evofit.curScheda.scheda.livello }}</p>
                        <p v-if="$root.evofit.curScheda.scheda.note != ''" class="mb-0 testo-generico orange-text-orange">Il trainer dice:</p>
                        <p v-if="$root.evofit.curScheda.scheda.note != ''" class="mb-0 testo-generico">{{ $root.evofit.curScheda.scheda.note }}</p>
                    </div>
                </div>    
            </div>
        </div>

        <div v-if="$root.evofit.curScheda && $root.evofit.curScheda.loaded == true" class="container-fluid my-0 px-0">
            <div v-for="seduta,index in $root.evofit.curScheda.sedute" :key="'sedant-' + index" class="row testo-generico orange-text-white">
                <div class="col-12" style="max-width:600px; margin-left:auto; margin-right:auto;">
                    <div class="row no-gutters px-2 py-1">
                        <div class="col-12 text-white text-center px-4">
                            <!-- <button v-if="$root.evofit.curScheda.sedute.length == 1" v-on:click="sceltaTipoAllenamento(index)" class="btn btn-block btn-orange btn-orange-brown orange-text-white">INIZIA ALLENAMENTO</button> -->
                            <div v-on:click="sceltaTipoAllenamento(index)" class="row p-0 orange-text-white">
                                <div class="col-12 border-right border-gray background-orange px-0">
                                    <button v-if="$root.evofit.curScheda.sedute.length == 1" class="btn btn-block testo-bottoni btn-orange btn-orange-orange orange-text-white">INIZIA ALLENAMENTO</button>
                                    <button v-if="$root.evofit.curScheda.sedute.length > 1" class="btn btn-block testo-bottoni btn-orange btn-orange-orange orange-text-white">INIZIO {{ (index + 1) }}° SEDUTA</button>
                                </div>
                                <!-- <div class="col-2 px-0 text-center background-brown">
                                    <span v-on:click.stop="openSeduta(index)" v-if="curSedutaOpen != index" class="ico-anteprima-seduta ico-orange"><span class="icon-accordion_apri"></span></span>
                                    <span v-on:click.stop="chiudiSeduta" v-else class="ico-anteprima-seduta ico-orange"><span class="icon-accordion_chiudi"></span></span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    
                    <!-- <div :class="{ 'seduta-aperta' : (curSedutaOpen == index), 'seduta-chiusa' : (curSedutaOpen != index) }" class="row no-gutters">
                        <div class="col-12">

                            <div class="row no-gutters">
                                <div class="col-12 orange-text-brown mt-4 text-center px-4">
                                    OVERVIEW
                                </div>
                                <div class="col-12 orange-text-white mt-4 text-center px-4">
                                    <p class="mb-0">OBIETTIVO</p>
                                    <p class="mt-0"><strong>{{ $root.evofit.curScheda.scheda.obiettivo }}</strong></p>
                                </div>
                                <div class="col-12 orange-text-white mt-4 text-center px-4">
                                    <p class="mb-0">TIPI DI ALLENAMENTO</p>
                                    <p class="mt-0"><strong>MANUALE E AUTOMATICO</strong></p>
                                </div>
                                <div class="col-12 orange-text-white mt-4 text-center px-4">
                                    <p class="mb-0">Circuiti {{ $root.evofit.curScheda.sedute.length }}</p>
                                    <p class="mt-0 mb-0">Esercizi {{ $root.evofit.curScheda.totEsercizi }}</p>
                                    <p class="mt-0">Livello {{ $root.evofit.curScheda.scheda.livello }}</p>
                                </div>
                                <div class="col-12 orange-text-white titolo-categoria mt-4 text-center px-4">
                                    <p class="mb-0">{{ $root.evofit.curScheda.scheda.note }}</p>
                                </div>
                                
                            </div>

                            <div class="row no-gutters px-3 mt-5">
                                <div class="col-12 text-center orange-text-brown">
                                    ESERCIZI
                                </div>
                                <div class="col-12 text-center">
                                    <div class="row mb-4">
                                        <div class="col-12 texto-generico text-left orange-text-white">
                                            {{ (index + 1) }}° Seduta
                                        </div>
                                    </div>
                                    <div v-for="esercizio,indexes in seduta.esercizi" :key="'eserc-' + index + '-' + indexes" class="row text-left pl-2 orange-text-white">                        
                                        <div v-if="(esercizio.tipo == 'I')" class="col-12">
                                            <p class="text-generico orange-text-white">Inizio Circuito. <strong><span v-text="esercizio.serie.replace('<G>',' ')"></span> giri</strong></p>
                                        </div>
                                        <div v-if="(esercizio.tipo == 'F')" class="col-12">
                                            <p class="text-generico orange-text-white">Fine Circuito.</p>
                                        </div>
                                        <div v-if="(esercizio.tipo == 'A')" class="col-12">
                                            <p class="text-generico orange-text-white">Inizio Superserie.</p>
                                        </div>
                                        <div v-if="(esercizio.tipo == 'B')" class="col-12">
                                            <p class="text-generico orange-text-white">Fine Superserie.</p>
                                        </div>
                                        <div v-if="(esercizio.tipo == 'N')" class="col-12">
                                            <p class="text-generico orange-text-white">{{ esercizio.note }}</p>
                                        </div>
                                        <div v-if="(esercizio.tipo != 'I') && (esercizio.tipo != 'F') && (esercizio.tipo != 'A') && (esercizio.tipo != 'B') && (esercizio.tipo != 'N')" class="col-12">
                                            <div class="row">
                                                <div class="col-4 pr-0 mx-0 bg-white">
                                                    <img class="img-esercizio-anteprima" v-if="esercizio.immagine" :src="esercizio.immagine">
                                                </div>
                                                <div class="col-8">
                                                    <p class="mb-0">{{ esercizio.descrizione }}</p>
                                                    <p>
                                                        <span v-if="esercizio.serieObj.tipo == 'car'">Cardio | {{ esercizio.serieObj.valori[0].val1 }}</span>
                                                        <span v-else>Muscolare</span>
                                                    </p>
                                                    <span v-on:click.stop="openAnteprima(index,indexes)" v-if="curAntSeduta != index && curAntEsercizio != indexes" class="ico-anteprima-esercizio ico-orange"><span class="icon-accordion_apri"></span></span>
                                                    <span v-on:click.stop="chiudiAnteprima" v-else class="ico-anteprima-esercizio ico-orange"><span class="icon-accordion_chiudi"></span></span>
                                                </div>
                                                <div v-if="esercizio.extvideo != null" :class="{ 'anteprima-aperta' : (curAntSeduta == index && curAntEsercizio == indexes) }" class="col-12 anteprima mt-2 mb-2 bg-white">
                                                    <iframe v-if="curAntSeduta == index && esercizio.extvideo.includes('vimeo') && esercizio.extvideo != ''" 
                                                    style="width:100%; min-height:280px; border:none; background-color:#fff; overflow:hidden;" 
                                                    :src="esercizio.extvideo + '?background=1&autoplay=1'" scrolling="no">
                                    
                                                    </iframe>
                                                    <video id="esercizioVideo" v-if="curAntSeduta == index && esercizio.extvideo.includes('.mp4')" autoplay muted loop="loop" style="margin-left:auto; margin-right:auto; width:100%; height:280px; display:block;">
                                                        <source :src="esercizio.extvideo" type="video/mp4">
                                                    </video>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> -->
                </div>
            </div> <!-- end of sedute for -->

            <!-- <div v-if="$root.userAbbonamERateOK()" class="row p-3">
                <div v-on:click.stop="$root.evofitGetAndShowMyTraining" v-if="$root.evofit.curScheda.isPersonal == false" class="col-12">
                    <button class="btn btn-block orange-text-white btn-orange btn-orange-brown testo-bottoni">VEDI SCHEDA PERSONALE</button>
                </div>
            </div> -->


        </div>

        <div v-if="($root.evofit.curScheda.isPersonal == false) && ($root.userAbbonamERateOK())" class="container-fluid m-0 px-0">
            <div class="row no-gutters">
                <div class="col text-center pt-5" >
                    <div class="col-12 pt-0">
                        <button v-on:click="$root.evofitGetAndShowMyTraining" style="vertical-align:middle;" class="btn btn-block btn-orange btn-outline-gray testo-bottoni"><span style="vertical-align:middle;">MIA SCHEDA</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="$root.userAbbonamERateOK()" class="container-fluid m-0 px-0">
            <div class="row no-gutters">
                <div class="col text-center pt-5" >
                    <div class="col-12 pt-0">
                        <button v-on:click="$root.openWhatsappTrainer" style="vertical-align:middle;" class="btn btn-block btn-orange btn-outline-gray testo-bottoni"><span style="display:inline-block; vertical-align:middle; height:10px; line-height:18px; margin-top:-10px; margin-right:10px; position:relative; font-size:18px;" class="icon-tools_whatsapp"></span><span style="vertical-align:middle;">CONTATTA TRAINER</span></button>
                    </div>
                </div>
            </div>
        </div>


        <div v-if="$root.userAbbonamERateOK()" class="container-fluid mx-0 mt-5 px-0">
            <div class="row no-gutters">
                <div class="col text-center">
                    <div class="row" style="height:49%;">
                        <div class="col-12 text-center">
                            <img src="img/questionario-sfondo.jpg" :style="{ 'height' : ($root.ui.squareHeight * 0.9) + 'px', 'width' : ($root.ui.squareHeight * 0.9) + 'px' }" style="border-radius:50%;  opacity:0.8; max-width:600px; margin-left:auto; margin-right:auto;">
                            <p class="titolo-35 orange-text-white testo-blocco-questionario" :style="{ 'height' : ($root.ui.squareHeight * 0.9) + 'px'}">CREA IL TUO PROGRAMMA PERSONALIZZATO</p>
                            <button v-on:click.stop="$root.surveyShow()" class="btn btn-orange btn-outline-white testo-bottoni bottone-questionario">INIZIA</button> 
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="container-fluid mx-0 mt-5 px-0">
            <div class="row no-gutters">
                <div class="col text-center" >
                    <div class="row" style="height:49%;">
                        <div class="col-12">
                            <img src="img/questionario-sfondo.jpg" :style="{ 'height' : ($root.ui.squareHeight * 0.9) + 'px', 'width' : ($root.ui.squareHeight * 0.9) + 'px' }" style="border-radius:50%; opacity:0.8; max-width:600px; margin-left:auto; margin-right:auto;">
                            <p class="titolo-35 orange-text-white testo-blocco-questionario">CREA IL TUO PROGRAMMA PERSONALIZZATO</p>
                        </div>
                    </div>
                    <div class="row" style="height:49%;">
                        <div class="col-12">
                            <button v-on:click.stop="tryRinnovaOCompra" style="margin-top:30%" class="btn btn-orange btn-outline-white testo-bottoni bottone-questionario">INIZIA</button> 
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'TrainerSchedaAttuale',
    data () {
        return {
            curAntSeduta: -1,
            curAntEsercizio: -1,

            curSedutaOpen: -1
        }
    },
    methods: {

        clickTitle( _idx) {
            this.curTitleSelected = _idx;
            let elem = document.getElementById('title-' + _idx);
            //elem.scrollIntoView({ behavior: 'smooth' , alignToTop: false});
            if(_idx == 0) {
                this.$root.showPage('trainermainpage', false);
            } else if(_idx == 2) {
                this.$root.rboShowPage();
            }
        },

        openAnteprima(_index, _indexes) {
            console.log('Open anteprima: ' + _index + '  ' + _indexes);
            this.curAntSeduta = _index;
            this.curAntEsercizio = _indexes;
        },

        chiudiAnteprima() {
            this.curAntSeduta = -1;
            this.curAntEsercizio = -1;
        },

        openSeduta(_index) {
            this.curSedutaOpen = _index;
        },

        chiudiSeduta() {
            this.curSedutaOpen = -1;
        },

        tryRinnovaOCompra() {
            if(this.$root.isApple == false) {
                this.$root.userRinnovaOCompra();
            } else {
                this.$root.showAppleMessage();
            }
        },

        sceltaTipoAllenamento(_sedutaIdx) {
            this.$root.$refs.pageMan.showAlertAllenamento('none','OK','CANCEL')
            .then( (_auto) => {
                console.log('automatico');

                if(this.$root.noSleepEnabled == false) {
                    this.$root.noSleepEnabled = true;
                    this.$root.noSleep.enable();
                }

                this.$root.evofit.curScheda.prepareSeduta(_sedutaIdx);
                this.$root.evofitStartEsercitazione(true);
                this.$root.rboAddData('open_scheda_auto', this.$root.evofit.curScheda.scheda.descrizione, 'SCHEDA', this.$root.evofit.curScheda.scheda.codice, 1, 1);
            })
            .catch( (_manuale) => {

                if(_manuale == null) {
                    return false;
                } else {
                    if(this.$root.noSleepEnabled == false) {
                        this.$root.noSleepEnabled = true;
                        this.$root.noSleep.enable();
                    }

                    this.$root.evofit.curScheda.prepareSeduta(_sedutaIdx);
                    this.$root.evofitPrepareEsercitazione(false);
                    this.$root.$refs.pageMan.showPage('manualelistapage');

                    this.$root.rboAddData('open_scheda_man', this.$root.evofit.curScheda.scheda.descrizione, 'SCHEDA', this.$root.evofit.curScheda.scheda.codice, 1, 1);
                }

            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #trainerscheda-logo {
        width:20%;
        max-width: 130px;
        margin-top:90px;
        margin-bottom:30px;
    }

    .schedaanteprima-header {
        min-height: 300px;
        background-size: cover;
        background-position: center center;
    }

    #schedaanteprima-back {
        width: 100%;
        height: 100%;
        text-align: center;
        background: rgba(0,0,0,0.6);
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.4) 49%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,1) 100%);    
        padding-left:25px;
        padding-right: 25px;
        display:block;
        position: relative;
    }

    .img-esercizio-anteprima {
        display:block;
        width: 100%;
        height: auto;
    }

    .ico-anteprima-esercizio {
        position: absolute;
        right: 15px;
        font-size: 32px;
        top: 50%;
        transform: translateY(-50%);        
    }

    .anteprima {
        height: 0px;
        max-height: 0px;
        transition: max-height 0.5s linear;
        overflow:hidden;
    }

    .anteprima-aperta {
        height: auto !important;
        max-height: 500px;
        transition: max-height 0.5s linear;
        overflow:hidden;
    }

    .ico-anteprima-seduta {
        position: absolute;
        font-size: 30px;
        text-align:center;
        left:0px;
        width:100%;
        top: 50%;
        transform: translateY(-50%);        
    }

    .seduta-chiusa {
        height: 0px;
        transition: all 0.5s linear;
        overflow:hidden;
    }

    .seduta-aperta {
        height: auto;
        transition: all 0.5s linear;
        overflow:hidden;
    }
</style>
