<template>
    <div>
        <div class="container-fluid">
            <div v-if="$root.centers.centers.length > 0 && typeof($root.centers.centers[ $root.user.centerId ]) != 'undefined'" class="row mt-0">
                <div class="col-12 mt-0">
                    <div id="centroaccordion">
                        <div class="card orange-text-white border-0">

                            <div class="card-header collapsed p-0" :id="'centerheading-' + 0">
                                <h5 class="mb-0">
                                    <button ref="accordionButton" v-on:click="toggleAccordion(0)" class="btn btn-block btn-orange btn-outline-white orange-text-black" data-toggle="collapse" :data-target="'#ratecoll-' + 0" aria-expanded="true" aria-controls="collapseOne">
                                        {{ $root.centers.centers[ $root.user.centerId ].name }}
                                        <span style="float:right;" v-show="(openAccordion.hasOwnProperty(0) ) && (openAccordion[0].open)" class="orange-ico orange-ico-dark"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_chiudi"></span></span>
                                        <span style="float:right;" v-show="(!(openAccordion.hasOwnProperty(0))) || (!openAccordion[0].open)" class="orange-ico orange-ico-dark"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_apri"></span></span>
                                    </button>
                                </h5>
                            </div>

                            <div :id="'ratecoll-' + 0" class="collapse" :aria-labelledby="'heading-' + 0" data-parent="#centroaccordion">
                                <div class="card-body testo-bottoni orange-text-black" style="font-size:0.8rem;">
                                    <div class="row" v-for="center, index in $root.centers.centers" :key="'cent-' + index">
                                        <div v-if="center.name.indexOf('REBORN') == -1 && center.name.indexOf('OUTDOOR') == -1" v-on:click="prenotaCambiaCentro(index)" class="col-12 border-bottom border-dark py-3">
                                            {{ center.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <p class="testo-generico orange-text-black text-center mt-4"><strong>{{ moment().format('MMMM').toUpperCase() }}</strong></p>
            <div class="row">
                <div class="col-12">
                    <div v-for="day,index in $root.reservation.calendar" v-bind:key="'resday-' + index" v-on:click="selectReservationCalNewDay(index)" class="day-col testo-generico orange-text-black">
                        <p class="day-text" v-bind:class="{ availLessons : (day.lessons.filter( l => l.AvailablePlaces > 0).length > 0) }" style="text-transform:uppercase;">
                            {{ day.day }}
                        </p>
                        <p class="day-num" v-bind:class="{'reborncal-selected' : day.selected }">{{ new Date(day.date).getDate() }}</p>
                    </div>
                </div>
            </div>

            <div v-if="$root.reservation.categoryFilter != '***' && $root.reservation.categoryFilter != 'MEDIC'" class="row">
                <div v-if="$root.reservation.reservationCategories.length > 0" class="col-12 p-2">
                    <div class="row px-3">
                        <div v-for="(rescat,index) in $root.reservation.reservationCategories.filter( cat => cat.Description.includes($root.reservation.categoryFilter) )" class="col-6 px-2 mt-3 text-center" :key="'rescat-' + index">
                            <span v-on:click="changeReservationService(rescat.CategoryID)" :class="{ 'btn-corso-selected' : ($root.reservation.curReservCategory == rescat.CategoryID) }" class="testo-generico border orange-border-dark btn-corso">{{ rescat.Description.replaceAll('SALA','') }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="$root.reservation.categoryFilter != 'MEDIC'" class="row">
                <div v-if="$root.reservation.calendar.length > 0 " class="col-12 text-left" style="max-height: 300px; overflow-y:auto;">
                    <div v-for="slot,index in $root.reservation.calendar[ $root.reservation.selectedCalDay ].lessons" 
                    :key="'calslot-' + index" 
                    :class="{ 'orange-text-white' : ((curSelectedSlotIdx != null) && ($root.reservation.calendar[ $root.reservation.selectedCalDay ].lessons[curSelectedSlotIdx].IDLesson == slot.IDLesson) && (userHasClicked)), 'background-orange' : ((curSelectedSlotIdx != null) && (curSelectedSlotIdx == index) && (userHasClicked)), 'orange-text-black' : (($root.reservation.calendar[ $root.reservation.selectedCalDay ].lessons[curSelectedSlotIdx].IDLesson != slot.IDLesson) || (!userHasClicked)) }" 
                    class="row testo-generico no-gutters p-2 my-3 border border-gray">
                        <div v-on:click.stop="prenotaClickSlot(slot)" class="col-8 pt-1">
                            <p v-if="slot.AdditionalInfo" :class="{ 'orange-text-white' : ((curSelectedSlotIdx != null) && ($root.reservation.calendar[ $root.reservation.selectedCalDay ].lessons[curSelectedSlotIdx].IDLesson == slot.IDLesson) && (userHasClicked)), 'orange-text-black' : (($root.reservation.calendar[ $root.reservation.selectedCalDay ].lessons[curSelectedSlotIdx].IDLesson != slot.IDLesson) && (!userHasClicked))}"><strong>{{ slot.AdditionalInfo.replace("/"," ") }}</strong></p>
                            <p v-else :class="{ 'orange-text-white' : ((curSelectedSlotIdx != null) && ($root.reservation.calendar[ $root.reservation.selectedCalDay ].lessons[curSelectedSlotIdx].IDLesson == slot.IDLesson) && (userHasClicked)), 'orange-text-black' : (($root.reservation.calendar[ $root.reservation.selectedCalDay ].lessons[curSelectedSlotIdx].IDLesson != slot.IDLesson) || (!userHasClicked))}"><strong>{{ slot.CategoryDescription.replace("***","") }}</strong></p>
                            <span class="reborncal-time">{{ slot.StartTime.trim().substr(11,5) }} - {{ slot.EndTime.trim().substr(11,5) }}</span>
                        </div>
                        <div v-if="$root.reservation.categoryFilter == '***'" v-on:click.stop="prenotaClickSlot(slot)" class="col-4 text-center">
                            <p style="display:block;">Disponibili<br><strong style="font-size:1.1rem;">{{ Math.round((slot.AvailablePlaces / slot.MaxPrenotazioni) * 100) }}%</strong></p>
                        </div>
                        <div v-if="$root.reservation.categoryFilter != '***'" v-on:click.stop="prenotaClickSlot(slot)" class="col-4 text-center">
                            <span style="display:block;">Posti: {{ slot.AvailablePlaces }}</span>
                        </div>
                    </div>    
                </div>
                <div v-else class="col-12 mt-5">
                    <p class="orange-text-black testo-generico text-center">Non ci sono lezioni di questa tipologia.</p>
                </div>
            </div>

            <div v-if="$root.reservation.categoryFilter == 'MEDIC'" class="row">
                <div v-if="$root.reservation.calendar.length > 0 && $root.reservation.categoryFilter != '***'" class="col-12 text-left" style="max-height: 300px; overflow-y:auto;">
                    <div v-for="slot,index in $root.reservation.calendar[ $root.reservation.selectedCalDay ].lessons.filter( l => parseInt(l.AvailablePlaces) > 0 )" :key="'calslot-' + index" :class="{ 'orange-text-white' : ((curSelectedSlotIdx != null) && ($root.reservation.calendar[ $root.reservation.selectedCalDay ].lessons[curSelectedSlotIdx].IDLesson == slot.IDLesson) && (userHasClicked)), 'background-orange' : ((curSelectedSlotIdx != null) && ($root.reservation.calendar[ $root.reservation.selectedCalDay ].lessons[curSelectedSlotIdx].IDLesson == slot.IDLesson) && (userHasClicked)), 'orange-text-black' : (($root.reservation.calendar[ $root.reservation.selectedCalDay ].lessons[curSelectedSlotIdx].IDLesson != slot.IDLesson) || (!userHasClicked))}" class="row testo-generico no-gutters p-2 my-3 border border-gray">
                        <div v-on:click.stop="prenotaClickSlot(slot)" class="col-8 pt-1">
                            <p v-if="slot.AdditionalInfo" v-bind:class="{ 'orange-text-white' : ((curSelectedSlotIdx != null) && ($root.reservation.calendar[ $root.reservation.selectedCalDay ].lessons[curSelectedSlotIdx].IDLesson == slot.IDLesson) && (userHasClicked)), 'orange-text-black' : (($root.reservation.calendar[ $root.reservation.selectedCalDay ].lessons[curSelectedSlotIdx].IDLesson != slot.IDLesson) || (!userHasClicked)) }"><strong>{{ slot.AdditionalInfo.replace("/"," ") }}</strong></p>
                            <p v-else :class="{ 'orange-text-white' : ((curSelectedSlotIdx != null) && ($root.reservation.calendar[ $root.reservation.selectedCalDay ].lessons[curSelectedSlotIdx].IDLesson == slot.IDLesson) && (userHasClicked)), 'orange-text-black' : (($root.reservation.calendar[ $root.reservation.selectedCalDay ].lessons[curSelectedSlotIdx].IDLesson != slot.IDLesson) || (!userHasClicked)) }"><strong>{{ slot.CategoryDescription }}</strong></p>
                            <span class="reborncal-time">{{ slot.StartTime.trim().substr(11,5) }} - {{ slot.EndTime.trim().substr(11,5) }}</span>
                        </div>
                        <div v-if="$root.reservation.categoryFilter == '***'" v-on:click.stop="prenotaClickSlot(slot)" class="col-4 text-center">
                            <p style="display:block;">Disponibili<br><strong style="font-size:1.1rem;">{{ Math.round((slot.AvailablePlaces / slot.MaxPrenotazioni) * 100) }}%</strong></p>
                        </div>
                        <div v-if="$root.reservation.categoryFilter != '***'" v-on:click.stop="prenotaClickSlot(slot)" class="col-4 text-center">
                            <span style="display:block;">Posti: {{ slot.AvailablePlaces }}</span>
                        </div>
                    </div>    
                </div>
                <div v-else class="col-12 mt-5">
                    <p class="orange-text-black testo-generico text-center">Non ci sono lezioni di questa tipologia.</p>
                </div>
            </div>
        </div>

        <div v-if="$root.reservation.categoryFilter != 'MEDIC' && userHasClicked" class="container-fluid">
            <div class="row mt-3">
                <div class="col-12">
                    <button v-if="curSelectedSlotIdx != null" class="btn btn-block btn-orange btn-orange-gray" v-on:click.stop="prenotaButtonClick()" href="#">PRENOTA</button>
                </div>
            </div>
        </div>
        <div v-if="$root.reservation.categoryFilter == 'MEDIC' && userHasClicked" class="container-fluid">
            <div class="row mt-3">                
                <div v-if="$root.payments.epurses.epurseVisiteMediche != null && $root.payments.epurses.epurseVisiteMediche.Balance > 38" class="col-12">
                    <div class="form-group form-check mb-4">
                        <input type="checkbox" v-model="medPrivacy" class="form-check-input" style="height:25px;" id="exampleCheck1">
                        <label style="padding-left:10px; padding-top:8px;" class="form-check-label orange-text-black" for="exampleCheck1">Acconsento al trattamento dei dati personali secondo il <a :href="$root.policiesUrls.privacy" target="_rpivframe">documento sulla privacy</a></label>
                    </div>                    
                    <button v-if="curSelectedSlotIdx != null && medPrivacy" class="btn btn-block btn-orange btn-orange-gray" v-on:click.stop="prenotaButtonClick()" href="#">PRENOTA VISITA</button>
                </div>
                <div v-else  class="col-12">
                    <div class="form-group form-check mb-4">
                        <input type="checkbox" v-model="medPrivacy"  class="form-check-input" style="height:25px;" id="exampleCheck1">
                        <label style="padding-left:10px; padding-top:8px;" class="form-check-label orange-text-black" for="exampleCheck1">Acconsento al trattamento dei dati personali secondo il <a :href="$root.policiesUrls.privacy" target="_rpivframe">documento sulla privacy</a></label>
                    </div>                    
                    <button v-if="curSelectedSlotIdx != null && medPrivacy" class="btn btn-block btn-orange btn-orange-gray" v-on:click.stop="prenotaButtonClick()" href="#">ACQUISTA VISITA</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'PrenotaIngresso',
    data () {
        return {
            openAccordion: [],
            curSelectedSlotIdx: 0,
            userHasClicked: false,
            medPrivacy: false
        }
    },
    created() {

    },
    methods: {
        toggleAccordion(index) {
            if(typeof this.openAccordion[index] == 'undefined') {
                Vue.set(this.openAccordion,  index, {
                    open: true
                } );
            } else {
                this.openAccordion[index].open = !this.openAccordion[index].open;
            }
        },

        changeReservationService(newCat) {
            this.userHasClicked = false;
            this.curSelectedSlotIdx = 0;
            this.$root.reservation.curReservCategory = newCat;
            this.$root.reservationGetServices(this.$root.reservation.curReservCategory);
        },

        selectReservationCalNewDay(_newDay) {
            this.userHasClicked = false;
            this.curSelectedSlotIdx = 0;
            this.$root.reservationSetCalendarDay(_newDay);
        },

        prenotaCambiaCentro(index) {
            this.userHasClicked = false;
            this.$root.user.centerId = index;
            this.$root.reservationGetServices(this.$root.reservation.curReservCategory );
            this.$refs.accordionButton.click();
            //this.$root.reservation.categoryFilter = 'SALA';  
        },

        prenotaClickSlot(_slot) {
            //devo cercare lo slot giusto
            let _index = -1;
            this.userHasClicked = true;
            this.$root.reservation.calendar[ this.$root.reservation.selectedCalDay ].lessons.forEach( (sl, idx) => {
                if(sl.IDLesson == _slot.IDLesson) {
                    _index = idx;
                }
            });
            if(this.curSelectedSlotIdx == _index) {
                this.curSelectedSlotIdx = 0;
            } else {
                this.curSelectedSlotIdx = _index;
            }
        },

        prenotaButtonClick() {   

            if(this.$root.reservation.categoryFilter == 'MEDIC') {
                if(this.$root.payments.epurses.epurseVisiteMediche != null) {
                    if(this.$root.payments.epurses.epurseVisiteMediche.Balance > 38) {        
                        //prenota visita medica
                        if(this.$root.userAbbonamERateOK()) {
                            let slot = this.$root.reservation.calendar[ this.$root.reservation.selectedCalDay ].lessons[ this.curSelectedSlotIdx ];
                            this.$root.reservationPrenotaConferma(slot);
                        } else {
                            if(this.$root.isApple) {
                                //mostra il messaggio epr gli utenti Apple
                                this.$root.showAppleMessage();
                            } else {
                                this.$root.userRinnovaOCompra();
                            }
                        }
                    } else {
                        //mostro l'overlay per comprare la ricarica visite mediche
                        let slot = this.$root.reservation.calendar[ this.$root.reservation.selectedCalDay ].lessons[ this.curSelectedSlotIdx ];
                        this.$root.$refs.pageMan.savePagemanSteps({
                            vars: [
                                { name: 'reservation', property: 'categoryFilter', value: this.$root.reservation.categoryFilter },
                                { name: 'reservation', property: 'curReservCategory', value: this.$root.reservation.curReservCategory },
                                { name: 'reservation', property: 'selectedCalDay' , value : this.$root.reservation.selectedCalDay },
                                { name: 'user', property: 'centerId', value: this.$root.user.centerId }
                            ],
                            ops: [
                                { funcname : 'reservationGetServices', param: this.$root.reservation.curReservCategory, pause: 1 },
                                { funcname : 'reservationSetCalendarDay', param: this.$root.reservation.selectedCalDay, pause: 1 },
                                { funcname : 'reservationPrenotaNoConferma', param: slot }
                            ],
                            endmessage: null
                        });
                        this.$root.$refs.pageMan.showOverlay('productsricarichemedicheoverlay');
                    }
                } else {
                    //utente senza borsellino visite mediche, deve fare ricarica
                    let slot = this.$root.reservation.calendar[ this.$root.reservation.selectedCalDay ].lessons[ this.curSelectedSlotIdx ];
                    this.$root.$refs.pageMan.savePagemanSteps({
                        vars: [
                            { name: 'reservation', property: 'categoryFilter', value: this.$root.reservation.categoryFilter },
                            { name: 'reservation', property: 'curReservCategory', value: this.$root.reservation.curReservCategory },
                            { name: 'reservation', property: 'selectedCalDay' , value : this.$root.reservation.selectedCalDay },
                            { name: 'user', property: 'centerId', value: this.$root.user.centerId }
                        ],
                        ops: [
                            { funcname : 'reservationGetServices', param: this.$root.reservation.curReservCategory, pause: 1 },
                            { funcname : 'reservationSetCalendarDay', param: this.$root.reservation.selectedCalDay, pause: 1 },
                            { funcname : 'reservationPrenotaNoConferma', param: slot }
                        ],
                        endmessage: null
                    });
                    this.$root.$refs.pageMan.showOverlay('productsricarichemedicheoverlay');
                }
            } else {            
                if(this.$root.userAbbonamERateOK()) {
                    let slot = this.$root.reservation.calendar[ this.$root.reservation.selectedCalDay ].lessons[ this.curSelectedSlotIdx ];
                    this.$root.reservationPrenotaConferma(slot);
                } else {
                    if(this.$root.isApple) {
                        this.$root.showAppleMessage();
                    } else {
                        this.$root.userRinnovaOCompra();
                    }
                }
            }
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.day-col {
    display:inline-block;
    width: calc(100% / 7);
    text-align:center;
    padding-left:5px;
    padding-right: 5px;
    cursor:pointer;
}

.day-num {
    height: 30px;
    width: 30px;
    margin-left: auto;
    line-height: 30px;
    margin-right: auto;
    border-radius:50%;
}

.day-text {
    width: 100%;
    margin-bottom:2px;
    padding-bottom:0px;
}

.reborncal-selected {
    background-color:#EF7E2D;
    color:#fff;
}

.availLessons {
    background-color:#f7e0d0;
    border-radius:8px;
}

.btn-corso {
    display:block;
    border-radius:15px;
    width: 100%;
    padding-top:5px;
    padding-bottom:5px;
    color:#000 !important;
}

.btn-corso-selected {
    background-color: #ef7e2d;
    color:#fff !important;
}
</style>
