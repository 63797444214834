<template>
    <div id="pagemanager">
		

		<div class="ls-overlay ls-spinner-overlay" v-on:click.stop="waitingClick" :class="{ 'ls-overlay-hidden' : (waiting.visible == false), 'ls-overlay-visible' : (waiting.visible == true)}">
			<div class="ls-spinner">
				<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>		
				<p>{{ waiting.message }}</p>
			</div>
		</div>

		<div class="ls-overlay ls-alert-overlay" :class="{ 'ls-overlay-hidden' : (alert.visible == false), 'ls-overlay-visible' : (alert.visible == true)}">
			<div class="ls-alert">
				<div class="row p-3">
					<div class="col-12">
						<p v-html="alert.message"></p>
					</div>
				</div>
				<div class="ls-alert-buttons">
					<div class="alert-btn-left">
						<button v-if="alert.cancelText != ''" v-on:click.stop="_alertCancelPressed" class="btn btn-block btn-orange btn-orange-cancel">{{ alert.cancelText }}</button>
					</div>
					<div class="alert-btn-right">
						<button v-on:click.stop="_alertOkPressed" class="btn btn-block btn-orange btn-orange-confirm">{{ alert.okText }}</button>
					</div>
				</div>
			</div>
		</div>

		<div class="ls-overlay ls-alert-overlay" :class="{ 'ls-overlay-hidden' : (alertModificaSerie.visible == false), 'ls-overlay-visible' : (alertModificaSerie.visible == true)}">
			<div v-if="alertModificaSerie.esercizioName != ''" class="ls-alert px-3">
				<div class="row p-2">
					<div class="col-12 mb-3 text-center">
						<p class="testo-generico"><strong>MODIFICA SERIE E CARICHI</strong></p>
						<p class="testo-generico">{{ alertModificaSerie.esercizioName }}</p>
					</div>
				</div>
				<div class="row p-2">
					<div class="col-6 text-center">
						Ripetizioni
					</div>
					<div class="col-6 text-center">
						Carichi (Kg)
					</div>
				</div>
				<div class="row px-2 titolo-35" v-for="valore,index in alertModificaSerie.arrSerie" :key="'modserie-'+ index + '-' + + alertModificaSerie.arrSerie[index].val1 + '-' + alertModificaSerie.arrSerie[index].val2">
					<div v-if="alertModificaSerie.tipoSerie == 'car'" class="col-12 mb-3">
						<div class="row py-2 text-center">
							<div class="col-6">
								<span style="vertical-align:top;" v-on:click.stop="alertModificaSerie.arrSerie[index].val1 = $root.evofitModificaValoreSerie(alertModificaSerie.arrSerie[index].val1, 1)" class="modifica-serie-block"><strong style="font-size:20px;">+</strong></span>
								<span style="vertical-align:top;" class="modifica-serie-block" v-html="valore.val1"></span>
								<span style="vertical-align:top;" v-on:click.stop="alertModificaSerie.arrSerie[index].val1 = $root.evofitModificaValoreSerie(alertModificaSerie.arrSerie[index].val1, -1)" class="modifica-serie-block"><strong style="font-size:20px;">-</strong></span>
							</div>
							<div class="col-6">
								<span style="vertical-align:top;" v-on:click.stop="alertModificaSerie.arrSerie[index].val2 = $root.evofitModificaValoreSerie(alertModificaSerie.arrSerie[index].val2, 1)" class="modifica-serie-block"><strong style="font-size:20px;">+</strong></span>
								<span style="vertical-align:top;" class="modifica-serie-block" v-html="valore.val2"></span>
								<span style="vertical-align:top;" v-on:click.stop="alertModificaSerie.arrSerie[index].val2 = $root.evofitModificaValoreSerie(alertModificaSerie.arrSerie[index].val2, -1)" class="modifica-serie-block"><strong style="font-size:20px;">-</strong></span>
							</div>
						</div>
					</div>
					<div  v-if="alertModificaSerie.tipoSerie == 'mus'" class="col-12 mb-3" >
						<div class="row py-2 text-center">
							<div class="col-6">
								<span style="vertical-align:top;" v-on:click.stop="alertModificaSerie.arrSerie[index].val1 = $root.evofitModificaValoreSerie(alertModificaSerie.arrSerie[index].val1, 1)" class="modifica-serie-block"><strong style="font-size:20px;">+</strong></span>
								<span style="vertical-align:top;" class="modifica-serie-block" v-html="valore.val1"></span>
								<span style="vertical-align:top;" v-on:click.stop="alertModificaSerie.arrSerie[index].val1 = $root.evofitModificaValoreSerie(alertModificaSerie.arrSerie[index].val1, -1)" class="modifica-serie-block"><strong style="font-size:20px;">-</strong></span>
							</div>
							<div class="col-6">
								<span style="vertical-align:top;" v-on:click.stop="alertModificaSerie.arrSerie[index].val2 = $root.evofitModificaValoreSerie(alertModificaSerie.arrSerie[index].val2, 1)" class="modifica-serie-block"><strong style="font-size:20px;">+</strong></span>
								<span style="vertical-align:top;" class="modifica-serie-block" v-html="valore.val2"></span>
								<span style="vertical-align:top;" v-on:click.stop="alertModificaSerie.arrSerie[index].val2 = $root.evofitModificaValoreSerie(alertModificaSerie.arrSerie[index].val2, -1)" class="modifica-serie-block"><strong style="font-size:20px;">-</strong></span>
							</div>
						</div>
					</div>
				</div>
				<div class="ls-alert-buttons">
					<div class="alert-btn-left">
						<button v-on:click.stop="_alertModificaSerieCancelPressed" class="btn btn-block testo-bottoni btn-orange btn-orange-cancel">ANNULLA</button>
					</div>
					<div class="alert-btn-right">
						<button v-on:click.stop="_alertModificaSerieOkPressed" class="btn btn-block testo-bottoni btn-orange btn-orange-confirm">MODIFICA</button>
					</div>
				</div>
			</div>
		</div>

		<div class="ls-overlay ls-alert-overlay" :class="{ 'ls-overlay-hidden' : (alertSceltaCashback.visible == false), 'ls-overlay-visible' : (alertSceltaCashback.visible == true)}">
			<div class="ls-alert">
				<div class="row">
					<div class="col-12 text-left p-2 px-3">
						<span style="font-size:20px; color:#fff; width:25px; height:25px; display:block;" v-on:click.stop="_alertSceltaCashbackClose" class="icon-navig_chiudi"></span>
					</div>
				</div>
				<div v-if="$root.payments.contest.contest != null" class="row p-3">
					<div class="col-12 text-center mt-0">
						<p class="titolo-piccolo orange-text-white">USA IL CASHBACK</p>
						<p class="testo-generico orange-text-white">Hai raggiunto la soglia di punti che ti permette di pagare una rata del tuo abbonamento oppure ricaricare il borsellino dei servizi.</p>
					</div>
					<div v-if="$root.payments.contest.contest.PointBalance >= 30" class="col-12 text-center pb-3">
						<div id="ratecbaccordion">
							<div class="card orange-text-white border-0">
								<div class="card-header collapsed p-0" :id="'ratecbheading-' + 0">
									<h5 class="mb-0">
										<button v-on:click="toggleAccordion(0)" class="border-white btn btn-block btn-orange btn-outline-white testo-bottoni orange-text-white" data-toggle="collapse" :data-target="'#ratecbcoll-' + 0" aria-expanded="true" aria-controls="collapseOne">
											PAGA RATA
											<span style="float:right;" v-show="(openAccordion.hasOwnProperty(0) ) && (openAccordion[0].open)" class="orange-ico orange-ico-dark"><span style="right:10px; color:#fff; position:absolute; margin-top:-5px;" class="icon-accordion_chiudi"></span></span>
											<span style="float:right;" v-show="(!(openAccordion.hasOwnProperty(0))) || (!openAccordion[0].open)" class="orange-ico orange-ico-dark"><span style="right:10px; color:#fff; position:absolute; margin-top:-5px;" class="icon-accordion_apri"></span></span>
										</button>
									</h5>
								</div>

								<div :id="'ratecbcoll-' + 0" class="collapse" :aria-labelledby="'ratecbheading-' + 0" data-parent="#ratecbaccordion">
									<div class="card-body testo-generico orange-text-white" style="font-size:0.8rem;">
										<div class="row" v-for="paym, index in $root.payments.currentEpursePayments.filter( pa => pa.iyesPayment.IDBE == 98)" :key="'paym-' + index">
											<div class="col-4 text-left">
												<strong>{{ paym.name }}</strong><br>{{ paym.date }}
											</div>
											<div class="col-4 text-right">
												{{ paym.amount }}&nbsp;&euro;                                            
											</div>
											<div v-on:click.stop="$root.paymentsRicaricaBorsellinoEPagaRata(paym.iyesPayment)" class="col-4 text-right orange-link-light" style="font-size:inherit;">
												Salda Ora
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 text-center">
							<button v-on:click.stop="_alertSceltaCashbackRicaricaPressed" class="btn btn-block testo-bottoni btn-orange  btn-outline-white border-white">RICARICA BORSELLINO 5&euro;</button>
					</div>
				</div>
			</div>
		</div>

		<div class="ls-overlay ls-alert-overlay" v-on:click.stop="_alertAllenamentoClose" :class="{ 'ls-overlay-hidden' : (alertAllenamento.visible == false), 'ls-overlay-visible' : (alertAllenamento.visible == true)}">
			<div class="ls-alert">
				<div class="row p-3">
					<div class="col-12 text-center orange-text-black px-3">
						<p >OPZIONI DI ALLENAMENTO</p>
						<p class="testo-generico orange-text-black">Scegli come preferisci fare questa scheda</p>
					</div>
				</div>
				<div class="row p-3">
					<div class="col-12">
						<button v-on:click.stop="_alertAllenamentoCancelPressed" class="btn btn-block btn-orange btn-orange-manuale">ALLENAMENTO MANUALE</button>
						<p style="text-align:justify;" class="testo-generico orange-text-black px-4 mt-3">Puoi allenarti in autonomia e modificare le ripetizioni e pesi per ogni singolo esercizio. Passi all'esercizio successivo confermando.</p>
					</div>
				</div>
				<div class="row p-3 pb-4">
					<div class="col-12 pb-4">
						<button v-on:click.stop="_alertAllenamentoOkPressed" class="btn btn-block btn-orange btn-orange-automatico">ALLENAMENTO AUTOMATICO</button>
						<p style="text-align:justify;" class="testo-generico orange-text-black px-4 mt-3">Ti verranno indicati tempi e la scheda procederà automaticamente. Potrai cambiare tempi e pesi per gli esercizi e ripetizioni.</p>
					</div>
				</div>
			</div>
		</div>

        <TopToolbar ref="topToolbar" class="ls-toolbar" id="top-toolbar" />
        <BottomToolbar ref="bottomToolbar" class="ls-toolbar" id="bottom-toolbar" />

		<PasswordRecoveryOverlay class="ls-overlay ls-overlay-dark" :class="{ 'ls-overlay-visible' : (this.curOverlay == 'password-recovery'), 'ls-overlay-hidden' : (this.curOverlay != 'password-recovery') }" id="password-recovery"/>
		<PasswordChangeOverlay class="ls-overlay ls-overlay-dark" :class="{ 'ls-overlay-visible' : (this.curOverlay == 'password-change'), 'ls-overlay-hidden' : (this.curOverlay != 'password-change') }" id="password-change"/>
		
		<SceltaCentroOverlay class="ls-overlay ls-overlay-light" :class="{ 'ls-overlay-visible' : (this.curOverlay == 'scelta-centro'), 'ls-overlay-hidden' : (this.curOverlay != 'scelta-centro') }" id="scelta-centro"/>

		<PreferitiOverlay class="ls-overlay ls-overlay-light" :class="{ 'ls-overlay-visible' : (this.curOverlay == 'preferitioverlay'), 'ls-overlay-hidden' : (this.curOverlay != 'preferitioverlay') }" id="preferitioverlay"/>
		<ProductsRicaricheOverlay class="ls-overlay ls-overlay-light" :class="{ 'ls-overlay-visible' : (this.curOverlay == 'productsricaricheoverlay'), 'ls-overlay-hidden' : (this.curOverlay != 'productsricaricheoverlay') }" id="productsricaricheoverlay"/>
		<ProductsRicaricheMedicheOverlay class="ls-overlay ls-overlay-light" :class="{ 'ls-overlay-visible' : (this.curOverlay == 'productsricarichemedicheoverlay'), 'ls-overlay-hidden' : (this.curOverlay != 'productsricarichemedicheoverlay') }" id="productsricarichemedicheoverlay"/>

		<ExternalIframeOverlay class="ls-overlay ls-overlay-light" :class="{ 'ls-overlay-visible' : (this.curOverlay == 'externaliframeoverlay'), 'ls-overlay-hidden' : (this.curOverlay != 'externaliframeoverlay') }" id="externaliframeoverlay"/>

        <WelcomePage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'welcomepage'), 'ls-page-hidden' : (this.curPage != 'welcomepage') }" id="welcomepage"/>
        <LoginPage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'loginpage'), 'ls-page-hidden' : (this.curPage != 'loginpage') }" id="loginpage"/>
        <ChooseSubscriptionPage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'choosesubscription'), 'ls-page-hidden' : (this.curPage != 'choosesubscription') }" id="choosesubscription"/>
        <RegistrationPage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'registrationpage'), 'ls-page-hidden' : (this.curPage != 'registrationpage') }" id="registrationpage"/>
        <MainPage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'mainpage'), 'ls-page-hidden' : (this.curPage != 'mainpage') }" id="mainpage"/>
        <BuySubscriptionPage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'buysubscription'), 'ls-page-hidden' : (this.curPage != 'buysubscription') }" id="buysubscription"/>
        <RegistrationPasswordPage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'registrationpasswordpage'), 'ls-page-hidden' : (this.curPage != 'registrationpasswordpage') }" id="registrationpasswordpage"/>


        <RebornCategoryPage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'reborncategorypage'), 'ls-page-hidden' : (this.curPage != 'reborncategorypage') }" id="reborncategorypage"/>
        <RebornPlayerPage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'rebornplayerpage'), 'ls-page-hidden' : (this.curPage != 'rebornplayerpage') }" style="padding-top:0px;" :style="{ 'padding-bottom' : (($root.player) && ($root.player.screenOrientation == 0) ? '100px' : '0px') }" id="rebornplayerpage"/>

        <SearchPage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'searchpage'), 'ls-page-hidden' : (this.curPage != 'searchpage') }" style="padding-top:0px;" id="searchpage"/>

        <SurveyPage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'surveypage'), 'ls-page-hidden' : (this.curPage != 'surveypage') }" style="padding-top:0px;" id="surveypage"/>
        <TrainerReviewPage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'trainerreviewpage'), 'ls-page-hidden' : (this.curPage != 'trainerreviewpage') }" style="padding-top:0px;" id="trainerreviewpage"/>

        <UserMainPage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'usermainpage'), 'ls-page-hidden' : (this.curPage != 'usermainpage') }" style="padding-top:0px;" id="usermainpage"/>
        <UserMieiDatiPage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'usermieidatipage'), 'ls-page-hidden' : (this.curPage != 'usermieidatipage') }" style="padding-top:0px;" id="usermieidatipage"/>
        <UserAbbonamentoPage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'userabbonamentopage'), 'ls-page-hidden' : (this.curPage != 'userabbonamentopage') }" style="padding-top:0px;" id="userabbonamentopage"/>
        <UserAbbonamentoCambiaPage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'userabbonamentocambiapage'), 'ls-page-hidden' : (this.curPage != 'userabbonamentocambiapage') }" style="padding-top:0px;" id="userabbonamentocambiapage"/>
        <UserPagamentoPage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'userpagamentopage'), 'ls-page-hidden' : (this.curPage != 'userpagamentopage') }" style="padding-top:0px;" id="userpagamentopage"/>
        <UserDocumentiPage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'userdocumentipage'), 'ls-page-hidden' : (this.curPage != 'userdocumentipage') }" style="padding-top:0px;" id="userdocumentipage"/>
        <UserRicevutePage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'userricevutepage'), 'ls-page-hidden' : (this.curPage != 'userricevutepage') }" style="padding-top:0px;" id="userricevutepage"/>
        <UserGreenPassPage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'usergreenpasspage'), 'ls-page-hidden' : (this.curPage != 'usergreenpasspage') }" style="padding-top:0px;" id="usergreenpasspage"/>
        <UserCertMedicoPage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'usercertmedicopage'), 'ls-page-hidden' : (this.curPage != 'usercertmedicopage') }" style="padding-top:0px;" id="usercertmedicopage"/>
        <UserDisdettePage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'userdisdettepage'), 'ls-page-hidden' : (this.curPage != 'userdisdettepage') }" style="padding-top:0px;" id="userdisdettepage"/>
        <UserTerminiPage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'userterminipage'), 'ls-page-hidden' : (this.curPage != 'userterminipage') }" style="padding-top:0px;" id="userterminipage"/>
        <UserBorsellinoPage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'userborsellinopage'), 'ls-page-hidden' : (this.curPage != 'userborsellinopage') }" style="padding-top:0px;" id="userborsellinopage"/>
        <UserPresentaAmicoPage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'userpresentaamicopage'), 'ls-page-hidden' : (this.curPage != 'userpresentaamicopage') }" style="padding-top:0px;" id="userpresentaamicopage"/>
		<UserCambioPasswordOverlay class="ls-overlay ls-overlay-light" :class="{ 'ls-overlay-visible' : (this.curOverlay == 'usercambiopassword'), 'ls-overlay-hidden' : (this.curOverlay != 'usercambiopassword') }" id="usercambiopassword"/>

        <TrainerMainPage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'trainermainpage'), 'ls-page-hidden' : (this.curPage != 'trainermainpage') }" style="padding-top:0px;" id="trainermainpage"/>
        <TrainerSchedeObiettivoPage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'trainerschedeobiettivopage'), 'ls-page-hidden' : (this.curPage != 'trainerschedeobiettivopage') }" style="padding-top:0px;" id="trainerschedeobiettivopage"/>
        <TrainerSchedaAttualePage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'trainerschedaattualepage'), 'ls-page-hidden' : (this.curPage != 'trainerschedaattualepage') }" style="padding-top:0px;" id="trainerschedaattualepage"/>
        <TrainerQuestionarioPage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'trainerquestionariopage'), 'ls-page-hidden' : (this.curPage != 'trainerquestionariopage') }" style="padding-top:0px;" id="trainerquestionariopage"/>

        <GetReadyPage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'getreadypage'), 'ls-page-hidden' : (this.curPage != 'getreadypage') }" style="padding-top:0px;" id="getreadypage"/>
        <EseguiPage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'eseguipage'), 'ls-page-hidden' : (this.curPage != 'eseguipage') }" style="padding-top:0px;" id="eseguipage" ref="eseguipage"/>
        <ManualeListaPage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'manualelistapage'), 'ls-page-hidden' : (this.curPage != 'manualelistapage') }" style="padding-top:0px;" id="manualelistapage" ref="manualelistapage"/>

        <RBODettagliPage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'rbodettaglipage'), 'ls-page-hidden' : (this.curPage != 'rbodettaglipage') }" style="padding-top:0px;" id="rbodettaglipage"/>

        <NewsPage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'newspage'), 'ls-page-hidden' : (this.curPage != 'newspage') }" style="padding-top:0px;" id="newspage"/>
        <ListaCentriPage class="ls-page" :class="{ 'ls-page-visible' : (this.curPage == 'listacentripage'), 'ls-page-hidden' : (this.curPage != 'listacentripage') }" style="padding-top:0px;" id="listacentripage"/>

		<DettagliCentroOverlay class="ls-overlay ls-overlay-dark" :class="{ 'ls-overlay-visible' : (this.curOverlay == 'dettaglicentrooverlay'), 'ls-overlay-hidden' : (this.curOverlay != 'dettaglicentrooverlay') }" id="dettaglicentrooverlay"/>

        <PrenotaMainPage class="ls-page ls-page-white" :class="{ 'ls-page-visible-top' : (this.curPage == 'prenotamainpage'), 'ls-page-hidden-top' : (this.curPage != 'prenotamainpage') }" id="prenotamainpage"/>
        <PrenotaIngressoPage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'prenotaingressopage'), 'ls-page-hidden' : (this.curPage != 'prenotaingressopage') }" id="prenotaingressopage"/>
        <PrenotaMiePage class="ls-page ls-page-white" :class="{ 'ls-page-visible' : (this.curPage == 'prenotamiepage'), 'ls-page-hidden' : (this.curPage != 'prenotamiepage') }" id="prenotamiepage"/>

    </div>
</template>

<script>
import WelcomePage from './pages/WelcomePage'
import LoginPage from './pages/LoginPage'
import ChooseSubscriptionPage from './pages/ChooseSubscription'
import RegistrationPage from './pages/RegistrationPage'
import RegistrationPasswordPage from './pages/RegistrationPassword.vue'
import MainPage from './pages/MainPage'
import BuySubscriptionPage from './pages/BuySubscription.vue'
import RebornCategoryPage from './pages/reborn/RebornCategoryPage'
import RebornPlayerPage from './pages/reborn/RebornPlayerPage'

import SearchPage from './pages/search/SearchPage.vue'

import SurveyPage from './pages/questionario/Questionario.vue'
import TrainerReviewPage from './pages/questionario/TrainerReview.vue'

import SceltaCentroOverlay from './overlays/SceltaCentro'
import PasswordRecoveryOverlay from './overlays/RecuperoPassword'
import PasswordChangeOverlay from './overlays/CambioPassword'

import PreferitiOverlay from './overlays/Preferiti'
import ExternalIframeOverlay from './overlays/PaymentOverlay'

import UserMainPage from './pages/utente/UserMain'
import UserMieiDatiPage from './pages/utente/UserMieiDati'
import UserAbbonamentoPage from './pages/utente/UserAbbonamentoPage'
import UserAbbonamentoCambiaPage from './pages/utente/UserAbbonamentoCambiaPage'
import UserPagamentoPage from './pages/utente/UserMetodoPagamento'
import UserDocumentiPage from './pages/utente/UserDocumenti'
import UserRicevutePage from './pages/utente/UserRicevute'
import UserCertMedicoPage from './pages/utente/UserCertMedico'
import UserGreenPassPage from './pages/utente/UserGreenPassPage'
import UserDisdettePage from './pages/utente/UserDisdettePage'
import UserTerminiPage from './pages/utente/UserTerminiPage.vue'
import UserBorsellinoPage from './pages/utente/UserBorsellino.vue'
import UserPresentaAmicoPage from './pages/utente/UserPresentaAmico.vue'
import UserCambioPasswordOverlay from './overlays/UserCambioPassword.vue'

import ProductsRicaricheOverlay from './overlays/prodotti/ProducstRicaricheOverlay.vue'
import ProductsRicaricheMedicheOverlay from './overlays/prodotti/ProductsRicaricheMedicheOverlay.vue'

import TrainerMainPage from './pages/trainer/TrainerMain'
import TrainerSchedeObiettivoPage from './pages/trainer/TrainerSchedeObiettivo'
import TrainerSchedaAttualePage from './pages/trainer/TrainerSchedaAttuale'
import TrainerQuestionarioPage from './pages/trainer/TrainerQuestionario.vue'

import GetReadyPage from './pages/trainer/player/GetReady'
import EseguiPage from './pages/trainer/player/Esegui'
import ManualeListaPage from './pages/trainer/player/ManualeLista'

import RBODettagliPage from './pages/rbo/RBODettagli'

import NewsPage from './pages/infos/NewsPage'
import ListaCentriPage from './pages/infos/ListaCentri'
import DettagliCentroOverlay from './pages/infos/DettaglioCentroOverlay'

import PrenotaMainPage from './pages/prenota/PrenotaMain'
import PrenotaIngressoPage from './pages/prenota/PrenotaIngresso'
import PrenotaMiePage from './pages/prenota/PrenotaMie'

import TopToolbar from './TopToolbar'
import BottomToolbar from './BottomToolbar'

import Vue from 'vue';

export default {
    name: 'page-manager',
    data() {
        return {
			openAccordion: [],

            navStack: [ 'welcomepage' ],
            curPage: 'welcomepage',
            lastPage: '',

			lastRootPage: '',	//if the page we just popped is a rootpage the call resetPagesStack

            overStack: [],
			curOverlay: '',

			curHeader: '',
			curFooter: '',

			tmpToolbarVisibility: null,
			tmpBottombarVisibility: null,

			alert: {
				visible: false,
				message: '',
				okText: 'OK',
				cancelText: 'Annulla',
				okCallback: function() {} ,
				cancelCallback:  function() {}
			},

			alertAllenamento: {
				visible: false,
				okCallback: function() {} ,
				cancelCallback:  function() {}
			},

			alertSceltaCashback: {
				visible: false,
				okCallback: function() {} ,
				cancelCallback:  function() {}
			},

			alertModificaSerie: {
				esercizioName: '',
				arrSerie: [],
				tipoSerie: 'car',
				visible: false,
				okCallback: function() {},
				cancelCallback: function() {}
			},

			waiting: {
				visible: true,
				message: ''
			},
			lockWaiting: false
        }
    },
    mounted() {

    },
    created() {

    },
    components: {
        WelcomePage,
        LoginPage,
		ChooseSubscriptionPage,
		RegistrationPage,
		RegistrationPasswordPage,
		MainPage,
		BuySubscriptionPage,
		RebornCategoryPage,
		RebornPlayerPage,

		SearchPage,
		SurveyPage,
		TrainerReviewPage,

		UserMainPage,
		UserMieiDatiPage,
		UserAbbonamentoPage,
		UserAbbonamentoCambiaPage,
		UserPagamentoPage,
		UserDocumentiPage,
		UserRicevutePage,
		UserGreenPassPage,
		UserCertMedicoPage,
		UserDisdettePage,		
		UserTerminiPage,
		UserBorsellinoPage,
		UserPresentaAmicoPage,

		UserCambioPasswordOverlay,
		ProductsRicaricheOverlay,
		ProductsRicaricheMedicheOverlay,

		PrenotaMainPage,
		PrenotaIngressoPage,
		PrenotaMiePage,

		TrainerMainPage,
		TrainerSchedeObiettivoPage,
		TrainerSchedaAttualePage,
		TrainerQuestionarioPage,

		GetReadyPage,
		EseguiPage,
		ManualeListaPage,

		RBODettagliPage,

		NewsPage,
		ListaCentriPage,
		DettagliCentroOverlay,

        TopToolbar,
		BottomToolbar,

		SceltaCentroOverlay,
		PasswordRecoveryOverlay,
		PasswordChangeOverlay,
		PreferitiOverlay,
		ExternalIframeOverlay
		
    },
    methods: {
        toggleAccordion(index) {
            if(typeof this.openAccordion[index] == 'undefined') {
	            console.log('open pageman accordion');
                Vue.set(this.openAccordion,  index, {
                    open: true
                } );
            } else {
                this.openAccordion[index].open = !this.openAccordion[index].open;
            }
        },

		resetPagesStack(_startPage) {
			this.navStack = [];
			this.navStack.push( _startPage );
			this.curPage = _startPage;
			this.lastPage = '';
			this.resetOverlayStack();
            this.prepareToolbar(_startPage);
		},

		savePagemanSteps(additionalInfo) {
			let savePageManData = {
				navStack : this.navStack,
				curPage: this.curPage,
				lastPage: this.lastPage,
				lastRotPage: this.lastRootPage,
				additionalInfo: additionalInfo
			}
			localStorage.setItem('pageman', JSON.stringify(savePageManData));
		},

		//reloads the pageman stack if there is one
		loadPageManSteps(additionalDestination) {
			this.lockWaiting = true;
			return new Promise( (resolve, reject) => {
				let pm = localStorage.getItem('pageman');
				if(pm != null) {
					localStorage.removeItem('pageman');
					console.log("Found pageManData");

					let loadPageManData = JSON.parse(pm);
					//automate the steps we got
					this.automateSteps(loadPageManData)
					.then( (res) => {
						resolve(res);
					})
					.catch( (err) => {
						reject(err);
					})

				} else {
					localStorage.removeItem('pageman');
					this.lockWaiting = false;
					reject(false);
				}
			});

		},

		automateSteps(pageManData) {
			return new Promise( (resolve, reject) => {

				if(!pageManData) {
					reject(false);
				} else {
					pageManData.navStack.forEach( (pageName) => {
						this.showPage( pageName );
					});
					//this.navStack = loadPageManData.navStack;

					pageManData.additionalInfo.vars.forEach( (v) => {
						Vue.set(this.$root[v.name], v.property, v.value );
					});

					let wait = 0;
					pageManData.additionalInfo.ops.forEach( (op, index) => {
						setTimeout( () => {
							this.$root[op.funcname]( op.param );
						}, index * 1000);
						wait = index;
					});

					setTimeout( () => {
						this.lockWaiting = false;
						this.hideWaiting();
						this.prepareToolbar(this.curPage);
						if( (typeof pageManData.additionalInfo.endmessage != 'undefined') && (pageManData.additionalInfo.endmessage != null)) {
							this.showAlert( pageManData.additionalInfo.endmessage.text, pageManData.additionalInfo.endmessage.btnok, pageManData.additionalInfo.endmessage.btncancel )
							.then( () => {
								resolve(true);
							})
							.catch( (() => { 
								resolve(true);
							}));
						} else {
							resolve(true);
						}
					}, (wait + 1) * 1500);

				}
			});
		},

		resetOverlayStack() {
			this.overStack = [];
			this.curOverlay = '';
			this.lastOverlay = '';
		},

        showPage(_page, _push = true) {
			console.log("curPage: " + this.curPage);
			console.log("_page:" + _page);
			console.log("Calls tack:");
			this.navStack.forEach( (pg) => {
				console.log(pg);
			});

			//if((this.curPage == _page) || (_page == this.navStack[ this.navStack.length - 1]))
			if(this.curPage == _page)
				return false;

			this.lastPage = this.curPage;
            this.curPage = _page;
			
			if(_push)
            	this.navStack.push(_page);

            this.prepareToolbar(_page);
        },

		showOverlay(_overlay) {
			if(this.curOverlay == _overlay)
				return false;

			this.tmpToolbarVisibility = this.$refs.topToolbar.visible;
			this.prepareToolbar(_overlay);
			this.overStack.push( _overlay );
			this.curOverlay = _overlay;
		},

		closeOverlay() {
			//we can have 0 overlay, pages can't
			console.log('Closing overlay');
            if(this.overStack.length > 0) {
                let lastOverlay = this.overStack.pop();
				if(this.overStack.length > 0) {
                	this.curOverlay = this.overStack[ this.overStack.length - 1];
	                this.prepareToolbar(this.curOverlay);
				} else {
					this.$refs.topToolbar.visible = this.tmpToolbarVisibility;
					this.curOverlay = '';
	                this.prepareToolbar(this.curPage);
				}
            }
		},

		closeAllOverlays() {
			this.overStack = [];
			this.curOverlay = '';			
			this.prepareToolbar(this.curPage);
		},

		showAlert(_message, _okBtnText, _cancelBtnText) {
			if(_okBtnText == undefined)
				_okBtnText = 'OK';

			if(_cancelBtnText == undefined)
				_cancelBtnText = '';
				
			this.tmpToolbarVisibility = this.$refs.topToolbar.visible;
			this.$refs.topToolbar.visible = false;
			this.tmpBottombarVisibility = this.$refs.bottomToolbar.visible;
			this.$refs.bottomToolbar.visible = false;
			this.$root.hideWaiting();
			return new Promise( (resolve,reject) => {
				this.alert.message = _message;
				this.alert.okText = _okBtnText;
				this.alert.cancelText = _cancelBtnText;
				this.alert.okCallback = resolve;
				this.alert.cancelCallback = reject;
				this.alert.visible = true;
			});
		},

		_alertOkPressed(_resolveEvent) {
			_resolveEvent.preventDefault();
			this.alert.visible = false;
			this.alert.okCallback('ok premuto');
			this.$refs.topToolbar.visible = this.tmpToolbarVisibility;
			this.$refs.bottomToolbar.visible = this.tmpBottombarVisibility;
		},

		_alertCancelPressed(_rejectEvent) {
			console.log(_rejectEvent);
			_rejectEvent.preventDefault();
			this.alert.visible = false;
			this.alert.cancelCallback('cancel premuto');
			this.$refs.topToolbar.visible = this.tmpToolbarVisibility;
			this.$refs.bottomToolbar.visible = this.tmpBottombarVisibility;
		},

		showAlertAllenamento(_message, _okBtnText, _cancelBtnText) {
			if(_okBtnText == undefined)
				_okBtnText = 'OK';

			if(_cancelBtnText == undefined)
				_cancelBtnText = '';
				
			this.tmpToolbarVisibility = this.$refs.topToolbar.visible;
			this.$refs.topToolbar.visible = false;
			this.tmpBottombarVisibility = this.$refs.bottomToolbar.visible;
			this.$refs.bottomToolbar.visible = false;
			return new Promise( (resolve,reject) => {
				this.alertAllenamento.message = _message;
				this.alertAllenamento.okText = _okBtnText;
				this.alertAllenamento.cancelText = _cancelBtnText;
				this.alertAllenamento.okCallback = resolve;
				this.alertAllenamento.cancelCallback = reject;
				this.alertAllenamento.visible = true;
			});
		},

		_alertAllenamentoOkPressed(_resolveEvent) {
			_resolveEvent.preventDefault();
			this.alertAllenamento.visible = false;
			this.alertAllenamento.okCallback('ok premuto');
			this.$refs.topToolbar.visible = this.tmpToolbarVisibility;
			this.$refs.bottomToolbar.visible = this.tmpBottombarVisibility;
		},

		_alertAllenamentoCancelPressed(_rejectEvent) {
			console.log(_rejectEvent);
			_rejectEvent.preventDefault();
			this.alertAllenamento.visible = false;
			this.alertAllenamento.cancelCallback('cancel premuto');
			this.$refs.topToolbar.visible = this.tmpToolbarVisibility;
			this.$refs.bottomToolbar.visible = this.tmpBottombarVisibility;
		},

		_alertAllenamentoClose(_closeEvent) {
			_closeEvent.preventDefault();
			this.alertAllenamento.visible = false;
			this.alertAllenamento.cancelCallback(null);
			this.$refs.topToolbar.visible = this.tmpToolbarVisibility;
			this.$refs.bottomToolbar.visible = this.tmpBottombarVisibility;
		},


		showAlertModificaSerie(_message, _okBtnText, _cancelBtnText) {
			if(_okBtnText == undefined)
				_okBtnText = 'OK';

			if(_cancelBtnText == undefined)
				_cancelBtnText = '';
				
			this.tmpToolbarVisibility = this.$refs.topToolbar.visible;
			this.$refs.topToolbar.visible = false;
			this.tmpBottombarVisibility = this.$refs.bottomToolbar.visible;
			this.$refs.bottomToolbar.visible = false;
			return new Promise( (resolve,reject) => {
				this.alertModificaSerie.okCallback = resolve;
				this.alertModificaSerie.cancelCallback = reject;
				this.alertModificaSerie.visible = true;
			});
		},

		_alertModificaSerieOkPressed(_resolveEvent) {
			_resolveEvent.preventDefault();
			this.alertModificaSerie.visible = false;
			this.alertModificaSerie.okCallback( this.alertModificaSerie.arrSerie );
			this.$refs.topToolbar.visible = this.tmpToolbarVisibility;
			this.$refs.bottomToolbar.visible = this.tmpBottombarVisibility;
		},

		_alertModificaSerieCancelPressed(_rejectEvent) {
			console.log(_rejectEvent);
			_rejectEvent.preventDefault();
			this.alertModificaSerie.visible = false;
			this.alertModificaSerie.cancelCallback( null );
			this.$refs.topToolbar.visible = this.tmpToolbarVisibility;
			this.$refs.bottomToolbar.visible = this.tmpBottombarVisibility;
		},

		_alertModificaSerieClose(_closeEvent) {
			_closeEvent.preventDefault();
			this.alertModificaSerie.visible = false;
			this.alertModificaSerie.cancelCallback(null);
			this.$refs.topToolbar.visible = this.tmpToolbarVisibility;
			this.$refs.bottomToolbar.visible = this.tmpBottombarVisibility;
		},


		_showAlertSceltaCashback() {
			this.tmpToolbarVisibility = this.$refs.topToolbar.visible;
			this.$refs.topToolbar.visible = false;
			this.tmpBottombarVisibility = this.$refs.bottomToolbar.visible;
			this.$refs.bottomToolbar.visible = false;
			this.alertSceltaCashback.visible = true;
		},

		_alertSceltaCashbackPagaRataPressed(_resolveEvent) {
			_resolveEvent.preventDefault();
			this.$root.paymentsRicaricaBorsellinoEPagaRata();
			this.alertSceltaCashback.visible = false;
			this.alertSceltaCashback.okCallback('ok premuto');
			this.$refs.topToolbar.visible = this.tmpToolbarVisibility;
		},

		_alertSceltaCashbackRicaricaPressed(_rejectEvent) {
			console.log(_rejectEvent);
			_rejectEvent.preventDefault();
			this.$root.paymentsRicaricaBorsellinoDaPrize();
			this.alertSceltaCashback.visible = false;
			this.$refs.topToolbar.visible = this.tmpToolbarVisibility;
		},

		_alertSceltaCashbackClose(_closeEvent) {
			_closeEvent.preventDefault();
			this.alertSceltaCashback.visible = false;
			this.alertSceltaCashback.cancelCallback('nessuno premuto');
			this.$refs.topToolbar.visible = this.tmpToolbarVisibility;
			this.$refs.bottomToolbar.visible = this.tmpBottombarVisibility;
		},

		buildStack(_stackArray) {
			this.resetPagesStack = _stackArray;
			this.showPage( _stackArray[ _stackArray.length - 1 ], false);
		},

		prepareToolbar(_pageOrOverlay) {

			this.$root.uiScrollToTop();

			//set defaults
			this.$refs.topToolbar.elements.closeButton = false;
			this.$refs.topToolbar.elements.backButton = false;
			this.$refs.topToolbar.elements.castButton = false;
			this.$refs.topToolbar.elements.savedButton = true;
			this.$refs.topToolbar.elements.userButton = true;
			this.$refs.topToolbar.elements.prenotaButton = true;
			this.$refs.topToolbar.darkMode = true;

			if(this.navStack.length > 1) {
				this.$refs.topToolbar.elements.backButton = true;
			}

			this.$refs.bottomToolbar.visible = true;

            switch(_pageOrOverlay) {
				case 'mainpage':
                    this.$refs.topToolbar.elements.solidBg = true;
					this.$refs.topToolbar.visible = true;
                    this.$refs.topToolbar.elements.backButton = false;
					this.$root.uiSetBottomIcon('main');
					this.lastRootPage = 'mainpage';
					break;
				case 'registrationpage':
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.elements.backButton = true;
					this.$refs.topToolbar.elements.savedButton = false;
					this.$refs.topToolbar.elements.userButton = false;
					this.$refs.topToolbar.elements.prenotaButton = false;
					this.$refs.bottomToolbar.visible = false;
                    break;
				case 'registrationpasswordpage':
					this.resetOverlayStack();
					this.$refs.topToolbar.visible = false;
					this.$refs.topToolbar.elements.backButton = false;
					this.$refs.topToolbar.elements.savedButton = false;
					this.$refs.topToolbar.elements.userButton = false;
					this.$refs.topToolbar.elements.prenotaButton = false;
					this.$refs.bottomToolbar.visible = false;
                    break;
                case 'loginpage':
                    this.$refs.topToolbar.elements.solidBg = true;
					this.$refs.topToolbar.visible = true;
                    this.$refs.topToolbar.elements.backButton = true;
					this.$refs.topToolbar.elements.savedButton = false;
					this.$refs.topToolbar.elements.userButton = false;
					this.$refs.topToolbar.elements.prenotaButton = false;
					this.$refs.bottomToolbar.visible = false;
                    break;
                case 'welcomepage':
                    this.$refs.topToolbar.elements.solidBg = true;
                    this.$refs.topToolbar.elements.backButton = false;
					this.$refs.topToolbar.elements.savedButton = false;
					this.$refs.topToolbar.elements.userButton = false;
					this.$refs.topToolbar.elements.prenotaButton = false;
					this.$refs.bottomToolbar.visible = false;
                    break;
				case 'scelta-centro':
					this.$refs.topToolbar.visible = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'password-recovery':
                    this.$refs.topToolbar.elements.solidBg = false;
					this.$refs.topToolbar.visible = false;
					this.$refs.topToolbar.elements.savedButton = false;
					this.$refs.topToolbar.elements.userButton = false;
					this.$refs.topToolbar.elements.prenotaButton = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'choosesubscription':
				case 'buysubscription':
                    this.$refs.topToolbar.elements.solidBg = true;
                    this.$refs.topToolbar.elements.backButton = true;
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.elements.savedButton = false;
					this.$refs.topToolbar.elements.userButton = false;
					this.$refs.topToolbar.elements.prenotaButton = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'password-change':
					this.$refs.topToolbar.visible = false;
					this.$refs.bottomToolbar.visible = false;
					this.$refs.topToolbar.elements.savedButton = false;
					this.$refs.topToolbar.elements.userButton = false;
					this.$refs.topToolbar.elements.prenotaButton = false;
					break;
				case 'reborncategorypage':
                    this.$refs.topToolbar.elements.solidBg = true;
                    this.$refs.topToolbar.elements.backButton = false;
					this.$refs.topToolbar.elements.castButton = false;
					this.$refs.topToolbar.visible = true;
					this.$root.uiSetBottomIcon('reborn');
					this.lastRootPage = 'reborncategorypage';
					break;
				case 'rebornplayerpage':
                    this.$refs.topToolbar.elements.solidBg = false;
                    this.$refs.topToolbar.elements.backButton = true;
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.elements.savedButton = false;
					this.$refs.topToolbar.elements.userButton = false;
					this.$refs.topToolbar.elements.castButton = true;
					this.$refs.topToolbar.elements.prenotaButton = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'preferitioverlay':
					this.$refs.topToolbar.visible = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'productsricaricheoverlay':
				case 'productsricarichemedicheoverlay':
					this.$refs.topToolbar.visible = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'usercambiopassword':
					this.$refs.topToolbar.visible = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'usermainpage':
                    this.$refs.topToolbar.elements.solidBg = false;
                    this.$refs.topToolbar.elements.backButton = false;
                    this.$refs.topToolbar.elements.closeButton = true;
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.darkMode = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'usermieidatipage':
				case 'userterminipage':
				case 'userpresentaamicopage':
				case 'userabbonamentopage':
                    this.$refs.topToolbar.elements.solidBg = false;
                    this.$refs.topToolbar.elements.backButton = true;
                    this.$refs.topToolbar.elements.closeButton = true;
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.darkMode = false;
					this.$refs.bottomToolbar.visible = false;
				case 'userdisdettepage':
				case 'userborsellinopage':
                    this.$refs.topToolbar.elements.solidBg = false;
                    this.$refs.topToolbar.elements.backButton = true;
                    this.$refs.topToolbar.elements.closeButton = true;
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.darkMode = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'usergreenpasspage':
                    this.$refs.topToolbar.elements.solidBg = false;
                    this.$refs.topToolbar.elements.backButton = true;
					this.$refs.topToolbar.elements.savedButton = false;
					this.$refs.topToolbar.elements.prenotaButton = false;
					this.$refs.topToolbar.elements.userButton = false;
					this.$refs.topToolbar.elements.castButton = false;
                    this.$refs.topToolbar.elements.closeButton = false;
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.darkMode = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'usercertmedicopage':
                    this.$refs.topToolbar.elements.solidBg = false;
                    this.$refs.topToolbar.elements.backButton = false;
					this.$refs.topToolbar.elements.savedButton = false;
					this.$refs.topToolbar.elements.prenotaButton = false;
					this.$refs.topToolbar.elements.userButton = false;
					this.$refs.topToolbar.elements.castButton = false;
                    this.$refs.topToolbar.elements.closeButton = true;
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.darkMode = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'userabbonamentocambiapage':
                    this.$refs.topToolbar.elements.solidBg = false;
                    this.$refs.topToolbar.elements.backButton = true;
                    this.$refs.topToolbar.elements.closeButton = true;
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.darkMode = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'prenotamainpage':
                    this.$refs.topToolbar.elements.solidBg = false;
                    this.$refs.topToolbar.elements.backButton = false;
                    this.$refs.topToolbar.elements.closeButton = true;
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.darkMode = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'prenotaingressopage':
                    this.$refs.topToolbar.elements.solidBg = false;
                    this.$refs.topToolbar.elements.backButton = true;
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.darkMode = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'prenotamiepage':
                    this.$refs.topToolbar.elements.solidBg = false;
                    this.$refs.topToolbar.elements.backButton = true;
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.darkMode = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'userpagamentopage':
                    this.$refs.topToolbar.elements.solidBg = false;
                    this.$refs.topToolbar.elements.backButton = true;
                    this.$refs.topToolbar.elements.closeButton = true;
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.darkMode = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'userdocumentipage':
                    this.$refs.topToolbar.elements.solidBg = false;
                    this.$refs.topToolbar.elements.backButton = true;
                    this.$refs.topToolbar.elements.closeButton = true;
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.darkMode = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'userricevutepage':
                    this.$refs.topToolbar.elements.solidBg = true;
                    this.$refs.topToolbar.elements.backButton = true;
                    this.$refs.topToolbar.elements.closeButton = true;
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.darkMode = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'externaliframeoverlay':
					this.$refs.topToolbar.visible = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				case 'searchpage':
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.elements.backButton = false;
					this.$root.uiSetBottomIcon('cerca');
					this.lastRootPage = 'searchpage';
					break;
				case 'trainerreviewpage':
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.elements.backButton = false;
					this.$refs.topToolbar.elements.closeButton = true;
					this.$refs.topToolbar.elements.savedButton = false;
					this.$refs.topToolbar.elements.userButton = false;
					this.$refs.topToolbar.elements.prenotaButton = false;
					this.$refs.topToolbar.elements.solidBg = false;
					this.$refs.topToolbar.darkMode = false;
					this.$refs.bottomToolbar.visible = false;
					this.$root.uiSetBottomIcon('main');
					this.lastRootPage = 'mainpage';
					break;
				case 'surveypage':
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.elements.backButton = false;
					this.$refs.topToolbar.elements.closeButton = true;
					this.$refs.topToolbar.elements.savedButton = false;
					this.$refs.topToolbar.elements.userButton = false;
					this.$refs.topToolbar.elements.prenotaButton = false;
					this.$refs.topToolbar.elements.solidBg = false;
					this.$refs.topToolbar.darkMode = false;
					this.$refs.bottomToolbar.visible = false;
					this.$root.uiSetBottomIcon('trainer');
					this.lastRootPage = 'trainermainpage';
					break;
				case 'trainermainpage':
                    this.$refs.topToolbar.elements.solidBg = true;
					this.$refs.topToolbar.visible = true;
					this.$root.uiSetBottomIcon('trainer');
					this.lastRootPage = 'trainermainpage';
					break;
				case 'rbodettaglipage':
				case 'trainerschedeobiettivopage':
                    this.$refs.topToolbar.elements.solidBg = true;
					this.$refs.topToolbar.visible = true;
					this.$root.uiSetBottomIcon('trainer');
					this.lastRootPage = 'main';
					break;
				case 'trainerquestionariopage':
					this.$refs.topToolbar.visible = true;
                    this.$refs.topToolbar.elements.solidBg = true;
					this.$refs.bottomToolbar.visible = true;
					this.$root.uiSetBottomIcon('trainer');
					break;
				case 'trainerschedaattualepage':
                    this.$refs.topToolbar.elements.solidBg = true;
					this.$refs.topToolbar.visible = true;
					this.$root.uiSetBottomIcon('trainer');
					this.lastRootPage = 'trainerschedaattualepage';
					if(this.$root.evofit.sedutaPlayer != null) {
						this.$root.evofitFineAllenamento();
					}
					break;
				case 'manualelistapage':
                    this.$refs.topToolbar.elements.solidBg = true;
                    this.$refs.topToolbar.elements.backButton = true;
					this.$refs.topToolbar.visible = true;
					break;
				case 'getreadypage':
				case 'eseguipage':
					this.$refs.topToolbar.elements.closeButton = true;
                    this.$refs.topToolbar.elements.solidBg = false;
                    this.$refs.topToolbar.elements.backButton = false;
					this.$refs.topToolbar.elements.savedButton = false;
					this.$refs.topToolbar.elements.userButton = false;
					this.$refs.topToolbar.elements.prenotaButton = false;
					this.$refs.topToolbar.visible = true;
					this.$refs.topToolbar.darkMode = false;
					this.$refs.bottomToolbar.visible = false;
					break;		
				case 'newspage':
					this.$refs.topToolbar.elements.savedButton = false;
                    this.$refs.topToolbar.elements.backButton = false;
					this.$refs.topToolbar.elements.savedButton = true;
					this.$refs.topToolbar.elements.userButton = true;
					this.$refs.topToolbar.elements.prenotaButton = true;
					this.$root.uiSetBottomIcon('info');
					this.lastRootPage = 'newspage';
					break;
				case 'listacentripage':
					this.$refs.topToolbar.elements.savedButton = true;
					this.$refs.topToolbar.elements.userButton = true;
					this.$refs.topToolbar.elements.prenotaButton = true;
                    this.$refs.topToolbar.elements.backButton = false;
					this.lastRootPage = 'listacentripage';
					break;
				case 'dettaglicentrooverlay':
					this.$refs.topToolbar.visible = false;
					this.$refs.bottomToolbar.visible = false;
					break;
				default:
					console.error('PageManager CHANGE PAGE not managed for ' + _pageOrOverlay);
            }
        },

		resetToLastRoot() {
			if(this.lastRootPage != '') {
				this.resetPagesStack( this.lastRootPage );
			} else {
				this.pageBack();
			}
		},

		_handlePageBack(_curPage, _lastPage) {
			if(_lastPage == 'rebornplayerpage') {
				this.$root.rebornDestroyVideo();
			}
		},

        pageBack() {
			console.log('Pageback:' + this.$root.isApple);
            if(this.navStack.length > 1) {
                this.lastPage = this.navStack.pop();
                this.curPage = this.navStack[ this.navStack.length - 1];
				switch(this.lastPage) {
					case 'trainerschedeobiettivopage':
						this.resetPagesStack('trainermainpage');
						break;
					case 'manualelistapage':
						this.showAlert('Sicuro di voler chiudere la seduta?','SI','NO')
						.then( (ok) => {
							this._handlePageBack(this.curPage, this.lastPage);
							this.prepareToolbar(this.curPage);
						})
						.catch( (no) => {
							this.navStack.push( this.lastPage);
							this.curPage = 'manualelistapage';
						});
						break;
					default:
						this._handlePageBack(this.curPage, this.lastPage);
						this.prepareToolbar(this.curPage);
				}
            }
        },

		showWaiting(_message) {
			if(_message == undefined) {
				_message = '';
			} else {
				this.waiting.message = _message.toUpperCase();
			}
			this.tmpToolbarVisibility = this.$refs.topToolbar.visible;
			this.tmpBottombarVisibility = this.$refs.bottomToolbar.visible;

			this.$refs.topToolbar.visible = false;
			this.waiting.visible = true;
		},

		hideWaiting() {		
			//waiting is locked by someone who needs it visible
			if(this.lockWaiting)
				return;

			setTimeout( () => {
				this.waiting.visible = false;
				this.prepareToolbar(this.curPage);
			}, 1000);
		},

		waitingClick(event) {
			event.preventDefault();
		}
    }
}
</script>

<style>
#pagemanager {
	display:block;
	position:relative;
	padding:0px;
	margin:0px;
	width:100%;
	height: 100vh;
	box-sizing: border-box;
	overflow:hidden;

	/* white-space: nowrap; */

	/* transform-origin: top left;
	-moz-transform-origin: top left;
	-ms-transform-origin: top left;
	-o-transform-origin: top left; */

	background-color:#000;
}

.ls-page {
	position:absolute;
	top:0px;
	left:0px; 
	bottom:0px;
	right:0px;
	display:block;
	width:100%;
	height:100vh;
	margin:0px;
	padding:0px;  
    padding-top:70px;
	padding-bottom:100px;
	z-index:100;

	white-space:pre-wrap;

	background-color: #000;

	overflow-y:auto;
	overflow-x:hidden;

	animation-duration: 0.3s;
	animation-direction: normal;
	animation-iteration-count: 1;
	animation-timing-function: linear;
	animation-fill-mode: forwards;

	user-select:none;
}


@media all and (min-width: 1200px) {
	.ls-page {
		padding-left:300px;
		padding-right:300px;
	}
}

.ls-page-white {
	background-color: #fff !important;
}

.ls-page > .container-fluid {
	position:relative;
	display:block;
}

.ls-page-visible {
	z-index:100 !important;
	display:block;
	animation-name: pageEnter; 
}

.ls-page-hidden {
	z-index:0 !important;
	display:none;
	animation-name: pageExit;
}

.ls-page-visible-top {
	z-index:100 !important;
	display:block;
	animation-name: pageEnterTop; 
}

.ls-page-hidden-top {
	z-index:0 !important;
	display:none;
	animation-name: pageExitTop;
}

@keyframes pageExit {
	from {
		/* TEMPORARILY DISABLED ANIMATIONS left:0px; */
		left:100%;
	}
	99% {
		left:100%;
	}
}

@keyframes pageEnter {
	from {
		/* TEMPORARILY DISABLED ANIMATIONS left:100%; */
		left:0px;
	}

	to {
		left:0px;
	}
}

@keyframes pageExitTop {
	from {
		transform: translateY(0%);		
		opacity:1;
	}
	40% {
		transform: translateY(15%);
		opacity:1;
	}
	to {
		transform: translateY(-100%);
		opacity:0;
	}
}

@keyframes pageEnterTop {
	from {
		transform: translateY(-100%);
		opacity:0;
	}
	to {
		transform: translateY(0%);
		opacity:1;
	}
}


.ls-overlay {
	position:fixed;
	top:0px;
	left:0px;
	bottom:0px;
	right:0px;
	display:block;
	width:100%;
	height:100vh;
	margin:0px;
	padding:0px;  

	opacity:0;
	z-index:200;

	overflow-y:auto;
	overflow-x:hidden;

	animation-duration: 0.3s;
	animation-direction: normal;
	animation-iteration-count: 1;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
}

@media all and (min-width: 1200px) {
	.ls-overlay {
		padding-left:300px;
		padding-right:300px;
	}
}


.ls-overlay-dark {
	background-color:#000;
}

.ls-overlay-light {
	background-color: #fff;
}

.ls-overlay-visible {
	animation-name: overlayEnter; 
}

.ls-overlay-hidden {
	animation-name: overlayExit;
}

@keyframes overlayExit {
	from {
		transform: translateY(0%);		
		opacity:1;
	}
	40% {
		transform: translateY(15%);
		opacity:1;
	}
	to {
		transform: translateY(-100%);
		opacity:0;
	}
}

@keyframes overlayEnter {
	from {
		transform: translateY(-100%);
		opacity:0;
	}
	to {
		transform: translateY(0%);
		opacity:1;
	}
}


.ls-modal {
	display:block;
	position:relative;
	width:100%;
	height: 400px;
	padding:0px;
	margin:0px;
	margin-top:50vh;
	transform: translateY(-200px);
	background-color: #AC8A65;
	color: #ffffff;
}

.ls-alert-overlay {
	background-color: rgba(0,0,0,0.5);
	backdrop-filter: blur(3px);
	-webkit-backdrop-filter: blur(3px);
	z-index:300;
}

.ls-alert {
	display:block;
	position:relative;
	width:100%;
	min-height: 200px;
	padding:0px;
	padding-bottom: 50px;
	margin:0px;
	margin-top:50vh;
	font-size:1.1rem;
	transform: translateY(-50%);
	background-color: #AC8A65;
	color: #ffffff;
}

.ls-alert-buttons {
	position:absolute;
	display:block;
	bottom:5px;
	left:0px;
	width:100%;
}

.alert-btn-left {
	display: inline-block;
	width:50%;
	padding:10px;	
}

.alert-btn-right {
	display: inline-block;
	width:50%;
	padding:10px;
}

.alert-btn-right:hover {
	color: #fff !important;
}


.ls-spinner-overlay {
	background-color: rgba(0,0,0,0.5);
	backdrop-filter: blur(3px);
	-webkit-backdrop-filter: blur(3px);
	text-align: center;
	z-index:4001;
}

.ls-spinner {
	display:block;
	position:relative;
	width:100%;
	min-height: 200px;
	padding:0px;
	margin:0px;
	margin-top:50vh;
	font-size:1.1rem;
	transform: translateY(-50%);
	background-color: transparent;
	color: #ffffff;
}


/********************************************************************************************************
SPINNER
*********************************************************************************************************/

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  

</style>