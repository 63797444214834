<template>
    <div>
        <div class="container-fluid">
            <div class="row mt-5">
                <div class="col-12 my-5 text-center">
                    <p class="titolo-piccolo orange-text-black text-center mt-4 mb-2 pb-0" style="text-transform:uppercase;">METODO DI PAGAMENTO</p>
                    <p class="testo-generico orange-text-black text-center" >In questa sezione puoi gestire la carta di credito utilizzata per effettuare i pagamenti di abbonamenti e servizi.</p>
                </div>            
            </div>
            <div class="row text-center">
                <div v-if="($root.payments.userCards != null) && ($root.payments.userCards.Sorgente)" class="col-12">
                    <p class="testo-generico orange-text-black"><strong>Intestazione</strong></p>
                    <p class="testo-generico orange-text-black mb-0">{{ $root.user.name }} {{ $root.user.surname }}</p>
                    <p class="testo-generico orange-text-black mt-0">{{ $root.payments.userCards.Email }}</p>
                    <p class="testo-generico orange-text-black mt-5"><strong>Carta Attiva</strong></p>
                    <p class="testo-generico orange-text-black">{{ $root.payments.userCards.Sorgente }}</p>
                    <p v-if="$root.user.IBAN != null" class="testo-generico orange-text-black mt-5"><strong>IBAN</strong></p>
                    <p v-if="$root.user.IBAN != null" class="testo-generico orange-text-black">{{ $root.user.IBAN }}</p>
                </div>
                <div v-else class="col-12">
                    <p class="testo-generico orange-text-black mt-3"><strong>Carta Attiva</strong></p>
                    <p class="testo-generico orange-text-black">NESSUNA</p>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-12">
                    <button v-if="($root.payments.userCards != null) && ($root.payments.userCards.Sorgente)" v-on:click.stop="$root.paymentsAddNewCard" class="btn btn-block btn-orange testo-bottoni btn-outline-white orange-text-black">SOSTITUISCI CARTA</button>
                    <button v-else v-on:click.stop="$root.paymentsAddNewCard" class="btn btn-block btn-orange testo-bottoni btn-outline-white orange-text-black">INSERISCI CARTA</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import config from '../../../config/config_local.js'

export default {
    name: 'UserPagamento',
    data () {
        return {
        }
    },
    methods: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>