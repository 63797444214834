'use strict'

class Validator {

    isLettersOnly(str) {
        return /^[A-Za-z][A-Za-z\s]*$/.test(str);
    }

    isNumbersOnly(str) {
        return /^[0-9]+$/.test(str);
    }

    isNumbersAndDigitsOnly(str) {
        return /^[A-Za-z0-9]+$/.test(str);
    }

    isValidPhone(phone) {
        if(phone.length < 8) {
            return false;
        }

        if(phone.substr(0,1) != '3'){
            return false;
        }

        return this.isNumbersOnly(phone);
    }

    isValidEmail(email) {
        if(email.length < 6) {
            return false;
        }

        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
        {
          return true;
        }
          return false;
    }

    isStrongPassword(pass) {
        //check string length
        if(pass.length < 10) {
            return false;
        }

        //check if the string contains numbers
        if(/\d/g.test(pass) == false) {
            return false;
        }

        if(/[a-zA-Z]/g.test(pass) == false) {
            return false;
        }

        /* SYMBOLS
        if(/[!-\/:-@[-`{-~]/gm.test(pass) == false) {
            return false;
        }
        */

        return true;
    }

    isCodiceFiscale(cfins)
	{
		var cf = cfins.toUpperCase();
		var cfReg = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
		if (!cfReg.test(cf))
		{
			return false;
		}
	  	
		var set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		var set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
		var setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		var setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
		var s = 0;
        var i = 0;
		for( i = 1; i <= 13; i += 2 )
		  s += setpari.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
		for( i = 0; i <= 14; i += 2 )
		  s += setdisp.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
		if ( s%26 != cf.charCodeAt(15)-'A'.charCodeAt(0) )
		  return false;
		return true;
	}
}

module.exports = Validator;