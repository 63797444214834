'use strict'

// See:
// https://github.com/kim-company/videojs-chromecast/blob/master/src/videojs.chromecast-component.coffee

/**
 * PlayerHandler
 *
 * This is a handler through which the application will interact
 * with both the RemotePlayer and LocalPlayer. Combining these two into
 * one interface is one approach to the dual-player nature of a Cast
 * Chrome application. Otherwise, the state of the RemotePlayer can be
 * queried at any time to decide whether to interact with the local
 * or remote players.
 *
 * To set the player used, implement the following methods for a target object
 * and call setTarget(target).
 *
 * Methods to implement:
 *  - play()
 *  - pause()
 *  - stop()
 *  - seekTo(time)
 *  - load(mediaIndex)
 *  - getMediaDuration()
 *  - getCurrentMediaTime()
 *  - setVolume(volumeSliderPosition)
 *  - mute()
 *  - unMute()
 *  - isMuted()
 *  - updateDisplayMessage()
 */
 var PlayerHandler = function(castPlayer) {
    this.target = {};

    this.setTarget= function(target) {
        this.target = target;
    };

    this.play = function() {
        console.log('play');
        if (castPlayer.playerState !== PLAYER_STATE.PLAYING &&
            castPlayer.playerState !== PLAYER_STATE.PAUSED &&
            castPlayer.playerState !== PLAYER_STATE.LOADED) {
            this.load(castPlayer.currentMedia);
            return;
        }

        this.target.play();
        castPlayer.playerState = PLAYER_STATE.PLAYING;
        document.getElementById(castPlayer.DOMElementsIds.play).style.display = 'none';
        document.getElementById(castPlayer.DOMElementsIds.pause).style.display = 'block';
        this.updateDisplayMessage();
    };

    this.pause = function() {
        console.log('pause');
        if (castPlayer.playerState !== PLAYER_STATE.PLAYING) {
            return;
        }

        this.target.pause();
        castPlayer.playerState = PLAYER_STATE.PAUSED;
        document.getElementById(castPlayer.DOMElementsIds.play).style.display = 'block';
        document.getElementById(castPlayer.DOMElementsIds.pause).style.display = 'none';
        this.updateDisplayMessage();
    };

    this.stop = function() {
        if(typeof this.target.stop != 'undefined')
            this.target.stop();
        if(typeof castPlayer.playerState != 'undefined')
            castPlayer.playerState = PLAYER_STATE.STOPPED;

        this.updateDisplayMessage();
    };

    this.load = function(media) {
        if(this.target.type != 'local') {
            let overlay = document.getElementById(castPlayer.DOMElementsIds.overlay);
            overlay.style.display = 'block';    
        }

        castPlayer.playerState = PLAYER_STATE.LOADING;

        //document.getElementById('media_title').innerHTML = castPlayer.mediaContents[castPlayer.currentMediaIndex]['title'];
        //document.getElementById('media_subtitle').innerHTML = castPlayer.mediaContents[castPlayer.currentMediaIndex]['subtitle'];
        //document.getElementById('media_desc').innerHTML = castPlayer.mediaContents[castPlayer.currentMediaIndex]['description'];

        this.target.load(media);
        this.updateDisplayMessage();
    };

    this.loaded = function() {
        castPlayer.currentMediaDuration = this.getMediaDuration();
        castPlayer._updateMediaDurationText();
        castPlayer.playerState = PLAYER_STATE.LOADED;
        if (castPlayer.currentMediaTime > 0) {
            this.seekTo(castPlayer.currentMediaTime);
        }
        this.play();
        castPlayer._startProgressTimer();
        this.updateDisplayMessage();
        
        if(this.target.type == 'local') {
            let overlay = document.getElementById(castPlayer.DOMElementsIds.overlay);
            overlay.style.display = 'none';        
        }
    };

    this.getCurrentMediaTime = function() {
        return this.target.getCurrentMediaTime();
    };

    this.getMediaDuration = function() {
        return this.target.getMediaDuration();
    };

    this.updateDisplayMessage = function () {
        if(typeof this.target.updateDisplayMessage != 'undefined')
            this.target.updateDisplayMessage();
    };

    this.setVolume = function(volumeSliderPosition) {
        this.target.setVolume(volumeSliderPosition);
    };

    this.mute = function() {
        this.target.mute();
        let audioon = document.getElementById(castPlayer.DOMElementsIds.audio_on);
        if(audioon != null) {
            audioon.style.display = 'none';
        }
        let audiooff = document.getElementById(castPlayer.DOMElementsIds.audio_off);
        if(audiooff != null) {
            audiooff.style.display = 'block';
        }
    };

    this.unMute = function() {
        this.target.unMute();
        let audioon = document.getElementById(castPlayer.DOMElementsIds.audio_on);
        if(audioon != null) {
            audioon.style.display = 'block';
        }
        let audiooff = document.getElementById(castPlayer.DOMElementsIds.audio_off);
        if(audiooff != null) {
            audiooff.style.display = 'none';
        }
    };

    this.isMuted = function() {
        return this.target.isMuted();
    };

    this.seekTo = function(time) {
        this.target.seekTo(time);
        this.updateDisplayMessage();
    };
};


var PLAYER_STATE = {
    IDLE: 'IDLE',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    PLAYING: 'PLAYING',
    PAUSED: 'PAUSED',
    STOPPED: 'STOPPED',
    ERROR: 'ERROR'
};

class CastApiClass {
    constructor() {

    	this.session = null;

    	this.version = 'full';

        this.playerHandler = new PlayerHandler(this);

        this.playerState = PLAYER_STATE.IDLE;

        /* Cast player variables */
        /** @type {cast.framework.RemotePlayer} */
        this.remotePlayer = null;
        /** @type {cast.framework.RemotePlayerController} */
        this.remotePlayerController = null;

        /** @type {number} A number for current media time */
        this.currentMediaTime = 0;
        /** @type {number} A number for current media duration */
        this.currentMediaDuration = -1;
        /** @type {?number} A timer for tracking progress of media */
        this.timer = null;   

        /** @type {function()} */
        this.incrementMediaTimeHandler = this._incrementMediaTime.bind(this);

        this.remotePlayer = null;
        this.remotePlayerController = null;

        this.uiInitialized = false;
        this.waitingRetries = 0;

        return this;
    }

    waitForCastAPI() {
        var loadCastInterval = setInterval(() => {
            if(typeof chrome == 'undefined') {
                clearInterval(loadCastInterval);
                this.version = 'light';
                console.log('castApi: full chrome.cast api is not available. Using mobile version.');
            } else {
                if ( chrome.cast && chrome.cast.isAvailable) {
                    console.log('castAPI: Cast API loaded.');
                    clearInterval(loadCastInterval);
                    //this.initializeCastApi();
                    this._initializeCastApi();
                } else {
                    this.waitingRetries++;
                    console.log('castAPI: (' + this.waitingRetries + ') Waiting...');
                    if(this.waitingRetries > 20) {
                        clearInterval(loadCastInterval);
                        this.version = 'light';
                        console.log('castApi: full cast api is not available. Using mobile version.');
                    }
                }
            }
        }, 1000);    
    }

    _initializeCastApi() {

        var options = {};

        // Set the receiver application ID to your own (created in the
        // Google Cast Developer Console), or optionally
        // use the chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID
        options.receiverApplicationId = chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID;
    
        // Auto join policy can be one of the following three:
        // ORIGIN_SCOPED - Auto connect from same appId and page origin
        // TAB_AND_ORIGIN_SCOPED - Auto connect from same appId, page origin, and tab
        // PAGE_SCOPED - No auto connect
        options.autoJoinPolicy = chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED;
    
    	//cordove doesn't support framework advanced features
    	if( (typeof cast != 'undefined') && (typeof cast.framework != 'undefined') ) {
    		//Initialize full featured castApi
	        cast.framework.CastContext.getInstance().setOptions(options);
	    
	        this.remotePlayer = new cast.framework.RemotePlayer();
	        this.remotePlayerController = new cast.framework.RemotePlayerController(this.remotePlayer);
	        this.remotePlayerController.addEventListener(
	            cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
	            this._switchPlayer.bind(this)
	        );    		
            console.log('castApi: Full cast API initialized.');
    	} else {
    		this.version = 'light';
    		console.log('castApi: full cast api is not available. Using mobile version.');
    	}
    
    }

    _initializeMobileCastApi(videoUrl) {
        if(typeof(chrome) != 'undefined') {
    		//initialize mobile cast api
	        var appId = chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID;
	        var autoJoinPolicy = chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED;
	        var sessionRequest = new chrome.cast.SessionRequest(appId);
	        var defaultActionPolicy = chrome.cast.DefaultActionPolicy.CREATE_SESSION;
	        var apiConfig = new chrome.cast.ApiConfig(sessionRequest, function sessionListener (session) {
	                // The session listener is only called under the following conditions:
	                // * will be called shortly chrome.cast.initialize is run
	                // * if the device is already connected to a cast session
	                // Basically, this is what allows you to re-use the same cast session 
	                // across different pages and after app restarts
                    console.log(session);
	            }, function receiverListener (receiverAvailable) {
	                // receiverAvailable is a boolean.
	                // True = at least one chromecast device is available
	                // False = No chromecast devices available
	                // You can use this to determine if you want to show your chromecast icon
                    console.log(receiverAvailable);
	            }, autoJoinPolicy, defaultActionPolicy);

	        // initialize chromecast, this must be done before using other chromecast features
	        var me = this;
	        chrome.cast.initialize(apiConfig, function () {
	            // Initialize complete
	            // Let's start casting
	            me._requestMobileSession(videoUrl);
	        }, function (err) {
	            console.log('castApi: Unable to initialize mobiel cast API.');
	        });    		
        } 
    }

	_requestMobileSession(videoUrl) {
        // This will open a native dialog that will let 
        // the user choose a chromecast to connect to
        // (Or will let you disconnect if you are already connected)
        var me = this;
        chrome.cast.requestSession(function (session) {
            // Got a session!
            me.session = session;
            me._startMobileMedia(videoUrl);
        }, function (err) {
            // Failed, or if err is cancel, the dialog closed
            console.log('mobileCastApi: cannot retrieve session');
            me.session = null;
        });
	}

	_startMobileMedia(videoUrl) {


        var mediaInfo = new chrome.cast.media.MediaInfo(videoUrl, 'video/mp4');
        mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();
        mediaInfo.metadata.metadataType = chrome.cast.media.MetadataType.GENERIC;
        mediaInfo.metadata.title = 'Orange Video';

		var me = this;
        this.session.loadMedia(new chrome.cast.media.LoadRequest(mediaInfo), function (media) {
            // You should see the video playing now!
            // Got media!
            var _media = media;
            me._setupLightPlayer();
            me.playerHandler.loaded();
        }, function (err) {
            // Failed (check that the video works in your browser)
            console.log('mobileCastApi: cannot load video');
        });		

	}

    _switchPlayer() {
        console.log('Switched player');
        this._stopProgressTimer();
        this._resetVolumeSlider();
        this.playerHandler.stop();
        this.playerState = PLAYER_STATE.IDLE;
        if (cast && cast.framework) {
            if (this.remotePlayer.isConnected) {
                this._setupRemotePlayer();
                return;
            }
        }
        this._setupLocalPlayer();    
    }

    _setupLocalPlayer( ) {
        var videoElement = document.getElementById( this.DOMElementsIds.video_element );
        videoElement.addEventListener(
            'loadeddata', this._onMediaLoadedLocally.bind(this));
    
        // This object will implement PlayerHandler callbacks with localPlayer
        var localPlayerTarget = { 
            type : 'local',
            DOMElementsIds: this.DOMElementsIds
        };
    
        localPlayerTarget.play = function() {
            videoElement.play();
    
            //var vi = document.getElementById(this.DOMElementsIds.video_image);
            //vi.style.display = 'none';
            //videoElement.style.display = 'block';
        };
    
        localPlayerTarget.pause = function () {
            videoElement.pause();
        };
    
        localPlayerTarget.stop = function () {
            videoElement.pause();
        };
    
        localPlayerTarget.load = function(media) {
            videoElement.src = media.sources[0];
            videoElement.load();
        }.bind(this);
    
        localPlayerTarget.getCurrentMediaTime = function() {
            return videoElement.currentTime;
        };
    
        localPlayerTarget.getMediaDuration = function() {
            return videoElement.duration;
        };
    
        localPlayerTarget.updateDisplayMessage = function () {
            //document.getElementById(this.DOMElementsIds.playerstate).style.display = 'none';
            //document.getElementById(this.DOMElementsIds.playerstatebg).style.display = 'none';
            //document.getElementById(this.DOMElementsIds.video_image_overlay).style.display = 'none';
        };
    
        localPlayerTarget.setVolume = function(volumeValue) {
            videoElement.volume = volumeValue;
            let vol = volumeValue;
            if(vol > 1)
                vol = 1;
            else if( vol < 0)
                vol = 0;
            var p = document.getElementById(this.DOMElementsIds.audio_intensity);
            if(p != null) {
                p.value = Math.floor(vol * 100);
            }
        };
    
        localPlayerTarget.mute = function() {
            videoElement.muted = true;
        };
    
        localPlayerTarget.unMute = function() {
            videoElement.muted = false;
        };
    
        localPlayerTarget.isMuted = function() {
            return videoElement.muted;
        };
    
        localPlayerTarget.seekTo = function(time) {
            videoElement.currentTime = time;
        };
    
        //make the player handler point the the just crated (local) playerTarget
        this.playerHandler.setTarget(localPlayerTarget);
    
        this.playerHandler.setVolume(0.8);
    
        //if we are swithcing from cast to local let's seek the correct video position
        if (this.currentMediaTime > 0) {
            this.playerHandler.play();
        }    
    }

    _setupRemotePlayer() {
        var castSession = cast.framework.CastContext.getInstance().getCurrentSession();

        // Add event listeners for player changes which may occur outside sender app
        this.remotePlayerController.addEventListener(
            cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
            function() {
                if (this.remotePlayer.isPaused) {
                    this.playerHandler.pause();
                } else {
                    this.playerHandler.play();
                }
            }.bind(this)
        );
    
        this.remotePlayerController.addEventListener(
            cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
            function() {
                if (this.remotePlayer.isMuted) {
                    this.playerHandler.mute();
                } else {
                    this.playerHandler.unMute();
                }
            }.bind(this)
        );
    
        this.remotePlayerController.addEventListener(
            cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED,
            function() {
                var newVolume = this.remotePlayer.volumeLevel * 100;
                var p = document.getElementById(this.DOMElementsIds.audio_intensity);
                if(p != null) {
                    p.value = Math.floor(newVolume);
                }
            }.bind(this)
        );
    
        // This object will implement PlayerHandler callbacks with
        // remotePlayerController, and makes necessary UI updates specific
        // to remote playback
        var remotePlayerTarget = { 
            type: 'remote',
            DOMElementsIds: this.DOMElementsIds
        };
    
        remotePlayerTarget.play = function () {
            if (this.remotePlayer.isPaused) {
                this.remotePlayerController.playOrPause();
            }
    
            //var vi = document.getElementById(this.DOMElementsIds.video_image);
            //vi.style.display = 'block';
            //var localPlayer = document.getElementById(this.DOMElementsIds.video_element);
            //localPlayer.style.display = 'none';
        }.bind(this);
    
        remotePlayerTarget.pause = function () {
            if (!this.remotePlayer.isPaused) {
                this.remotePlayerController.playOrPause();
            }
        }.bind(this);
    
        remotePlayerTarget.stop = function () {
             this.remotePlayerController.stop();
        }.bind(this);
    
        remotePlayerTarget.load = function (media) {
            console.log('Loading...' + media.title);
            var mediaInfo = new chrome.cast.media.MediaInfo(
                media.sources[0], 'video/mp4');
    
            mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();
            mediaInfo.metadata.metadataType = chrome.cast.media.MetadataType.GENERIC;
            mediaInfo.metadata.title = media.title;
            mediaInfo.metadata.images = [
                {'url': media.thumb }];
    
            var request = new chrome.cast.media.LoadRequest(mediaInfo);
            castSession.loadMedia(request).then(
                this.playerHandler.loaded.bind(this.playerHandler),
                function (errorCode) {
                    this.playerState = PLAYER_STATE.ERROR;
                    console.log('Remote media load error: ' +
                        CastPlayer.getErrorMessage(errorCode));
                }.bind(this));
        }.bind(this);
    
        remotePlayerTarget.getCurrentMediaTime = function() {
            return this.remotePlayer.currentTime;
        }.bind(this);
    
        remotePlayerTarget.getMediaDuration = function() {
            return this.remotePlayer.duration;
        }.bind(this);
    
        remotePlayerTarget.updateDisplayMessage = function () {
            /*
            document.getElementById(this.DOMElementsIds.playerstate).style.display = 'block';
            document.getElementById(this.DOMElementsIds.playerstatebg).style.display = 'block';
            //document.getElementById(this.DOMElementsIds.video_image_overlay).style.display = 'block';
            document.getElementById(this.DOMElementsIds.playerstate).innerHTML =
                this.mediaContents[ this.currentMediaIndex]['title'] + ' ' +
                this.playerState + ' on ' + castSession.getCastDevice().friendlyName;
                */
        }.bind(this);
    
        remotePlayerTarget.setVolume = function (newVolume) {
            this.remotePlayer.volumeLevel = newVolume;
            this.remotePlayerController.setVolumeLevel();
        }.bind(this);
    
        remotePlayerTarget.mute = function () {
            if (!this.remotePlayer.isMuted) {
                this.remotePlayerController.muteOrUnmute();
            }
        }.bind(this);
    
        remotePlayerTarget.unMute = function () {
            if (this.remotePlayer.isMuted) {
                this.remotePlayerController.muteOrUnmute();
            }
        }.bind(this);
    
        remotePlayerTarget.isMuted = function() {
            return this.remotePlayer.isMuted;
        }.bind(this);
    
        remotePlayerTarget.seekTo = function (time) {
            this.remotePlayer.currentTime = time;
            this.remotePlayerController.seek();
        }.bind(this);
    
        //Make the playerHandler point to the just created (remote) playerTarget
        this.playerHandler.setTarget(remotePlayerTarget);
    
        // Setup remote player volume right on setup
        // The remote player may have had a volume set from previous playback
        if (this.remotePlayer.isMuted) {
            this.playerHandler.mute();
        }
        var currentVolume = this.remotePlayer.volumeLevel * 100;
        var p = document.getElementById(this.DOMElementsIds.audio_intensity);
        if(p != null) {
            p.value = Math.floor(currentVolume);
        }
    
        this.playerHandler.play();
    
    }

    _setupLightPlayer() {
    
        // This object will implement PlayerHandler callbacks with
        // remotePlayerController, and makes necessary UI updates specific
        // to remote playback
        var lightPlayerTarget = { 
            type:'light',
            DOMElementsIds: this.DOMElementsIds,
            videoElement: null
        };
  
        lightPlayerTarget.videoElement = document.getElementById(this.DOMElementsIds.video_element);

        lightPlayerTarget.play = function () {
            //if (this.session.media[0].playerState != 'PLAYING') {
                this.session.media[0].play();
            //}
    
            //var vi = document.getElementById(this.DOMElementsIds.video_image);
            //vi.style.display = 'block';
            //var localPlayer = document.getElementById(this.DOMElementsIds.video_element);
            //localPlayer.style.display = 'none';
        }.bind(this);
    
        lightPlayerTarget.pause = function () {
            //if (!this.session.media[0].playerState == 'PLAYING') {
                this.session.media[0].pause();
            //}
        }.bind(this);
    
        lightPlayerTarget.stop = function () {
             this.session.media[0].stop();
        }.bind(this);
    
        lightPlayerTarget.load = function (media) {
            /* console.log('Loading...' + media.title);
            var mediaInfo = new chrome.cast.media.MediaInfo(
                media.sources[0], 'video/mp4');
    
            mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();
            mediaInfo.metadata.metadataType = chrome.cast.media.MetadataType.GENERIC;
            mediaInfo.metadata.title = media.title;
            mediaInfo.metadata.images = [
                {'url': media.thumb }];
    
            var request = new chrome.cast.media.LoadRequest(mediaInfo);
            castSession.loadMedia(request).then(
                this.playerHandler.loaded.bind(this.playerHandler),
                function (errorCode) {
                    this.playerState = PLAYER_STATE.ERROR;
                    console.log('Remote media load error: ' +
                        CastPlayer.getErrorMessage(errorCode));
                }.bind(this)); */
        }.bind(this);
    
        lightPlayerTarget.getCurrentMediaTime = function() {
            return this.session.media[0].getEstimatedTime();
        }.bind(this);
    
        lightPlayerTarget.getMediaDuration = function() {
        	let videoElem = document.getElementById(this.DOMElementsIds.video_element);
            return videoElem.duration;
        }.bind(this);
    
        lightPlayerTarget.updateDisplayMessage = function () {
            /*
            document.getElementById(this.DOMElementsIds.playerstate).style.display = 'block';
            document.getElementById(this.DOMElementsIds.playerstatebg).style.display = 'block';
            //document.getElementById(this.DOMElementsIds.video_image_overlay).style.display = 'block';
            document.getElementById(this.DOMElementsIds.playerstate).innerHTML =
                this.mediaContents[ this.currentMediaIndex]['title'] + ' ' +
                this.playerState + ' on ' + castSession.getCastDevice().friendlyName;
                */
        }.bind(this);
    
        lightPlayerTarget.setVolume = function (newVolume) {
        	let request = new chrome.cast.media.VolumeRequest();
        	let volume = new chrome.cast.Volume();
        	volume.level = newVolume;
        	volume.muted = false;
        	request.volume = volume;
        	this.session.media[0].setVolume( request, function(s) {
        		console.log(s);
        	}, function(e) {
        		console.log(e);
        	});
        }.bind(this);
    
        lightPlayerTarget.mute = function () {
            if (!this.session.media[0].volume.muted) {
            	let curVolume = this.session.media[0].volume;
            	curVolume.muted = true;
            	let request = new chrome.cast.media.VolumeRequest();
            	request.volume = curVolume;
                this.session.media[0].setVolume( request, (s) => {
                	console.log(s);
                }, (e) => {
                	console.log(e);
                });
            }
        }.bind(this);
    
        lightPlayerTarget.unMute = function () {
            if (this.session.media[0].volume.muted) {
            	let curVolume = this.session.media[0].volume;
            	curVolume.muted = false;
            	let request = new chrome.cast.media.VolumeRequest();
            	request.volume = curVolume;
                this.session.media[0].setVolume( request, () => {}, () => {});
            }
        }.bind(this);
    
        lightPlayerTarget.isMuted = function() {
            return this.session.media[0].volume.muted;
        }.bind(this);
    
        lightPlayerTarget.seekTo = function (time) {
        	let newTime = Math.floor(time);
        	let request = new chrome.cast.media.SeekRequest();
        	request.currentTime = newTime;
        	request.resumeState = chrome.cast.media.ResumeState.PLAYBACK_START;
            castApi.session.media[0].seek( request , (e) => {
            	console.log(e);
            }, (e) => {
            	console.log(e);
            });
        }.bind(this);
    
        //Make the playerHandler point to the just created (remote) playerTarget
        this.playerHandler.setTarget(lightPlayerTarget);
    
        // Setup remote player volume right on setup
        // The remote player may have had a volume set from previous playback
        if (this.session.media[0].volume.muted) {
        	let curVolume = this.session.media[0].volume.level;
            this.session.media[0].setVolume( new chrome.cast.media.VolumeRequest( new chrome.cast.Volume(curVolume ,true)), () => {}, () => {});
        }
        var currentVolume = this.session.media[0].volume * 100;
        var p = document.getElementById(this.DOMElementsIds.audio_intensity);
        if(p != null) {
            p.value = Math.floor(currentVolume);
        }
    
        this.playerHandler.play();

    }

    //Callback for media loaded locally
    _onMediaLoadedLocally() {
        var localPlayer = document.getElementById(this.DOMElementsIds.video_element);
        localPlayer.currentTime = this.currentMediaTime;
    
        //notify the double playerHandler that loaded happened
        this.playerHandler.loaded();        
    }    

    _initializeUI(DOMElements) {

        // Add event handlers to UI components
        document.getElementById(DOMElements.progress).addEventListener(
            'change', this._handleSeekMedia.bind(this));
        let audioon = document.getElementById(DOMElements.audio_on);
        if(audioon != null) {
            audioon.addEventListener(
                'click', this.playerHandler.mute.bind(this.playerHandler));
        }
        let audiooff = document.getElementById(DOMElements.audio_off);
        if(audiooff != null) {
            audiooff.addEventListener(
                'click', this.playerHandler.unMute.bind(this.playerHandler));
        }
        let audioint = document.getElementById(DOMElements.audio_intensity);
        if(audioint != null) {
            audioint.addEventListener(
                'change', this._handleSetVolume.bind(this));
        }
        //show the UI on mouse enter/leave
        /* document.getElementById(DOMElements.video_element).addEventListener(
            'mouseover', this.showMediaControl.bind(this));
        document.getElementById(DOMElements.video_element).addEventListener(
            'mouseout', this.hideMediaControl.bind(this));
        document.getElementById(DOMElements.media_control).addEventListener(
            'mouseover', this.showMediaControl.bind(this));
        document.getElementById(DOMElements.media_control).addEventListener(
            'mouseout', this.hideMediaControl.bind(this)); */

        //show the UI on mouse click
        document.getElementById(DOMElements.play_controls).addEventListener(
            'click', this.showMediaControl.bind(this));


        // Enable play/pause buttons
        document.getElementById(DOMElements.play).addEventListener(
            'click', this.playerHandler.play.bind(this.playerHandler));
        document.getElementById(DOMElements.pause).addEventListener(
            'click', this.playerHandler.pause.bind(this.playerHandler));

        //replace the chromecast button if  full cast api is not available
        if( (typeof cast == 'undefined') || (typeof cast.framework == 'undefined') ){
        	let castBtnContainer = document.getElementById('cast_button_container');
            if(typeof(chrome) != 'undefined') {
                castBtnContainer.innerHTML = '<strong><span id="cast_button" style="font-size:24px; line-height:45px;" class="icon-cromcast"></span></strong>';
                let newCastBtn = document.getElementById('cast_button');
                newCastBtn.addEventListener('click', () => {
                    var videoElem = document.getElementById(this.DOMElementsIds.video_element);
                    this.playMobileMedia(videoElem);
                });    
            } else {
                castBtnContainer.innerHTML = '';
            }
        }
        this.uiInitialized = true;
    }

    //set all the dom elements IDs
    /**
     * 
     * @param {*} DOMElementsIds 
     * 
     * Required DOM elements IDs are:
     * video_element
     * progress
     * audio_on
     * audio_off
     * audio_on
     * audio_intensity
     * main_video
     * media_control
     * play
     * pause
     * 
     */
    setUIDOMElements(DOMElementsIds) {
        this.DOMElementsIds = DOMElementsIds;
    }

    /**
     * 
     * @param {*} mediaData 
     * 
     * Required mediaData fields :
     * 
     * description
     * sources[]
     * subtitle
     * thumb
     * title
     */
    playMedia( mediaData ) {
        this.currentMedia = mediaData;

        if(!this.uiInitialized) {
            this._setupLocalPlayer( this.DOMElementsIds.videoPlayer );
            this._initializeUI( this.DOMElementsIds );        
        }

        // Set video image
        //var vi = document.getElementById(this.DOMElementsIds.video_image);
        //vi.src = mediaData.thumb;

        // Reset progress bar
        var p = document.getElementById(this.DOMElementsIds.progress);
        p.value = 0;
        this.updateSliderCSS(0);

        // Reset currentMediaTime
        this.currentMediaTime = 0;

        this.playerState = PLAYER_STATE.IDLE;
        this.playerHandler.play();
    }

    updateSliderCSS(newWidth) {
        let customCSS = document.getElementById('custom-style-element-related-to-range');
        if(customCSS == null)  {
            var win = window,
            doc = document,
            docElem = doc.documentElement,
            body = doc.getElementsByTagName('body')[0],
            x = win.innerWidth || docElem.clientWidth || body.clientWidth,
            y = win.innerHeight|| docElem.clientHeight|| body.clientHeight;

            let style = document.createElement('style');
            style.id = 'custom-style-element-related-to-range';
            style.innerHTML = '.slider::-webkit-slider-thumb { box-shadow: -' + x + 'px 0 0 ' + x + 'px; }';
            document.body.appendChild(style);   
        }
    }

	playMobileMedia() {
        this._stopProgressTimer();
        this._resetVolumeSlider();
        this.playerHandler.stop();
        this.playerState = PLAYER_STATE.IDLE;

		if((this.playerHandler.target.type == 'light' || this.playerHandler.target.type == 'remote') ) {

            this._setupLocalPlayer();
            this.playerHandler.stop();
	        this.playerState = PLAYER_STATE.STOPPED;
            this.session.stop();

		} else {

			let videoElem = document.getElementById(this.DOMElementsIds.video_element);
			let videoUrl = videoElem.src;
			this.playerHandler.pause();

			//this creates the session and starts casting
			this._initializeMobileCastApi(videoUrl);

		}
	}

    //handles clicks on the time bar
    _handleSeekMedia(event) {
        var prog = document.getElementById(this.DOMElementsIds.progress);

        let curr = prog.value / 100;
        let newTime = curr * this.currentMediaDuration;
        if (this.playerState === PLAYER_STATE.PLAYING ||
            this.playerState === PLAYER_STATE.PAUSED) {
            this.currentMediaTime = newTime;
        }
    
        //notify the double player handler to seek
        this.playerHandler.seekTo(newTime);    
    }

    //handle clicks on the volume bar
    _handleSetVolume(mouseEvent) {
        var int = document.getElementById(this.DOMElementsIds.audio_intensity);

        if(int != null) {
            //notify the double playerhandler of volume change
            this.playerHandler.setVolume(int.value / 100);    
        }
    }

    //start the interval for time update
    _startProgressTimer() {
        this._stopProgressTimer();

        // Start progress timer
        this.timer =
            setInterval(this.incrementMediaTimeHandler, 1000);    
    }

    //stop interval for time update
    _stopProgressTimer() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }        
    }

    _incrementMediaTime() {
        // First sync with the current double player's time
        this.currentMediaTime = this.playerHandler.getCurrentMediaTime();
        this.currentMediaDuration = this.playerHandler.getMediaDuration();

        if (this.playerState === PLAYER_STATE.PLAYING) {
            if (this.currentMediaTime < this.currentMediaDuration) {
                this.currentMediaTime += 1;
                this._updateProgressBarByTimer();
            } else {
                this.endPlayback();
            }
        }
    }

    _updateProgressBarByTimer() {
        var p = document.getElementById(this.DOMElementsIds.progress);
        var pp = Math.floor(100 * this.currentMediaTime / this.currentMediaDuration);
    
        p.value = pp;

        this.updateSliderCSS(pp);
    }

    endPlayback() {

        if(!this.uiInitialized)
            return false;

        this.currentMediaTime = 0;
        this._stopProgressTimer();
        
        if(this.playerHandler)
            this.playerHandler.stop();

        this.playerState = PLAYER_STATE.IDLE;
        if(this.playerHandler)
            this.playerHandler.updateDisplayMessage();

        if(this.session)
            this.session.stop();
    
        if(this.uiInitialized) {
            document.getElementById(this.DOMElementsIds.play).style.display = 'block';
            document.getElementById(this.DOMElementsIds.pause).style.display = 'none';    
        }
    }

    /**
     * @param {number} durationInSec
     * @return {string}
     */
    getDurationString(durationInSec) {
        var durationString = '' + Math.floor(durationInSec % 60);
        var durationInMin = Math.floor(durationInSec / 60);
        if (durationInMin === 0) {
            return durationString;
        }
        durationString = (durationInMin % 60) + ':' + durationString;
        var durationInHour = Math.floor(durationInMin / 60);
        if (durationInHour === 0) {
            return durationString;
        }
        return durationInHour + ':' + durationString;    
    }

    _updateMediaDurationText() {
        //document.getElementById(this.DOMElementsIds.duration).innerHTML = CastApiClass.getDurationString(this.currentMediaDuration);        
    }

    showMediaControl() {
        //document.getElementById(this.DOMElementsIds.media_control).style.opacity = 0.7;
        document.getElementById(this.DOMElementsIds.play_controls).style.opacity = 1;
        setTimeout( () => {
            document.getElementById(this.DOMElementsIds.play_controls).style.opacity = 0;
        }, 2000);
    }

    hideMediaControl() {
        //document.getElementById(this.DOMElementsIds.media_control).style.opacity = 0;
        document.getElementById(this.DOMElementsIds.play_controls).style.opacity = 0;
    }


    _resetVolumeSlider() {
        let audio = document.getElementById(this.DOMElementsIds.audio_intensity);
        if(audio != null) {
            audio.value = 100;
        }
        let audioon = document.getElementById(this.DOMElementsIds.audio_on);
        if(audioon != null) {
            audioon.style.display = 'block';
        }
        let audiooff = document.getElementById(this.DOMElementsIds.audio_off);    
        if(audiooff != null) {
            audiooff.style.display = 'none'            
        }

    }

    getErrorMessage(error) {
        switch (error.code) {
            case chrome.cast.ErrorCode.API_NOT_INITIALIZED:
                return 'The API is not initialized.' +
                    (error.description ? ' :' + error.description : '');
            case chrome.cast.ErrorCode.CANCEL:
                return 'The operation was canceled by the user' +
                    (error.description ? ' :' + error.description : '');
            case chrome.cast.ErrorCode.CHANNEL_ERROR:
                return 'A channel to the receiver is not available.' +
                    (error.description ? ' :' + error.description : '');
            case chrome.cast.ErrorCode.EXTENSION_MISSING:
                return 'The Cast extension is not available.' +
                    (error.description ? ' :' + error.description : '');
            case chrome.cast.ErrorCode.INVALID_PARAMETER:
                return 'The parameters to the operation were not valid.' +
                    (error.description ? ' :' + error.description : '');
            case chrome.cast.ErrorCode.RECEIVER_UNAVAILABLE:
                return 'No receiver was compatible with the session request.' +
                    (error.description ? ' :' + error.description : '');
            case chrome.cast.ErrorCode.SESSION_ERROR:
                return 'A session could not be created, or a session was invalid.' +
                    (error.description ? ' :' + error.description : '');
            case chrome.cast.ErrorCode.TIMEOUT:
                return 'The operation timed out.' +
                    (error.description ? ' :' + error.description : '');
        }    
    }

}

module.exports = CastApiClass;