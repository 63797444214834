import axios from 'axios';
import config from '../config/config_local.js'

import moment from 'moment'
import momentIT from 'moment/locale/it'

export default  {
    data() {
        return {
            payments: {
                userCards: null,
                currentEpursePayments: [],
                currentOrders: [],
                currentSelectedRates: [],

                currentEpurseMovements: [],
                epurseBalance: 0,

                rateScadute: false,

                pollingRetries: 0,
                pollingIntervalID: null,

                externalIframeModal: {
                    url: '',
                    callBackClose: null,
                    visible: false
                },

                inAppBrowser: null,
                
                epurses: {
                    currentEpurseMovements: [],
                    epurseBalance: 0,
                    epurses: [],
                    epurseVisiteMediche: null,
                    epurseDisdette: null
                },

                contest: {
                    contest: null,
                    prizes: []
                },

                oldCardForCheck: {
                    Sorgente: ''
                }
            }
        }
    },
    methods: {

        paymentsReset() {
            this.contest = {
                contest: null,
                prizes: []
            };

            this.epurses = {
                currentEpurseMovements: [],
                epurseBalance: 0,
                epurses: [],
                epurseVisiteMediche: null,
                epurseDisdette: null
            };

            this.userCards = null;
            this.currentEpursePayments = [];
            this.currentOrders = [];
            this.currentSelectedRates = [];
            this.currentEpurseMovements = [];
            this.epurseBalance = 0;
            this.rateScadute = false;
            this.pollingRetries = 0;
            this.pollingIntervalID = null;
        },

        paymentsGetUserContest() {
            if(this.user.iyestoken) {
                axios.post( config.serverUrl + '/epurse/mycontest', {
                    iyestoken : this.user.iyestoken
                })
                .then( (resmy) => {
                    if(resmy.data.error == false) {
                        this.payments.contest.contest = resmy.data.data;
                        this._paymentsGetContestPrizes(this.payments.contest.contest.ContestID);
                    } else {
                        this.payments.contest.contest = null;
                    }
                })
                .catch( (errmy) => {
                    console.log('WARNING! Error retrieving user contest');
                    console.log(errmy);
                });    
            }             
        },

        _paymentsGetContestPrizes(_contestID) {
            if(this.user.iyestoken) {
                axios.post( config.serverUrl + '/epurse/contestprizes', {
                    iyestoken : this.user.iyestoken,
                    contestID: _contestID
                })
                .then( (resmy) => {
                    if(resmy.data.error == false) {
                        this.payments.contest.prizes = resmy.data.data;
                    } else {
                        this.payments.contest.prizes = [];
                    }
                })
                .catch( (errmy) => {
                    console.log('WARNING! Error retrieving user contest prizes');
                    console.log(errmy);
                });    
            }             
        },

        paymentsReclaimPrize(prize) {
            return new Promise( (resolve, reject) => {
                if(this.user.iyestoken) {
                    axios.post( config.serverUrl + '/epurse/reclaimprize', {
                        iyestoken : this.user.iyestoken,
                        prizeId: prize.PrizeContestID
                    })
                    .then( (resmy) => {
                        if(resmy.data.error == false) {
                            console.log('prize reclaimed');
                            this.paymentsGetAndShowMyEpurses();
                            this.paymentsGetUserContest();
                            resolve(true);
                        } else {
                            console.log('prize failed');
                            reject(false);
                        }
                    })
                    .catch( (errmy) => {
                        console.log('WARNING! Error reclaiming prizes');
                        console.log(errmy);
                        reject(false);
                    });    
                } else {
                    reject(false);
                }    
            });
        },

        paymentsRicaricaBorsellinoDaPrize() {
            //find the correct prize (ricarica borsellino 5 euro = PrizeContestID:5 )
            let prize = this._findPrizeByID(5);
            if(prize) {
                this.paymentsReclaimPrize(prize);
            } else {
                console.log('PRIZES Ricarica: cannot find PrizeContestID = 5');
            }
        },

        paymentsRicaricaBorsellinoEPagaRata(_iyesPayment) {
            //find the correct prize (paga rata = PrizeContestID:4 )
            let prize = this._findPrizeByID(4);
            if(prize) {
                this.paymentsReclaimPrize(prize)
                .then( (prizeok) => {
                    //paga l'ultima rata poichè la ricarica è andata a buon fine
                    this._payRataByBE(_iyesPayment);
                    alert('pagam ok');
                })
                .catch( (prizeerr) => {
                    //impossibile ritirare il premio
                    alert('pagam fail');
                });
            } else {
                console.log('PRIZES Pagarata: cannot find PrizeContestID = 4');
            }
        },

        _payRataByBE(rataPayment) {
            return new Promise( (resolve, reject) => {
                if(this.user.iyestoken) {
                    axios.post( config.serverUrl + '/epurse/payratabybe', {
                        iyestoken : this.user.iyestoken,
                        rataPayment: rataPayment
                    })
                    .then( (resmy) => {
                        if(resmy.data.error == false) {
                            //refresh the payments data if everything was ok
                            this.loadEpursePayments();
                            resolve(true);
                        } else {
                            console.log('paybybe failed');
                            reject(false);
                        }
                    })
                    .catch( (errmy) => {
                        console.log('WARNING! Error paying rata by BE');
                        console.log(errmy);
                        reject(false);
                    });    
                } else {
                    reject(false);
                }    
            });
        },

        _findPrizeByID(_prizeID) {
            let found = false;
            let i = 0;
            while(found == false && i < this.payments.contest.prizes.length) {
                if(this.payments.contest.prizes[ i ].PrizeContestID == _prizeID) {
                    found = true;
                }

                if(!found)
                    i++;
            }

            if(found) {
                return this.payments.contest.prizes[ i ];
            } else {
                return null;
            }
        },

        //gets visite mediche epurses
        paymentsGetVisiteMedicheEpurses() {
            if(this.user.iyestoken) {
                axios.post( config.serverUrl + '/epurse/myepurses', {
                    iyestoken : this.user.iyestoken
                })
                .then( (resmy) => {
                    //console.log('Got epurses');
                    //console.log(resmy);
                    let tmpEpurses = resmy.data.data.data;
                    tmpEpurses.forEach((epurse) => {
                        if(epurse.EPurseID == 93) {     //VISITE MEDICHE
                            this.payments.epurses.epurseVisiteMediche = epurse;
                        }
                    });

                })
                .catch( (errmy) => {
                    console.log('WARNING! Error retrieving user visite mediche epurses');
                    console.log(errmy);
                    this.$root.hideWaiting();
                });    
            }             
        },

        //gets all epurses and show by default the first one
        paymentsGetAndShowMyEpurses(_show = true) {
            if(this.user.iyestoken) {
                axios.post( config.serverUrl + '/epurse/myepurses', {
                    iyestoken : this.user.iyestoken
                })
                .then( (resmy) => {
                    //console.log('Got epurses');
                    //console.log(resmy);
                    let tmpEpurses = resmy.data.data.data;
                    tmpEpurses.forEach((epurse) => {
                        if(epurse.Description == "ORANGE PALESTRE") {
                            this.payments.epurses.epurseBalance = epurse.Balance;
                            this.payments.epurses.epurses.push( epurse );
                        } else if(epurse.Description.toLowerCase().indexOf('disdett') !== -1) {
                            this.payments.epurses.epurseDisdette = epurse;
                        }
                    });

                    //if the user doesn't have a subscription he also doesn't have epurses
                    if(this.payments.epurses.epurses.length > 0) {
                        this.paymentsGetAndShowEpurseMovements(this.payments.epurses.epurses[0].UserEPurseID, _show);
                    } else {
                        this.paymentsGetAndShowEpurseMovements(null, _show);
                    }
                })
                .catch( (errmy) => {
                    console.log('WARNING! Error retrieving user epurses');
                    console.log(errmy);
                    this.$root.hideWaiting();
                });    
            }             
        },

        paymentsGetAndShowEpurseMovements(_userepurseId, _show) {
            if(this.user.iyestoken) {
                //if the user doesn't have a subscription he also doesn't have epurses
                if(_userepurseId != null) {
                    axios.post( config.serverUrl + '/epurse/movements', {
                        iyestoken : this.user.iyestoken,
                        userepurseid: _userepurseId
                    })
                    .then( (resmy) => {
                        //console.log('Got epurse movements');
                        //console.log(resmy);
                        this.payments.epurses.currentEpurseMovements = [];
                        if(resmy.data.data.data.length > 0) {
                            resmy.data.data.data.forEach( (epurse) => {
                                if( Math.abs( parseFloat(epurse.Balance)) > 0.05 ) {
                                    this.payments.epurses.currentEpurseMovements.push({
                                        cause: epurse.Cause,
                                        name: epurse.EPurseName,
                                        date: moment(epurse.MovementDateTime).format('dddd, D MMMM YYYY'),
                                        amount: parseFloat(epurse.Balance)
                                    });
                                }
                            });        
                        } else {
                            this.payments.epurses.currentEpurseMovements.push({
                                cause: 'Nessun movimento presente',
                                name: '',
                                date: '',
                                amount: ''
                            });
                        }
                        this.$root.hideWaiting();
                        if(_show) {
                            this.$root.$refs.pageMan.showPage('userborsellinopage');
                        }
                    })
                    .catch( (errmy) => {
                        console.log('WARNING! Error retrieving user epurse movements');
                        console.log(errmy);
                        this.$root.hideWaiting();
                    });    
    
                } else {
                    this.$root.hideWaiting();
                    if(_show) {
                        this.$root.$refs.pageMan.showPage('userborsellinopage');
                    }
                }
            }             
        },

        paymentsGetUserCards() {
            return new Promise( (resolve, reject) => {
                if(this.user.iyestoken) {
                    let cardsUrl = config.serverUrl + '/epurse/usercards';
                    axios({
                        method: 'POST',
                        url: cardsUrl,
                        data: {
                            iyestoken: this.user.iyestoken
                        }
                    })
                    .then( (res) => {
                        if(res.data.error) {
                            //console.log(res);
                            this.payments.userCards = null;
                            reject(false);
                        } else {
                            if(Array.isArray(res.data.data.data)) {
                                this.payments.userCards = res.data.data.data[0];
                                resolve(true);
                            } else {
                                this.payments.userCards = res.data.data.data;
                                resolve(true);
                            }
                            if(typeof this.payments.userCards == 'undefined') {
                                this.payments.userCards = null;
                                reject(false);
                            }
                        }
                    })
                    .catch( (err) => {
                        console.log('WARNING! cannot retrieve user cards');
                        console.log(err);
                        this.payments.userCards = null;
                        reject(false);
                    });
                } else {
                    reject(false);
                }
            });
        },

        loadEpursePayments() {
            return new Promise( (resolve, reject) => {
                if(this.user.iyestoken) {
                
                    this.payments.currentEpursePayments = [];
                    this.payments.rateScadute = false;
    
                    axios.post( config.serverUrl + '/epurse/payments', {
                        iyestoken : this.user.iyestoken
                    })
                    .then( (resmy) => {
                        if(!resmy.data.error) {
                            //console.log('Got epurse payments');
                            //console.log(resmy);
                            this.payments.currentEpursePayments = [];
                            if(resmy.data.data.data.length > 0) {
                                resmy.data.data.data.forEach( (payment) => {
                                    this.payments.currentEpursePayments.push({
                                        id: payment.IDRata,
                                        name: payment.DescrizioneAbbonamento,
                                        date: moment(payment.DataRata).format('DD/MM/YYYY'),
                                        datePayd: moment(payment.DataPagato).format('dddd, D MMMM YYYY'),
                                        amount: Math.abs(payment.Importo),
                                        iyesPayment: payment
                                    });
                                });    
                                //verifica se ci sono rate scadute
                                this._paymentsCheckRateScadute();

                                resolve();
                            } else {
                                console.log('No payments or error.');
                                this.subscriptions.nuovoAbbonamentoCliccato = 'NESSUNO';
                                resolve();
                            }    
                        } else {
                            //console.log('No epurse payments.');
                            resolve();
                        }
                    })
                    .catch( (errmy) => {
                        console.log('WARNING! Error retrieving user epurse movements');
                        console.log(errmy);
                        resolve();
                    });    
                }  else {
                    resolve();
                }           
    
            });
        },

        //verifica se ci sono rate scadute
        _paymentsCheckRateScadute() {
            let oggi = moment();
            this.payments.rateScadute = false;
            this.payments.currentEpursePayments.forEach( (rata) => {
                let scadRata = moment(rata.date,'DD/MM/YYYY');
                if( oggi.isAfter(scadRata) ) {
                    this.payments.rateScadute = true;
                }
            })
        },

        //prepare the payment request
        paymentsCreateRateOrder(_rataIdx) {
            if(this.$root.isApple) {
                this.$root.showAppleMessage();
                return false;
            }

            this.$root.showWaiting('Attendere, non chiudere l\'applicazione.');

            if( typeof(this.payments.userCards.Sorgente) == 'undefined' || this.payments.userCards.Sorgente == '') {
                this.$root.hideWaiting();
                this.$root.$refs.pageMan.showAlert('Non hai ancora impostato un metodo di pagamento. Vuoi impostarlo ora?','SI','NO')
                .then( (ok) => {
                    this.$root.$refs.pageMan.showPage('userpagamentopage');
                })
                return false;
            }

            var rataDaPagare = this.payments.currentEpursePayments[_rataIdx ];
            var rataDaPagareId = rataDaPagare.id;

            this.loadEpursePayments()
            .then( (newrate) => {

                //verifica che la rata da pagare esista ancora
                let esiste = false;
                this.payments.currentEpursePayments.forEach( (checkrata) => {
                    if(checkrata.id == rataDaPagareId) {
                        esiste = true;
                    }
                });

                if(!esiste) {
                    this.$root.hideWaiting();
                    this.$root.$refs.pageMan.showAlert('La rata che stai cercando di pagare risulta già pagata.','OK','');
                    return false;
                }

                //la rata esiste ancora, procediamo col pagamento
                if(this.user.iyestoken) {
        
                    this.payments.currentOrders = [];
                    this.payments.currentSelectedRates = [];                
                    this.payments.currentSelectedRates.push( rataDaPagare.iyesPayment );
                    axios({
                        method: 'POST',
                        url: config.serverUrl + '/epurse/createorders',
                        data: { 
                            iyestoken : this.user.iyestoken,
                            rates: this.payments.currentSelectedRates 
                        }
                    })
                    .then( (res) => {
                        if(!res.data.error) {
                            this.payments.currentOrders = res.data.data.data;
                            if(this.payments.currentOrders.length > 0) {
                                var amountOrder = this.payments.currentOrders[0].Rates[0].Importo;
                                this.paymentCreateOrderPinCode(this.payments.currentOrders[0].IdTransazione)
                                .then( (ok) => {
                                    this.paymentPayTsPay(this.payments.currentOrders, amountOrder)
                                    .then( (okpag) => {
                                        this.$root.hideWaiting();
                                        this.$root.$refs.pageMan.showAlert('Pagamento avvenuto con successo.','OK','')
                                        .then( (okris) => {
                                            this.$root.hideWaiting();
                                            this.getMe();
                                        })
                                        .catch( (cancris) => {
                                            this.$root.hideWaiting();
                                            this.getMe();
                                        });    
                                    })
                                    .catch( (boherr) => {
                                        console.log(boherr);
                                        this.$root.hideWaiting();
                                        this.$root.$refs.pageMan.showAlert('Impossibile pagare ordine rata. Contattare assistenza','OK','');
                                    });
    
                                })
                                .catch( (ko) => {
                                    this.$root.hideWaiting();
                                    this.register.registerProduct = null;
                                    this.$root.$refs.pageMan.showAlert('Impossibile creare PIN ordine.','OK','');
                                });
        
                            } else {
                                this.$root.hideWaiting();
                                this.$root.$refs.pageMan.showAlert('Impossibile creare PIN ordine rata. Contattare assistenza','OK','');
                            }
        
                        } else {
                            this.$root.hideWaiting();
                            console.log('WARNING! Error creating rate payment orders.');
                        }
                        console.log(res);
                    })
                    .catch( (err) => {
                        this.$root.hideWaiting();
                        console.log('WARNING! Unable to create payment order');
                        console.log(err);
                    });
                }
    
            })
            .catch( ( newrateerr) => {

            });

        },

        
        paymentCreateProductsOrder(product) {

            this.$root.showWaiting('ATTENDERE...');

            //se il prezzo è 0 regalo il prodotto
            if(product.Price == 0) {
                let dataInizio = moment().format('YYYY-MM-DD');
                this.paymentGiftProduct( product, dataInizio)
                .then( (ok) => {
                    this.$root.hideWaiting();
                    this.$root.$refs.pageMan.showAlert("Prodotto assegnato con successo.",'OK','');
                    this.getMe();
                })
                .catch( (ko) => {
                    this.$root.hideWaiting();
                    this.userDoLogout();
                });
                return;
            }
            
            //verifica se l'utente ha una carta, se no fagliela inserire
            if( typeof(this.payments.userCards) == 'undefined' || typeof(this.payments.userCards.Sorgente) == 'undefined' || this.payments.userCards.Sorgente == '') {
                this.$root.hideWaiting();
                this.$root.$refs.pageMan.showAlert('Non hai ancora impostato un metodo di pagamento. Vuoi impostarlo ora?','SI','NO')
                .then( (ok) => {
                    this.$root.$refs.pageMan.showPage('userpagamentopage');
                })
                return false;
            }
            var productId = product.Id;

            this.$root.getMyStatus()
            .then( (okstatus) => {
                console.log(this.subscriptions);
                //se dopo aver riletto il documento informativo trovo già lo stesso prodotto
                if(this.subscriptions.abbonamenti.length > 0) {
                    if( (product.Name == this.subscriptions.currentAbbonamentoName) || (product.Name.includes(this.subscriptions.currentAbbonamentoName)) || (this.subscriptions.currentAbbonamentoName.includes(product.Name)) ) {
                        //verifica se l'abbonamento trovato comincia oggi
                        let todayStr = moment( new Date() ).format('DD/MM/YYYY');
                        let cominciaOggi = false;
                        this.subscriptions.abbonamenti.forEach( (abb) => {
                            if(abb.inizio.format('DD/MM/YYYY') == todayStr) {
                                cominciaOggi = true;
                            }
                        });
                        if(cominciaOggi) {
                            this.$root.hideWaiting();
                            this.$root.$refs.pageMan.showAlert('Il prodotto potrebbe essere in fase di conferma. Eseguire accesso e riprovare più tardi.','OK','');
                            this.$root.getMe();
                            return false;    
                        }
                    }    
                }

                let currentSelectedProducts = [];
                currentSelectedProducts.push( product );
                var amountOrder = product.Price;
                axios({
                    method: 'POST',
                    url: config.serverUrl + '/epurse/createprodorders',
                    data: { 
                        iyestoken : this.user.iyestoken,
                        products: currentSelectedProducts 
                    }
                })
                .then( (res) => {
                    if(!res.data.error) {
                        this.payments.currentOrders = res.data.data.data;
                        if(this.payments.currentOrders.length > 0) {
                            this.paymentCreateOrderPinCode(this.payments.currentOrders[0].IdTransazione)
                            .then( (ok) => {
                                this.paymentPayTsPay(this.payments.currentOrders, amountOrder)
                                .then( (okpag) => {
                                    //if it was a registration the registerProduct must be reset
                                    if(this.register.registerProduct == null) {
                                        this.$root.hideWaiting();
                                        this.$root.$refs.pageMan.showAlert('Pagamento avvenuto con successo.','OK','')
                                        .then( (okris) => {
                                            this.getMe();
                                        })
                                        .catch( (cancris) => {
                                            this.getMe();
                                        });    
                                    } else {
                                        this.$root.hideWaiting();
                                        //se era una registrazione resetto il registerProduct
                                        this.register.registerProduct = null;
                                        //non dovrebbe servire, ma cancelliamo l'interval (viene chiuso dalla carta di credito)
                                        clearInterval( this.payments.pollingIntervalID);                            
                                        this.paymentsHideExternalIframeModal();
                                        this.$root.$refs.pageMan.showAlert('Registrazione avvenuta con successo, riceverai una mail con la password per accedere al sistema.','OK','')
                                        .then( (okris) => {
                                            //se esiste un registerProduct significa che mi sono appena registrato comprando un prodotto
                                            //devo impostare la password
                                            this.$root.$refs.pageMan.resetOverlayStack();   //remove all payments overlays
                                            this.$root.$refs.pageMan.showPage('registrationpasswordpage');
                                            //this.$root.userDoLogout();
                                        })
                                        .catch( (cancris) => {
                                            //se esiste un registerProduct significa che mi sono appena registrato comprando un prodotto
                                            //devo impostare la password
                                            this.$root.$refs.pageMan.resetOverlayStack();   //remove all payments overlays
                                            this.$root.$refs.pageMan.showPage('registrationpasswordpage');
                                            //this.$root.userDoLogout();
                                        });    
                                    }
                                })
                                .catch( (boherr) => {
                                    this.$root.hideWaiting();
                                    console.log(boherr);
                                    this.register.registerProduct = null;
                                    this.$root.$refs.pageMan.showAlert('Impossibile pagare ordine. Contattare assistenza','OK','');
                                    this.getMe();
                                });
                            })
                            .catch( (ko) => {
                                this.$root.hideWaiting();
                                this.register.registerProduct = null;
                                this.$root.$refs.pageMan.showAlert('Impossibile creare PIN ordine.','OK','');
                                this.getMe();
                            });
                        } else {
                            this.$root.hideWaiting();
                            this.register.registerProduct = null;
                            this.$root.$refs.pageMan.showAlert('Impossibile creare PIN ordine. Contattare assistenza','OK','');
                            this.getMe();
                        }
                    } else {
                        this.$root.hideWaiting();
                        this.register.registerProduct = null;
                        console.log('WARNING! Error creating product order.');
                        alert('Impossibile creare gli ordini. Riprovare più tardi.');
                        this.getMe();
                    }
                })
                .catch( (err) => {
                    this.$root.hideWaiting();
                    this.register.registerProduct = null;
                    console.log('WARNING! Unable to create product order');
                    console.log(err);
                    alert('Impossibile effettuare il pagamento. Riprovare più tardi.');
                    this.getMe();
                });    
            })
            .catch( (statusfail) => {
                //se non riesco nemmeno a rileggere il documento informativo
                this.$root.$refs.pageMan.showAlert('Non è stato possibile verificare l\'attivazione di questo prodotto. Riprovare più tardi.','OK','');
            });
        },

        paymentCreateOrderPinCode(_idTransazione) {
            return new Promise( (resolve, reject) => {
                let pinUrl = config.serverUrl + '/epurse/createpincode';
                axios({
                    method: 'POST',
                    url: pinUrl,
                    data: {
                        iyestoken: this.user.iyestoken,
                        OrderKey: _idTransazione
                    }
                })
                .then( (ok)=> {
                    if(typeof(ok.data.data.PINCode) != 'undefined') {
                        resolve( ok.data.data.PINCode);
                    } else {
                        reject(false);
                    }
                })
                .catch( (ko) => {
                    reject(false);
                });
            });
        },

        paymentPayTsPay(_idsTransazioni, _amountOrder ) {
            return new Promise( (resolve, reject) => {
                let pinUrl = config.serverUrl + '/epurse/payordertspay';
                axios({
                    method: 'POST',
                    url: pinUrl,
                    data: {
                        iyestoken: this.user.iyestoken,
                        transactionOrderIds: _idsTransazioni,
                        externalRef: this.user.iyesUserId,
                        amountOrder: _amountOrder
                    }
                })
                .then( (ok)=> {
                    if(ok.data.error) {
                        reject(false);
                    } else {
                        resolve(true);
                    }
                })
                .catch( (ko) => {
                    reject(false);
                });
            });
        },

        paymentGiftProduct(_giftProduct, _dataInizio) {
            return new Promise( (resolve, reject) => {
                if(this.user.iyestoken) {
                    let giftUrl = config.serverUrl + '/user/newuserproduct';
                    axios({
                        method: 'POST',
                        url: giftUrl,
                        data: {
                            iyestoken: this.user.iyestoken,
                            userId: this.user.iyesUserId,
                            registerProduct: _giftProduct,
                            dataInizio: ((typeof(_dataInizio) == 'Object') ? _dataInizio.format('YYYY-MM-DD') : _dataInizio)
                        }
                    })
                    .then( (resgift) => {
                        if( (typeof(resgift.data.error) != 'undefined') && resgift.data.error == false) {
                            resolve(true);
                        } else {
                            this.userDoLogout();
                            reject(false);
                        }
                    })
                    .catch( (errgift) => {
                        this.userDoLogout();
                    });
                } else {
                    reject(false);
                }    
            });
        },

        paymentsAddNewCard() {
            if(this.user.iyestoken) {

                let payUrl = config.serverUrl + '/epurse/upsertcardurl';
                //store the old card, so we can check if it has changed (not just if it exists, as before)
                if(this.payments.userCards == null ) {
                    this.payments.oldCardForCheck.Sorgente = '';
                } else {
                    if(this.payments.userCards.hasOwnProperty('Sorgente')) {
                        this.payments.oldCardForCheck.Sorgente = this.payments.userCards.Sorgente;
                    } else {
                        this.payments.oldCardForCheck.Sorgente = '';
                    }
                }
                axios({ 
                    method: 'POST',
                    url: payUrl,
                    data: {
                        iyestoken: this.user.iyestoken,
                    }
                })
                .then( (res) => {
                    if(res.data.error) {
                        this.$root.$refs.pageMan.showAlert('Non è possibile operare sulla carta di credito. Riprovare più tardi.','OK','');
                        console.log('WARNING! Cannot retrieve upsertcard url');
                        console.log(res);
                    } else {
                        this.$root.hideWaiting();
                        //aprire iframe modale
                        this.paymentShowExternalIframeOverlay(res.data.data)
                        .then((modalres) => {
                            this.paymentsHideExternalIframeModal();
                        });
                        this.paymentPollUpsertCard();
                    }
                })
                .catch( (err) => {
                    this.$root.hideWaiting();
                    console.log('WARNING! Error getting upsert card url');
                    console.log(err);
                });
            }
        },

        /*
        
        not needed anymore

        paymentGetPayUrl(amountOrder) {
            if(this.user.iyestoken) {
                let payUrl = config.serverUrl + '/epurse/payurl';
                //let availableEmail = (this.payments.userCards.Email) ? this.payments.userCards.Email : this.user.email;
                let availableEmail = this.user.iyesUserId;
                axios({ 
                    method: 'POST',
                    url: payUrl,
                    data: {
                        iyestoken: this.user.iyestoken,
                        orderdata: this.payments.currentOrders[0],
                        email: availableEmail,
                        amountOrder: amountOrder
                    }
                })
                .then( (res) => {
                    if(res.data.error) {
                        alert('Non è stato possibile reperire i dati dell\'ordine. Riprovare più tardi.');
                        console.log('WARNING! Cannot get pay url');
                        console.log(res);
                    } else {
                        //payurl returns a newly created transaction ID
                        let orderID = res.data.data.TrxID;

                        //store this transaction ID and orderID couple. We will poll the server for payment success cause...
                        //...the callback called by TSPay on the server deletes the correct order record
                        axios({
                            method: 'POST',
                            url: config.serverUrl + '/epurse/storetrxid',
                            data: {
                                orderID: orderID,
                                transactionID: this.payments.currentOrders[0].IdTransazione,
                                email: availableEmail
                            }
                        })
                        .then( (storeres) => {
                            //open external payment URL
                            if(!storeres.data.error)  {
                                //this.mywindow.open(res.data.data.URL);
                                this.paymentShowExternalIframeOverlay(res.data.data.URL)
                                .then((modalres) => {
                                    this.paymentsHideExternalIframeModal();
                                });
                                this.paymentPollConfirmation(this.payments.currentOrders[0].IdTransazione);
                            } else {
                                alert('Impossibile salvare la transazione di pagamento.');
                                console.log('WARNING! CAnnot save payment transaction');
                                console.log(storeres);
                            }
                        })
                        .catch( (storeerr) => {
                            alert('Impossibile salvare la transazioe di pagamento.');
                            console.log('WARNING! Cannot save payment transaction');
                            console.log(storeerr);
                        })

                        //console.log(res);
                    }
                })
                .catch( (err) => {
                    console.log('WARNING! Error getting pay url');
                    console.log(err);
                });
            }
        },

        */

        paymentShowExternalIframeOverlay(_url) {
            if(window.cordova && window.cordova.InAppBrowser) {
                return new Promise( (resolve, reject) => {
                    let params = 'location=yes,clearcache=yes,footer=no,hardwareback=yes,hidenavigationbuttons=yes,hideurlbar=yes,fullscreen=yes'
                    this.payments.inAppBrowser = null;
                    this.payments.inAppBrowser = cordova.InAppBrowser.open(_url, '_blank', params);
                    let _res = resolve;
                    let _iab = this.payments.inAppBrowser;
                    this.payments.inAppBrowser.addEventListener('exit', () => {
                        console.log('Closing payment in app browser');
                        _iab.close();
                        _res();
                    });
                });
            } else {
                return new Promise( (resolve,reject) => {
                    this.payments.externalIframeModal.url = _url;
                    this.payments.externalIframeModal.callbackClose = resolve;
                    this.payments.externalIframeModal.visible = true;
                    this.$root.$refs.pageMan.showOverlay('externaliframeoverlay');
                });    
            }
        },

        paymentsHideExternalIframeModal() {
            this.payments.externalIframeModal.visible = false;
            this.payments.externalIframeModal.url = '';
            clearInterval(this.payments.pollingIntervalID);
            this.payments.pollingRetries = 0;            
            if(this.payments.inAppBrowser) {
                this.payments.inAppBrowser.close();
                this.payments.inAppBrowser = null;
            }
        },

        paymentPollUpsertCard() {
            this.payments.pollingRetries = 0;
            //let availableEmail = (this.payments.userCards.Email) ? this.payments.userCards.Email : this.user.email;
            let availableEmail = this.user.iyesUserId;
            this.payments.pollingIntervalID = setInterval( () => {
                console.log('Polling Interval for cards: ' + this.payments.pollingIntervalID);
                this.paymentsGetUserCards()
                .then( (ok) => {
                    if(typeof (this.payments.userCards.Sorgente) != 'undefined') {
                        console.log('Sorgente attuale: ' + this.payments.userCards.Sorgente);
                        //console.log('Sorgente per check: '+ this.payments.oldCardForCheck.Sorgente);
                        //check if a card exists AND if it's different from the old one (if any)
                        if( (this.payments.userCards.Sorgente != '') && (this.payments.userCards.Sorgente != this.payments.oldCardForCheck.Sorgente) ) {
                        //if( (this.payments.userCards.Sorgente != '') ) {
                            console.log('Clearing polling Interval for cards: ' + this.payments.pollingIntervalID);
                            clearInterval( this.payments.pollingIntervalID);                            
                            this.paymentsHideExternalIframeModal()
                            if(this.register.registerProduct == null) {
                                this.$refs.pageMan.showAlert('Metodo di pagamento aggiunto con successo, ora puoi fare acquisti o prenotazioni.','OK')
                                .then( (ok) => {
                                        this.$refs.pageMan.closeOverlay();
                                        this.getMe();
                                        this.$refs.pageMan.resetPagesStack('mainpage');    
                                });                            
                            } else {
                                this.paymentCreateProductsOrder( this.register.registerProduct );
                            }
                        }
                    } else {
                        this.payments.pollingRetries += 1;
                    }
                    if(this.payments.pollingRetries > 50) {
                        console.log('Timeout polling Interval for cards: ' + this.payments.pollingIntervalID);
                        this.payments.pollingRetries = 0;
                        clearInterval(this.payments.pollingIntervalID);    
                        this.paymentsHideExternalIframeModal()
                        this.register.registerProduct = null;
                        this.$refs.pageMan.showAlert('Operazione andata in timeout. Contattare l\'amministrazione','OK','')
                        .then( (ok) => {
                            this.$refs.pageMan.closeOverlay();
                            this.$refs.pageMan.resetPagesStack('welcomepage');
                        });
                    }
                })
                .catch( (ko) => {
                    //do notghing and wait some more...
                });
            }, 5000);
            console.log('Started polling Interval for cards: ' + this.payments.pollingIntervalID);
        },

        paymentPollConfirmation(_transactionID) {
            this.payments.pollingRetries = 0;
            //let availableEmail = (this.payments.userCards.Email) ? this.payments.userCards.Email : this.user.email;
            let availableEmail = this.user.iyesUserId;
            this.payments.pollingIntervalID = setInterval( () => {
                axios({
                    method: 'POST',
                    url: config.serverUrl + '/epurse/polltrxid',
                    data: {
                        transactionID: _transactionID,
                        email: availableEmail
                    }
                })
                .then( (res) => {
                    console.log('Polling Interval for payments: ' + this.payments.pollingIntervalID);
                    if(res.data.error == false) {
                        if(typeof res.data.data.orderConfirmed !== 'undefined' && res.data.data.orderConfirmed) {
                            console.log('Clearing polling Interval for payments: ' + this.payments.pollingIntervalID);
                            clearInterval( this.payments.pollingIntervalID);                            
                            this.paymentsHideExternalIframeModal()
                            this.$refs.pageMan.showAlert('Pagamento avvenuto con successo.','OK')
                            .then( (ok) => {
                                this.$refs.pageMan.closeOverlay();
                                this.getMe();
                                this.$refs.pageMan.resetPagesStack('mainpage');
                            });
                        } else {
                            this.payments.pollingRetries += 1;
                        }
                        if(this.payments.pollingRetries > 30) {
                            console.log('Timeout polling Interval for payments: ' + this.payments.pollingIntervalID);
                            this.payments.pollingRetries = 0;
                            clearInterval(this.payments.pollingIntervalID);    
                            this.paymentsHideExternalIframeModal()
                            this.$refs.pageMan.showAlert('Impossibile confermare pagamento. Contattare l\'amministrazione','OK')
                            .then( (ok) => {
                                this.$refs.pageMan.closeOverlay();
                                this.getMe();
                                this.$refs.pageMan.resetPagesStack('mainpage');
                            });
                        }
                    } else {
                        this.payments.pollingRetries = 0;
                        clearInterval(this.payments.pollingIntervalID);
                        this.paymentsHideExternalIframeModal()
                        this.$refs.pageMan.showAlert('Si è verificato un errore durante la procedura di pagamento.','OK')
                        .then( (ok) => {
                            this.$refs.pageMan.closeOverlay();
                            this.getMe();
                            this.$refs.pageMan.resetPagesStack('mainpage');
                        });
                    }
                })
                .catch( (err) => {
                    this.payments.pollingRetries = 0;
                    clearInterval(this.payments.pollingIntervalID);
                    alert('Errore durante la conferma ordine. Riprovare più tardi.');
                    this.$root.$refs.pageMan.resetPagesStack('mainpage');
                    this.getMe();
                });
            }, 5000);
            console.log('Started polling Interval for payments: ' + this.payments.pollingIntervalID);
        },


    }
}