<template>
    <div>
        <div v-if="$root.subscriptions.abbonamentoProduct != null" class="container-fluid">
            <div class="row mt-5 mb-0">
                <!-- <div class="col-12 my-5 text-center">
                    <p class="titolo-piccolo orange-text-black text-center mt-4 mb-2 pb-0" style="text-transform:uppercase;">CAMBIA ABBONAMENTO</p>
                    <p class="testo-generico orange-text-black text-center" >Puoi modificare la tipologia del tuo abbonamento facendo un Upgrade verso un abbonamento migliore o un Downgrade verso un abbonamento più economico.</p>
                </div> -->
                <div class="col-12">
                    <p class="testo-generico orange-text-black text-center" ><strong>Abbonamento attivo</strong></p>
                    <p class="titolo-piccolo orange-text-black text-center mt-4 mb-2 pb-0" style="text-transform:uppercase;">{{ $root.subscriptions.currentAbbonamentoName }}</p>
                </div>
                <div class="col-12 my-4">
                    <p class="testo-generico orange-text-black text-center mb-0" ><strong>Prossima data di fatturazione</strong></p>
                    <p class="testo-generico orange-text-black text-center" style="text-align:justify;">{{ $root.subscriptions.scadenzaAbbonamento.format('DD/MM/YYYY') }}</p>
                </div>
            </div>
        </div>
        <div v-else class="container-fluid">
            <div class="row mt-5 mb-0">
                <div class="col-12 my-5 text-center">
                    <p class="titolo-piccolo orange-text-black text-center mt-4 mb-2 pb-0" style="text-transform:uppercase;">GESTISCI ABBONAMENTO</p>
                    <p class="testo-generico orange-text-black text-center" >Non hai alcun abbonamento attivo attualmente. Seleziona un abbonamento da quelli disponibili.</p>
                </div>
            </div>            
        </div>
        
        <div v-if="$root.subscriptionsInAttesaCambio() == ''" class="container-fluid">
            <div class="row mt-5">
                <div class="col-12 mt-4">
                    <div v-if="$root.subscriptions.abbonamentoProduct != null" id="prodsaccordion">
                        <div v-for="prod, index in $root.products.abbonamentiCambio" :key="'subprod-' + index" class="card orange-text-black border-0">
                            <div v-show="!($root.subscriptions.abbonamentoProduct.Name.includes( prod.Name.split('-')[0] ))" class="card-header collapsed p-0" :id="'heading-' + index">
                                <h5 class="mb-0">
                                    <button v-on:click="toggleAccordion(index)" class="btn btn-block btn-orange btn-outline-white orange-text-black" data-toggle="collapse" :data-target="'#prodcoll-' + index" aria-expanded="true" aria-controls="collapseOne">
                                        {{ prod.Name }}
                                        <span style="float:right;" v-show="(openAccordion.hasOwnProperty(index) ) && (openAccordion[index].open)" class="orange-ico orange-ico-dark"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_chiudi"></span></span>
                                        <span style="float:right;" v-show="(!(openAccordion.hasOwnProperty(index))) || (!openAccordion[index].open)" class="orange-ico orange-ico-dark"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_apri"></span></span>
                                    </button>
                                </h5>
                            </div>

                            <div :id="'prodcoll-' + index" 
                            v-show="!($root.subscriptions.abbonamentoProduct.Name.includes( prod.Name.split('-')[0]))" 
                            class="collapse" 
                            :aria-labelledby="'heading-' + index" 
                            data-parent="#prodsaccordion">
                                <div class="card-body testo-generico orange-text-black">
                                    <p v-html="prod.Description"></p>
                                    <button v-if="$root.subscriptions.abbonamenti.length > 0" v-on:click="setAbbonamentoAndShowRegistration(prod)" class="btn btn-block btn-orange btn-orange-orange">PASSA A</button>
                                    <button v-else v-on:click="setAbbonamentoAndShowRegistration(prod)" class="btn btn-block btn-orange testo-bottoni btn-orange-orange">ABBONATI</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div v-else id="prodsaccordion">
                        <div v-for="prod, index in $root.products.abbonamentiCambio" :key="'subprod-' + index" class="card orange-text-black border-0">
                            <div class="card-header collapsed p-0" :id="'heading-' + index">
                                <h5 class="mb-0">
                                    <button v-on:click="toggleAccordion(index)" class="btn btn-block btn-orange btn-outline-white orange-text-black" data-toggle="collapse" :data-target="'#prodcoll-' + index" aria-expanded="true" aria-controls="collapseOne">
                                        {{ prod.Name }}
                                        <span style="float:right;" v-show="(openAccordion.hasOwnProperty(index) ) && (openAccordion[index].open)" class="orange-ico orange-ico-dark"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_chiudi"></span></span>
                                        <span style="float:right;" v-show="(!(openAccordion.hasOwnProperty(index))) || (!openAccordion[index].open)" class="orange-ico orange-ico-dark"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_apri"></span></span>
                                    </button>
                                </h5>
                            </div>

                            <div :id="'prodcoll-' + index" class="collapse" :aria-labelledby="'heading-' + index" data-parent="#prodsaccordion">
                                <div class="card-body testo-generico orange-text-black">
                                    <p><strong>{{ prod.Price }}&nbsp;&euro;&nbsp;al mese</strong></p>
                                    <p v-html="prod.Description"></p>
                                    <button v-on:click="setAbbonamentoAndShowRegistration(prod)" class="btn btn-block btn-orange testo-bottoni btn-orange-orange">ABBONATI</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div v-else class="container-fluid">
            <div v-if="$root.subscriptions.abbonamenti.length > 0" class="row">
                <div class="col-12 text-center testo-generico orange-text-black">
                    Il tuo abbonamento è stato modificato in:
                </div>
                <div class="col-12 text-center testo-generico orange-text-black">
                    <strong>{{ $root.subscriptionsInAttesaCambio().split('-')[0] }}</strong>
                </div>
                <div v-if="$root.subscriptions.abbonamenti.length > 0" class="col-12 text-center testo-generico orange-text-black">
                    <p>Dal: <strong>{{ $root.subscriptions.abbonamenti[0].inizio.format('DD/MM/YYYY') }}</strong></p>
                </div>
            </div>
        </div>

        <div class="container-fluid mt-5">
            <div v-on:click="$root.openWhatsappAmministrazione" class="col-12 text-center">
                <span class="orange-ico-dark" style="font-size:28px;"><span class="icon-tools_whatsapp"></span></span>
                <p class="testo-generico orange-text-black">Per maggiori informazioni contatta l'amministrazione.</p>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import config from '../../../config/config_local.js'
import moment from 'moment';
import Vue from 'vue'

export default {
    name: 'UserAbbonamentoCambia',
    data () {
        return {
            openAccordion: []
        }
    },
    
    methods: {

        toggleAccordion(index) {
            console.log('toggle');
            if(typeof this.openAccordion[index] == 'undefined') {
                Vue.set(this.openAccordion,  index, {
                    open: true
                } );
            } else {
                this.openAccordion[index].open = !this.openAccordion[index].open;
            }
        },

        /*
        Quando si fa UPGRADE: 
        metto disdetta sull'abb attuale POI (perchè se no ne vedo due nel passaggio seguente) il nuovo abb. parte da subito (inizio data abbonamento: oggi) 
        (devo far pagare l'utente apro finestra pagamento)

        Quando si fa DOWNGRADE: 
        metto disdetta sull'abb attuale POI faccio il nuovo che deve partire a: scadenza abb vecchio attuale meno un mese
        non si apre la finestra del pagamento (funzione regalo)
        */
        setAbbonamentoAndShowRegistration(_prod) {

            let dataInizio = this._calcAbbonamentoDowngradeDate();
            let now = moment();

            let testoAlert = '<strong>Confermi il cambio di abbonamento?</strong><br>';
            testoAlert = testoAlert + (_prod.Name + '<br>');
            testoAlert = testoAlert + 'Il nuovo abbonamento sarà attivo da:<br>';

            this.$root.$refs.pageMan.showAlert()
            let nextAbbName = _prod.Name;
            let curAbbName = this.$root.subscriptions.currentAbbonamentoName;
            if(curAbbName.includes('PREMIUM')) {
                //DOWNGRADE
                this.$root.$refs.pageMan.showAlert( testoAlert + dataInizio.format('DD/MM/YYYY'), 'OK','ANNULLA')
                .then( (okc) => {

                    this.$root.abbonamentiDoDisdettaSync()
                    .then( (okdisd) => {
                        this.$root.paymentGiftProduct(_prod, dataInizio)
                        .then( (okk) => {
                            this.$root.getMe();
                        })
                        .catch( (kko) => {
                            this.$root.$refs.pageMan.showAlert('Impossibile cambiare abbonamento. Contattare assistenza.','OK','');
                        });
                    })
                    .catch( (kodisd) => {
                        this.$root.$refs.pageMan.showAlert('Impossibile disdire abbonamento corrente. Contattare assistenza.','OK','');
                    });

                });
            } else if (curAbbName.includes('LIGHT')) {
                //UPGRADE
                this.$root.$refs.pageMan.showAlert( testoAlert + now.format('DD/MM/YYYY'), 'OK','ANNULLA')
                .then( (okc) => {
                    this.$root.paymentCreateProductsOrder(_prod);
                });
            } else if(curAbbName.includes('BASE') && nextAbbName.includes('PREMIUM')) {
                //UPGRADE
                this.$root.$refs.pageMan.showAlert( testoAlert + now.format('DD/MM/YYYY'), 'OK','ANNULLA')
                .then( (okc) => {
                    this.$root.paymentCreateProductsOrder(_prod);
                });

            } else if (curAbbName.includes('BASE') && nextAbbName.includes('LIGHT')) {
                // DOWNGRADE
                this.$root.$refs.pageMan.showAlert( testoAlert + dataInizio.format('DD/MM/YYYY'), 'OK','ANNULLA')
                .then( (okc) => {

                    this.$root.abbonamentiDoDisdettaSync()
                    .then( (okdisd) => {
                        this.$root.paymentGiftProduct(_prod, dataInizio)
                        .then( (okk) => {
                            this.$root.getMe();
                        })
                        .catch( (kko) => {
                            this.$root.$refs.pageMan.showAlert('Impossibile cambiare abbonamento. Contattare assistenza.','OK','');
                        });
                    })
                    .catch( (kodisd) => {
                        this.$root.$refs.pageMan.showAlert('Impossibile disdire abbonamento corrente. Contattare assistenza.','OK','');
                    });

                });
            }

        },

        _calcAbbonamentoDowngradeDate() {
            //in caso di downgrade il nuovo abbonamento deve partire a "data abbonamento che scade più tardi" meno un mese
            //gli abbonamenti sono ordinati per data, quindi il primo è quello che scade più tardi
            console.log(this.$root.subscriptions.abbonamenti[0].scad.format('YYYY-MM-DD'));
            let lastScadenza = this.$root.subscriptions.abbonamenti[0].scad.startOf('day');
            //let newInizio = lastScadenza.clone().subtract(1,'month');
            let newInizio = lastScadenza.clone().subtract(1,'days');
            return newInizio;
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #centro-logo {
        max-width:80%;
    }
</style>
