<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="text-center">
                        <p class="titolo-piccolo orange-text-black text-center mt-4 mb-2 pb-0" style="text-transform:uppercase;">LE MIE PRENOTAZIONI</p>
                        <p class="testo-generico orange-text-black text-center" >Prenotazioni attive.</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="$root.reservation.activeReservations.length > 0" class="container-fluid">
            <div v-for="acres,index in $root.reservation.activeReservations" :key="'actres-' + index" class="row testo-generico orange-text-white background-orange no-gutters p-2 my-3 border border-gray mb-2">
                <div class="col-12 px-2 py-2">
                    <div v-if="acres.AdditionalInfo != acres.ServiceDescription" class="row">
                        <div class="col-12">
                            <strong>{{ acres.ServiceDescription }}</strong>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <span v-if="acres.AdditionalInfo.length == 0">{{ acres.CategoryDescription }}</span>
                            <strong v-else>{{ acres.AdditionalInfo }}</strong>
                        </div>                        
                        <div v-on:click="clickCancelReservation(index)" class="col-6 text-right">
                            CANCELLA
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-4" style="font-size:1.1rem;">
                            <strong>{{ moment(acres.StartTime).format('DD/MM') }}</strong>
                        </div>
                        <div class="col-4" style="font-size:1.1rem;">
                            <strong>{{ moment(acres.StartTime).format('HH:mm') }} - {{ moment(acres.EndTime).format('HH:mm') }}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="$root.reservation.activeReservations.length == 0" class="container-fluid">
            <div class="row">
                <div class="col-12 text-center">
                    <p class="testo-generico orange-text-black">Non ci sono prenotazioni attive.</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import momentIT from 'moment/locale/it'

export default {
    name: 'PrenotaMie',
    data () {
        return {
        }
    },
    created() {
    },
    methods: {
        clickCancelReservation(index) {
            this.$root.reservationCancelReservation(index);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
