<template>
    <div ref="trainerMainCore">

        <div class="container-fluid mt-3 px-0">
            <div class="row pt-3 mt-5 h-100">
                <div v-if="$root.questionario.visible == true" class="col-12 text-center h-100">
                    <iframe style="border:none; width:100%; height:100vh; margin:0px;" :src="$root.questionario.url"></iframe>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'TrainerQuestionario',
    data () {
        return {
        }
    },
    methods: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
