//import ContactsX from 'cordova-plugin-contacts-x';

class PhoneContacts {

    constructor() {
        this.platform = 'web';
        this.platform = this.getPlatform();
        this.uuid = new Date().getUTCMilliseconds();
    }

    getPlatform() {
        if(typeof window.ContactsX != 'undefined') {
            return 'native';
        } else {
            return 'web';
        }
    }

    _selectPhoneContacts(_askPermission) {
        return new Promise( (resolve, reject) => {
            if(_askPermission) {
                try {
                    window.ContactsX.requestPermission( function(success) {
                        window.ContactsX.find( function(result) {
                            //console.log(result);
                            resolve({ contacts: [ result ], handMade: true, mode: 'permission' });
                        }, function (cerr) {
                            reject(cerr);
                        }, {
                            fields: {
                                displayName: true,
                                firstName: true,
                                middleName: true,
                                familyName: true,
                                emails: true,
                                phoneNumbers: true
                            }
                        });                    
                    }, function(perror) {
                        reject(perror);
                    });        
                } catch( tcerr ) {
                    console.log(tcerr);
                }
            } else {
                window.ContactsX.pick( function(result) {
                    //console.log(result);
                    resolve({ contacts: [ result ], handMade: true, mode: 'pick' });
                }, function (cerr) {
                    reject(cerr);
                });                    
            }
        });
    };

    _getModalCode(_title, _description) {
        return `
        <div class="modal-dialog modal-dialog-centered" role="document" style="z-index:4000;">
          <div class="modal-content">
            <div class="modal-body background-brown testo-generico orange-text-white">
              <p>${ _description }</p>
              <div class="row">
                <div class="col-12">
                    <label for="cont_name_input_${ this.uuid }">Nome e Cognome</label>
                    <input id="cont_name_input_${ this.uuid }" class="form-control rounded-0" type="text" placeholder="Nome e Cognome">
                </div>
                <div class="col-12 mt-3">
                    <label for="cont_num_input_${ this.uuid }">Numero Cellulare</label>
                    <input id="cont_num_input_${ this.uuid }" class="form-control rounded-0" type="text" placeholder="Numero Cellulare">
                </div>
                <div class="col-12 mt-3">
                    <label for="cont_email_input_${ this.uuid }">Email</label>
                    <input id="cont_email_input_${ this.uuid }" class="form-control rounded-0" type="text" placeholder="Email">
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6 px-2">
                    <button id="cancel_${this.uuid}" type="button" class="btn btn-block btn-orange border-white testo-bottoni btn-outline-white" data-dismiss="modal">Annulla</button>
                </div>
                <div class="col-6 px-2">
                    <button id="ok_${this.uuid}" type="button" class="btn btn-block btn-orange testo-bottoni btn-orange-orange">OK</button>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      `;
    }

    _handInsertContacts(_title, _description) {
        return new Promise( (resolve,reject) => {
            let div = document.createElement('div');
            //<div class="modal show" tabindex="-1" role="dialog">
            div.classList.add('modal', 'show', 'in', 'd-block', 'fade');
            div.role = "dialog";
            div.setAttribute('style', 'background-color:rgba(0,0,0,0.8); z-index:4000;');
            div.setAttribute('id','modal_' + this.uuid);
            div.innerHTML = this._getModalCode(_title, _description);
            document.body.appendChild(div);
            let okbtn = document.getElementById('ok_' + this.uuid);
            let cancelbtn = document.getElementById('cancel_' + this.uuid);
            okbtn.addEventListener('click', (event) => {
                let nome_input = document.getElementById('cont_name_input_' + this.uuid);
                if(nome_input.value.length < 7) {
                    nome_input.focus();
                    return;
                }
                let num_input = document.getElementById('cont_num_input_' + this.uuid);
                if(num_input.value.length < 7) {
                    num_input.focus();
                    return;
                }
                if(num_input.value.substring(0,1) != "3") {
                    num_input.focus();
                    return;
                }
                let email_input = document.getElementById('cont_email_input_' + this.uuid);
                if( (email_input.value.length < 7) || (email_input.value.indexOf('@') == -1) || ((email_input.value.indexOf('.') == -1))){
                    //we now accept invitation without an email
                    //email_input.focus();
                    //return;
                }
                
                resolve({ 
                    handMade: true,
                    mode: 'pick',
                    contacts : [{ 
                        contactId: 1,
                        displayName: nome_input.value, 
                        phoneNumbers: [ { number: num_input.value } ], 
                        emails: [ { address: email_input.value.trim().toLowerCase()  } ]
                    }]                 
                });
                this._closeModal();               
            });
            cancelbtn.addEventListener('click', (event) => {
                this._closeModal();
                reject(null);
            });
        });
    }

    _closeModal() {
        let modal = document.getElementById('modal_' + this.uuid);
        modal.remove();
    }

}

PhoneContacts.prototype.selectContacts = function(_title, _description) {     
    return new Promise( (resolve,reject) => {
        if(this.getPlatform() != 'web') {
            let me = this;
            //getPermissions is a promise! I should check the result
            window.ContactsX.hasPermission(function(success) {
                    if(success.read) {
                        me._selectPhoneContacts(false)
                        .then( (conts) => {
                            resolve(conts);
                        })
                        .catch( (err) => {
                            console.log(err);
                            reject(err);
                        })
                    } else {
                        me._selectPhoneContacts(true)
                        .then( (conts) => {
                            resolve(conts);
                        })
                        .catch( (err) => {
                            console.log(err);
                            reject(err);
                        })
                    }
                
                }, function (error) {
                    me._selectPhoneContacts(true)
                    .then( (conts) => {
                        resolve(conts);
                    })
                    .catch( (err) => {
                        console.log(err);
                        reject(err);
                    })
                }
            );
        } else {
            resolve(this._handInsertContacts(_title, _description));
        }                
    });
}


module.exports = PhoneContacts;