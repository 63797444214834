<template>
    <div v-if="$root.evofit.sedutaPlayer != null && $root.evofit.sedutaPlayer.scheda != null && $root.evofit.sedutaPlayer.scheda.curPlaylistEntry && $root.evofit.sedutaPlayer.scheda.isPlaying == true">

        <div v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.tipo == 'esercizio'" v-on:click.stop="$root.evofit.sedutaPlayer.playNextVideo(false)" class="skip-button">
            <span class="icon-skip"></span>
        </div>

        <div v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.tipo == 'esercizio'" v-on:click.stop="$root.evofit.sedutaPlayer.markAsFatto(true)" class="fatto-button background-orange orange-text-white testo-generico">
            <span class="fatto-testo">FATTO TUTTO</span><span class="fatto-icon icon-fatto_cerchio"></span>
        </div>

        <div v-if="($root.evofit.sedutaPlayer.scheda.curPlaylistEntry.durata == 0) && ($root.evofit.sedutaPlayer.scheda.curPlaylistEntry.tipo == 'esercizio')" v-on:click.stop="$root.evofitModificaSerieEsercizio" class="modifica-button">
            <span class="icon-lista"></span>
        </div>

        <div class="row no-gutters" v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry !=null && $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.tipo == 'esercizio'">
            <div class="col-12 col-md-6" style="overflow-x:hidden;">
                <div v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry" class="row" :style="{'height' : $root.ui.squareHeight + 'px', 'overflow' : 'hidden'}">
                    <div class="col-12 text-center p-0 text-center bg-white" style="overflow:hidden;">
                        <iframe v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.extvideo.includes('vimeo') && $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.extvideo != ''" 
                        style="width:100%; min-height:280px; border:none; background-color:#fff; overflow:hidden; margin-top:50%; transform:translateY(-50%);" 
                        :src="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.extvideo + '?background=1&autoplay=' + (($root.evofit.sedutaPlayer.scheda.isPlaying) ? '1' : '0')" 
                        :key="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.extvideo + 'ifr'"
                        scrolling="no">
        
                        </iframe>
                        <video id="esercizioVideo" v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.extvideo.includes('.mp4')" autoplay muted loop="loop" :key="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.extvideo" style="margin-left:auto; margin-right:auto; width:100%; height:280px; display:block; margin-top:50%; transform:translateY(-50%);">
                            <source :src="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.extvideo" type="video/mp4">
                        </video>
                    </div>
        
                   <!--  <div class="col-12 text-center px-3 py-1">
                        <h4 class="my-0" style="font-size:1.2rem;"><strong>{{ $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.descrizione }}</strong></h4>
                    </div>        -->
                </div>
            </div>

            <div class="col-12 col-md-6">

                <div class="row no-gutters">
                    <div v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.durata != 0" class="col-12">
                        <div class="progress m-0" style="border-radius:0px; height:5px;">
                            <div class="progress-bar bg-warning progress-bar-animated" role="progressbar" :style="{ 'width' : $root.evofit.sedutaPlayer.scheda.progress + '%' }" :aria-valuenow="$root.evofit.sedutaPlayer.scheda.progress" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
        
                <!-- automatico cardio -->
                <div class="row no-gutters" v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.durata != 0 && $root.evofit.sedutaPlayer.scheda.modalitaAutomatica">
                    <div class="col-6 pl-3 pt-3 testo-generico orange-text-orange">
                        <p class="mb-0"><strong>{{ $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.descrizione }}</strong></p>
                        <p>Cardio</p>
                    </div>
                    <div class="col-6">
                        <div class="row no-gutters">
                            <div class="col-4 text-center p-2 pt-3 icone-ripetizioni">
                                <!-- <h5><i class="fas fa-stopwatch"></i>&nbsp;{{ $root.evofit.sedutaPlayer.scheda.timerValue.seconds }}"</h5>
                                <p class="mb-1">TEMPO</p> -->
                                <span class="icon-ripetizioni" style="font-size:20px;"></span>
                                <span style="font-size:13px; padding-top:2px;"><b>{{ $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.ripetizioni }}&nbsp;</b></span>
                            </div>
                            <div class="col-4 text-center p-2 pt-3 icone-ripetizioni">
                                <span class="icon-durata" style="font-size:20px;"></span>
                                <span style="font-size:13px; padding-top:2px;"><b>{{ $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.durata }}&nbsp;</b></span>
                            </div>
                            <div class="col-4 text-center p-2 pt-3 icone-ripetizioni">
                                <!-- <span class="icon-durata" style="font-size:20px;"></span>
                                <span style="font-size:13px; padding-top:2px;"><b>{{ $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.durata }}&nbsp;</b></span> -->
                            </div>
                        </div>
                    </div>
                </div>
        
                <!-- manuale cardio -->
                <div class="row no-gutters" v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.durata != 0 && $root.evofit.sedutaPlayer.scheda.modalitaAutomatica == false">
                    <div class="col-6 pl-3 pt-3 testo-generico orange-text-orange">
                        <p class="mb-0"><strong>{{ $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.descrizione }}</strong></p>
                        <p v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.serieObj.tipo == 'car'">Cardio</p>
                        <p v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.serieObj.tipo == 'mus'">Muscolare</p>
                    </div>
                    <div class="col-6">
                        <div class="row no-gutters">
                            <div class="col-4 text-center p-2 pt-3 icone-ripetizioni">
                                <!-- <h5><i class="fas fa-stopwatch"></i>&nbsp;{{ $root.evofit.sedutaPlayer.scheda.timerValue.seconds }}"</h5>
                                <p class="mb-1">TEMPO</p> -->
                                <span class="icon-ripetizioni" style="font-size:20px;"></span>
                                <span style="font-size:13px; padding-top:2px;"><b>{{ $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.ripetizioni }}&nbsp;</b></span>
                            </div>
                            <div class="col-4 text-center p-2 pt-3 icone-ripetizioni">
                                <span class="icon-durata" style="font-size:20px;"></span>
                                <span style="font-size:13px; padding-top:2px;"><b>{{ $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.durata }}&nbsp;</b></span>
                            </div>
                            <div class="col-4 text-center p-2 pt-3 icone-ripetizioni">
                                <!-- <span class="icon-durata" style="font-size:20px;"></span>
                                <span style="font-size:13px; padding-top:2px;"><b>{{ $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.durata }}&nbsp;</b></span> -->
                            </div>
                        </div>
                    </div>
                </div>
        
                <!-- muscolare -->
                <div class="row no-gutters" v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.durata == 0">
                    <div class="col-4 pl-3 pt-3 testo-generico orange-text-orange">
                        <p class="mb-0"><strong>{{ $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.descrizione }}</strong></p>
                        <p v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.serieObj.tipo == 'car'">Cardio</p>
                        <p v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.serieObj.tipo == 'mus'">Muscolare</p>
                    </div>
                    <div class="col-8 pr-3">
                        <div class="row no-gutters" v-for="valore,serindex in $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.esercizio.serieObj.valori.filter( val => val.val1 != '')" :key="'valsereser-' + serindex" :class="{'orange-text-orange' : ($root.evofit.sedutaPlayer.scheda.curPlaylistEntry.idserie == serindex) }">
                            <div class="col-4 text-right pt-3 icone-ripetizioni" >
                                <!-- <h5><i class="fas fa-stopwatch"></i>&nbsp;{{ $root.evofit.sedutaPlayer.scheda.timerValue.seconds }}"</h5>
                                <p class="mb-1">TEMPO</p> -->
                                <span class="icon-ripetizioni" style="font-size:20px;" :style="{ 'opacity' : ($root.evofit.sedutaPlayer.scheda.curPlaylistEntry.idserie == serindex) ? '1' : '0' }"></span>
                                <span style="font-size:13px; padding-top:2px;"><b>{{ valore.val1 }}&nbsp;</b></span>
                            </div>
                            <div class="col-4 text-center pt-3 icone-ripetizioni">
                                <span class="icon-pesi" style="font-size:20px;" :style="{ 'opacity' : ($root.evofit.sedutaPlayer.scheda.curPlaylistEntry.idserie == serindex) ? '1' : '0' }" ></span>
                                <span style="font-size:13px; padding-top:2px;"><b>{{ valore.val2 }}&nbsp;</b></span>
                            </div>
                            <div class="col-4 text-left pt-3 icone-ripetizioni">
                                <span class="icon-recupero" style="font-size:20px;" :style="{ 'opacity' : ($root.evofit.sedutaPlayer.scheda.curPlaylistEntry.idserie == serindex) ? '1' : '0' }" ></span>
                                <span style="font-size:13px; padding-top:2px;"><b>{{ $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.recupero }}"&nbsp;</b></span>
                            </div>
                        </div>
                    </div>
                </div>
        
                <!-- prossimo -->
                <!-- <div v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next != null" class="row no-gutters">
                    <div class="col-2">                
                        &nbsp;
                    </div>
                    <div class="col-2 bg-white mx-0">
                        <img :src="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next.immagine" style="height:50px;">
                    </div>
                    <div class="col-6 bg-secondary text-white text-left">
                        <p class="mb-0">Prossimo esercizio</p>
                        <strong>{{ $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next.descrizione }}</strong>
                    </div>
                    <div class="col-2 text-center">                
                        <a class="btn text-white" v-on:click.prevent="$root.evofit.sedutaPlayer.playNextVideo(false)" href="#"><i class="fas fa-sign-out-alt"></i></a> 
                        <p class="mb-1">VAI A</p>                
                    </div>
                </div> 
        
                <div v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next == null" class="row no-gutters">
                    <div class="col-4">                
                        &nbsp;
                    </div>
                    <div class="col-6 bg-secondary text-white text-left px-2">
                        <p class="mb-0">Prossimo step</p>
                        <strong>RECUPERO</strong>
                    </div>
                    <div class="col-2 text-center">                
                        <a class="btn text-white" v-on:click.prevent="$root.evofit.sedutaPlayer.playNextVideo(false)" href="#"><i class="fas fa-sign-out-alt"></i></a> 
                        <p class="mb-1">VAI A</p>                
                    </div>
                </div> -->
                        
                <div class="row no-gutters">
                    <div class="col-12 p-4 pt-2" style="max-height: 300px; overflow-y:scroll;">
                        <p>{{ $root.evofit.sedutaPlayer.scheda.curConsiglio }}</p>
                    </div>
                </div>
        
            </div>
        </div>


    </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'PlayerEsegui',
    data () {
        return {
        }
    },
    methods: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .modifica-button {
        position:fixed;
        bottom: 80px;
        left:30px;
        background-color: rgba(0,0,0,0.6);
        color:#fff;
        height: 42px;
        width: 42px;
        text-align: center;
        line-height: 42px;
        padding: 0px;
        font-size:28px;
        z-index:300;
    }

    .modifica-button span {
        line-height: 42px;
        padding: 0px;
        font-size:28px;
    }

    .skip-button {
        position:fixed;
        bottom: 80px;
        right:30px;
        background-color: rgba(0,0,0,0.6);
        color:#fff;
        height: 42px;
        width: 42px;
        text-align: center;
        line-height: 42px;
        padding: 0px;
        font-size:28px;
        z-index:300;
    }

    .skip-button span {
        line-height: 42px;
        padding: 0px;
        font-size:28px;
    }

    .fatto-button {
        position:fixed;
        bottom: 80px;
        color:#fff;
        height: 42px;
        width: 170px;
        text-align: center;
        line-height: 42px;
        padding: 0px;
        left:50%;
        transform:translateX(-50%);
        z-index:300;
    }

    .fatto-testo {
        vertical-align: middle;
        position:relative;
        display:inline-block;
        height: 42px;
        margin-right:5px;
        line-height: 42px;
        padding-top:1px;
    }

    .fatto-button .fatto-icon {
        line-height: 42px;
        padding: 0px;
        font-size:20px;
        vertical-align: middle;
        position:relative;
        display:inline-block;
    }
</style>
