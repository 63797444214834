<template>
    <div>
        <div class="container-fluid mb-4">
            <div class="row mt-5">
                <div class="col-12 my-5 text-center">
                    <p class="titolo-piccolo orange-text-black text-center mt-4 mb-2 pb-0" style="text-transform:uppercase;">DOCUMENTI E ABBONAMENTO</p>
                    <p class="testo-generico orange-text-black text-center" >Qui puoi vedere e gestire tutti i documenti relativi al tuo abbonamento attivo.</p>
                </div>            
            </div>
            <div class="row text-center">
                <div v-if="$root.subscriptions.abbonamenti.length > 0" class="col-12">
                    <p class="testo-generico orange-text-black mb-0">Abbonamento attivo</p>
                    <p class="testo-generico orange-text-black mt-0 mb-0"><strong>{{ $root.subscriptions.currentAbbonamentoName }}</strong></p>
                </div>
            </div>
        </div>

        <div class="container-fluid border-top border-bottom">
            <div class="row">
                <div class="col-12 py-2 text-center">
                    <span class="titolo-piccolo orange-text-black">INVIA UN DOCUMENTO</span>
                    <p v-if="$root.isApple == false" class="testo-generico orange-text-black text-center" >Seleziona una foto o un documento dal tuo dispositivo per inviarci il documento.</p>
                    <p v-else class="testo-generico orange-text-black text-center" >Seleziona un documento dal tuo dispositivo per inviarci il documento.</p>
                </div>
                <div class="col-12 px-4 mt-3">
                    <select v-model="$root.documentUpload.Type" class="form-control">
                        <!-- <option value="CertificatoMedico" selected>Certificato Medico</option> -->
                        <option v-if="$root.isApple == false" value="FotoPersonale" selected>Foto Personale</option>
                        <option value="DocRiconoscimento" selected>Doc. Personale</option>
                    </select>
                </div>
            </div>
            <!--<div v-if="$root.documentUpload.Type == 'CertificatoMedico'" id="certData" ref="certData" class="row mt-4 px-2 mb-0">
                <div class="col-12 py-2 text-center">
                    <span class="titolo-piccolo orange-text-black">SCEGLI DATA CERTIFICATO MEDICO</span>
                </div>
                <div class="col-4">
                    <div class="ico-container-sel"><span class="icon-nascita"></span></div>
                    <select class="form-control orange-select" v-on:change="updateDataCert" v-model="dataCertAnno">
                        <option :key="'year--1' "  selected disabled value="-1">AAAA</option>  
                        <option v-for="year in datacertYears" :key="'certyear-' + year" :value="year">{{ year }}</option>
                    </select>
                </div>
                <div class="col-4">
                    <select class="form-control orange-select" v-on:change="updateDataCert" v-model="dataCertMese">
                        <option selected disabled value="-1">MMM</option>  
                        <option v-for="mese,index in mesi" :key="'certmonth-' + String(index + 1).padStart(2,'0')" :value="index">{{ mese }}</option>
                    </select>
                </div>
                <div class="col-4">
                    <select class="form-control orange-select" v-on:change="updateDataCert" v-model="dataCertGiorno">
                        <option  selected disabled value="-1">GG</option>  
                        <option v-for="giorno,index in datacertDays" :key="'certday-' + String(index + 1).padStart(2,'0')" :value="String(index + 1).padStart(2,'0')">{{ giorno }}</option>
                    </select>
                </div>
                <div v-if="validDataCert == false" class="col-12">
                    <span class="testo-generico orange-text-red">Data certificato medico non valida.</span>
                </div>
            </div> -->

            <!-- <div v-if="$root.subscriptions.certmedInScadenza" style="height:40px; line-height:40px;" class="row mt-5 mx-2 testo-generico background-red orange-text-white"> -->
            <div style="height:40px; line-height:40px;" class="row mt-2 mb-5 mx-2 testo-generico background-green orange-text-white">
                <div class="col-8 border-right testo-bottoni text-center">
                    <span v-if="$root.documentUpload.Type == 'CertificatoMedico'">CERTIFICATO MEDICO</span>
                    <span v-if="$root.documentUpload.Type == 'FotoPersonale'">FOTO PERSONALE</span>
                    <span v-if="$root.documentUpload.Type == 'DocRiconoscimento'">DOCUMENTO PERSONALE</span>
                </div>
                <div class="col-4 testo-bottoni text-center">
                    <label for="aggiungiDoc">
                    <input v-if="$root.isApple == false" :disabled="$root.documentUpload.documentDate == null && $root.documentUpload.Type == 'CertificatoMedico'" v-on:change.stop="$root.documentPrepareForUpload" type="file" id="aggiungiDoc" name="aggiungiDoc" capture="environment" accept="image/*,application/pdf">
                    AGGIUNGI &nbsp;&nbsp;<strong style="font-size:1.2rem;">+</strong>
                    <input v-if="$root.isApple" :disabled="$root.documentUpload.documentDate == null && $root.documentUpload.Type == 'CertificatoMedico'" v-on:change.stop="$root.documentPrepareForUpload" type="file" id="aggiungiDoc" name="aggiungiDoc" accept="application/pdf">
                    AGGIUNGI &nbsp;&nbsp;<strong style="font-size:1.2rem;">+</strong>
                    </label>
                </div>
            </div>
            <!-- <div v-if="$root.subscriptions.certmedInScadenza" class="row mt-3">
                <div class="col-12">
                    <p class="testo-generico orange-text-red text-center">Attualmente non richiesto <span v-if="$root.subscriptions.scadenzaCertMedico != null">il {{ $root.subscriptions.scadenzaCertMedico.format('DD-MM-YYYY')}}</span></p>
                </div>
            </div> -->
        </div>

        <div class="container-fluid border-bottom">
            <div class="row px-3">
                <div class="col-12 px-2 my-3">
                    <button v-on:click.stop="$root.$refs.pageMan.showPage('userricevutepage', true)" class="btn btn-block rounded-0 btn-success testo-bottoni py-2">DOCUMENTI COMMERCIALI</button>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row mt-5">
                <div class="col-12 px-2">
                    <div id="persdocsaccordion">
                        <div :key="'subprod-pers'" class="card orange-text-black border-0">
                            <div class="card-header collapsed p-0" :id="'heading-pers'">
                                <h5 class="mb-0">
                                    <button v-on:click="toggleAccordion(0)" class="btn btn-block btn-orange btn-outline-white orange-text-black" data-toggle="collapse" :data-target="'#docscoll-pers'" aria-expanded="true" aria-controls="collapseOne">
                                        DOCUMENTI PERSONALI
                                        <span style="float:right;" v-show="(openAccordion.hasOwnProperty(0) ) && (openAccordion[0].open)" class="orange-ico orange-ico-dark"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_chiudi"></span></span>
                                        <span style="float:right;" v-show="(!(openAccordion.hasOwnProperty(0))) || (!openAccordion[0].open)" class="orange-ico orange-ico-dark"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_apri"></span></span>
                                    </button>
                                </h5>
                            </div>

                            <div :id="'docscoll-pers'" class="collapse" :aria-labelledby="'heading-pers'" data-parent="#persdocsaccordion">
                                <div class="card-body testo-generico orange-text-black">
                                    <div v-for="doc,index in $root.currentDocuments.filter( d => d.Type == 1)" :key="'persdoc-' + index" class="row testo-generico orange-text-black py-2 border-bottom">
                                        <div class="col-10">
                                            {{ doc.FileName }}
                                        </div>
                                        <div v-on:click.stop="$root.documentDownload(index)" class="col-2">
                                            <span style="font-size:25px;" class="icon-pdf"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12 px-2">
                    <div id="firmdocsaccordion">
                        <div :key="'subprod-firm'" class="card orange-text-black border-0">
                            <div class="card-header collapsed p-0" :id="'heading-pers'">
                                <h5 class="mb-0">
                                    <button v-on:click="toggleAccordion(1)" class="btn btn-block btn-orange btn-outline-white orange-text-black" data-toggle="collapse" :data-target="'#docscoll-firm'" aria-expanded="true" aria-controls="collapseOne">
                                        DOCUMENTI FIRMATI
                                        <span style="float:right;" v-show="(openAccordion.hasOwnProperty(1) ) && (openAccordion[1].open)" class="orange-ico orange-ico-dark"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_chiudi"></span></span>
                                        <span style="float:right;" v-show="(!(openAccordion.hasOwnProperty(1))) || (!openAccordion[1].open)" class="orange-ico orange-ico-dark"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_apri"></span></span>
                                    </button>
                                </h5>
                            </div>

                            <div :id="'docscoll-firm'" class="collapse" :aria-labelledby="'heading-firm'" data-parent="#firmdocsaccordion">
                                <div class="card-body testo-generico orange-text-black">
                                    <div v-for="doc,index in $root.currentDocuments.filter( d => d.Type == 0)" :key="'firmdoc-' + index" class="row testo-generico orange-text-black py-2 border-bottom">
                                        <div class="col-10">
                                            {{ doc.FileName }}
                                        </div>
                                        <div v-on:click.stop="$root.documentDownload(index)" class="col-2">
                                            <span style="font-size:20px;" class="icon-pdf"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row mt-3">
                <div class="col-12 px-2">
                    <div id="conddocsaccordion">
                        <div :key="'subprod-cond'" class="card orange-text-black border-0">
                            <div class="card-header collapsed p-0" :id="'heading-pers'">
                                <h5 class="mb-0">
                                    <button v-on:click="toggleAccordion(2)" class="btn btn-block btn-orange btn-outline-white orange-text-black" data-toggle="collapse" :data-target="'#docscoll-cond'" aria-expanded="true" aria-controls="collapseOne">
                                        DOCUMENTI CONDIVISI
                                        <span style="float:right;" v-show="(openAccordion.hasOwnProperty(2) ) && (openAccordion[2].open)" class="orange-ico orange-ico-dark"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_chiudi"></span></span>
                                        <span style="float:right;" v-show="(!(openAccordion.hasOwnProperty(2))) || (!openAccordion[2].open)" class="orange-ico orange-ico-dark"><span style="right:10px; position:absolute; margin-top:-5px;" class="icon-accordion_apri"></span></span>
                                    </button>
                                </h5>
                            </div>

                            <div :id="'docscoll-cond'" class="collapse" :aria-labelledby="'heading-cond'" data-parent="#conddocsaccordion">
                                <div class="card-body testo-generico orange-text-black">
                                    <div v-for="doc,index in $root.currentDocuments.filter( d => d.Type == 2)" :key="'conddoc-' + index" class="row testo-generico orange-text-black py-2 border-bottom">
                                        <div class="col-10">
                                            {{ doc.FileName }}
                                        </div>
                                        <div v-on:click.stop="$root.documentDownload(index)" class="col-2">
                                            <span style="font-size:20px;" class="icon-pdf"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue'

import axios from 'axios'
import config from '../../../config/config_local.js'
import moment from 'moment';

export default {
    name: 'UserDocumenti',
    data () {
        return {
            mesi: { 1: 'Gen', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'Mag', 6: 'Giu', 7: 'Lug' , 8:'Ago', 9:'Set', 10: 'Ott', 11: 'Nov', 12:'Dic'},
            openAccordion: [],
            dataCertAnno: -1,
            dataCertGiorno: -1,
            dataCertMese: -1,
            validDataCert: true,
            dataCert: null
        }
    },
    computed: {
        datacertYears () {
            const year = new Date().getFullYear();
            return [year, year - 1];
        },
        datacertDays () {
            let padMese = String(this.dataCertMese).padStart(2,'0');
            var days = 30;
            if(this.dataCertAnno > -1) {
                days = moment(this.dataCertAnno + '-' + padMese + '-01 00:00:00.000').daysInMonth();
            }
            return Array.from({length: days}, (value, index) => 1 + index)
        }

    },
    methods: {
        toggleAccordion(index) {
            console.log('toggle');
            if(typeof this.openAccordion[index] == 'undefined') {
                Vue.set(this.openAccordion,  index, {
                    open: true
                } );
            } else {
                this.openAccordion[index].open = !this.openAccordion[index].open;
            }
        },

        updateDataCert() {
            if(this.dataCertAnno < 0 || this.dataCertMese < 0 || this.dataCertGiorno < 0) {
                this.validDataCert = false;
                return;
            }
            let year = String(this.dataCertAnno);
            let month = String(this.dataCertMese).padStart(2,'0');
            let day = String(this.dataCertGiorno).padStart(2,'0');
            this.dataCert = moment(year + '-' + month + '-' + day + ' 00:00:00.000');
            if(this.dataCert > moment()) {
                this.validDataCert = false;
                this.$root.documentUpload.documentDate = null;
            } else {
                this.validDataCert = true;
                this.$root.documentUpload.documentDate = this.dataCert;
            }
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.ico-bottone-documenti {
    font-size: 18px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 5px;
    margin-right: 10px;    
}

.ico-bottone-documenti-piu {
    font-size: 18px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 5px;
    margin-right: 10px;    
}

#aggiungiDoc {
    position:absolute;
    top:0px;
    left:0px;
    visibility:hidden;
    width:100%;
    height: 40px;
}
</style>