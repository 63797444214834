<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div v-on:click="closeThisOverlay()" class="col-2 pt-2 text-black">
                    <strong><span style="font-size:24px; line-height:45px;" class="icon-navig_chiudi"></span></strong>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-12 mb-5">
                    <p class="titolo-piccolo orange-text-black text-center">ALLENAMENTI PREFERITI</p>
                    <p v-if="$root.preferiti.length == 0" class="testo-generico orange-text-black">Non hai ancora aggiunto neussun preferito.</p>
                </div>
                <div v-for="pref,index in $root.preferiti" :key="'pref-' + index" class="col-12">
                    <div v-if="pref.pref_type == 'video'" class="row px-2 py-0 my-2">
                        <div v-on:click="_handlePreferitiVideoClick(pref.videoObj)" class="col-4 p-0">
                            <div v-if="pref.videoObj != null" class="preferiti-list-img" :style="{ 'background-image'  : 'url(' + pref.videoObj.url_preview + ')'}">
                            </div>
                        </div>
                        <div v-if="pref.videoObj != null" class="col-6 preferiti-list-description">
                            <p class="my-0 preferiti-title"><strong>{{ pref.videoObj.name }}</strong></p>
                            <p class="my-0">{{ pref.videoObj.istruttore }}</p>
                            <p class="mt-0 mb-2">{{ pref.videoObj.durata }} minuti</p>
                        </div>
                        <div v-if="pref.videoObj != null" class="col-2 preferiti-list-icons">
                            <span v-on:click.stop="$root.togglePreferitiVideo('video', pref.videoObj)" style="color:#EE7D2D;" class="icon-preferito_cerchio"></span>
                        </div>
                    </div>
                    <div v-if="pref.pref_type == 'scheda'" class="row px-2 py-0 my-2">
                        <div v-on:click="_handlePreferitiSchedaClick(pref)" class="col-4 p-0">
                            <div v-if="pref.schedaObj != null" class="preferiti-scheda-img" :style="{ 'background-image'  : 'url(img/obiettivi/MODELLO4_3/' + pref.schedaObj.descimmagine + '.jpeg)'}">
                            </div>
                        </div>
                        <div v-if="pref.schedaObj != null" class="col-6 preferiti-list-description">
                            <p class="my-0 preferiti-title"><strong>{{ pref.schedaObj.descrizione }}</strong></p>
                            <p class="my-0">{{ pref.schedaObj.livello }}</p>
                            <p class="mt-0 mb-2">Scheda</p>
                        </div>
                        <div v-if="pref.schedaObj != null" class="col-2 preferiti-list-icons">
                            <span v-on:click.stop="$root.togglePreferitiScheda( pref.schedaObj.codice,pref.schedaObj.descrizione)" style="color:#EE7D2D;" class="icon-preferito_cerchio"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import popper from 'popper.js'
import axios from 'axios'
import config from '../../config/config_local.js'
import Validator from '../../js/classes/classValidators'

export default {
    name: 'Preferiti',
    data () {
        return {
            checks: {
                valid: true
            }
        }
    },
    methods: {
        _handlePreferitiVideoClick(_video) {
            this.closeThisOverlay();
            if(this.$root.userAbbonamERateOK()) {
                this.$root.rebornShowVideo(_video.id);
            } else {
                this.$root.userRinnovaOCompra();
            }

        },

        _handlePreferitiSchedaClick(_pref) {
            this.closeThisOverlay();
            if(this.$root.userAbbonamERateOK()) {
                this.$root.evofitSetSchedaFromPreferiti(_pref.pref_id);
            } else {
                this.$root.userRinnovaOCompra();
            }
        },

        closeThisOverlay() {
            this.$root.$refs.pageMan.closeOverlay();
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #centro-logo {
        max-width:80%;
    }
</style>
