import "core-js/stable";
import "regenerator-runtime/runtime";

const Timer = require('easytimer');


export default class SedutaPlayer {

    constructor(_scheda, _showPage, _updateEvofitDiary, _endCallback) {
        this.scheda = null;
        this.endCallback = null;
        this.showPage = null;
        this.updateEvofitDiary = null;
        this.beep = null;

        this.mustPushPage = true;   //the first warmup will be pushed into page stack so taht the pageback leads to the correct page
        this.scheda = _scheda;
        this.endCallback = _endCallback;
        this.showPage = _showPage;
        this.updateEvofitDiary = _updateEvofitDiary;
        this.scheda.curPlaylistEntryIndex = 0;

        this.singleModeEsercizioIdx = null;
        this.singleModeEsercizioCodice = '';

        this.beep = new Audio('https://reborn.orangepalestre.it/audio/beep.mp3');
    }

    updateCurrentEsercizio() {
        let esIdx = this.scheda.curPlaylistEntryIndex;

        if(esIdx < this.scheda.curSedutaPlaylist.length) {

            this.scheda.curPlaylistEntry = this.scheda.curSedutaPlaylist[ esIdx ];
            
            if( (this.scheda.modalitaAutomatica == false) && (this.scheda.curPlaylistEntry.esercizio != null) ){
                if(this.scheda.curPlaylistEntry.tipo == 'esercizio') {
                    if(this.scheda.curPlaylistEntry.esercizio.codice != this.singleModeEsercizioCodice) {
                        //we found a different esercizio code, if we are in modalità manuale we should exit play mode
                        this.endCallback();
                        return;
                    }
                }
            }

            switch( this.scheda.curPlaylistEntry.tipo ) {
                case 'warmup':
                    //first warmup must be pushed into apge stack for pageback to work properly
                    this.showPage('getreadypage', this.mustPushPage);
                    this.mustPushPage = false;
                    break;
                case 'endreward':
                    this.showPage('getreadypage', false);
                    break;
                case 'recupero':
                    this.showPage('getreadypage',false);
                    break;
                case 'esercizio':
                    //in modalità manuale il primissimo eseguipage va pushato altrimenti il chiudi torna alla lista delle sedue e non alla lista esercizi
                    this.showPage('eseguipage', this.mustPushPage);
                    this.mustPushPage = false;
                    break;
            }    

            //setup timer if needed
            if( this.scheda.timer == null) {

                this.buildEsercizioConsigli( this.scheda.curPlaylistEntry );

                this.scheda.timer = new Timer();
                if(this.scheda.curPlaylistEntry.durata != 0)
                    this.scheda.timer.start({countdown: true, startValues: {seconds: this.scheda.curPlaylistEntry.durata}});
                else
                    this.scheda.timer.start();

                this.scheda.isPlaying = true;

                //start the timer for this excercise page (we ALWAYS NEED IT need it for hints)
                this.scheda.timer.addEventListener('secondsUpdated', (e) => {
                    if(this.scheda.timer) {
                        this.scheda.timerValue = this.scheda.timer.getTotalTimeValues();
                        if(this.scheda.curPlaylistEntry) {
                            this.scheda.progress = 100 - (this.scheda.timerValue.seconds / this.scheda.curPlaylistEntry.durata) * 100;
                            if(this.scheda.curPlaylistEntry.durata > 10) {
                                if(this.scheda.timerValue.seconds < 6) {
                                    this.beep.play();
                                }
                            }    
                        }     
                    }

                    if(this.scheda.curPlaylistEntry.tipo != "recupero")
                        this.updateConsigli();

                });

                this.scheda.timer.addEventListener('targetAchieved', (e) => {
                    //if(this.scheda.curPlaylistEntry.tipo != 'esercizio') {
                        this.playNextVideo(true);
                    //} else {
                    //    this.scheda.timer.stop();
                    //}
                });
            }


        } else {
            this.endCallback();
        }
    }

    markAsFatto(update) {
        //tell evofit we did this excercise first
        if(this.scheda.curPlaylistEntry.esercizio) {
            if(update == true) {
                this.updateEvofitDiary();
            }
        }

        //skip all this excercise serie playlist entries
        let curCodice = this.scheda.curPlaylistEntry.esercizio.codice;
        let curIdx = this.scheda.curPlaylistEntryIndex;
        let broke = false;
        while( curIdx < this.scheda.curSedutaPlaylist.length && broke == false) {
            if(this.scheda.curSedutaPlaylist[ curIdx ].tipo == 'esercizio') {
                if(this.scheda.curSedutaPlaylist[curIdx].esercizio.codice != curCodice) {
                    broke = true;
                    this.scheda.curPlaylistEntryIndex = curIdx;
                }
            }
            curIdx++;
        }

        // abbiamo fatto la rottura per trovare il prossimo esercizio...
        // o siamo arrivati alla fine della playlist?
        if(curIdx == this.scheda.curSedutaPlaylist.length ) {
            //non abbiamo fatto la rottura per il prossimo esercizio, significa che abbiamo svolto l'ultimo esercizio della playlist
            //dobbiamo uscire
            this.scheda.curPlaylistEntryIndex = this.scheda.curSedutaPlaylist.length - 1;
        }

        //also skip the next recupero if any
        if(this.scheda.modalitaAutomatica == false) {
            if(this.scheda.curSedutaPlaylist[ this.scheda.curPlaylistEntryIndex ].tipo == 'recupero') {
                this.scheda.curPlaylistEntryIndex++;
            }    
        }

        //ALMOST same as playNextVideo()
        if(this.scheda.timer != null) {
            this.scheda.timer.stop();
            this.scheda.timer = null;    
        }

        this.scheda.progress = 0;
        this.updateCurrentEsercizio();
    }

    start() {
        this.updateCurrentEsercizio();
    }

    //play a single excercise then call endCallBack
    startSingle(_esercizioCodice) {
        console.log('Executing single sercizio');
        //set the ID of the first esercizio according to its code
        let esIdx = 0;
        let found = false;
        while( esIdx < this.scheda.curSedutaPlaylist.length && found == false) {
            if(this.scheda.curSedutaPlaylist[ esIdx ].esercizio != null) {
                if(this.scheda.curSedutaPlaylist[ esIdx ].esercizio.codice == _esercizioCodice) {
                    found = true;
                    this.singleModeEsercizioIdx = esIdx;
                }
            }
            esIdx++;
        }

        if(found) {
            this.scheda.curPlaylistEntryIndex = this.singleModeEsercizioIdx;
            this.singleModeEsercizioCodice = _esercizioCodice;
            //reset timer
            this.scheda.timer = null;
            this.scheda.progress = 0;
            this.updateCurrentEsercizio();
        } else {
            console.log('ERROR! CAnnot find esercizio for startSingle()');
        }

    }

    pauseVideo() {
        this.scheda.isPlaying = false;
        this.scheda.timer.pause();
        this.updateVideoPlayState();
    }

    resumeVideo() {            
        this.scheda.isPlaying = true;
        this.scheda.timer.start();
        this.updateVideoPlayState();
    }

    updateVideoPlayState() {
        if(this.scheda.curPlaylistEntry.esercizio.extvideo.includes('vimeo')) {
            //stop/start the iframe
        }
        if(this.scheda.curPlaylistEntry.esercizio.extvideo.includes('.mp4')) {
            //stop/start the video element
            if(this.scheda.isPlaying == false) {
                let vid = document.getElementById('esercizioVideo');
                vid.pause();
            } else {
                let vid = document.getElementById('esercizioVideo');
                vid.play();
            }
        }
    }

    //this must be only called from evofit mixin (see evofitFineAllenamento and evofitFineSingoloEsercizio)
    stopAllenamento() {
        if(this.scheda.timer != null) {
            this.scheda.timer.stop();
            this.scheda.timer = null;    
        }
        this.scheda.curPlaylistEntry = null;  
        this.singleModeEsercizioCodice = '';
        this.singleModeEsercizioIdx = null;
    }


    playNextVideo(update = true) {
        console.log('Playing next video');
        //tell evofit we did this excercise first
        if(this.scheda.curPlaylistEntry.esercizio) {
            if(update == true)
                this.updateEvofitDiary();
        }

        if(this.scheda.timer != null) {
            this.scheda.timer.stop();
            this.scheda.timer = null;    
        }
        this.scheda.progress = 0;
        this.scheda.curPlaylistEntryIndex += 1;
        this.updateCurrentEsercizio();
    }

    updateConsigli() {
        if(this.scheda.curPlaylistEntry.tipo != 'esercizio') {
            this.scheda.curConsiglio = '';
            return;
        }

        if( (this.scheda.curConsiglio == '') && (this.scheda.consigli.length > 0) && (typeof(this.scheda.consigli[0]) != 'undefined') ) {
            this.scheda.curConsiglio = this.scheda.consigli[0].replace(/ *\[[^\]]*]/g, '');;                
        } 

        let secondi = this.scheda.timerValue.seconds;

        if((secondi % 10) == 0) {
            let rnd = Math.floor( Math.random() * this.scheda.consigli.length);
            if(typeof(this.scheda.consigli[ rnd ]) != 'undefined') {
                this.scheda.curConsiglio = this.scheda.consigli[ rnd ].replace(/ *\[[^\]]*]/g, '');;
            }
        }
    }

    buildEsercizioConsigli(curEntry) {
        if(curEntry.tipo == 'esercizio') {
            this.scheda.consigli = [];
            if(typeof(curEntry.esercizio.info) == 'undefined')
                return;

            if(curEntry.esercizio.info.descIniziale)
                this.scheda.consigli.push( curEntry.esercizio.info.descIniziale );

            if(curEntry.esercizio.info.descMov)
                this.scheda.consigli.push( curEntry.esercizio.info.descMov );

            if(curEntry.esercizio.info.descScopo)
                this.scheda.consigli.push( curEntry.esercizio.info.descScopo );

            if(curEntry.esercizio.info.descResp)
                this.scheda.consigli.push( curEntry.esercizio.info.descResp );

            if(curEntry.esercizio.info.descCons)
                this.scheda.consigli.push( curEntry.esercizio.info.descCons );

            if(curEntry.esercizio.info.descErr)
                this.scheda.consigli.push( curEntry.esercizio.info.descErr );

        }
    }

    

}

