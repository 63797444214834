<template>
    <div>
        <div class="container-fluid">
            <div v-if="$root.reborn.videosLoaded" class="row mt-5 pt-5 mb-0" >
                <div class="col-12 px-0 text-center">
                    <p class="titolo-piccolo orange-text-white"><strong>COSA VUOI FARE OGGI?</strong></p>
                </div>
            </div>
            <div class="row mx-1 mt-2 pb-0 border-bottom">
                <div class="col-10 text-left">
                   <input v-on:keyup.enter="eseguiRicerca" type="text" id="search-input" placeholder="Ricerca" v-model="searchText"> 
                </div>
                <div class="col-2 text-right">
                    <span v-on:click="eseguiRicerca" style="display:block; height:30px; width:30px; font-size:20px; color:#fff;" class="icon-tab_cerca"></span>
                </div>
            </div>
            <div class="row mt-4 pt-0 px-0">
                <div class="col-6">
                    <button v-on:click.stop="changeSearchType('video')" :class="{ 'btn-search-selected' : (curSearchType == 'video') }" class="testo-generico orange-border-dark btn-search">Video Lezioni</button>
                </div>
                <div class="col-6">
                    <button v-on:click.stop="changeSearchType('schede')" :class="{ 'btn-search-selected' : (curSearchType == 'schede') }" class="testo-generico orange-border-dark btn-search">Programmi Allenamento</button>
                </div>
            </div>

            <div v-if="curSearchType == 'video'" class="row mt-4 pt-0 px-0 testo-generico orange-text-white">
                <div v-on:click.stop="showFilter('categoria')" :class="{ 'orange-text-orange' : (videoFilters.categorie.length > 0) }" class="col-3 px-0 mx-0 text-center" style="height:30px; line-height:30px;">
                    <span class="icona-tipo-ricerca">Categoria<span class="icon-accordion_apri"></span></span>
                </div>
                <div v-on:click.stop="showFilter('durata')" :class="{ 'orange-text-orange' : (videoFilters.durate.length > 0) }" class="col-3 px-0 mx-0 text-center" style="height:30px; line-height:30px;">
                    <span class="icona-tipo-ricerca">Durata<span class="icon-accordion_apri"></span></span>
                </div>
                <div v-on:click.stop="showFilter('istruttori')" :class="{ 'orange-text-orange' : (videoFilters.istruttori.length > 0) }" class="col-3 px-0 mx-0 text-center" style="height:30px; line-height:30px;">
                    <span class="icona-tipo-ricerca">Istruttori<span class="icon-accordion_apri"></span></span>
                </div>
                <div v-on:click.stop="showFilter('attrezzi')" :class="{ 'orange-text-orange' : (videoFilters.attrezzi.length > 0) }" class="col-3 px-0 mx-0 text-center" style="height:30px; line-height:30px;">
                    <span class="icona-tipo-ricerca">Attrezzi<span class="icon-accordion_apri"></span></span>
                </div>
            </div>
            <div v-if="curSearchType == 'schede'" class="row mt-4 pt-0 px-0 testo-generico orange-text-white">
                <div v-on:click.stop="showFilter('obiettivi')" :class="{ 'orange-text-orange' : (schedeFilters.obiettivi.length > 0) }" class="col-6 px-0 mx-0 text-center" style="height:30px; line-height:30px;">
                    <span class="icona-tipo-ricerca">Obiettivi<span class="icon-accordion_apri"></span></span>
                </div>
                <div v-on:click.stop="showFilter('livelli')" :class="{ 'orange-text-orange' : (schedeFilters.livelli.length > 0) }" class="col-6 px-0 mx-0 text-center" style="height:30px; line-height:30px;">
                    <span class="icona-tipo-ricerca">Livelli<span class="icon-accordion_apri"></span></span>
                </div>
            </div>

        </div>

        <div v-if="curSearchType == 'video'" class="row mx-2">
            <div v-on:click.stop="confirmShowVideo(vidres)" v-for="vidres,index in $root.reborn.searchResults" class="col-6 p-1 mt-3 orange-text-white" :key="'vvidres-' + index"> 
                <div class="vvidres-box" :style="{ 'background-image' : 'url(' + vidres.url_preview + ')' }">
                    <div v-if="vidres.url_video == ''" class="video-blocked"><span class="icon-allert"></span></div>
                    <div class="vvidres-info testo-generico orange-text-white">
                        <p class="my-0"><strong>{{ vidres.name.toUpperCase() }}</strong></p>
                        <p class="my-0">{{ vidres.istruttore.toUpperCase() }}</p>
                        <p class="my-0">{{ vidres.durata }}"</p>
                    </div>
                </div>
            </div>
        </div>


        <div v-if="curSearchType == 'schede'" class="row mx-2">
            <div v-on:click="confirmShowScheda(index)" v-for="schedres,index in $root.evofit.searchResults" class="col-6 p-1 mt-3 orange-text-white" :key="'svidres-' + index"> 
                <div v-if="parseInt(schedres.descimmagine) < 50" class="svidres-box">
                    <img :src="'img/obiettivi/MODELLO1_1/' + schedres.descimmagine + '.jpeg'" class="svidres-image">
                    <div class="svidres-info testo-generico orange-text-white">
                        <p class="my-0"><strong>{{ schedres.descrizione.toUpperCase() }}</strong></p>
                        <p class="my-0">LIVELLO: {{ schedres.livello.toUpperCase() }}</p>
                        <p class="my-0">SEDUTE: {{ schedres.sedute }}</p>
                    </div>
                </div>
                <div v-else class="svidres-box">
                    <img src="img/obiettivi/MODELLO1_1/default.jpeg" class="svidres-image">
                    <div class="svidres-info testo-generico orange-text-white">
                        <p class="my-0"><strong>{{ schedres.descrizione.toUpperCase() }}</strong></p>
                        <p class="my-0">LIVELLO: {{ schedres.livello.toUpperCase() }}</p>
                        <p class="my-0">SEDUTE: {{ schedres.sedute }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div id="filter-modal-container" :class="{ 'filter-visible' : (filterVisible == true)}">
            <div class="row px-3 mt-3">
                <div class="col-12 px-3 text-right">
                    <span v-on:click.stop="closeFilters" style="color:#000; width:25px; height:25px; font-size:25px;" class="icon-navig_chiudi"></span>
                </div>
            </div>

            <div v-if="filterData.type == 'categoria'" class="row px-3 categorie-filter-container filter-modal">
                <div class="col-12 text-center">
                    <p class="titolo-piccolo orange-text-black">CATEGORIE</p>
                </div>
                <div class="col-6 mt-3 orange-text-black" v-for="videocat, index in $root.reborn.categorie.filter( cat => cat.name != 'Short')" :key="'catbtn-' + index">
                    <span :class="{ 'filter-btn-active' : videoFilters.categorie.includes(videocat.name) }"  v-on:click.stop="setFilterName('categorie', videocat.name)" class="filter-button testo-generico">{{ videocat.name }}</span>
                </div>
                <div class="col-12 mt-3 orange-text-black" :key="'catbtn-all'">
                    <span :class="{ 'filter-btn-active' : videoFilters.categorie.length == 0 }"  v-on:click.stop="setFilterName('categorie', '')" class="filter-button testo-generico">Tutte</span>
                </div>
            </div>

            <div v-if="filterData.type == 'durata'" class="row px-3 categorie-filter-container filter-modal">
                <div class="col-12 text-center">
                    <p class="titolo-piccolo orange-text-black">DURATE</p>
                </div>
                <div class="col-6 mt-4 orange-text-black">
                    <span :class="{ 'filter-btn-active' : videoFilters.durate.includes('50') }"  v-on:click.stop="setFilterName('durate', '50')" class="filter-button testo-generico">50"</span>
                </div>
                <div class="col-6 mt-4 orange-text-black">
                    <span :class="{ 'filter-btn-active' : videoFilters.durate.includes('30') }"  v-on:click.stop="setFilterName('durate', '30')" class="filter-button testo-generico">30"</span>
                </div>
                <div class="col-6 mt-4 orange-text-black">
                    <span :class="{ 'filter-btn-active' : videoFilters.durate.includes('15') }"  v-on:click.stop="setFilterName('durate', '15')" class="filter-button testo-generico">15"</span>
                </div>
                <div class="col-6 mt-4 orange-text-black">
                    <span :class="{ 'filter-btn-active' : videoFilters.durate.length == 0 }"  v-on:click.stop="setFilterName('durate', '')" class="filter-button text-center testo-generico">Tutte</span>
                </div>
            </div>

            <div v-if="filterData.type == 'istruttori'" class="row px-3 categorie-filter-container filter-modal">
                <div class="col-12 text-center">
                    <p class="titolo-piccolo orange-text-black">ISTRUTTORI</p>
                </div>
                <div class="col-4 mt-3 orange-text-black" v-for="videoist, index in $root.reborn.istruttori" :key="'istrbtn-' + index">
                    <span :class="{ 'filter-btn-active' : videoFilters.istruttori.includes(videoist) }"  v-on:click.stop="setFilterName('istruttori', videoist)" class="filter-button testo-generico">{{ videoist }}</span>
                </div>
                <div class="col-12 mt-3 orange-text-black" :key="'istrbtn-all'">
                    <span :class="{ 'filter-btn-active' : videoFilters.istruttori.length == 0 }"  v-on:click.stop="setFilterName('istruttori', '')" class="filter-button text-center testo-generico">Tutti</span>
                </div>
            </div>

            <div v-if="filterData.type == 'attrezzi'" class="row px-3 categorie-filter-container filter-modal">
                <div class="col-12 text-center">
                    <p class="titolo-piccolo orange-text-black">ATTREZZI</p>
                </div>
                <div class="col-6 mt-3 orange-text-black" v-for="attr, index in $root.reborn.attrezzi" :key="'attrbtn-' + index">
                    <span :class="{ 'filter-btn-active' : videoFilters.attrezzi.includes(attr) }"  v-on:click.stop="setFilterName('attrezzi', attr)" class="filter-button testo-generico">{{ attr }}</span>
                </div>
                <div class="col-12 mt-3 orange-text-black" :key="'attrbtn-all'">
                    <span :class="{ 'filter-btn-active' : videoFilters.attrezzi.length == 0 }"  v-on:click.stop="setFilterName('attrezzi', '')" class="filter-button text-center testo-generico">Tutti</span>
                </div>
            </div>


            <div v-if="filterData.type == 'obiettivi'" class="row px-3 categorie-filter-container filter-modal">
                <div class="col-12 text-center">
                    <p class="titolo-piccolo orange-text-black">OBIETTIVI</p>
                </div>
                <div class="col-6 mt-3 orange-text-black" v-for="obiet, index in $root.evofit.modsched.obiettivi" :key="'obibtn-' + index">
                    <span :class="{ 'filter-btn-active' : schedeFilters.obiettivi.includes(obiet.descrizione) }"  v-on:click.stop="setFilterName('obiettivi', obiet.descrizione)" class="filter-button testo-generico">{{ obiet.descrizione }}</span>
                </div>
                <div class="col-12 mt-3 orange-text-black" :key="'catbtn-all'">
                    <span :class="{ 'filter-btn-active' : schedeFilters.obiettivi.length == 0 }"  v-on:click.stop="setFilterName('obiettivi', '')" class="filter-button text-center testo-generico">Tutti</span>
                </div>
            </div>

            <div v-if="filterData.type == 'livelli'" class="row px-3 categorie-filter-container filter-modal">
                <div class="col-12 text-center">
                    <p class="titolo-piccolo orange-text-black">LIVELLI</p>
                </div>
                <div class="col-12 mt-3 orange-text-black">
                    <span :class="{ 'filter-btn-active' : schedeFilters.livelli.includes('PRINCIPIANTE') }"  v-on:click.stop="setFilterName('livelli', 'PRINCIPIANTE')" class="filter-button testo-generico">Principiante</span>
                </div>
                <div class="col-12 mt-3 orange-text-black">
                    <span :class="{ 'filter-btn-active' : schedeFilters.livelli.includes('INTERMEDIO') }"  v-on:click.stop="setFilterName('livelli', 'INTERMEDIO')" class="filter-button testo-generico">Intermedio</span>
                </div>
                <div class="col-12 mt-3 orange-text-black">
                    <span :class="{ 'filter-btn-active' : schedeFilters.livelli.includes('AVANZATO') }"  v-on:click.stop="setFilterName('livelli', 'AVANZATO')" class="filter-button testo-generico">Avanzato</span>
                </div>
                <div class="col-12 mt-3 orange-text-black" :key="'catbtn-all'">
                    <span :class="{ 'filter-btn-active' : schedeFilters.livelli.length == 0 }"  v-on:click.stop="setFilterName('livelli', '')" class="filter-button text-center testo-generico">Tutti</span>
                </div>
            </div>

            <div class="row px-3 mt-3">
                <div class="col-12 px-3">
                    <button v-on:click.stop="eseguiRicerca" class="btn btn-block btn-orange btn-orange-gray testo-bottoni">APPLICA</button>
                </div>
            </div>
        </div>


    </div>
</template>

<script>

export default {
    name: 'SearchPage',
    data () {
        return {
            searchText: '',
            curSearchType: 'video',
            filterVisible: false,
            filterData: {
                type: 'categoria',
            },
            videoFilters: {
                categorie: [],
                durate: [],
                istruttori: [],
                attrezzi:[]
            },
            schedeFilters: {
                obiettivi: [],
                livelli:[]
            }   
        }
    },
    created() {

    },
    methods: {

        confirmShowVideo(_video) {
            if(this.$root.userAbbonamERateOK()) {
                let prompt = 'Vuoi aprire la seguente video lezione?<br><br>';
                prompt += '<strong>' + _video.name.toUpperCase() + '</strong><br>';
                prompt += '<p>Durata: ' + _video.durata + ' min</p>';
                prompt += '<p>Istruttore: ' + _video.istruttore + ' min</p>';
                prompt += '<p>' + _video.description + '</p>';
                this.$root.$refs.pageMan.showAlert( prompt, 'SI','NO')
                .then( (sipress) => {
                    this.$root.rebornShowVideo(_video.id)
                })
                .catch( (nores) => {
                    console.log(nores);
                });
            } else {
                if(this.$root.isApple) {
                    //APPLE
                    this.$root.showAppleMessage();
                } else {
                    //NOT APPLE
                    let prompt = '';
                    prompt += '<strong>' + _video.name.toUpperCase() + '</strong><br>';
                    prompt += '<p>Durata: ' + _video.durata + ' min</p>';
                    prompt += '<p>Istruttore: ' + _video.istruttore + ' min</p>';
                    prompt += '<p>' + _video.description + '</p>';
                    prompt += 'Necessario abbonamento attivo per accedere a videolezioni REBORN. Vuoi attivare un abbonamento adesso?'
                    this.$root.$refs.pageMan.showAlert( prompt, 'SI','NO')
                    .then( (sipress) => {
                        this.$root.userRinnovaOCompra();
                    })
                    .catch( (nores) => {
                        console.log(nores);                    
                    });

                }
            }
        },

        confirmShowScheda(_index) {
            //BEWARE: _index is the index inside the searchResults array, not the modesched.schede array
            //let scheda = this.$root.evofit.modsched.schede[ _index ];
            let scheda = this.$root.evofit.searchResults[ _index ];
            if( (this.$root.userAbbonamERateOK()) || (scheda.descrizione.toLowerCase().indexOf('free') != -1) ) {
                //retrieve the real scheda index inside the modsched.schede array
                let schedaIndex = 0;
                this.$root.evofit.modsched.schede.forEach( (sch, index) => {
                    if(scheda.codice == sch.codice) {
                        schedaIndex = index;
                    }
                });

                //load this scheda inside the curObiettivoSchede array
                this.$root._evofitGetSchedaModello(schedaIndex)
                .then( (ressched) => {
                    let prompt = 'Vuoi allenarti con questa scheda?<br><br>';
                    prompt += '<strong>' + scheda.descrizione + '</strong><br>';
                    prompt += '<p class="mb-0">Livello: ' + scheda.livello + '</p>';
                    prompt += '<p class="mb-0">Obiettivo: ' + scheda.obiettivo + '</p>';
                    prompt += '<p class="mb-0">Sedute: ' + scheda.sedute + '</p>';

                    this.$root.$refs.pageMan.showAlert( prompt, 'SI','NO')
                    .then( (oksched) => {
                        //we loaded only one scheda inside the curObiettivoSchede, so we load the index = 0
                        this.$root.evofitSetSchedaFromModello(0);
                    });
                })
                .catch( (errsched) => {
                    console.log('Search: Cannot set scheda modello.');
                    console.log(errsched);
                });
            } else {
                if(this.$root.isApple) {
                    //APPLE
                    this.$root.$refs.pageMan.showAlert('La scheda che hai scelto <b>non è adatta</b> ad un profilo non verificato, per utilizzarla devi verificare il tuo profilo in un centro Orange.<br>Vuoi vedere ora la lista dei centri Orange?','LISTA CENTRI','CHIUDI')
                    .then( (vai) => {
                        this.showPage('listacentripage');
                    })
                    .catch( (chiudi) => {

                    });
                } else {
                    //NON APPLE
                    let prompt = '';
                    prompt += '<strong>' + scheda.descrizione + '</strong><br>';
                    prompt += '<p class="mb-0">Livello: ' + scheda.livello + '</p>';
                    prompt += '<p class="mb-0">Obiettivo: ' + scheda.obiettivo + '</p>';
                    prompt += '<p class="mb-0">Sedute: ' + scheda.sedute + '</p><br>';
                    prompt += 'Necessario abbonamento attivo per allenarsi con le schede Orange. Vuoi attivare un abbonamento adesso?'
                    this.$root.$refs.pageMan.showAlert( prompt, 'SI','NO')
                    .then( (sipress) => {
                        this.$root.userRinnovaOCompra();
                    })
                    .catch( (nores) => {
                        console.log(nores);
                    });

                }
            }
        },

        changeSearchType(_newtype) {
            this.curSearchType = _newtype;
            this.searchText = '';
        },

        closeFilters() {
            this.filterVisible = false;
        },

        eseguiRicerca() {
            if(this.curSearchType == 'video') {
                this.eseguiRicercaVideo()
            } else {
                this.eseguiRicercaSchede();
            }
        },

        eseguiRicercaSchede() {
            this.closeFilters();
            if(this.searchText != '') {
                this.$root.evofit.searchResults = [];
                this.$root.evofit.modsched.schede.forEach( (scheda) => {
                    if(scheda.descrizione.toLowerCase().includes( this.searchText.toLowerCase())) {
                        this.$root.evofit.searchResults.push( scheda );
                    }
                });
            } else {
                this.$root.evofit.searchResults = [];
                this.$root.evofit.modsched.schede.forEach( (scheda) => {
                    let validObiet = false;
                    let validLivel = false;

                    if(this.schedeFilters.obiettivi.length == 0) {
                        validObiet = true;
                    } else {
                        this.schedeFilters.obiettivi.forEach( (filtob) => {
                            if(scheda.obiettivo == filtob) {
                                validObiet = true;
                            }
                        });
                    }

                    if(this.schedeFilters.livelli.length == 0) {
                        validLivel = true;
                    } else {
                        this.schedeFilters.livelli.forEach( (filtliv) => {
                            if(scheda.livello == filtliv) {
                                validLivel = true;
                            }
                        });
                    }

                    if(validObiet && validLivel) {
                        this.$root.evofit.searchResults.push( scheda );
                    }

                });
            }

            this.$root.evofit.searchResults.sort( () => Math.random() - 0.5);

        },


        eseguiRicercaVideo() {
            this.closeFilters();
            if(this.searchText != '') {
                this.$root.reborn.searchResults = [];
                this.$root.reborn.videos.forEach( (video) => {
                    if(video.name.toLowerCase().includes( this.searchText.toLowerCase()) || video.description.toLowerCase().includes( this.searchText.toLowerCase()) || video.tags.toLowerCase().includes( this.searchText.toLowerCase())) {
                        this.$root.reborn.searchResults.push( video );
                    }
                });
            } else {
                this.$root.reborn.searchResults = [];
                this.$root.reborn.videos.forEach( (video) => {
                    if(video.name.indexOf('CYCLING') != -1) {
                        let a = 10;
                    }
                    let validCat = false;
                    let validDur = false;
                    let validIstr = false;
                    let validAttr = false;

                    if(this.videoFilters.categorie.length == 0) {
                        validCat = true;
                    } else {
                        this.videoFilters.categorie.forEach( (filtcat) => {
                            video.categorie.forEach( (vidcat) => {
                                if(vidcat.name == filtcat) {
                                    validCat = true;
                                }
                            });
                        });
                    }

                    if(this.videoFilters.durate.length == 0) {
                        validDur = true;
                    } else {
                        this.videoFilters.durate.forEach( (filtdur) => {
                            if(video.durata == parseInt(filtdur)) {
                                validDur = true;
                            }
                        });
                    }

                    if(this.videoFilters.istruttori.length == 0) {
                        validIstr = true;
                    } else {
                        this.videoFilters.istruttori.forEach( (filtistr) => {
                            if(video.istruttore == filtistr) {
                                validIstr = true;
                            }
                        });
                    }

                    if(this.videoFilters.attrezzi.length == 0) {
                        validAttr = true;
                    } else {
                        this.videoFilters.attrezzi.forEach( (filtattr) => {
                            if(video.attrezzi && video.attrezzi.length > 0) {
                                video.attrezzi.foreach( (attr) => {
                                    if(attr.name == filtattr) {
                                        validAttr = true;
                                    }
                                });
                            }
                        });
                    }

                    if(validCat && validDur && validIstr && validAttr) {
                        this.$root.reborn.searchResults.push( video );
                    }
                });
            }

            this.$root.reborn.searchResults.sort( () => Math.random() - 0.5);

        },

        showFilter(_filterType) {
            this.filterVisible = !this.filterVisible;
            this.filterData.type = _filterType;
        },

        setFilterName(_type, _newVal) {
            switch(_type) {
                case 'categorie':
                    this._setCategoryFilter(_newVal);
                    break;
                case 'durate':
                    this._setDurateFilter(_newVal);
                    break;
                case 'istruttori':
                    this._setIstruttoriFilter(_newVal);
                    break;
                case 'attrezzi':
                    this._setAttrezziFilter(_newVal);
                    break;
                case 'obiettivi':
                    this._setObiettiviFilter(_newVal);
                    break;
                case 'livelli':
                    this._setLivelliFilter(_newVal);
                    break;
            }
        }, 

        _setObiettiviFilter(_newVal) {
            if(_newVal == '') {
                this.schedeFilters.obiettivi = [];
                return;
            }
            if(this.schedeFilters.obiettivi.includes( _newVal) ) {
                let i = 0;
                for(i = 0; i < this.schedeFilters.obiettivi.length; i++) {
                    if(this.schedeFilters.obiettivi[i] == _newVal) {
                        this.schedeFilters.obiettivi.splice(i,1);
                        i--;
                    }
                }
            } else {
                this.schedeFilters.obiettivi.push( _newVal );
            }
        },

        _setLivelliFilter(_newVal) {
            if(_newVal == '') {
                this.schedeFilters.livelli = [];
                return;
            }
            if(this.schedeFilters.livelli.includes( _newVal) ) {
                let i = 0;
                for(i = 0; i < this.schedeFilters.livelli.length; i++) {
                    if(this.schedeFilters.livelli[i] == _newVal) {
                        this.schedeFilters.livelli.splice(i,1);
                        i--;
                    }
                }
            } else {
                this.schedeFilters.livelli.push( _newVal );
            }
        },

        _setCategoryFilter(_newVal) {
            if(_newVal == '') {
                this.videoFilters.categorie = [];
                return;
            }
            if(this.videoFilters.categorie.includes( _newVal) ) {
                let i = 0;
                for(i = 0; i < this.videoFilters.categorie.length; i++) {
                    if(this.videoFilters.categorie[i] == _newVal) {
                        this.videoFilters.categorie.splice(i,1);
                        i--;
                    }
                }
            } else {
                this.videoFilters.categorie.push( _newVal );
            }
        },

        _setDurateFilter(_newVal) {
            if(_newVal == '') {
                this.videoFilters.durate = [];
                return;
            }
            if(this.videoFilters.durate.includes( _newVal) ) {
                let i = 0;
                for(i = 0; i < this.videoFilters.durate.length; i++) {
                    if(this.videoFilters.durate[i] == _newVal) {
                        this.videoFilters.durate.splice(i,1);
                        i--;
                    }
                }
            } else {
                this.videoFilters.durate.push( _newVal );
            }
        },

        _setIstruttoriFilter(_newVal) {
            if(_newVal == '') {
                this.videoFilters.istruttori = [];
                return;
            }
            if(this.videoFilters.istruttori.includes( _newVal) ) {
                let i = 0;
                for(i = 0; i < this.videoFilters.istruttori.length; i++) {
                    if(this.videoFilters.istruttori[i] == _newVal) {
                        this.videoFilters.istruttori.splice(i,1);
                        i--;
                    }
                }
            } else {
                this.videoFilters.istruttori.push( _newVal );
            }
        },

        _setAttrezziFilter(_newVal) {
            if(_newVal == '') {
                this.videoFilters.attrezzi = [];
                return;
            }
            if(this.videoFilters.attrezzi.includes( _newVal) ) {
                let i = 0;
                for(i = 0; i < this.videoFilters.attrezzi.length; i++) {
                    if(this.videoFilters.attrezzi[i] == _newVal) {
                        this.videoFilters.attrezzi.splice(i,1);
                        i--;
                    }
                }
            } else {
                this.videoFilters.attrezzi.push( _newVal );
            }
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#search-input {
    display:block;
    width:100%;
    background-color:#000;
    font-family: 'Circe',Arial,helvetica,sans-serif;
    color:#fff;
    border:none;
    outline:none;
    box-shadow:none;
}

.btn-search {
    display:block;
    border:1px solid #666;
    border-radius:13px;
    width: 100%;
    padding-top:5px;
    padding-bottom:5px;
    color:#fff !important;
    background-color:#000;
}

.btn-search-selected {
    background-color: #ef7e2d;
    color:#fff !important;
    border-color: #ef7e2d !important;
}

.icona-tipo-ricerca {
    display:inline-block;
    height: 30px;
    line-height: 30px;
}

.icona-tipo-ricerca span {
    display:inline-block;
    font-size:18px !important;
    line-height: 30px !important;
    height: 30px !important;
}

#filter-modal-container {
    position:fixed;
    bottom:-500px;
    height: 500px;
    width: 100%;
    display: block;
    background-color:#fff;
    z-index:4000;
    transition:all 0.3s linear;
}

.filter-visible {
    bottom: 0px !important;
    transition:all 0.3s linear;
}

.filter-button {
    height: 30px;
    line-height: 30px;
    border:1px solid #666;
    border-radius: 13px;
    text-align: left;
    padding-left:10px;
    display:block;
    width: 100%;
    color:#000 !important;
}

.filter-btn-active {
    background-color: orange;
    color:#fff !important;
    border:1px solid orange;
}


.svidres-box {
    text-align: center;
}

.svidres-info {
    position:relative;
    bottom:0px;
    left:0px;
    width:100%;
    background-color:rgba(0,0,0,0.6);
    height:60px;
    padding:5px;
    padding-left:10px;
}

.svidres-image {
    display:block;
    width:80%;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    background-position: center center;
    background-size: cover;
}

.svidres-info p, .filter-button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
}


.vvidres-box {
    height: 180px;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}

.vvidres-info {
    position:absolute;
    bottom:0px;
    left:0px;
    width:100%;
    background-color:rgba(0,0,0,0.6);
    height:60px;
    padding:5px;
    padding-left:10px;
}

.vvidres-image {
    display:block;
    width:80%;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    background-position: center center;
    background-size: cover;
}

.vvidres-info p, .filter-button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
}

</style>
