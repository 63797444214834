<template>
    <div v-if="$root.evofit.sedutaPlayer != null && $root.evofit.sedutaPlayer.scheda != null && $root.evofit.sedutaPlayer.scheda.curPlaylistEntry && $root.evofit.sedutaPlayer.scheda.isPlaying == true">

        <div v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry!= null && $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.tipo != 'endreward'" v-on:click.stop="$root.evofit.sedutaPlayer.playNextVideo(false)" class="skip-button">
            <span class="icon-skip"></span>
        </div>

        <!-- warmup -->
        <div v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry != null && $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.tipo == 'warmup'" class="row no-gutters" style="background-color:white; padding-top:45px;">
            <div class="col-12 text-center p-5" :style="{ 'height' : $root.ui.squareHeight + 'px' }">
                <div id="warmup-circle">
                    <div id="warmup-info">
                        <p class="testo-generico orange-text-orange">INIZIAMO IN</p>
                        <p v-if="$root.evofit.sedutaPlayer.scheda.timerValue != null" class="titolo-grande" style="font-size:4rem">{{ $root.evofit.sedutaPlayer.scheda.timerValue.toString(['minutes','seconds']) }}</p>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="progress m-0" style="height:5px;">
                    <div class="progress-bar bg-warning" role="progressbar" :style="{ 'width' : $root.evofit.sedutaPlayer.scheda.progress + '%' }" :aria-valuenow="$root.evofit.sedutaPlayer.scheda.progress" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>

            <div v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry!= null && $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next != null" class="col-12 testo-generico orange-text-black px-2">
                <p class="my-4 text-center">PROSSIMO ESERCIZIO</p>
                <div class="row">
                    <div class="col-6">
                        <p class="mb-0"><strong>{{ $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next.descrizione }}</strong></p>
                        <p v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next.serieObj.tipo == 'car'">Cardio</p>
                        <p v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next.serieObj.tipo == 'mus'">Muscolare</p>
                    </div>
                    <div v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next.serieObj.tipo == 'car'" class="col-6">
                        <div class="esercizio-serie" v-for="valore,serindex in $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next.serieObj.valori.filter( val => val.val1 != '')" :key="'valsernxt-' + serindex">
                            <p>
                                <span class="icon-durata"></span>
                                <span style="font-size:13px; padding-top:3px;"><b>{{ valore.val1 }}&nbsp;</b></span>
                            </p>
                            <span v-if="valore.val2 != ''">{{ valore.val2 }}&nbsp;<span class="icon-durata"></span></span>
                        </div>
                    </div>
                    <div v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next.serieObj.tipo == 'mus'" class="col-6">
                        <div class="esercizio-serie" v-for="valore,serindex in $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next.serieObj.valori.filter( val => val.val1 != '')" :key="'valsernxt-' + serindex" :class="{'orange-text-orange' : ($root.evofit.sedutaPlayer.scheda.curPlaylistEntry.idserie == serindex) }">
                            <p>
                                <span class="icon-durata"></span>
                                <span style="font-size:13px; padding-top:3px;"><b>{{ valore.val1 }}&nbsp;</b></span>
                            </p>
                            <p>
                                <span class="icon-pesi"></span>
                                <span style="font-size:13px; padding-top:3px;" v-if="valore.val2 != ''"><b>{{ valore.val2 }}&nbsp;</b></span>
                            </p>
                            <p v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.recupero != 0">
                                <span class="icon-recupero"></span>
                                <span style="font-size:13px; padding-top:3px;" v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.recupero != 0"><b>{{ $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.recupero }}"</b></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- recupero -->
        <div v-if="($root.evofit.sedutaPlayer.scheda.curPlaylistEntry != null) && ($root.evofit.sedutaPlayer.scheda.curPlaylistEntry.tipo == 'recupero')" class="row no-gutters" style="background-color:white; padding-top:45px;">
            <div class="col-12 text-center p-5" :style="{ 'height' : $root.ui.squareHeight + 'px' }">
                <div id="warmup-circle">
                    <div id="warmup-info">
                        <p class="testo-generico orange-text-orange">RECUPERO</p>
                        <p v-if="$root.evofit.sedutaPlayer.scheda.timerValue != null" class="titolo-grande" style="font-size:4rem">{{ String($root.evofit.sedutaPlayer.scheda.timerValue.minutes).padStart(2,'0') }}:{{ String($root.evofit.sedutaPlayer.scheda.timerValue.seconds % 60).padStart(2,'0') }}</p>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="progress m-0" style="height:5px;">
                    <div class="progress-bar bg-warning" role="progressbar" :style="{ 'width' : $root.evofit.sedutaPlayer.scheda.progress + '%' }" :aria-valuenow="$root.evofit.sedutaPlayer.scheda.progress" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>

            <div v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next != null" class="col-12 testo-generico orange-text-black px-2">
                <p class="my-4 text-center">PROSSIMO ESERCIZIO</p>
                <div class="row">
                    <div class="col-6">
                        <p class="mb-0"><strong>{{ $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next.descrizione }}</strong></p>
                        <p v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next.serieObj.tipo == 'car'">Cardio</p>
                        <p v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next.serieObj.tipo == 'mus'">Muscolare</p>
                    </div>
                    <div v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next.serieObj.tipo == 'car'" class="col-6">
                        <div class="esercizio-serie" v-for="valore,serindex in $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next.serieObj.valori.filter( val => val.val1 != '')" :key="'valsernxt-' + serindex">
                            <p>
                                <span class="icon-durata"></span>
                                <span style="font-size:13px; padding-top:3px;"><b>{{ valore.val1 }}&nbsp;</b></span>
                            </p>
                            <span v-if="valore.val2 != ''">{{ valore.val2 }}&nbsp;<span class="icon-durata"></span></span>
                        </div>
                    </div>
                    <div v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next.serieObj.tipo == 'mus'" class="col-6">
                        <div class="esercizio-serie" v-for="valore,serindex in $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.next.serieObj.valori.filter( val => val.val1 != '')" :key="'valsernxt-' + serindex" :class="{'orange-text-orange' : ($root.evofit.sedutaPlayer.scheda.curPlaylistEntry.idserie == serindex) }">
                            <p>
                                <span class="icon-durata"></span>
                                <span style="font-size:13px; padding-top:3px;"><b>{{ valore.val1 }}&nbsp;</b></span>
                            </p>
                            <p>
                                <span class="icon-pesi"></span>
                                <span style="font-size:13px; padding-top:3px;" v-if="valore.val2 != ''"><b>{{ valore.val2 }}&nbsp;</b></span>
                            </p>
                            <p v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.recupero != 0">
                                <span class="icon-recupero"></span>
                                <span style="font-size:13px; padding-top:3px;" v-if="$root.evofit.sedutaPlayer.scheda.curPlaylistEntry.recupero != 0"><b>{{ $root.evofit.sedutaPlayer.scheda.curPlaylistEntry.recupero }}"</b></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- fine seduta -->
        <div v-if="($root.evofit.sedutaPlayer.scheda.curPlaylistEntry != null) && ($root.evofit.sedutaPlayer.scheda.curPlaylistEntry.tipo == 'endreward')" class="row no-gutters" style="min-height:100%;">
            <div class="col-12 text-center pt-5 orange-text-orange" style="height:100%; display:block; position:relative;">
                <div class="end-reward-container">
                    <p><span style="font-size:40px;" class="icon-fatto_cerchio"></span></p>
                    <span class="testo-generico orange-text-orange">COMPLETATO</span>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'PlayerGetReady',
    data () {
        return {
        }
    },
    methods: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #warmup-circle {
        border: dashed 1px black;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    #warmup-info {
        display:block;
        position:relative;
        margin-top:45%;
        transform: translateY(-50%);
    }

    .esercizio-serie {
        height: 25px;
        display:block;
    }

    .esercizio-serie > p  {
        padding:0px;
        margin:0px;
        display:inline-block;
        line-height: 25px;
        height: 25px;
        vertical-align: top;
    }

    .esercizio-serie > p > span {
        padding:0px;
        margin:0px;
        display:inline-block;
        font-size:22px;
        line-height: 25px;
        height: 25px;
        vertical-align: top;
    }

    .skip-button {
        position:fixed;
        bottom: 30px;
        right:30px;
        background-color: rgba(0,0,0,0.6);
        color:#fff;
        height: 42px;
        width: 42px;
        text-align: center;
        line-height: 42px;
        padding: 0px;
        z-index:300;
    }

    .end-reward-container {
        margin-top:50%;
        transform:translateY(-50%);
    }
</style>
