<template>
    <div>
        <div class="container-fluid">
            <div class="row mt-5">
                <div class="col text-center">
                    <img id="news-logo" src="img/orangeLogo.svg">
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <p class="mb-4 text-center titolo-categoria">Sei già registrato in Orange?</p>
                    <button v-on:click="showLoginPage" class="btn btn-block btn-orange testo-bottoni btn-outline-gray">LOGIN</button>
                </div>
                <div v-if="$root.user.canUseBiometrics" class="col-12 mt-3 text-center">
                    <span v-on:click.stop="bioLogin" class="orange-link-light"><img src="img/fingerprint.png" style="height:32px; margin-right:10px; display:inline-block;">Login con impronta o volto</span>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <p class="my-4 text-center titolo-categoria">Sei nuovo? Crea il tuo account</p>
                    <button v-on:click="showChooseSubscriptionPage()" class="btn btn-block py-3 testo-bottoni btn-orange-orange">REGISTRATI</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'Welcome',
    data () {
        return {
        }
    },
    methods: {
        showLoginPage() {
            this.$root.showPage('loginpage');
        },

        showChooseSubscriptionPage() {            
            if(this.$root.isApple) {

                let freeProd = this.$root.productExistsFree();
                if(freeProd == null) {
                    this.$root.register.registrationSubscriptionIdx = -1;
                    this.$root.showPage('registrationpage');
                } else {
                    this.$root.showPage('choosesubscription');
                }

            } else {
                this.$root.showPage('choosesubscription');
            }
        },

        bioLogin() {
            this.$root.doBiometricsLogin();
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #news-logo {
        max-width:80%;
    }
</style>
