import axios from 'axios';
import config from '../config/config_local.js'

import class3DGallery from '../js/classes/class3DGallery'
import ZingTouch from 'zingtouch'
import Vue from 'vue';


import CastApiClass from '../js/classes/castApi'

export default  {
    data() {
        return {
            reborn: {
                enabled: false,
                selectedCalDay: 0,
                videos: [],
                suggested: [],
                preferiti: [],
                recent: [],
                videosLoaded: false,
                filteredVideos: {
                    all: [],
                    cycling: [],
                    names: [],
                },
                searchResults: [],
                curPageVideos: [],
                currentFilters: {
                    duration: '',
                    category: '',
                    attrezzi: [],
                    stringa: ''
                },
                defaultVideo: null,
                
                categorie: [],
                attrezzi:[],
                istruttori: [],
                swiperCategorie: null
            },
            player: {
                playerCurrentVideo: null,
                playerCurrentVideoUrl: '',
                playerUseNative: false,
                screenOrientation: 0,
                castApi: null    
            }
        }
    },
    mounted() {

    },
    created() {
        //this.player.castApi = new CastApiClass(true);
    },
    methods: {
        rebornReset() {
            this.reborn.enabled = false;
            this.reborn.selectedCalDay = 0;
            this.reborn.videos = [];
            this.reborn.suggested = [];
            this.reborn.preferiti = [];
            this.reborn.recent = [];
            this.reborn.videosLoaded = false;

            this.reborn.filteredVideos.all = [];
            this.reborn.filteredVideos.cycling = [];
            this.reborn.filteredVideos.names = [];

            this.reborn.curPageVideos= [];

            this.reborn.currentFilters.duration = '';
            this.reborn.currentFilters.category = '';
            this.reborn.currentFilters.attrezzi = [];
            this.reborn.currentFilters.stringa = '';

            this.reborn.defaultVideo= null;
            
            this.reborn.categorie = [];
            this.reborn.istruttori = [];
            this.reborn.attrezzi = [];
            this.reborn.swiperCategorie = null;

            this.player.playerCurrentVideo = null;
            this.player.playerCurrentVideoUrl = '';
            this.player.playerUseNative = false;
            this.player.screenOrientation = 0;
            this.player.castApi = null;   
            this.initializeCastApi();
        },

        initializeCastApi() {
            this.player.castApi = new CastApiClass(true);
            this.player.castApi.waitForCastAPI();
        },
        rebornSetup() {
            console.log('Checking REBORN activation.');
            this.reborn.enabled = this.userAbbonamERateOK();
            this.rebornGetVideos();
        },

        rebornGetVideos() {
            axios.get( config.serverUrl + '/reborn/allvideos?active=' + this.reborn.enabled)
            .then( (resmy) => {
                if(resmy) {
                    if(resmy.data.error == false) {
                        //console.log('Got reborn videos');
                        //check if we are apple and with no subscription (show only free videos as nothing happened)
                        console.log(resmy.data.data);
                        if( (config.isApple == true) && (this.subscriptions.abbonamenti.length == 0)) {
                            let appleVideos = resmy.data.data.filter( (vid) => vid.isfree );
                            resmy.data.data = appleVideos;
                        }
                        this.reborn.videos = resmy.data.data;
                        this.reborn.videos.forEach( (video) => {
                            video.description = video.description.replace("_","'");
                        });

                        this._rebornBuildCategoriesArray();
                        this._rebornBuildIstruttoriArray();
                        this._rebornBuildAttrezziArray();

                        this.reborn.videosLoaded = true; 

                        //choose a default video for this user
                        this.rebornChooseDefaultVideo();
                        //build the suggested video array (according to reborn activation)
                        this.rebornBuildSuggestedVideos();

                        //build the suggested videos array
                        this.rebornBuildRecentVideos();

                        //create starting filtered videos
                        this.rebornRebuildFilteredVideos();
                        this.rebornInitSearchResults();

                        if(this.preferitiLoaded == true)
                            this.preferitiAssignVideosToPreferiti();
                            

                    } else {
                        console.log( resmy.data );
                    }
                }
            })
            .catch( (errmy) => {
                console.log('WARNING! Error retrieving reborn videos');
                console.log(errmy);
            });    

        },

        _rebornBuildCategoriesArray() {
            this.reborn.videos.forEach( (video) => {
                video.categorie.forEach( (cat) => {
                    if(!this._categoryExists(cat)) {
                        this.reborn.categorie.push( cat );
                        this.reborn.categorie[ this.reborn.categorie.length -1 ].numVideos = 1;
                    }
                    
                });
            });

            //set a default currentFilter to avoid rendering errors
            this.reborn.currentFilters.category = this.reborn.categorie[0];
        },

        _rebornBuildIstruttoriArray() {
            this.reborn.videos.forEach( (video) => {
                if(!this.reborn.istruttori.includes( video.istruttore)) {
                    this.reborn.istruttori.push( video.istruttore);
                }
            });
        },

        _rebornBuildAttrezziArray() {
            this.reborn.videos.forEach( (video) => {
                video.attrezzi.forEach( (curAttr) => {
                    if(!this.reborn.attrezzi.includes( curAttr.name)) {
                        this.reborn.attrezzi.push( curAttr.name );
                    }    
                } );
            });
        },

        _categoryExists(cat) {
            let i = 0;
            let found = false;
            while( i < this.reborn.categorie.length && found == false) {
                if(this.reborn.categorie[i].id == cat.id) {
                    found = true;
                    this.reborn.categorie[i].numVideos ++;
                }

                if(!found)
                    i++
            }

            return found;
        },

        rebornChooseDefaultVideo() {
            let freeVideos = [];
            this.reborn.videos.forEach( (video) => {
                if(video.isfree && video.name != '') {
                    freeVideos.push( video );
                }
            });

            this.reborn.defaultVideo = freeVideos[Math.floor(Math.random()*freeVideos.length)];
        },

        rebornBuildSuggestedVideos() {
            if(this.reborn.videosLoaded == false)
                return;

            let max = this.reborn.videos.length - 1;
            let min = 1;
            this.reborn.suggested = [];
            if(this.reborn.enabled == true) {
                for(let i = 0; i < 6; i++) {
                    let randIdx = Math.floor(Math.random() * (max - min + 1) + min);
                    this.reborn.suggested.push( this.reborn.videos[randIdx]);
                }    
            } else {
                let aggiunti = 0;
                this.reborn.videos.forEach( (video, idx) => {
                    if( (video.isfree == 1) && (aggiunti <= 6) ) {
                        this.reborn.suggested.push( this.reborn.videos[idx]);
                        aggiunti += 1;
                    }
                });
            }
        },

        rebornBuildRecentVideos() {
            if(this.reborn.videosLoaded == false) {
                console.log('REBORN Recent: videos not yet loaded.')
                return;
            }

            let max = this.reborn.videos.length - 1;
            let min = 1;
            this.reborn.recent = [];
            if(this.reborn.enabled == true) {
                for(let i = 0; i < 6; i++) {
                    let randIdx = Math.floor(Math.random() * (max - min + 1) + min);
                    this.reborn.recent.push( this.reborn.videos[randIdx]);
                }                        let randIdx = Math.floor(Math.random() * (max - min + 1) + min);
            } else {
                let aggiunti = 0;
                this.reborn.videos.forEach( (video, idx) => {
                    if( (video.isfree == 1) && (aggiunti <= 6) ) {
                        this.reborn.recent.push( this.reborn.videos[idx]);
                        aggiunti += 1;
                    }
                });
            }

            //if we found some recenti, then setup the slider for the main page (see slidersMixin)
            if(this.reborn.recent.length > 0) {
                this.$root.setupRecentimainSlider();
            } else {
                console.log('REBORN recent videos missing.');
            }
        },

        rebornInitSearchResults() {
            let i = 0;
            this.reborn.searchResults = [];
            for(i = 0; i < 8; i++) {
                let pos = Math.floor( Math.random() * this.reborn.videos.length);
                this.reborn.searchResults.push( this.reborn.videos[ pos] );
            }
        },

        rebornRebuildFilteredVideos() {
            this.reborn.filteredVideos.all = [];
            this.reborn.filteredVideos.names = [];

            let durMin = 0;
            let durMax = 0;
            switch(this.reborn.currentFilters.duration) {
                case '50':
                    durMin = 45;
                    durMax = 200;
                    break;
                case '30':
                    durMin = 16;
                    durMax = 44;
                    break;
                case '15':
                    durMin = 0;
                    durMax = 15;
                    break;
                case '':
                    durMin = 0;
                    durMax = 200;
                    break;
            }

            //first of all match the category, it wins over everything else
            if(this.reborn.currentFilters.category == '')  {
                this.reborn.filteredVideos.all = this.reborn.videos.filter( vid => {
                    let valid = false;
                    if( (vid.durata > durMin) && (vid.durata < durMax)) {
                        valid = true;
                    }

                    //check attrezzi, if needed
                    if(this.reborn.currentFilters.attrezzi.length > 0) {
                        let attrezziFound = 0;
                        this.reborn.currentFilters.attrezzi.forEach( (attrezzo) => {
                            vid.attrezzi.forEach( (videoAttr) => {
                                if(attrezzo.toUpperCase() == videoAttr.name.toUpperCase() ) {
                                    attrezziFound++;
                                }
                            });
                        });
    
                        if(attrezziFound == 0)
                            valid = false;    
                    }

                    //if there is a string search, verify matches
                    if(this.reborn.currentFilters.stringa.length > 0) {
                        if( (!vid.name.toUpperCase().includes( this.reborn.currentFilters.stringa.toUpperCase() )) && 
                        (!vid.istruttore.toUpperCase().includes( this.reborn.currentFilters.stringa.toUpperCase() )) && 
                        (!vid.ospite1.toUpperCase().includes( this.reborn.currentFilters.stringa.toUpperCase() )) && 
                        (!vid.ospite2.toUpperCase().includes( this.reborn.currentFilters.stringa.toUpperCase() )) ){
                            valid = false;
                        }
                    }

                    return valid;
                });

            } else {
                this.reborn.filteredVideos.all = this.reborn.videos.filter( vid => {
                    let valid = false;
                    let catFilt = this.reborn.currentFilters.category.name.toUpperCase();
                    vid.categorie.forEach( (cat) => {
                        if(cat.name.toUpperCase() == catFilt) {
                            valid = true;
                        }
                    });
                    //check duration
                    if( valid && ((vid.durata < durMin) || (vid.durata > durMax)) )
                        valid = false;

                    //check attrezzi, if needed
                    if(this.reborn.currentFilters.attrezzi.length > 0) {
                        let attrezziFound = 0;
                        this.reborn.currentFilters.attrezzi.forEach( (attrezzo) => {
                            vid.attrezzi.forEach( (videoAttr) => {
                                if(attrezzo.toUpperCase() == videoAttr.name.toUpperCase() ) {
                                    attrezziFound++;
                                }
                            });
                        });
    
                        if(attrezziFound == 0)
                            valid = false;    
                    }

                    //if there is a string search, verify matches
                    if(this.reborn.currentFilters.stringa.length > 0) {
                        if( (!vid.name.toUpperCase().includes( this.reborn.currentFilters.stringa.toUpperCase() )) && _ 
                        (!vid.istruttore.toUpperCase().includes( this.reborn.currentFilters.stringa.toUpperCase() )) && _
                        (!vid.ospite1.toUpperCase().includes( this.reborn.currentFilters.stringa.toUpperCase() )) && _
                        (!vid.ospite2.toUpperCase().includes( this.reborn.currentFilters.stringa.toUpperCase() )) ){
                            valid = false;
                        }
                    }

                    return valid;
                });    

            }

            //subdivide results by name
            this.reborn.filteredVideos.names = [];
            this.reborn.filteredVideos.all.forEach( (vid) => {
                let name = this._getRebornNameWithoutNumber(vid.name);
                this._filteredVideoAddVideoToName(name, vid);
            })

            //not enbled anymore, see function too (commented out)
            //this._mainInitCategorieSwiper();

            //here filteredVideos.names is filled with filter results
            //activate sliders
            console.log('Setup video sliders');
            this.$nextTick( () => {
                this.$root.videoSliders.names.forEach( (nameSlider) => {
                    nameSlider.destroy();
                });
                this.$root.videoSliders.names = [];
                this.reborn.filteredVideos.names.forEach( (nameObj, index) => {
                    let slider = this.$root.setupSlider('#slider-' + index );
                    this.$root.videoSliders.names.push( slider );
                })
            }); 
            
        }, 

        /*
        _mainInitCategorieSwiper() {
            if(this.reborn.swiperCategorie == null) {
                Vue.nextTick( () => {                
                    this.reborn.swiperCategorie = new class3DGallery( this._handleCategorieSwiperClick );
                });    
            }
        },
        */

        _handleCategorieSwiperClick(element) {
            //get the data-subacategory value
            let category = element.dataset.category;
            let filterType = 'category';
            this.rebornUpdateFilters(filterType,'', category);
            this.$root.$refs.pageMan.resetPagesStack('reborncategorypage');
        },

        _getRebornNameWithoutNumber(fullname) {
            let name = fullname;
            let idx = name.lastIndexOf(' ');
            let res = name.substring(0,idx);
            res = res.replace('_','-');
            res = res.replace(' ','-');
            return res.trim().toUpperCase();
        },

        _filteredVideoAddVideoToName(name, vid) {
            let found = false;
            this.reborn.filteredVideos.names.forEach( (names) => {
                if(names.name == name) {
                    found = true;
                    names.videos.push( vid );
                }
            })

            if(found == false) {
                this.reborn.filteredVideos.names.push( { name: name, videos : [ vid ] } );
            }

            return found;
        },

        _getCategoryByName(catName) {
            let i = 0;
            let found = false;
            let name = catName.trim().toLowerCase();
            while(i < this.reborn.categorie.length && found == false) {
                if(this.reborn.categorie[i].name.trim().toLowerCase() == name)
                    found = true;
                
                if(!found)
                    i++;
            }

            if(found)
                return this.reborn.categorie[i];
            else
                return '';
        },

        rebornUpdateFilters(filterType, operation, value) {
            console.log('Reborn updating filter to: ' + value);
            switch(filterType) {
                case 'category':
                    this.reborn.currentFilters.stringa = '';
                    this.reborn.currentFilters.category = this._getCategoryByName(value);
                    break;
                case 'duration':
                    this.reborn.currentFilters.stringa = '';
                    this.reborn.currentFilters.duration = value;
                    break;
                case 'attrezzi':
                    this.reborn.currentFilters.stringa = '';
                    let index = this.reborn.currentFilters.attrezzi.indexOf(value);
                    if(index != -1) {
                        this.reborn.currentFilters.attrezzi.splice(index,1);
                    } else {
                        this.reborn.currentFilters.attrezzi.push( value );
                    }
                    break;
                case 'stringa':
                    this.reborn.currentFilters.attrezzi = [];
                    this.reborn.currentFilters.duration = '';
                    this.reborn.currentFilters.category = '';                    
                    break;
            }
            this.rebornRebuildFilteredVideos();
            this.rebornInitSearchResults();

        },

        rebornShowVideo(video_id) {
            
            let video = this.findVideoById(video_id);

            //video not found
            if(!video) {
                return false;
            }

            //if video url is '' this video is NOT FREE
            if(video.url_video == '') {
                //can the user watch this video?
                if(this.userAbbonamERateOK() == false) {
                    if(this.isApple == false) {
                        this.userRinnovaOCompra();
                    } else {
                        this.$root.showAppleMessage();
                    }
                    return false;    
                }
            }

            if(this.$root.noSleepEnabled == false) {
                this.$root.noSleepEnabled = true;
                this.$root.noSleep.enable();
            }

            //check if video has url, if it doesn't then the user can't see it
            if(video && video.url_video == '') {
                this.$root.$refs.pageMan.showAlert('Per visualizzare questa lezione è necessario un abbonamento attivo. Scegliere abbonamento o rinnovare rata adesso?','OK','Annulla')
                .then( (rinn) => {
                    this.$root.userRinnovaOCompra();
                })
                .catch( (err) => {
                    
                });
                return false;
            }
            this.player.playerCurrentVideo = video;


            /* if( (this.reborn.enabled == false) && (video.isfree == 0)) {
                //alert('Per usufruire di questo video serve un abbonamento.');
                this.showPage('reborn-buy');
                return;
            } */

            let origurl = video.url_video; //'https://vimeo.com/456354300/337cee2a7d';
            let baseUrl = 'https://player.vimeo.com/video/';
            let arr = origurl.split('/');
            let videoId = arr[3];
            this._rebornGetVideoUrl(videoId) 
            .then( (url) => {
                this.player.playerCurrentVideoUrl = url;
                this.player.playerUseNative = true;
                this.showPage('rebornplayerpage');
                setTimeout( () => {

                    window.addEventListener('orientationchange', (event) => {
                        if(typeof(event.target.screen.orientation) == 'undefined') {
                            this.player.screenOrientation = screen.orientation.angle;
                        } else {
                            this.player.screenOrientation = event.target.screen.orientation.angle;
                        }
                    });
        
                    //this.$refs.video_element.load();
                    let DOMElements = {
                        overlay: 'video_overlay',
                        video_element: 'video_element',
                        progress: 'progress',
                        audio_on: 'audio_on',
                        audio_off: 'audio_off',
                        audio_intensity: 'intensity',
                        audio_bg: 'volume',
                        media_control: 'controls',
                        play_controls: 'play_controls',
                        play: 'play_btn',
                        pause: 'pause_btn'
                    }
                    this.player.castApi.setUIDOMElements(DOMElements);
                    this.player.castApi.playMedia({
                        description: video.description,
                        sources: [ url ],
                        subtitle: 'REBORN',
                        thumb: '',
                        title: video.name
                    });
                },1000);

                this.$root.rboAddData('reborn_video', video.name, 'VIDEO', videoId, 1, 1);

            })
            .catch( (err) => {
                this.player.playerUseNative = false;
                this.player.playerCurrentVideoUrl = baseUrl + videoId;
                console.log('Error retrieving video url!');
                console.log(err);
                this.showPage('rebornplayerpage');
            });

            axios({
                url: config.serverUrl + '/reborn/visitvideo',
                method: 'POST',
                data: {
                    videoid: video.id
                }
            })
            .then( (res) => {
                console.log(res);
            })
            .catch( (err) => {
                console.log('WARNING! Cannot update reborn video visit');
                console.log(err);
            })


        },

        rebornDestroyVideo() {
            this.player.playerCurrentVideoUrl = '';
            this.player.playerCurrentVideo = null;
            //stop the cast if we are leaving the player
            this.player.castApi.endPlayback();            
        },

        findVideoById(video_id) {

            let i = 0;
            let idx = 0;
            let found = false;

            while(i < this.reborn.videos.length && found == false) {
                if(this.reborn.videos[i].id == video_id) {
                    found = true;
                    idx = i;
                }
                i++;
            }

            if(found)
                return this.reborn.videos[idx];
            else
                return null;
        },

        _rebornGetVideoUrl(videoId) {
            return new Promise( (resolve,reject) => {
                let vQuality = 'high';
                if(parseFloat(this.$root.newtworkInfo.mbps) < 5)
                    vQuality = 'mid'

                if(parseFloat(this.$root.newtworkInfo.mbps) < 2)
                    vQuality = 'low'
                console.log("Requesting video quality: "+ vQuality);
                axios({
                    method: 'POST',
                    url: config.serverUrl + '/reborn/getlink',
                    data: {
                        videoId: videoId,
                        quality: vQuality
                    }
                }).then((res) => {
                    resolve(res.data.data);
                }).catch( (err) => {
                    reject(err);
                });        
            })
        },

        new_rebornGetVideoUrl(videoId) {
            return new Promise( (resolve, reject) => {
                axios({
                    method: 'GET',
                    url: 'https://player.vimeo.com/video/' + videoId + '/config'
                })
                .then( (res) => {
                    if(res.data.request.files.progressive) {
                        let progs = res.data.request.files.progressive;
                        let resLink = '';
                        progs.forEach( (prog) => {
                            if(prog.profile == 175 && quality == 'high') {
                                resLink = prog.url;
                            }
                            if(prog.profile == 165 && quality == 'mid') {
                                resLink = prog.url;
                            }
                            if(prog.profile == 164 && quality == 'low') {
                                resLink = prog.url;
                            }
                        });
    
                        resolve(resLink);
    
                    } else {
                        reject(false);
                    }
                })
                .catch( (err) => {
                    reject(err);
                });    
            });
        }


    }
}