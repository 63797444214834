<template>
    <div>
        <div v-if="$root.centers.curCenterDetails != null" class="container-fluid vh-100 background-black">
            <div class="row">
                <div v-on:click="closeThisOverlay()" class="col-2 pt-2 text-white">
                    <strong><span style="font-size:24px; line-height:45px;" class="icon-navig_chiudi"></span></strong>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center p-0" style="display:block; position:relative; width:100%; height:250px; background-size:cover;" :style="{ backgroundImage: 'url(img/centri/' + $root.centers.curCenterDetails.name.replaceAll(' ','_') + '/' + $root.centers.curCenterDetails.name.replaceAll(' ','_') + '1.jpg)' }">
                    <div class="gradient-centro">
                        <img id="centrodetail-logo" src="img/orangeLogo.svg">
                        <p id="centrodetail-name" class="titolo-grande">{{ $root.centers.curCenterDetails.name }}</p>
                    </div>
                </div>
                <div class="col-12 mt-5 text-center testo-generico orange-text-white">
                    <p>Indirizzo: {{ $root.centers.curCenterDetails.address }}</p>
                    <p>Orari: {{ $root.centers.curCenterDetails.times }}</p>
                    <p>{{ $root.centers.curCenterDetails.telefono }}</p>
                    <p>Email: {{ $root.centers.curCenterDetails.email }}</p>
                </div>                
            </div>

            <div class="row mt-5">
                <div class="col-12 px-5">
                    <button v-on:click.stop="handleShowPrenota" class="btn btn-block btn-orange btn-orange-orange testo-bottoni">PRENOTA LEZIONI</button>
                </div>
                <div class="col-12 my-5 px-5">
                    <p v-on:click.stop="setDefaultCenter" class="orange-link-light" style="text-decoration-style:dashed;">Imposta come centro preferito</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-center mt-2 p-0" style="overflow:hidden; height:400px; margin-bottom:20px;">
                    <iframe style="width:100%; height:100%; overflow:hidden; border:none; padding:0px; margin:0px;" scroll="no" frameBorder="0" :src="$root.centers.curCenterDetails.gmapsiframe"></iframe>
                </div>
            </div>

            <div class="row" v-for="image,index in ($root.centers.curCenterDetails.numfoto - 1)" :key="'foto-' + index">
                <div class="col-12">
                    <img style="width:100%; height:auto; margin-bottom:20px;" :src="'img/centri/' + $root.centers.curCenterDetails.name.replaceAll(' ','_') + '/' + $root.centers.curCenterDetails.name.replaceAll(' ','_') + (index + 1) + '.jpg'">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ListaCentriPage',
    data () {
        return {
            curTitleSelected: 1
        }
    },
    methods: {
        clickTitle( _idx) {
            this.curTitleSelected = _idx;
            let elem = document.getElementById('title-' + _idx);
            if(_idx == 1) {
                this.$root.showPage('centriinfo', false);
            } else if(_idx == 2) {
                this.$root.showPage('contattipage', false);
            }
        },
        closeThisOverlay() {
            this.$root.$refs.pageMan.closeOverlay();
        },
        showcenter(_centro) {

            this.$root.centers.curCenterDetails = centro;
            this.$root.$refs.pageMan.showOverlay('dettagiocentrioverlay');
        },

        setDefaultCenter() {
            this.$root.user.realCenterId = this.$root.centers.curCenterDetails.iyes.id;
            this.$root.user.centerId = this.$root.centers.curCenterDetails.iyes.id;            
            localStorage.setItem('centro-default', this.$root.user.centerId);
            this.$root.$refs.pageMan.showAlert('Palestra ' + this.$root.centers.curCenterDetails.name + ' impostata come centro preferito.','OK','');
        },

        handleShowPrenota() {
            this.$root.user.realCenterId = this.$root.centers.curCenterDetails.iyes.id;
            this.$root.user.centerId = this.$root.centers.curCenterDetails.iyes.id;            
            localStorage.setItem('centro-default', this.$root.user.centerId);
            this.closeThisOverlay();
            this.$root.$refs.pageMan.showPage('prenotamainpage');
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #centrodetail-logo {
        max-width:40%;
        margin-top:150px;
    }

    #centrodetail-name {
        margin-top:20px;
    }

    .gradient-centro {
        display:block;
        position:relative;
        width: 100%;
        height: 250px;
        background: rgb(0,0,0);
        background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 85%, rgba(0,0,0,1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 85%, rgba(0,0,0,1) 100%);
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 85%, rgba(0,0,0,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    }

</style>
