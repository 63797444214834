<template>
    <div>

        <div v-if="$root.evofit.modsched.curObiettivoName != null" class="container-fluid my-0 px-0">
            <div class="row">
                <div class="col-12" style="min-height:320px; background-size:cover; background-position:center top;" :style="{ 'background-image' : 'url(img/obiettivi/OBIETTIVO4_3/' + $root.evofit.modsched.curObiettivoName.immagine + '.jpeg)'}">
                   <div id="obiettivo-header">
                        <img id="trainer-logo" src="img/logo_rbo.png">
                        <p class="titolo-grande mt-4">{{ $root.evofit.modsched.curObiettivoName.descrizione }}</p>
                        <p class="testo-generico">{{ $root.evofit.modsched.curObiettivoName.schedeCount }} esercizi</p>
                   </div> 
                </div>
            </div>
        </div>

        <div class="container-fluid px-0">
            <div v-if="$root.evofit.modsched.curObiettivoName != null" class="row no-gutters mb-2" style="min-height: 200px;">
                
                <div v-for="obscheda,index in $root.evofit.modsched.curObiettivoSchede" :key="'scheda-' + index" class="p-2 col-12 text-center mt-1">
                    <div v-on:click="$root.evofitSetSchedaFromModello(index)" class="row no-gutters p-2">
                        <div class="col-12">
                            <img class="img-scheda" v-on:error="errorLoadingSchedaImage" v-if="obscheda.descimmagine != ''" :src="'img/obiettivi/MODELLO1_1/' + obscheda.descimmagine + '.jpeg'">
                            <img class="img-scheda" v-on:error="errorLoadingSchedaImage" v-if="obscheda.descimmagine == ''" src="img/obiettivi/MODELLO1_1/default.jpeg">
                        </div>
                        <div class="col-12 pt-0 mt-0 text-left text-center">
                            <p class="titolo-piccolo orange-text-white mt-2 mb-0" style="padding-left:20%; padding-right:20%;"><strong>{{ obscheda.descrizione }}</strong></p>
                            <p class="texto-generico orange-text-white">Livello {{ obscheda.livello }} | Esercizi {{ obscheda.dettagli.length }}</p>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 text-center orange-text-white">
                                    <span v-on:click="$root.togglePreferitiScheda(obscheda.codice, obscheda.descrizione)" class="ico-orange" style="font-size:30px;" :class="{ 'orange-text-orange' : $root.isPreferito('scheda', obscheda.codice ) }"><span class="icon-preferito_cerchio"></span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>

        </div>

    </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'TrainerSchedeObiettivo',
    data () {
        return {
        }
    },
    methods: {
        clickTitle( _idx) {
            this.curTitleSelected = _idx;
            let elem = document.getElementById('title-' + _idx);
            //elem.scrollIntoView({ behavior: 'smooth' });
        },

        errorLoadingSchedaImage(event) {
            let targ = event.target || event.currentTarget;
            if(targ) {
                console.log('Cannot load ' + targ.src);
                targ.src = 'img/obiettivi/MODELLO4_3/default.jpeg';
            }
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #trainer-logo {
        width:20%;
        max-width: 130px;
        margin-top:100px;
    }

    #obiettivo-header {
        width: 100%;
        height: 100%;
        text-align: center;
        background: rgba(0,0,0,0.6);
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.4) 49%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,1) 100%);    
        padding-left:25px;
        padding-right: 25px;
    }

    .img-scheda {
        width: 190px;
        height: 190px;
        border-radius:50%;
    }
</style>
