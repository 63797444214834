<template>
    <div>
        <div class="container-fluid">
            <div class="row mt-5">
                <div class="col-12 my-5 text-center">
                    <!-- <span style="width:25%;" class="icon-avatar"></span> -->
                    <div v-if="$root.user.iyesUserId != null" class="profile-picture" v-bind:style="{ 'backgroundImage' : 'url(https://iyes.orangepalestre.it:65433/foto_02/' + $root.user.iyesUserId.toString().padStart(8,'0') + '.jpg)' }"></div>
                    <p class="titolo-sottile orange-text-black text-center mt-4 mb-0 pb-0" style="text-transform:uppercase;">{{ $root.user.name }} {{ $root.user.surname }}</p>
                </div>
            </div>
            <div class="row text-center">
                <div v-on:click="showMieiDati()" class="col-12">
                    <p class="titolo-piccolo orange-text-black"><strong>FAI DA TE</strong></p>
                </div>
                <div v-on:click="$root.$refs.pageMan.showOverlay('scelta-centro')" class="col-12">
                    <p class="titolo-piccolo orange-text-black"><strong>LA MIA PALESTRA</strong></p>
                </div>
                <div class="col-12">
                    <p v-on:click.stop="$root.cashbackGetAndShow" class="titolo-piccolo orange-text-black"><strong>PRESENTA UN AMICO</strong></p>
                </div>
                <div v-on:click.stop="getAndShowEpurses" class="col-12">
                    <p class="titolo-piccolo orange-text-black"><strong>BORSELLINO</strong></p>
                </div>
                <!-- <div v-on:click.stop="$root.userGetAndShowGreenPass" class="col-12">
                    <p class="titolo-piccolo orange-text-black"><strong>GREEN PASS</strong></p>
                </div> -->
                <div class="col-12 mt-5">
                    <p v-on:click="$root.userDoLogout()" class="orange-link-dark">Logout</p>
                </div>
            </div>
        </div>

        <div class="container-fluid mt-5">
            <div v-on:click="$root.openWhatsappAmministrazione" class="col-12 text-center">
                <span class="orange-ico-dark" style="font-size:28px;"><span class="icon-tools_whatsapp"></span></span>
                <p class="testo-generico orange-text-black">Contatta l'amministrazione.</p>

            </div>
            <div class="col-12 text-center mt-5">
                <span class="orange-text-black"> {{ this.getVersion() }}</span>
                <span v-if="$root.deviceRunning != null" class="orange-text-black">{{ $root.deviceRunning.name }}</span>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import config from '../../../config/config_local'

export default {
    name: 'UserMain',
    data () {
        return {
        }
    },
    methods: {

        getVersion() {
            if(config) {
                return 'Ver: ' + config.version.major + '.' + config.version.minor + '.' + config.version.build;
            } else {
                return '';
            }
        },

        showMieiDati() {
            this.$root.paymentsGetAndShowMyEpurses(false);
            this.$root.$refs.pageMan.showPage('usermieidatipage')
        },

        forcedLogout() {
            //also deletes the local storage data (token and centro preferito)
            localStorage.removeItem('iyestoken');
            localStorage.removeItem('centro-default');
            this.userDoLogout();            
        },

        getAndShowEpurses() {
            this.$root.showWaiting();

            //gets the user contest status
            this.$root.paymentsGetUserContest();

            //gets all epurses and show the first one (treu for showing the page)
            this.$root.paymentsGetAndShowMyEpurses(true);
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #centro-logo {
        max-width:80%;
    }

    .avatar-container {
        display:block;
        position:relative;
        width: 140px;
        height: 140px;
        font-size:90px;
        line-height: 90px !important;
        color:#000;
        margin-left: auto;
        margin-right: auto;
    }

    .profile-picture {
        display:block;
        width:140px;
        height: 140px;
        border-radius:50%;
        background-position: center top;
        background-size: cover;
        margin-left: auto;
        margin-right: auto;
    }
</style>
