<template>
    <div>
        <div class="container-fluid mb-2">
            <div class="row mt-5">
                <div class="col-12 mt-5 text-center">
                    <p class="titolo-piccolo orange-text-black text-center mt-4 mb-2 pb-0" style="text-transform:uppercase;">LE TUE RICEVUTE FISCALI</p>
                    <p class="testo-generico orange-text-black text-center" >Qui puoi vedere e gestire tutte le tue ricevute fiscali.</p>
                </div>            
            </div>
        </div>

        <div v-if="$root.user.iyesUser.hasOwnProperty('FiscalCode')" class="container-fluid">
            <div v-if="($root.user.iyesUser.FiscalCode == '') || ($root.user.iyesUser.FiscalCode.substring(0,3) == 'XYZ')" class="row px-2 mb-3 pb-3 border-bottom">
                <div class="col-12 text-center">
                    <p class="texto-generico orange-text-black">
                        Dati anagrafici mancanti! Passa in segreteria per impostare i tuoi dati personali e poter scaricare le tue ricevute in autonomia.
                    </p>
                </div>
            </div>

            <div v-if="($root.user.iyesUser.FiscalCode != '') || ($root.user.iyesUser.FiscalCode.substring(0,3) != 'XYZ')" id="receiptsData" ref="receiptsData" class="row px-2 mb-3 pb-3 border-bottom">
                <div class="col-12 text-center">
                    <span class="titolo-piccolo orange-text-black">FILTRA PER ANNO PRIMA RICEVUTA</span>
                </div>
                <div class="col-12">
                    <div class="ico-container-sel"><span class="icon-nascita"></span></div>
                    <select class="form-control orange-select" v-model="dataReceipt">
                        <option v-for="year in datareceiptYears" :key="'receiptyear-' + year" :value="year">{{ year }}</option>
                    </select>
                    <button v-on:click.stop="$root.receiptsPrepareDownload('year', null, dataReceipt)" class="btn btn-block rounded-0 mt-2 btn-warning testo-bottoni orange-text-white">SCARICA TUTTE DEL {{ dataReceipt }}</button>
                </div>
            </div>

            <div v-for="receipt,index in $root.receipts.filter( d => d.DocumentData.substring(0,4) == dataReceipt )" :key="'rece-' + index" class="row testo-generico orange-text-black py-2 border-bottom">
                <div class="col-10">
                    <p class="my-0 py-0 orange-text-black">{{ receipt.DocumentType }}</p>
                    <p class="my-0 py-0 orange-text-black"><strong>{{ receipt.DocumentData.substring(0,10) }}</strong></p>
                    <ul v-for="raw,rindex in receipt.Raws" :key="'rec-' + index + '-' + rindex" class="my-0 mt-1 py-0 pl-1 testo-generico orange-text-black" style="font-size:11px;">
                        <li><strong>{{ raw.UnitPrice }} € </strong> : {{ raw.Description.trim() }}</li>
                    </ul> 
                </div>
                <div v-on:click.stop="$root.receiptsPrepareDownload('single', receipt.Id, null)" class="col-2">
                    <span style="font-size:25px;" class="icon-pdf"></span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue'

import axios from 'axios'
import config from '../../../config/config_local.js'
import moment from 'moment';

export default {
    name: 'UserRicevute',
    data () {
        return {
            mesi: { 1: 'Gen', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'Mag', 6: 'Giu', 7: 'Lug' , 8:'Ago', 9:'Set', 10: 'Ott', 11: 'Nov', 12:'Dic'},
            dataReceipt: '2022'
        }
    },
    computed: {
        datareceiptYears () {
            const year = new Date().getFullYear();
            let years = [];
            years.push( year );
            years.push( year - 1);
            return years;
        }
    },
    methods: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.ico-bottone-documenti {
    font-size: 18px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 5px;
    margin-right: 10px;    
}

.ico-bottone-documenti-piu {
    font-size: 18px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 5px;
    margin-right: 10px;    
}

#aggiungiDoc {
    position:absolute;
    top:0px;
    left:0px;
    visibility:hidden;
    width:100%;
    height: 40px;
}
</style>