<template>
    <div>
        <div class="container-fluid">
            <div class="row mt-3 mb-3 pb-3 border-bottom">
                <div class="col-12 mt-5 text-center">
                    <p class="titolo-piccolo orange-text-black text-center mt-4 mb-2 pb-0" style="text-transform:uppercase;">CERTIFICATO MEDICO</p>
                    <p class="testo-generico orange-text-black text-center" >Da questa pagina è possibile gestire il tuo Certificato medico.</p>
                </div>            
            </div>
            <div class="row text-center">
                <div class="col-12">
                    <p v-if="$root.subscriptions.scadenzaCertMedico != null && $root.subscriptions.scadenzaCertMedico.isAfter( moment() ) && $root.subscriptions.certmedScaduto == false">
                        Il tuo Certificato Medico risulta essere valido.
                    </p>
                    <p v-if="$root.subscriptions.certmedScaduto == true">
                        Il tuo Certificato Medico è scaduto!
                    </p>
                </div>
            </div>

            <div class="row text-center">
                <div v-if="$root.subscriptions.scadenzaCertMedico != null" class="col-12">
                    <p class="mt-2 mb-0 titolo-piccolo ">
                        Sadenza certificato medico
                    </p>
                    <p class="mt-0">
                        <b>{{ $root.subscriptions.scadenzaCertMedico.format('DD') }} {{ $root.subscriptions.scadenzaCertMedico.format('MMM').toUpperCase() }} {{ $root.subscriptions.scadenzaCertMedico.format('YYYY') }} </b>
                    </p>
                </div>
            </div>
        </div>

        <div class="container-fluid border-top border-bottom">
            <div class="row d-none">
                <div class="col-12 py-2 text-center">
                    <span class="titolo-piccolo orange-text-black">SCEGLI IL TIPO DOCUMENTO</span>
                </div>
                <div class="col-12 px-4 mt-3">
                    <select v-model="$root.documentUpload.Type" class="form-control">
                        <option value="CertificatoMedico" selected>Certificato Medico</option>
                    </select>
                </div>
            </div>
            <div v-if="$root.documentUpload.Type == 'CertificatoMedico'" id="certData" ref="certData" class="row mt-4 px-2 mb-0">
                <div class="col-12 py-2 text-center">
                    <span class="titolo-piccolo orange-text-black">INVIA CERTIFICATO MEDICO</span>
                    <p class="testo-generico orange-text-black text-center" >Se hai già fatto la visita medica e sei in possesso di un certificato medico valido inviacelo.</p>
                </div>
                <div class="col-4">
                    <div class="ico-container-sel"><span class="icon-nascita"></span></div>
                    <select class="form-control orange-select" v-on:change="updateDataCert" v-model="dataCertAnno">
                        <option :key="'year--1' "  selected disabled value="-1">AAAA</option>  
                        <option v-for="year in datacertYears" :key="'certyear-' + year" :value="year">{{ year }}</option>
                    </select>
                </div>
                <div class="col-4">
                    <select class="form-control orange-select" v-on:change="updateDataCert" v-model="dataCertMese">
                        <option selected disabled value="-1">MMM</option>  
                        <option v-for="mese,index in mesi" :key="'certmonth-' + String(index + 1).padStart(2,'0')" :value="index">{{ mese }}</option>
                    </select>
                </div>
                <div class="col-4">
                    <select class="form-control orange-select" v-on:change="updateDataCert" v-model="dataCertGiorno">
                        <option  selected disabled value="-1">GG</option>  
                        <option v-for="giorno,index in datacertDays" :key="'certday-' + String(index + 1).padStart(2,'0')" :value="String(index + 1).padStart(2,'0')">{{ giorno }}</option>
                    </select>
                </div>
                <div v-if="validDataCert == false" class="col-12">
                    <span class="testo-generico orange-text-red">Data certificato medico non valida.</span>
                </div>
            </div>

            <!-- <div v-if="$root.subscriptions.certmedInScadenza" style="height:40px; line-height:40px;" class="row mt-5 mx-2 testo-generico background-red orange-text-white"> -->
            <div style="height:40px; line-height:40px;" class="row mt-2 mb-5 mx-2 testo-generico background-green orange-text-white">
                <div class="col-8 border-right testo-bottoni text-center">
                    <span v-if="$root.documentUpload.Type == 'CertificatoMedico'">CERTIFICATO MEDICO</span>
                </div>
                <div class="col-4 testo-bottoni text-center">
                    <label for="aggiungiDoc">
                    <input :disabled="$root.documentUpload.documentDate == null && $root.documentUpload.Type == 'CertificatoMedico'" v-on:change.stop="$root.documentPrepareForUpload" type="file" id="aggiungiDoc" name="aggiungiDoc" capture="environment" accept="image/*,application/pdf">
                    AGGIUNGI &nbsp;&nbsp;<strong style="font-size:1.2rem;">+</strong>
                    </label>
                </div>
            </div>

            <div class="row">
                <div class="col-12 py-2 text-center">
                    <span class="titolo-piccolo orange-text-black">PRENOTA VISITA MEDICA</span>
                    <p class="testo-generico orange-text-black text-center" >Se non sei in possesso di un certificato medico puoi prenotare una visita medica ed ottenerne uno valido.</p>
                </div>
                <div class="col-12">
                    <button class="btn btn-block btn-orange testo-bottoni btn-orange-orange" v-on:click="$root.$refs.pageMan.showPage('prenotamainpage')">PRENOTA VISITA MEDICA</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import Vue from 'vue';
import moment from 'moment';
        
export default {
    name: 'UserCertMedico',
    data () {
        return {
            mesi: { 1: 'Gen', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'Mag', 6: 'Giu', 7: 'Lug' , 8:'Ago', 9:'Set', 10: 'Ott', 11: 'Nov', 12:'Dic'},
            openAccordion: [],
            dataCertAnno: -1,
            dataCertGiorno: -1,
            dataCertMese: -1,
            validDataCert: true,
            dataCert: null
        }
    },
    computed: {
        datacertYears () {
            const year = new Date().getFullYear();
            return [year, year - 1];
        },
        datacertDays () {
            let padMese = String(this.dataCertMese).padStart(2,'0');
            var days = 30;
            if(this.dataCertAnno > -1) {
                days = moment(this.dataCertAnno + '-' + padMese + '-01 00:00:00.000').daysInMonth();
            }
            return Array.from({length: days}, (value, index) => 1 + index)
        }

    },

    methods: {
        toggleAccordion(index) {
            console.log('toggle');
            if(typeof this.openAccordion[index] == 'undefined') {
                Vue.set(this.openAccordion,  index, {
                    open: true
                } );
            } else {
                this.openAccordion[index].open = !this.openAccordion[index].open;
            }
        },

        updateDataCert() {
            if(this.dataCertAnno < 0 || this.dataCertMese < 0 || this.dataCertGiorno < 0) {
                this.validDataCert = false;
                return;
            }
            let year = String(this.dataCertAnno);
            let month = String(this.dataCertMese).padStart(2,'0');
            let day = String(this.dataCertGiorno).padStart(2,'0');
            this.dataCert = moment(year + '-' + month + '-' + day + ' 00:00:00.000');
            if(this.dataCert > moment()) {
                this.validDataCert = false;
                this.$root.documentUpload.documentDate = null;
            } else {
                this.validDataCert = true;
                this.$root.documentUpload.documentDate = this.dataCert;
            }
        },

        openOverlayCashback() {
            this.$root.$refs.pageMan._showAlertSceltaCashback();
        },

        showProductsRicariche() {
            this.$root.$refs.pageMan.showOverlay('productsricaricheoverlay');
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .contrattolink {
        text-decoration: none;
    }

    .scrollaccordion {
        max-height: 300px;
        overflow-x:hidden;
        overflow-y:scroll;
    }
</style>