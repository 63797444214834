<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col text-center">
                    <img id="main-logo" src="img/orangeLogo.svg">
                </div>
            </div>
            <div class="row mt-3 mb-0 pb-0">
                <div class="col-12 text-center">
                    <p class="titolo-35 text-white">Ciao {{ $root.user.name }}</p>
                </div>
            </div>
            <div class="row mt-3 mb-0 pb-0">
                <div v-if="$root.isApple == false" class="col-12 text-center mb-3 px-4">
                    <!-- <button v-if="$root.subscriptions.abbonamenti.length > 0" v-on:click="$root.userGetAndShowGreenPass" class="btn btn-block testo-bottoni btn-orange btn-orange-green left-icon-holder"><span class="ico-button"><img src="img/greenpass.png" class="pull-left" height="18"></span>&nbsp;GREEN PASS</button> -->
                    <button v-if="($root.subscriptions.certmedTemporaneo == true) && ($root.subscriptions.abbonamenti.length > 0) && ($root.subscriptions.certmedInScadenza == false) && ($root.subscriptions.certmedScaduto == false) && ($root.subscriptions.scadenzaCertMedico != null)" v-on:click="$root.showPage('usercertmedicopage')" class="btn btn-block testo-bottoni btn-orange btn-orange-green left-icon-holder"><span class="ico-button"><img src="img/greenpass.png" class="pull-left" height="18"></span>&nbsp;CERT.MED.TEMPORANEO</button>
                    <button v-if="($root.subscriptions.certmedTemporaneo == false) && ($root.subscriptions.abbonamenti.length > 0) && ($root.subscriptions.certmedInScadenza == false) && ($root.subscriptions.certmedScaduto == false) && ($root.subscriptions.scadenzaCertMedico != null)" v-on:click="$root.showPage('usercertmedicopage')" class="btn btn-block testo-bottoni btn-orange btn-orange-green left-icon-holder"><span class="ico-button"><img src="img/greenpass.png" class="pull-left" height="18"></span>&nbsp;CERTIFICATO MEDICO</button>
                    <button v-if="($root.subscriptions.certmedTemporaneo == false) && ($root.subscriptions.abbonamenti.length > 0) && ($root.subscriptions.certmedInScadenza == true) && ($root.subscriptions.certmedScaduto == false) && ($root.subscriptions.scadenzaCertMedico != null)" v-on:click="$root.showPage('usercertmedicopage')" class="btn btn-block testo-bottoni btn-orange btn-orange-red left-icon-holder"><span class="ico-button"><img src="img/greenpass.png" class="pull-left" height="18"></span>&nbsp;CERTIFICATO IN SCADENZA</button>
                    <button v-if="($root.subscriptions.certmedTemporaneo == false) && (($root.subscriptions.abbonamenti.length > 0) && ($root.subscriptions.certmedScaduto == true)) || ($root.subscriptions.scadenzaCertMedico == null)" v-on:click="$root.showPage('usercertmedicopage')" class="btn btn-block testo-bottoni btn-orange btn-orange-red left-icon-holder"><span class="ico-button"><img src="img/greenpass.png" class="pull-left" height="18"></span>&nbsp;CERTIFICATO SCADUTO</button>
                </div>
                <!-- NOT APPLE -->
                <div v-if="$root.isApple == false" class="col-12 text-center px-4">
                    <button v-if="$root.subscriptions.abbonamenti.length > 0" v-on:click="$root.showPage('prenotamainpage')" class="btn btn-block testo-bottoni btn-orange btn-orange-orange left-icon-holder"><span class="ico-button"><span class="icon-tools_prenota"></span></span>PRENOTA</button>
                    <button v-if="$root.subscriptions.abbonamenti.length == 0 && $root.payments.rateScadute == false" v-on:click="$root.userRinnovaOCompra()" class="btn btn-block testo-bottoni btn-orange btn-orange-orange left-icon-holder"><span class="ico-button"><span class="icon-tools_shoppingbag"></span></span>ABBONATI</button>
                    <button v-if="$root.subscriptions.abbonamenti.length > 0  && $root.payments.rateScadute == true" v-on:click="$root.userRinnovaOCompra()" class="btn btn-block mt-3 testo-bottoni btn-orange btn-orange-orange left-icon-holder"><span class="ico-button"><span class="icon-tools_shoppingbag"></span></span>SALDA RATA</button>
                </div>
                <!-- APPLE -->
                <div v-if="$root.isApple" class="col-12 text-center px-4">
                    <button v-if="$root.subscriptions.abbonamenti.length > 0" v-on:click="$root.showPage('prenotamainpage')" class="btn btn-block testo-bottoni btn-orange btn-orange-orange left-icon-holder"><span class="ico-button"><span class="icon-tools_prenota"></span></span>PRENOTA</button>
                    <button v-if="$root.subscriptions.abbonamenti.length == 0 && $root.payments.rateScadute == false" v-on:click="$root.showAppleMessage()" class="btn btn-block testo-bottoni btn-orange btn-orange-orange left-icon-holder"><span class="ico-button"><span class="icon-tools_shoppingbag"></span></span>INIZIA ORA</button>
                    <button v-if="$root.subscriptions.abbonamenti.length > 0  && $root.payments.rateScadute == true" v-on:click="$root.userRinnovaOCompra()" class="btn btn-block mt-3 testo-bottoni btn-orange btn-orange-orange left-icon-holder"><span class="ico-button"><span class="icon-tools_shoppingbag"></span></span>RATE SCADUTE</button>
                </div>
                <div class="col-12 text-center px-4">
                    <div class="row px-3">
                        <div class="col-6 text-center mt-3 px-0 pr-1">
                            <button v-on:click.stop="$root.evofitShowMain(-1)" class="btn p-0 text-center btn-block testo-bottoni btn-orange btn-outline-gray text-white"><span>IL MIO PROGRAMMA</span></button>
                        </div>
                        <div class="col-6 text-center mt-3 mb-3 px-0 pl-1">
                            <button v-on:click.stop="$root.cashbackGetAndShow" class="btn p-0 text-center btn-block testo-bottoni btn-orange btn-outline-gray orange-text-white"><span>INVITA AMICI</span></button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="row mt-0 pt-0 px-0">
                <div class="col-12 px-0">
                    <div id="gal-parent">
                        <div id="gal-container">
                            <div v-for="cat in $root.reborn.categorie.filter( ct => ct.name != 'Short')" :key="'swipcat-' + cat.id" :data-category="cat.name" class="img-cont"  :style="{ 'background-image' : 'url(img/categorie_video/' + cat.name.trim().toLowerCase() + '.jpeg)' } ">
                                <div class="img-fader">
                                </div>
                                <div class="cat-descr">
                                    <p class="titolo-grande" style="text-transform:uppercase;">{{ cat.name }}</p>
                                    <p class="titolo-categoria"><strong>{{ cat.numVideos }} video</strong></p>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div> -->

            <div class="row mt-5">
                <div class="col-12 p-0 m-0">
                    <div class="rbo-container">
                        <p class="titolo-categoria px-4 rbo-label">Il tuo RBO di questo mese</p>
                        <div class="rbo-img-container">
                            <img src="img/rbo/RBO_sotto50.png" class="rbo-img"  style="-webkit-animation:spinr 8s linear infinite; -moz-animation:spinr 8s linear infinite; animation:spinr 8s linear infinite;    
" :style="{'opacity' : ( ($root.rbo.curValue <= 25) ? 0.9 : 0.3) }">
                        </div>
                        <div class="rbo-img-container">
                            <img src="img/rbo/RBO_50-99.png" class="rbo-img" style="-webkit-animation:spinl 12s linear infinite; -moz-animation:spinl 12s linear infinite; animation:spinl 12s linear infinite;    
" :style="{'opacity' : ( ($root.rbo.curValue > 25) ? 0.9 : 0.3) }">
                        </div>
                        <div class="rbo-img-container">
                            <img src="img/rbo/RBO_100-149.png" class="rbo-img" style="-webkit-animation:spinr 16s linear infinite; -moz-animation:spinr 16s linear infinite; animation:spinr 16s linear infinite;    
" :style="{'opacity' : ( ($root.rbo.curValue > 50) ? 0.9 : 0.3) }">
                        </div>
                        <div class="rbo-img-container">
                            <img src="img/rbo/RBO_150-200.png" class="rbo-img" style="-webkit-animation:spinl 20s linear infinite; -moz-animation:spinl 20s linear infinite; animation:spinl 20s linear infinite;    
" :style="{'opacity' : ( ($root.rbo.curValue > 75) ? 0.9 : 0.3) }">
                        </div>
                        <div class="rbo-img-container">
                            <img src="img/rbo/RBO_sopra200.png" class="rbo-img" style="-webkit-animation:spinr 24s linear infinite; -moz-animation:spinr 24s linear infinite; animation:spinr 24s linear infinite;    
" :style="{'opacity' : ( ($root.rbo.curValue > 90) ? 0.9 : 0.3) }">
                        </div>
                        <div class="rbo-img-container">
                        <p class="rbo-value titolo-medio" v-on:click="$root.rboShowPage()" style="color:white;">{{ $root.rbo.curValue }}<span style="font-size:0.8em; font-weight:light;">/10</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <button v-on:click="$root.rboShowPage()" class="btn btn-block testo-bottoni btn-orange btn-outline-gray">I TUOI RISULTATI</button>
                </div>
            </div>
        </div>

        <div v-show="$root.preferiti.filter( pr => pr.pref_type == 'video').length > 0" class="container-fluid mx0 mt-5 px-0">
            <div class="row no-gutters">
                <div class="col-12 py-2 px-4">
                    <p class="titolo-categoria text-left"><strong>Le tue preferenze</strong></p>
                </div>
            </div>
            <div class="row no-gutters m-0 mt-0">
                <div class="col-12 text-center">
                    <div id="sliderpreferitimain" class="splide">
                        <div class="splide__track">
                            <ul class="splide__list">
                                <li class="splide__slide swiper-slide" v-for="pref,index in $root.preferiti.filter( pr => pr.pref_type == 'video')" v-bind:key="'prefmain-' + index" :index="index">
                                    <div v-if="pref.videoObj != null" v-on:click.stop="$root.rebornShowVideo(pref.videoObj.id)" class="lesson-preview">
                                        <div class="lesson-preview-image" v-bind:style="{ backgroundImage: 'url(' + pref.videoObj.url_preview + ')' }">
                                            <div v-if="pref.videoObj.url_video == ''" class="video-blocked"><span class="icon-allert"></span></div>
                                        </div>
                                        <div class="lesson-preview-darkbox">
                                            <b style="text-transform: uppercase; font-size:1.1rem; font-weight: bold;">{{ pref.videoObj.name.replace("_","'") }}</b>
                                            <span style="padding-bottom:2px;"><i class="far fa-user"></i>&nbsp;{{ pref.videoObj.istruttore }} - <i class="far fa-clock"></i>&nbsp;{{ pref.videoObj.durata }} min</span>
                                        </div>
                                    </div>        
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div v-show="$root.reborn.recent.length > 0" class="container-fluid mx0 mt-5 px-0">
            <div class="row no-gutters">
                <div class="col-12 py-2 px-4">
                    <p class="titolo-categoria text-left"><strong>Recenti</strong></p>
                </div>
            </div>
            <div class="row no-gutters m-0 mt-0">
                <div class="col-12 text-center">
                    <div id="sliderrecentimain" class="splide">
                        <div class="splide__track">
                            <ul class="splide__list">
                                <li class="splide__slide swiper-slide" v-for="vid,index in $root.reborn.recent" v-bind:key="'recentmain-' + index" :index="index">
                                    <div v-if="vid != null" v-on:click.stop="$root.rebornShowVideo(vid.id)" class="lesson-preview">
                                        <div class="lesson-preview-image" v-bind:style="{ backgroundImage: 'url(' + vid.url_preview + ')' }">
                                            <div v-if="vid.url_video == ''" class="video-blocked"><span class="icon-allert"></span></div>
                                        </div>
                                        <div class="lesson-preview-darkbox">
                                            <b style="text-transform: uppercase; font-size:1.1rem; font-weight: bold;">{{ vid.name.replace("_","'") }}</b>
                                            <span style="padding-bottom:2px;"><i class="far fa-user"></i>&nbsp;{{ vid.istruttore }} - <i class="far fa-clock"></i>&nbsp;{{ vid.durata }} min</span>
                                        </div>
                                    </div>        
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="$root.evofit.modsched.obiettivi.length > 0" class="container-fluid mx-0 mt-5 px-0">
            <div class="row no-gutters">
                <div class="col-12 py-2 px-4">
                    <p class="titolo-categoria text-left">Programmi di allenamento</p>
                </div>
            </div>
            <div class="row no-gutters m-0 mt-0 min-row-height">
                <div class="col-12 text-center">
                    <div id="sliderobiettivischeda" class="splide">
                        <div class="splide__track">
                            <ul class="splide__list">
                                <li class="splide__slide obiettivo-slide" v-for="obiettivo,index in $root.evofit.modsched.obiettivi" v-bind:key="'obb-' + index" :index="index">
                                    <div v-on:click.stop="$root.evofitShowObiettivo(index)" class="obiettivo-preview">
                                        <div class="obiettivo-preview-image" v-bind:style="{ backgroundImage: 'url(img/obiettivi/OBIETTIVO1_1/' + obiettivo.immagine + '.jpeg)' }">
                                            <!-- <span style="position:absolute; left:10px; top:10px; width:20px; border-radius:5px; height:20px; background-color:rgba(0,0,0,0.5); color:#fff;">{{ obiettivo.immagine }}</span> -->
                                        </div>
                                        <div class="obiettivo-preview-darkbox">
                                            <div class="titolo-obiettivo">
                                                <b style="display:block; text-transform: uppercase; font-size:1.1rem; font-weight: bold; white-space:normal;">{{ obiettivo.descrizione }}</b>
                                                <span style="font-size:0.9rem;">{{ obiettivo.schedeCount }} schede</span>
                                            </div>
                                        </div>
                                    </div>        
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="$root.userAbbonamERateOK()" class="container-fluid mx-0 mt-5 px-0">
            <div class="row no-gutters">
                <div class="col text-center">
                    <div class="row" style="height:49%;">
                        <div class="col-12 text-center">
                            <img src="img/questionario-sfondo.jpg" :style="{ 'height' : ($root.ui.squareHeight * 0.7) + 'px', 'width' : ($root.ui.squareHeight * 0.7) + 'px' }" style="border-radius:50%;  max-width:600px; margin-left:auto; margin-right:auto;">
                            <p class="titolo-35 orange-text-white testo-blocco-questionario" :style="{ 'height' : ($root.ui.squareHeight * 0.7) + 'px'}">CREA IL TUO PROGRAMMA PERSONALIZZATO</p>
                            <button v-on:click.stop="$root.surveyShow()" class="btn btn-orange btn-outline-white testo-bottoni bottone-questionario">INIZIA</button> 
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="container-fluid mx-0 mt-5 px-0">
            <div class="row no-gutters">
                <div class="col text-center" >
                    <div class="row" style="height:49%;">
                        <div class="col-12">
                            <img src="img/questionario-sfondo.jpg" :style="{ 'height' : ($root.ui.squareHeight * 0.7) + 'px', 'width' : ($root.ui.squareHeight * 0.7) + 'px' }" style="border-radius:50%;  max-width:600px; margin-left:auto; margin-right:auto;">
                            <p class="titolo-35 orange-text-white testo-blocco-questionario">CREA IL TUO PROGRAMMA PERSONALIZZATO</p>
                        </div>
                    </div>
                    <div class="row" style="height:49%;">
                        <div class="col-12">
                            <button v-on:click.stop="$root.userRinnovaOCompra" style="margin-top:30%" class="btn btn-orange btn-outline-white testo-bottoni bottone-questionario">INIZIA</button> 
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: 'Main',
    data () {
        return {
        }
    },
    methods: {
        //l'utente ha un abbonamento scaduto, senza abbonamento o rate scadute, decidi cosa fare
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    #main-logo {
        width:40%;
        max-width: 280px;
    }

.testo-blocco-questionario {
    display:block;
    position:absolute;
    padding-top:15%;
    text-align:center;
    width:100%;
    padding-left:20%;
    padding-right: 20%;
    top:0px;
    left:0px;
}

@media all and (min-width:750px) {

    .testo-blocco-questionario {
        display:block;
        position:absolute;
        padding-top:10%;
        text-align:center;
        width:100%;
        padding-left:40%;
        padding-right: 40%;
        top:0px;
        left:0px;
    }

}

.bottone-questionario {
    position:absolute;
    display:block;
    margin-left: auto;
    margin-right: auto;
    margin-top:20px;
    bottom:25%;
    left:50%;
    transform: translateX(-50%);
}
</style>
