<template>
    <div v-if="visible == true" id="toptoolbar">
        <div class="top-toolbar" :class="{ 'toolbar-orange' : (elements.solidBg == true) }">
            <div v-if="darkMode == true" class="row">
                <div class="col-12">
                    <div v-if="elements.backButton == true" v-on:click="$root.$refs.pageMan.pageBack()" style="float:left; display:inline-block;">
                        <strong><span style="font-size:24px; line-height:45px;" class="icon-navig_back"></span></strong>
                    </div>
                    <div v-if="elements.closeButton == true" v-on:click="handleCloseClick" style="float:left; display:inline-block;">
                        <strong><span style="font-size:24px; line-height:45px;" class="icon-navig_chiudi"></span></strong>
                    </div>
                    <div id="cast_button_container" v-if="elements.castButton == true" v-on:click="handleChromeCastClick" style="float:right; margin-right:5px; display:inline-block;">
                        <google-cast-launcher id="cast_button" style="display:block; width:35px; height:35px; padding-top:5px;"></google-cast-launcher>
                    </div>
                    <div v-if="elements.userButton == true" v-on:click="handleUserClick" style="float:right; margin-right:5px; display:inline-block;">
                        <strong><span style="font-size:24px; line-height:45px;" :class="{ 'orange-text-orange' : ($root.$refs.pageMan.curPage.includes('user')) }" class="icon-tools_profilo"></span></strong>
                    </div>
                    <div v-if="elements.prenotaButton == true" v-on:click="handlePrenotaClick" style="float:right; margin-right:5px; display:inline-block;">
                        <strong><span style="font-size:24px; line-height:45px;" :class="{ 'orange-text-orange' : ($root.$refs.pageMan.curPage.includes('prenota')) }" class="icon-tools_prenota"></span></strong>
                    </div>
                    <!-- <div v-if="elements.waButton == true" v-on:click="handleWaClick" style="float:right; margin-right:5px; display:inline-block;">
                        <strong><span style="font-size:24px; line-height:45px;" class="icon-tools_whatsapp"></span></strong>
                    </div> -->
                    <div v-if="elements.savedButton == true" v-on:click="handlePreferitiClick" style="float:right; margin-right:5px; display:inline-block;">
                        <strong><span style="font-size:24px; line-height:45px;" :class="{ 'orange-text-orange' : ($root.$refs.pageMan.curPage.includes('preferiti')) }" class="icon-tools_preferiti"></span></strong>
                    </div>
                    <div style="clear:both;"></div>
                </div>
            </div>
            <div v-if="darkMode == false" class="row">
                <div class="col-12" :class="{ 'bg-white' : (elements.solidBg == true) }">
                    <div v-if="elements.backButton == true" v-on:click="$root.$refs.pageMan.pageBack()" style="float:left; display:inline-block;">
                        <strong><span style="color:#000; font-size:24px; line-height:45px;" class="icon-navig_back"></span></strong>
                    </div>
                    <div v-if="elements.closeButton == true" v-on:click="handleCloseClick" style="float:left; display:inline-block;">
                        <strong><span style="color:#000; font-size:24px; line-height:45px;" class="icon-navig_chiudi"></span></strong>
                    </div>
                    <div id="cast_button_container" v-if="elements.castButton == true" v-on:click="handleChromeCastClick" style="float:right; margin-right:5px; display:inline-block;">
                        <google-cast-launcher id="cast_button" style="display:block; width:35px; height:35px; padding-top:5px;"></google-cast-launcher>
                    </div>
                    <div v-if="elements.userButton == true" v-on:click="handleUserClick" style="float:right; margin-right:5px; display:inline-block;">
                        <strong><span style="color:#000; font-size:24px; line-height:45px;" :class="{ 'orange-text-orange' : ($root.$refs.pageMan.curPage.includes('user')) }" class="icon-tools_profilo"></span></strong>
                    </div>
                    <div v-if="elements.prenotaButton == true" v-on:click="handlePrenotaClick" style="float:right; margin-right:5px; display:inline-block;">
                        <strong><span style="color:#000; font-size:24px; line-height:45px;" :class="{ 'orange-text-orange' : ($root.$refs.pageMan.curPage.includes('prenota')) }" class="icon-tools_prenota"></span></strong>
                    </div>
                    <!-- <div v-if="elements.waButton == true" v-on:click="handleWaClick" style="float:right; margin-right:5px; display:inline-block;">
                        <strong><span style="color:#000; font-size:24px; line-height:45px;" class="icon-tools_whatsapp"></span></strong>
                    </div> -->
                    <div v-if="elements.savedButton == true" v-on:click="handlePreferitiClick" style="float:right; margin-right:5px; display:inline-block;">
                        <strong><span style="color:#000; font-size:24px; line-height:45px;" :class="{ 'orange-text-orange' : ($root.$refs.pageMan.curPage.includes('preferiti')) }" class="icon-tools_preferiti"></span></strong>
                    </div>
                    <div style="clear:both;"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopToolbar',
    data () {
        return {
            visible: false,
            darkMode: true,
            elements: {
                solidBg: false,
                backButton: false,
                closeButton: false,
                castButton: false,
                waButton: false,
                savedButton: true,
                userButton: true,
                prenotaButton: true
            }
        }
    },
    methods: {
        hide() {
            this.visible = false;
        },

        handlePreferitiClick() {
            //we could assing preferiti videos here to ensure they are available
            this.$root.$refs.pageMan.showOverlay('preferitioverlay')
        },

        handleChromeCastClick() {

        },

        handleUserClick() {
            if(this.$root.$refs.pageMan.curPage.includes('user')) {
                this.$root.$refs.pageMan.resetToLastRoot();
            } else {
                this.$root.$refs.pageMan.showPage('usermainpage')                
            }
        },

        handlePrenotaClick() {
            if(this.$root.$refs.pageMan.curPage.includes('prenota')) {
                this.$root.$refs.pageMan.resetToLastRoot();
            } else {
                this.$root.$refs.pageMan.showPage('prenotamainpage');
            }
        },

        handleWaClick(event) {
            this.$root.openWhatsappAmministrazione(event);
        },

        handleCloseClick() {
            //maybe we are still polling..
            this.$root.paymentsHideExternalIframeModal();
            if(this.$root.$refs.pageMan.curPage == 'eseguipage' && this.$root.evofit.sedutaPlayer != null && this.$root.evofit.sedutaPlayer.singleModeEsercizioIdx != null) {
                this.$root.$refs.pageMan.pageBack();
            } else {
                this.$root.$refs.pageMan.resetToLastRoot();
            }
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
