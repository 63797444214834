import axios from 'axios';
import config from '../config/config_local.js'

import moment from 'moment'
import momentIT from 'moment/locale/it'

export default  {
    data() {
        return {
            subscriptions: {
                canRevoke: false,
                revocableSubscriptions: [],

                scadenzaCertMedico: null,
                certmedInScadenza: false,
                certmedScaduto: false,
                certmedTemporaneo: false,

                abbonamentoInScadenza: false,
                scadenzaAbbonamento: null,
                currentAbbonamentoName: '',
                abbonamentoProduct: null,
                nuovoAbbonamentoCliccato: '',
                isPromo: false,     //this subscription is a promo subscription
                abbonamenti:[],
                
                sospensione: {
                    abbonamentiLista: [],
                    giorniInizioLista: [],
                    motiviLista: [],
                    abbonamentoIdx: 0,
                    durateLista: [],

                    durataMesi: 1,
                    giornoInizioIdx: 0,
                    motivoIdx: 0,
                    abbonamentoIdx: 0,
                    sospensioniAttive:[]
                },

                disdetta: {
                    abbonamentiLista: [],
                    motiviLista: []
                }
            }
        }
    },
    watch: {
    },
    methods: {
        abbonamentiReset() {
            this.subscriptions.canRevoke = false;
            this.subscriptions.revocableSubscriptions = [];
            this.subscriptions.scadenzaCertMedico = null;
            this.subscriptions.certmedInScadenza = false;
            this.subscriptions.certmedTemporaneo = false;
            this.subscriptionsInAttesaCambio.certmedScaduto = false;
            this.subscriptions.abbonamentoInScadenza = false;
            this.subscriptions.scadenzaAbbonamento = null;
            this.subscriptions.currentAbbonamentoName = '';
            this.subscriptions.abbonamentoProduct = null;
            this.subscriptions.nuovoAbbonamentoCliccato = '';
            this.subscriptions.abbonamenti =[];
            
            this.subscriptions.sospensione.abbonamentiLista= [];
            this.subscriptions.sospensione.giorniInizioLista= [];
            this.subscriptions.sospensione.motiviLista= [];
            this.subscriptions.sospensione.abbonamentoIdx= 0;
            this.subscriptions.sospensione.durateLista= [];
            this.subscriptions.sospensione.durataMesi= 1;
            this.subscriptions.sospensione.giornoInizioIdx= 0;
            this.subscriptions.sospensione.motivoIdx= 0;
            this.subscriptions.sospensione.abbonamentoIdx= 0;
            this.subscriptions.sospensione.sospensioniAttive = [];

            this.subscriptions.disdetta.abbonamentiLista= [];
            this.subscriptions.disdetta.motiviLista= [];
            this.subscriptions.isPromo = false;
        },

        abbonamentiAggiungiSospensioneDaDoc(_docContents) {
            if(_docContents[0].Text.toLowerCase().includes('nessun abbonamento')) {
                return false;
            }
            _docContents.forEach( (_sosp) => {
                //il testo della sospensione arriva nel formato:
                //PREMIUM-EX 1 M PREMIUM-24 dal 01/06/2021 al 01/10/2021
                let start = _sosp.Contents[0].Text.indexOf('dal');
                if(start > -1) {
                    let inizioStr = _sosp.Contents[0].Text.substr( start + 4, 10);
                    let inizio = moment( inizioStr, 'DD/MM/YYYY');
                    let end = _sosp.Contents[0].Text.indexOf(' al ');
                    let fineStr = _sosp.Contents[0].Text.substr( end + 4, 10 );
                    let fine = moment( fineStr, 'DD/MM/YYYY');
                    if(moment() >= inizio && moment() <= fine) {
                        this.subscriptions.sospensione.sospensioniAttive.push({
                            name: _sosp.Text, inizio: inizio, fine: fine
                        });
                    }

                }
            });
        },

        getRevocableSubscriptions() {
            if(this.user.iyestoken) {
                axios.post( config.serverUrl + '/epurse/allrevocablesubscriptions', {
                    iyestoken : this.user.iyestoken
                })
                .then( (resmy) => {
                    if(!resmy.data.error) {
                        //console.log('Got revocable subscriptions');
                        if(resmy.data.data.message && resmy.data.data.message.includes('No subscriptions')) {
                            this.subscriptions.canRevoke = false;
                            this.subscriptions.revocableSubscriptions = [];
                        } else {
                            //console.log(resmy.data.data.data[0]);
                            if(resmy.data.data.data[0].RinnovoAutomatico == -1) {
                                this.subscriptions.canRevoke = true;
                            } else {
                                this.subscriptions.canRevoke = false;
                            }
                            this.subscriptions.revocableSubscriptions = resmy.data.data.data[0];        
                        }
                    } else {
                        this.subscriptions.canRevoke = false;
                    }
                })
                .catch( (errmy) => {
                    console.log('WARNING! Cannot retrieve revokable subscriptions');
                    console.log(errmy);
                });    
            }         
        },

        getScadenzaAbbonamento() {
            console.log('Getting scadenza abbonamento');
            let abbonamenti = [];
            let today = moment( new Date() );
            this.user.documents.forEach( (doc) => {
                if(doc.Text == "ABBONAMENTI E PAGAMENTI") {
                    doc.Contents.forEach( (abbodc) => {
                        let testo = abbodc.Text;
                        if(testo) {
                            //check if is a promo subscription
                            if(testo.indexOf('7 G') != -1) {
                                this.subscriptions.isPromo = true;
                            }
                            if(testo.indexOf('Nessun abbonamento') != -1) {
                                this.subscriptions.abbonamentoInScadenza = true;
                                this.subscriptions.scadenzaAbbonamento = null;
                                return false;
                            } else {
                                let inscadenza = false;
                                //INZIO: RIMUVOE TUTTI GLI ABBONAMENTI CHE CONTENGONO DISP:99
                                //La priorità va all'abbonamento che ha DISP:X con X maggiore
                                //Poichè potrei avere più di un abbonamento attivo
                                if(!testo.includes('DISP:99')) {

                                    if(testo.indexOf("(IN SCADENZA)") != -1) {
                                        inscadenza = true;
                                        testo = testo.replace("(IN SCADENZA)","");
                                        testo = testo.trim();
                                    }

                                    //remove DISP:X used in the old app
                                    let primaParte = '';
                                    let secondaParte = '';
                                    
                                    while(testo.indexOf('DISP:') != -1) {
                                      let inizioDisp = testo.indexOf('DISP:');
                                      if(inizioDisp != -1) {
                                            primaParte = testo.substring(0, inizioDisp - 1);
                                        secondaParte = testo.substring(inizioDisp + 6, 255);
                                        testo = primaParte + secondaParte;
                                      }
                                    }


                                    testo = testo.replace('(','');
                                    testo = testo.replace(')','');
                                    let data = testo.substr(-10,10);
                                    let tmpScad = moment(data,"DD/MM/YYYY");
                                    tmpScad.add(1, 'day');

                                    let tmpInizioStr = this._findInizioAbbonamento(testo.toLowerCase() );
                                    let tmpInizio = moment(tmpInizioStr,'DD/MM/YYYY');

                                    if(!tmpScad.isBefore( today )) {
                                        abbonamenti.push({
                                            disp:  this._findDispAbbonamento(testo),
                                            name: this._findNameAbbonamentoFromUserDoc(testo),
                                            active: true,
                                            inscadenza: inscadenza,
                                            scad: tmpScad,
                                            inizio: tmpInizio
                                        });    
                                    } else {
                                        abbonamenti.push({
                                            disp:  this._findDispAbbonamento(testo),
                                            name: this._findNameAbbonamentoFromUserDoc(testo),
                                            active: false,
                                            inscadenza: inscadenza,
                                            scad: tmpScad,
                                            inizio: tmpInizio
                                        });    
                                    }

                                }
                                //FINE: COVID/DISP
                            }
                        }
                    });
                }
            });

            console.log('Found abbonamenti: ' + abbonamenti.length);
            if(abbonamenti.length > 0) {
                //sort abbonamenti using DISP and SCAD
                abbonamenti.sort( this._compareAbbonamento );

                //chose first abbonamento
                this.subscriptions.abbonamentoInScadenza = abbonamenti[0].inscadenza;
                this.subscriptions.scadenzaAbbonamento = abbonamenti[0].scad;
                this.subscriptions.currentAbbonamentoName = (abbonamenti[0].name == 'RID') ? 'RID RINNOVO': abbonamenti[0].name;
                /* if(abbonamenti.length > 1 ) {
                    if(abbonamenti[1].active == true) {
                        let secAbb = (abbonamenti[1].name == 'RID') ? 'RID RINNOVO': abbonamenti[1].name;
                        this.subscriptions.currentAbbonamentoName += (' + ' + secAbb);
                    }
                } */
                this.subscriptions.nuovoAbbonamentoCliccato = this.subscriptions.currentAbbonamentoName;
                this.subscriptions.abbonamenti = abbonamenti;

                //if this user has some subscriptions update the invitations records
                this.cashbackSetMeSubscribed();
            } else {
                this.subscriptions.abbonamentoInScadenza = true;
                this.subscriptions.scadenzaAbbonamento = null;
                this.subscriptions.currentAbbonamentoName = 'NESSUNO';
                this.subscriptions.nuovoAbbonamentoCliccato = this.subscriptions.currentAbbonamentoName;
                this.subscriptions.abbonamenti = [];
            }

        },

        _findInizioAbbonamento(_stringa) {
            //"BASE 1 M BASE - BASE DISP:2 dal 01/06/2021 al 30/06/2021"
            let posDal = _stringa.indexOf('dal ');
            posDal += 4;  //position + 'dal '
            return _stringa.substr(posDal, 10);
        },

        subscriptionsInAttesaCambio() {
            let found = '';
            this.subscriptions.disdetta.abbonamentiLista.forEach( (abb) => {
                if(abb.DescrizioneDurata.toUpperCase().includes('-1G')) {
                    found = abb.DescrizioneDurata;
                }
            });

            return found;
        },

        _compareAbbonamento( a, b) {
            //vecchio ordinamento usando il disp
            /* if(a.active && b.active) {
                if(a.disp < b.disp) {
                    return b;
                } else {
                    return a;
                }
            } else if (a.active && !b.active) {
                return a;
            } else {
                return b;
            } */
            if(a.active && b.active) {
                if(b.scad.isBefore( a.scad )) {
                    return -1;
                } else {
                    return 1;
                }
            } else if (a.active && !b.active) {
                return -1;
            } else {
                return 1;
            } 
        },

        _isProdDisdicibile(prod) {
            let disdicibile = false;
            this.subscriptions.disdetta.abbonamentiLista.forEach( (abb) => {
                if(prod.Name == abb.DescrizioneDurata) {
                    disdicibile = true;
                }
            });

            return disdicibile;
        },

        //Set the product corresponding to current main abbonamento
        abbonamentiSetCurrentProduct() {
            console.log('Set abbonamentoProduct');
            let found = false;
            let abbIdx = 0;
            this.subscriptions.abbonamenti.forEach( (abb) => {
                if(abb.name != "OUTDOOR") {

                    this.products.tutti.forEach( (prod) => {
                        if(prod.Name.includes( abb.name )) {
                            //sicuramente l'abbonamento attivo è tra i disdicibili
                            //if(this._isProdDisdicibile(prod)) {
                                this.subscriptions.abbonamentoProduct = prod;
                            //}
                        }
                    });

                }
            });

            //if no product was found check if the user has outdoor only
            if(this.subscriptions.abbonamentoProduct == null) {
                this.subscriptions.abbonamenti.forEach( (abb) => {
                    if(abb.name == "OUTDOOR") {
                        this.products.tutti.forEach( (prod) => {
                            if(prod.Name.includes( abb.name )) {
                                this.subscriptions.abbonamentoProduct = prod;
                            }
                        });
                    }
                });    
            }

        },

        getScadenzaCertificatoMedico() {
            this.user.documents.forEach( (doc) => {
                if(doc.Text == "CERTIFICATO MEDICO") {
                    doc.Contents.forEach( (certmed) => {
                        let data = certmed.Text;
                        if(data) {
                            if(data.indexOf("Nessun certificato") != -1) {
                                this.subscriptions.ico = null;
                                this.subscriptions.certmedInScadenza = true;
                                this.subscriptions.certmedTemporaneo = false;
                                return false;
                            } else {
                                data = data.substr(-12,12);
                                data = data.replace('(','');
                                data = data.replace(')','');
                                //console.log("Scadenza certificato medico: " + data);
                                this.subscriptions.scadenzaCertMedico = moment(data,"DD/MM/YYYY");    
                                if(data.toLowerCase().includes('in scadenza')) {
                                    this.subscriptions.certmedInScadenza = true;
                                } else {
                                    if(this.subscriptions.scadenzaCertMedico < moment().add(15,'days')) {
                                        this.subscriptions.certmedInScadenza = true;
                                    } else if( this.subscriptions.scadenzaCertMedico < moment() ) {
                                        this.subscriptions.certmedScaduto = true;
                                    }

                                    //verifica se è un cert temporaneo
                                    if(this.subscriptions.scadenzaCertMedico.format('DD') == '30') {
                                        if(this.subscriptions.scadenzaCertMedico.format('MM') == '06' || this.subscriptions.scadenzaCertMedico.format('MM') == '09') {
                                            this.subscriptions.certmedTemporaneo = true;
                                        }
                                    }
                                }
                            }
                        }
                    });
                }
            });
        },

        abbonamentiGetDatiSospensione() {
            if(this.user.iyestoken) {
                this.subscriptions.sospensione.abbonamentiLista = [];
                this._getRagioniSospensione();
                axios.post( config.serverUrl + '/epurse/datisospensione', {
                    iyestoken : this.user.iyestoken
                })
                .then( (resmy) => {
                    if(!resmy.error) {
                        //console.log('Got sospensione');
                        //console.log(resmy);
                        if(resmy.data.data && resmy.data.data.data) {
                            this.subscriptions.sospensione.abbonamentiLista = resmy.data.data.data;
                        } 
                        this._abbonamentiUpdateDatiSospensione();    
                    } else {
                        console.log('WARNING! Got empty suspension data.');
                        console.log(errmy);
                        this.subscriptions.sospensione.abbonamentiLista = [];
                    }
                })
                .catch( (errmy) => {
                    console.log('WARNING! Cannot retrieve suspension data.');
                    console.log(errmy);
                });    
            }             
        },


        abbonamentiDoSospensione() {
            if(this.user.iyestoken) {  
                this.$root.$refs.pageMan.showAlert('Sicuro di voler sospendere l\'abbonamento?','SI','Annulla')
                .then( (ok) => {

                    this.subscriptions.sospensione.durateLista = [];              
                    let iscrizioneId = this.subscriptions.sospensione.abbonamentiLista[ this.subscriptions.sospensione.abbonamentoIdx ].IdIscrizione;
                    let numeroGiorni = this._calcGiorniSospensioneFromMesi();
                    let dataInizio = this.subscriptions.sospensione.giorniInizioLista[ this.subscriptions.sospensione.giornoInizioIdx ].data;
                    axios.post( config.serverUrl + '/epurse/dosospensione', {
                        iyestoken : this.user.iyestoken,
                        IDSubscription: iscrizioneId,
                        SuspensionStartDate: dataInizio,
                        GiorniSospensione: (parseInt(numeroGiorni) + 1),
                        Motivo: this.subscriptions.sospensione.motiviLista[ this.subscriptions.sospensione.motivoIdx ]
                    })
                    .then( (resmy) => {
                        if(resmy.data && resmy.data.error == false) {                        
                            //console.log('Sospensione avvenuta');
                            //console.log(resmy);
                            this.$root.$refs.pageMan.showAlert('Sospensione avvenuta con successo.');   
                            this.getMe(); 
                        } else {
                            this.$root.$refs.pageMan.showAlert('Impossibile effettuare sospensione. Contattare assistenza.');
                        }
                    })
                    .catch( (errmy) => {
                        console.log(errmy);
                        this.$root.$refs.pageMan.showAlert('Impossibile effettuare sospensione. Rispovare più tardi.');
                    });        
                })
                .catch( (ko) => {

                });
            }             

        },

        //restituisce il numero di giorni presenti nei mesi successivi
        _calcGiorniSospensioneFromMesi() {
            let mesi = this.subscriptions.sospensione.durataMesi;
            let totGiorni = 0;
            for(let i = 0; i < mesi; i++) {
                totGiorni += moment().add(i + 1,'M').daysInMonth();
            }
            return totGiorni - 1;

        },

        _getRagioniSospensione() {
            if(this.user.iyestoken) {
                this.subscriptions.sospensione.motiviLista = [];
                axios.post( config.serverUrl + '/epurse/ragionisospensione', {
                    iyestoken : this.user.iyestoken
                })
                .then( (resmy) => {
                    if(!resmy.error) {
                        //console.log('Got ragioni sospensione');
                        //console.log(resmy);
                        if(resmy.data.data.data) {
                            this.subscriptions.sospensione.motiviLista = resmy.data.data.data;    
                        } else {
                            console.log('WARNING! Got empty suspension motivations.');
                        }
                    }
                })
                .catch( (errmy) => {
                    console.log('WARNING! Error retrieving suspensions motivation.');
                    console.log(errmy);
                });    
            }             
        },

        _abbonamentiUpdateDatiSospensione() {
            this._abbonamentiGetGiorniSospensione();
        },

        _abbonamentiGetGiorniSospensione() {
            if(this.user.iyestoken) {   
                this.subscriptions.sospensione.giorniInizioLista = [];  

                if(this.subscriptions.sospensione.abbonamentiLista.length == 0)           
                    return false;

                let iscrizioneId = this.subscriptions.sospensione.abbonamentiLista[ this.subscriptions.sospensione.abbonamentoIdx ].IdIscrizione;
                axios.post( config.serverUrl + '/epurse/giornisospensione', {
                    iyestoken : this.user.iyestoken,
                    IdIscrizione: iscrizioneId
                })
                .then( (resmy) => {
                    //console.log('Got giorni sospensione');
                    //console.log(resmy);
                    resmy.data.data.data.forEach( (giorno) => {
                        let oggiObj = moment(giorno);
                        let oggi = oggiObj.format('YYYY-MM-DD');
                        let ultimo = oggiObj.clone().endOf('month').format('YYYY-MM-DD');
                        //if(oggi == ultimo) {
                            let testo = moment(giorno).format('DD - MMM - YYYY');
                            //la sospensione inizia effettivamente dal giorno seguente
                            let seguente = moment(giorno); //.clone().add(1,'days');
                            let seguenteStr = seguente.format('YYYY-MM-DD') + 'T00:00:00';
                            this.subscriptions.sospensione.giorniInizioLista.push({ testo: testo, data: seguenteStr });
                        //}
                    });                    
                    
                })
                .catch( (errmy) => {
                    console.log('WARNING! Error retrieving cancellation days');
                    console.log(errmy);
                });    
                this._abbonamentiGetDurateSospensione();
            }             
        },

        _abbonamentiGetDurateSospensione() {
            if(this.user.iyestoken) {  
                this.subscriptions.sospensione.durateLista = [];              
                let iscrizioneId = this.subscriptions.sospensione.abbonamentiLista[ this.subscriptions.sospensione.abbonamentoIdx ].IdIscrizione;
                axios.post( config.serverUrl + '/epurse/duratesospensione', {
                    iyestoken : this.user.iyestoken,
                    IdIscrizione: iscrizioneId
                })
                .then( (resmy) => {
                    //console.log('Got giorni sospensione');
                    //console.log(resmy);
                    resmy.data.data.data.forEach( (giorno) => {
                        let testo = moment(giorno).format('DD - MMM - YYYY');
                        this.subscriptions.sospensione.durateLista.push({ testo: testo, data: giorno });
                    });                    
                })
                .catch( (errmy) => {
                    console.log('WARNING! Cannot retrieve cancellation durations');
                    console.log(errmy);
                });    
            }             
        },

        abbonamentiGetDatiDisdetta() {
            if(this.user.iyestoken) {                
                this._getRagioniDisdetta();
                axios.post( config.serverUrl + '/epurse/datidisdetta', {
                    iyestoken : this.user.iyestoken
                })
                .then( (resmy) => {
                    //console.log('Got disdetta');
                    //console.log(resmy);
                    if(resmy.data.data.data) {
                        this.subscriptions.disdetta.abbonamentiLista = resmy.data.data.data;
                        this.abbonamentiDisdiciCausaCambioAbbonamento();
                    } else {
                        console.log('WARNING! Got empty cancel motivations');
                    }
                })
                .catch( (errmy) => {
                    console.log('WARNING! Error getting cancel motivations');
                    console.log(errmy);
                });    
            }             

        },

        //se trovo due abbonamenti disdicibili significa che ho comprato un abbonamento durante il CAMBIA ABBONAMENTO
        //e devo disdire quello con DataInizio antecedente ad oggi 
        //Questo perchè se un utente clicca il cambio abbonamento, ma poi nno paga si ritrova con l'abbonamento precedente 
        //disdettato e quindi nessun abbonamento.
        abbonamentiDisdiciCausaCambioAbbonamento() {
            //solo se ci sono più abbonamenti da disdire!!
            if(this.subscriptions.disdetta.abbonamentiLista.length == 1) {
                return false;
            }

            let now = moment();
            let nowStr = now.format('YYYY-MM-DD') + 'T00:00:00';

            //se esiste un abbonamento DI OGGI che contiene 1G, significa che ho appena fatto un cambio abbonamento 
            //e sono subito dopo il reload del pagamento 
            let trovatoG = false;
            this.subscriptions.abbonamenti.forEach( (abb) => {
                let inizioStr = abb.inizio.format('YYYY-MM-DD') + 'T00:00:00';
                if( nowStr == inizioStr) {
                    if(abb.name.includes('-1G')) {
                        trovatoG = true;
                    }
                }
            });

            //se non ho abbonamenti con -1G iniziati oggi non devo disdire successivamente al cambio abbonamento
            if(trovatoG == false) {
                return false;
            }

            this.subscriptions.disdetta.abbonamentiLista.forEach( (disdAbb) => {
                if(disdAbb.DataInizio < nowStr ) {
                    this.$root.abbonamentiDoDisdettaSync()
                    .then( (ok) =>{
                        console.log('DISDETTA ABBONAMENTO: OK');
                    })
                    .catch( (err) => {
                        console.log('Errore disdetta abbonamento.');
                    })
                }
            });
        },

        abbonamentiDoDisdettaSync() {
            return new Promise( (resolve,reject) => {
                if(this.user.iyestoken) {  
                    axios.post( config.serverUrl + '/epurse/revokesub', {
                        iyestoken: this.user.iyestoken,
                        Descrizione: 'Disdetta'
                    })
                    .then( (resmy) => {
                        if(resmy.data && (resmy.data.error == false)) {                        
                            resolve(true);
                        } else {
                            reject(false);
                        }
                    })
                    .catch( (errmy) => {
                        console.log(errmy);
                        reject(false);
                    });        
                } else {
                    reject(false);
                }
    
            });
        },

        //Agosto 2022 - per disdire serve avere credito sul borsellino disdette,
        _abbonamentiCheckBorsellinoDisdetta() {
            let puoDisdire = false;

            if( (this.payments.epurses.epurseDisdette == null) || (typeof(this.payments.epurses.epurseDisdette) == 'undefined')) {
                return false;
            }
            
            //se non è un abbonamento con disdetta gratuita devo controllare il borsellino disdette
            if( parseFloat(this.payments.epurses.epurseDisdette.Balance) >= 19.9 ) {
                puoDisdire = true;
            } else {
                puoDisdire = false;
            }
            
            return puoDisdire;
            
        },


        //per disdire è necessario avere il borsellino disdette carico
        //alcuni abbonamenti han diritto ad una ricarica gratuita, altri la devono pagare
        _abbonamentiBuyRicaricaDisdetta() {
            return new Promise( (resolve, reject) => {
                let ricaricaGratuita = false;
                if(this.subscriptions.abbonamentoProduct.Name.toLowerCase().indexOf('ex') !== -1) {
                    //ricarica gratuita
                    ricaricaGratuita = true;
                }
    
                if(this.subscriptions.abbonamentoProduct.Name.toLowerCase().indexOf('prova') !== -1) {
                    //ricarica gratuita
                    ricaricaGratuita = true;
                }
    
                //ritorna il prodotto "ricarica borsellino disdette" in base al tipo di abbonamento (ricarica gratis o a pagamento)
                let prodRicaricaDisdetta = this.productsFindRicaricaDisdetta(ricaricaGratuita);
                
                if(ricaricaGratuita) {
                    //l'utente ha diritto ad una ricarica gratuita del borsellino disdette
                    let dataInizio = moment().format('YYYY-MM-DD');
                    this.paymentGiftProduct(prodRicaricaDisdetta, dataInizio)
                    .then( (ok) => {
                        resolve(true);
                    })
                    .catch( (fail) => {
                        reject(false);
                    });
                } else {

                    if(this.$root.isApple) {

                        this.$root.$refs.pageMan.showAlert('Spiacenti, per disdire sarà necessario visitare un centro Orange.<br>Vuoi vedere ora la lista dei centri Orange?','LISTA CENTRI','CHIUDI')
                        .then( (vai) => {
                            this.showPage('listacentripage');
                            resolve(false);
                        })
                        .catch( (chiudi) => {
                            resolve(false);  
                        });

                    } else {

                        //l'utente deve pagare la ricarica del borsellino disdette
                        this.$root.$refs.pageMan.showAlert('Il tuo contratto prevede un costo di disdetta pari a 19,90 euro. Vuoi procedere o preferisci sospendere gratuitamente?','DISDICI','ANNULLA')
                        .then( (conf) => {
                            //non stiamo acquistando un abbonamento durante la fase di registrazione
                            this.register.registerProduct = null;

                            //avvia la procedura di pagamento di un prodotto
                            this.paymentCreateProductsOrder(prodRicaricaDisdetta);

                            //riporto l'utente alla pagina della disdetta
                            this.$root.$refs.pageMan.savePagemanSteps({
                                endmessage: {
                                    text: 'Ora puoi disdire il tuo abbonamento.',
                                    btnok: 'OK',
                                    btncancel: null
                                },
                                vars: [],
                                ops: [{
                                    funcname: 'paymentsGetAndShowMyEpurses',
                                    param: false
                                }]
                            });
                
                        })
                        .catch( (annul) => {
                            console.log('Disdetta annullata');
                        });
                    } //if isApple
                }
            });

        },

        //ora le disdette hanno un costo, il suddetto costo viene pagato dal borsellino disdette
        //se il borsellino disdette è scarico è necessario ricaricarlo prima di fare la disdetta
        //ad alcuni abbonamenti la ricarica viene regalata, altri la devono pagare
        //questa funzione verifica il tipo di abbonamento ed eventualmente esegue la ricarica gratuita o pagata
        abbonamentiTryDisdetta() {

            /* this.$root.$refs.pageMan.savePagemanSteps({
                endmessage: {
                    text: 'Ora puoi disdire il tuo abbonamento.',
                    btnok: 'OK',
                    btncancel: null
                },
                vars: [],
                ops: [{
                    funcname: 'paymentsGetAndShowMyEpurses',
                    param: false
                }]
            });

            return; */

            //controlliamo il balance del borsellino disdette
            if( this._abbonamentiCheckBorsellinoDisdetta() ) {

                //ha il borsellino carico, facciamo la disdetta
                this._abbonamentiDoDisdetta();

            } else {

                //ha il borsellino disdette scarico, compriamo la ricarica gratis o a pagamento in base all'abbonamento
                this._abbonamentiBuyRicaricaDisdetta()
                .then( (ricaricato) => {
                    //ricarica ok, disdiciamo
                    this._abbonamentiDoDisdetta();
                })
                .catch( (nonricaricato) => {
                    //ricarica fallita
                });

            }
        },

        //richiama effettivamente la funzione di disdetta dopo aver controllato lo stato del borsellino disdette (vedi tryDisdetta)
        _abbonamentiDoDisdetta() {
            this.$root.$refs.pageMan.showWaiting();
            if(this.user.iyestoken) {  
                let _name = this.user.name;
                let _surname = this.user.surname;
                let _phone = this.user.iyesUser.Mobile;
                axios.post( config.serverUrl + '/epurse/revokesub', {
                    iyestoken: this.user.iyestoken,
                    Descrizione: 'Disdetta',
                    name: _name,
                    surname: _surname,
                    phone: _phone
                })
                .then( (resmy) => {
                    if(resmy.data && (resmy.data.error == false)) {                        
                        //console.log('Sospensione avvenuta');
                        //console.log(resmy);
                        
                        this.$root.$refs.pageMan.hideWaiting();
                        this.$root.$refs.pageMan.showAlert('Disdetta avvenuta con successo.');    
                        this.getMe();
                    } else {
                        this.$root.$refs.pageMan.hideWaiting();
                        this.$root.$refs.pageMan.showAlert('Impossibile effettuare disdetta. Contattare assistenza.');
                    }
                })
                .catch( (errmy) => {
                    this.$root.$refs.pageMan.hideWaiting();
                    console.log(errmy);
                    this.$root.$refs.pageMan.showAlert('Impossibile effettuare disdetta. Contattare assistenza.');
                });            
            }
        },



        _getRagioniDisdetta() {
            if(this.user.iyestoken) {
                axios.post( config.serverUrl + '/epurse/ragionidisdetta', {
                    iyestoken : this.user.iyestoken
                })
                .then( (resmy) => {
                    //console.log('Got ragioni disdetta');
                    //console.log(resmy);
                    if(resmy.data.data.data) {
                        this.subscriptions.disdetta.motiviLista = resmy.data.data.data;
                    } else {
                        this.subscriptions.disdetta.motiviLista = [];
                    }
                })
                .catch( (errmy) => {
                    console.log('WARNING! Empty error getting cancellation motivatiosn');
                    console.log(errmy);
                });    
            }             
        },

        _findDispAbbonamento(testo)  {
            let pos = testo.indexOf('DISP:');
            let disp = 99;
            if(pos != -1) {
                disp = parseFloat( testo.substr(pos + 5, 2).trim() );
            } else {
                disp = 98;
            }
            return disp;
        },

        _findNameAbbonamentoFromUserDoc(testo) {
            let words = testo.split(" ");
            if(words.length > 0) {
                //the word in the Text of user document that contains the abbonamento name (was the first word 0, now it's 4th word)
                return words[3];
            } else {
                return 'SCONOSCIUTO';
            }
        }
    }
}